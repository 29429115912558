import { Instrument, Entity, Transaction } from '@/models';
import { uniq, sortBy } from 'lodash';

export default class TransactionFilters {
  constructor(transactions: Transaction[]) {
    const getOwnerOrInvestor = (transaction: Transaction) => {
      const { owner, investor } = transaction;
      return owner.isInsuranceCompany ? investor : owner;
    };

    this.owners = sortBy(uniq(transactions.map(transaction => transaction.owner)), 'viewName');
    this.ownerOrInvestors = sortBy(uniq(transactions.map(transaction => getOwnerOrInvestor(transaction))), 'viewName');
    this.issuers = sortBy(uniq(transactions.map(transaction => transaction.instrument.entity)), 'viewName');
    this.instruments = sortBy(uniq(transactions.map(transaction => transaction.instrument)), 'name');
  }
  issuers: Entity[];
  instruments: Instrument[];
  owners: Entity[];
  ownerOrInvestors: Entity[];
}
