<template>
  <q-page v-if="ready" class="InvestorBookPage">
    <div class="row justify-between">
      <h1>Investerarbok</h1>
      <LastEntityUpdate />
    </div>
    <SharesInfo
      :shareRegister="shareRegister"
      :readonly="true"
      instrumentsHeading="Värdepapper"
      distributionLabel="Antal/Nominellt belopp"
    />
    <div class="tables-header">
      <q-tabs class="table-toggle" align="left" v-model="tab">
        <q-tab name="investerarbok">Investerarbok</q-tab>
        <q-tab name="full-konvertering">Vid full konvertering</q-tab>
      </q-tabs>
      <ExportLinks>
        <ExportExcel :exporter="investorBookExporter" v-if="ready" />
        <EmailAllButton
          :register="shareRegister"
          :entities="mailableEntities"
          label="Skapa mejl, till alla investeringsansvariga"
        />
      </ExportLinks>
    </div>
    <q-tab-panels v-model="tab">
      <q-tab-panel name="investerarbok">
        <InvestorBookTable :shareRegister="shareRegister" />
      </q-tab-panel>
      <q-tab-panel name="full-konvertering">
        <FullConversionTable :shareRegister="shareRegister" />
      </q-tab-panel>
    </q-tab-panels>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import LastEntityUpdate from '@shared/components/LastEntityUpdate.vue';
import SharesInfo from '@shared/views/shareRegister/SharesInfo.vue';
import InvestorBookTable from '@shared/views/investorBook/InvestorBookTable.vue';
import FullConversionTable from '@shared/views/investorBook/FullConversionTable.vue';
import ExportLinks from '@shared/components/ExportLinks.vue';
import ExportExcel from '@shared/views/excel/ExportExcel.vue';
import EmailAllButton from '@shared/components/EmailAllButton.vue';

import TableSettings from '@shared/web/models/shareRegister/TableSettings';
import { investorBookExporter } from '@shared/excel/shareRegisterExport';
import { findShareRegister } from '@shared/web/models/shareRegister/shareRegisterService';
import { InstrumentCategory, ShareRegister } from '@/models';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { findIssuerInfo } from '@shared/web/models/entity/issuerInfoService';
import { isToNewPage } from '@/utils';
import { Products } from '@shared/index';
@Component({
  components: {
    LastEntityUpdate,
    SharesInfo,
    InvestorBookTable,
    FullConversionTable,
    ExportLinks,
    ExportExcel,
    EmailAllButton,
  },
})
export default class InvestorBookPage extends Vue {
  shareRegister: ShareRegister = null;
  tab = 'investerarbok';
  issuerInfo = null;

  async created() {
    await this.loadData();
  }

  get mailableEntities() {
    return this.shareRegister.positions
      .map(position => {
        return [position.owner, position.investor];
      })
      .flat()
      .filter(entity => !!entity);
  }

  get investorBookExporter() {
    const showFullConversion = this.tab === 'full-konvertering';
    return investorBookExporter(this.shareRegister, this.issuerInfo, new TableSettings(), showFullConversion);
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const entity = await findEntityWithRouteParams(this.$route.params);
    this.shareRegister = await findShareRegister({
      entity: entity,
      instrumentCategory: [InstrumentCategory.DEBT, InstrumentCategory.RIGHTS, InstrumentCategory.SHA],
      instrumentProduct: Products.KAPCLEAR,
    });
    this.issuerInfo = await findIssuerInfo(entity);
  }

  get ready() {
    return this.shareRegister != null;
  }
}
</script>
<style lang="scss">
.InvestorBookPage {
  .tables-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 50px;
  }
  .export-link-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .q-tab-panel {
    padding: 0;
  }
  .q-tab__content {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }
}
</style>
