/* eslint-disable @typescript-eslint/no-explicit-any */
import Model from './Model';
import * as _ from 'lodash';
import { getModel, addModel, addBackup } from '@shared/web/store';
import { serialize } from './serialize';
import { isString } from 'lodash';

export function newModelWithoutInit<C extends typeof Model>(
  data: any,
  M: C,
  updateStore = false,
): InstanceType<typeof M> {
  const model = (updateStore && getModel(data.id, M)) || new M();
  Object.assign(model, data);
  updateStore && addModel(model);
  return model as InstanceType<C>;
}

export function newModel<C extends typeof Model>(data: any, M: C, updateStore = false): InstanceType<typeof M> {
  const model = newModelWithoutInit(data, M, updateStore);
  return initModel(model, updateStore) as InstanceType<C>;
}

function getExistingModel<C extends typeof Model>(id: string, M: C): InstanceType<typeof M> | string {
  const model = getModel(id, M);
  if (!model) {
    return id;
  }
  return model;
}

function initValue(value, def, updateStore) {
  if (def.model) {
    if (def.model instanceof Array && value instanceof Array) {
      const [ValueModel] = def.model;
      return value.map(v => asModel(v, ValueModel, updateStore));
    }
    if (_.isString(value)) {
      return getExistingModel(value, def.model);
    } else if (value instanceof Object) {
      return asModel(value, def.model, updateStore);
    }
  }
  if (def.embeddedModel) {
    if (def.embeddedModel instanceof Array && value instanceof Array) {
      const [EmbeddedModel] = def.embeddedModel;
      return value.map(v => asModel(v, EmbeddedModel, updateStore));
    }
    if (def.embeddedModel.prototype instanceof Model && value instanceof Object) {
      const EmbeddedModel = def.embeddedModel;
      return asModel(value, EmbeddedModel, updateStore);
    }
  }
  if (def.type) {
    if (def.type === 'date' && value != null) {
      return new Date(value);
    }
  }

  return value;
}

export function initModel<T extends Model>(instance: T, updateStore = false): T {
  const M = instance.constructor as typeof Model;

  updateStore && addModel(instance);

  if (M.define) {
    _.forOwn(M.define(), (def, attr) => {
      instance[attr] = initValue(instance[attr], def, updateStore);
    });
  }
  updateStore && addBackup(serialize(instance));
  instance.postInitModel && instance.postInitModel();
  return instance;
}

function asModel(value, M, updateStore) {
  if (value instanceof M) {
    return value;
  }
  if (isString(value)) {
    return getModel(value as string, M);
  }
  return newModel(value, M, updateStore);
}
