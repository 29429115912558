import { Entity } from '@shared/web/models';
import { IssuerInfo } from '@shared/web/models';

import { modelHandler } from '@shared/web/models/base';
import axios from 'axios';
import { isBackOffice } from '@shared/web/utils';
const baseUrl = `/${isBackOffice() ? 'api' : 'webapi'}/issuer-info`;
const { saveModel } = modelHandler(IssuerInfo, baseUrl);

export async function findIssuerInfo(entity: Entity) {
  const { data } = await axios.get(baseUrl + '/entity/' + entity.id);
  return IssuerInfo.newModel(data, true).copy();
}

export async function saveIssuerInfo(info: IssuerInfo): Promise<IssuerInfo> {
  const { model } = await saveModel(info);

  return model;
}
