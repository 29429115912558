import { Model } from '../base';
import { InstrumentSource } from '@shared/web/models';
import IssueShareData from './IssueShareData';
import RightsData from '../instrument/RightsData';
import { ICorporateEvent } from '@shared/models/modelTypes';
export default class CorporateEvent extends Model implements ICorporateEvent {
  instrumentSources: Array<InstrumentSource> = null;
  recordDate: Date = null;
  interimRegistrationDate: Date = null;
  rightsData: RightsData = null;
  issueShareData?: IssueShareData = null;
  splitFactor: number = null;
  splitHappened?: boolean = false;

  static define = () => ({
    instrumentSources: {
      embeddedModel: [InstrumentSource],
      serializeChanges: true,
    },
    recordDate: {
      type: 'date',
    },
    interimRegistrationDate: {
      type: 'date',
    },
    rightsData: {
      embeddedModel: RightsData,
    },
    issueShareData: {
      embeddedModel: IssueShareData,
    },
  });
}
