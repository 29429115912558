import { Transaction, Instrument, Entity } from '@shared/web/models';
import { isTradeEvent } from '@shared/instruction/utils';
import * as _ from 'lodash';

type Holder = Instrument | Entity;

type TransactionGroup = {
  holder: Holder;
  transactions: Array<ExtendedTransaction>;
};

export type ExtendedTransaction = Transaction & { previousOwner?: Entity };

export function getInstrumentTransactionGroups(
  transactions: Array<Transaction>,
  sortProperty: string,
  sortDesc: boolean,
): Array<TransactionGroup> {
  transactions = sortTransactions(transactions, sortProperty, sortDesc);
  const instruments = _.uniq(transactions.map(pos => pos.instrument));
  return groupTransactions(
    transactions,
    instruments,
    instrument => transaction => transaction.instrument === instrument,
  );
}

function groupTransactions(transactions: Array<Transaction>, holders: Array<Holder>, filter) {
  return holders.map(holder => {
    const transactionsFilter = filter(holder);
    return {
      holder: holder,
      transactions: transactions.filter(transactionsFilter),
    };
  });
}

function sortTransactions(
  transactions: Array<Transaction>,
  sortProperty: string,
  sortDesc: boolean,
): Array<Transaction> {
  if (sortProperty == null) {
    return transactions;
  }
  const comparator = getComparator(sortProperty, sortDesc);
  return transactions.slice().sort(comparator);
}

export function getUniqueOwners(transactions: Array<Transaction>) {
  const asEntity = (owner: Entity) => owner;
  return _.uniqBy(
    transactions.map(pos => pos.owner),
    asEntity,
  );
}

function getComparator(property, sortDesc) {
  const comparator = comparators[property];
  return comparator && comparator(sortDesc);
}

const comparators = {
  quantity: sortDesc => (a: Transaction, b: Transaction) => intComparator(a.quantity, b.quantity, sortDesc),
  price: sortDesc => (a: Transaction, b: Transaction) => intComparator(a.price, b.price, sortDesc),
  owner: sortDesc => (a: Transaction, b: Transaction) => stringComparator(a.owner.viewName, b.owner.viewName, sortDesc),
  previousOwner: sortDesc => (a: ExtendedTransaction, b: ExtendedTransaction) => {
    if (a.previousOwner && b.previousOwner)
      return stringComparator(a.previousOwner.viewName, b.previousOwner.viewName, sortDesc);
    if (a.previousOwner && !b.previousOwner) return sortDesc ? 1 : -1;
    if (!a.previousOwner && b.previousOwner) return sortDesc ? -1 : 1;
    return stringComparator(a.owner.viewName, b.owner.viewName, sortDesc);
  },
  settleDate: sortDesc => (a: Transaction, b: Transaction) => dateComparator(a.settleDate, b.settleDate, sortDesc),
  tradeDate: sortDesc => (a: Transaction, b: Transaction) => dateComparator(a.tradeDate, b.tradeDate, sortDesc),

  instrument: sortDesc => (a: Transaction, b: Transaction) =>
    stringComparator(a.instrument.viewName, b.instrument.viewName, sortDesc),
};

function intComparator(a, b, sortDesc) {
  return sortDesc ? b - a : a - b;
}

function dateComparator(a, b, sortDesc) {
  if (a === b) {
    return 0;
  }
  if (sortDesc) {
    return a < b ? 1 : -1;
  } else {
    return a < b ? -1 : 1;
  }
}

function stringComparator(a, b, sortDesc) {
  if (a === b) {
    return 0;
  }
  if (sortDesc) {
    return a < b ? 1 : -1;
  } else {
    return a < b ? -1 : 1;
  }
}

const siblingFinder = transaction => t => t.instruction === transaction.instruction;

const mergeSiblings = siblings => {
  const sourceIndex = siblings.findIndex(t => !t.debit);
  const [source] = siblings.splice(sourceIndex, 1);

  return siblings.filter(s => s.debit).map(sibling => Object.assign(sibling, { previousOwner: source.owner }));
};

export const mergeTransactions = (transactions: ExtendedTransaction[], results: ExtendedTransaction[] = []) => {
  if (transactions.length === 0) return results;
  const [transaction, ...remains] = transactions;
  if (transaction && isTradeEvent(transaction.transactionType) && transaction.instruction != null) {
    let siblings = [transaction];
    const finder = siblingFinder(transaction);

    for (let i = remains.findIndex(finder); i !== -1; i = remains.findIndex(finder)) {
      siblings = siblings.concat(remains.splice(i, 1));
    }

    return mergeTransactions(remains, [...results, ...mergeSiblings(siblings)]);
  }

  return mergeTransactions(remains, [...results, transaction]);
};
