<template>
  <q-page v-if="ready" class="SubmittedIssueWarrantPage">
    <h1>{{ instruction.processTitle }}</h1>
    <Stepper :pageType="instruction.type" :currentstep="4" :isDone="true" :urlId="instruction.id" />
    <div v-if="instruction && instruction.isWarrant" class="q-mt-lg">
      <h4 class="q-my-md">Teckningsoptionsprogrammet är nu inskickat</h4>
      <p class="p2">Vi kommer nu att verifiera teckningsoptionen. Normal väntetid är 1-2 arbetsdagar.</p>
      <p class="p2">Du kommer att bli kontaktad på {{ email }} när emitteringen av teckningsoptionerna är genomförd.</p>
    </div>
    <div v-if="instruction && instruction.isConvertible" class="q-mt-lg">
      <h4 class="q-my-md">Konvertibelprogrammet är nu inskickat</h4>
      <p class="p2">Vi kommer nu att verifiera konvertibeln. Normal väntetid är 1-2 arbetsdagar.</p>
      <p class="p2">Du kommer att bli kontaktad på {{ email }}.</p>
    </div>
    <div v-if="instruction && instruction.isBond" class="q-mt-lg">
      <h4 class="q-my-md">Obligationsprogrammet är nu inskickat</h4>
      <p class="p2">Vi kommer nu att verifiera konvertibeln. Normal väntetid är 1-2 arbetsdagar.</p>
      <p class="p2">Du kommer att bli kontaktad på {{ email }}.</p>
    </div>
    <div v-if="instruction && instruction.isDebenture" class="q-mt-lg">
      <h4 class="q-my-md">Vinstandelslånet är nu inskickat</h4>
      <p class="p2">Vi kommer nu att verifiera bolagshändelsen. Normal väntetid är 1-2 arbetsdagar.</p>
      <p class="p2">Du kommer att bli kontaktad på {{ email }}.</p>
    </div>
    <div v-if="instruction && instruction.isShareholderContribution" class="q-mt-lg">
      <h4 class="q-my-md">Registeringen av aktieägartillskott är nu inskickad.</h4>
      <p class="p2">Vi kommer nu att verifiera bolagshändelsen. Normal väntetid är 1-2 arbetsdagar.</p>
      <p class="p2">Du kommer att bli kontaktad på {{ email }}.</p>
    </div>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { ShareRegister, Instruction, EntityAdmin } from '@/models';
import Stepper from '@/components/Stepper.vue';
import { findInstruction } from '@/models/instruction/instructionService';
import { findCurrentEntityAdmin } from '@/models/entityAdmin/entityAdminService';
import { isToNewPage } from '@/utils';
@Component({
  components: { Stepper },
})
export default class InstructionSubmittedPage extends Vue {
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;
  entityAdmin: EntityAdmin = null;

  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;
    const { shareRegister, instruction } = await findInstruction(id);
    const entityAdmin = await findCurrentEntityAdmin(shareRegister.entity);
    Object.assign(this, { shareRegister, instruction, entityAdmin });
  }

  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }

  get email() {
    return this.entityAdmin?.email;
  }
}
</script>
