<template>
  <div>
    <template v-if="lastUpdate"> Sparat {{ lastUpdate }} </template>
  </div>
</template>

<script lang="ts">
import { store } from '@shared/web/store';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { formatDate } from '@shared/utils';
@Component
export default class LastEntityUpdate extends Vue {
  @Prop() date: Date;

  get lastUpdate() {
    const lastUpdate = this.date !== undefined ? this.date : store.activeEntity?.issuerData?.lastUpdate;
    return lastUpdate && formatDate(lastUpdate, 'yyyy-MM-dd/HH:mm');
  }
}
</script>
