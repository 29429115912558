<template>
  <div v-if="ready" class="RecentTradeEventTable">
    <h4 class="table-header">
      Pågående ägarbyten (för att se tidigare genomförda ägarbyte, se dessa under rubriken
      <router-link :to="`${activeEntity?.baseUrl}/agarbyte`">ägarbyten</router-link>)
    </h4>
    <q-markup-table flat class="standard-table position-table" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Värdepapper</th>
          <th class="text-left">Typ</th>
          <th class="text-left">Säljare</th>
          <th class="text-left">Köpare</th>
          <th class="text-left">Införd i aktieboken</th>
          <th class="text-left">Status</th>
        </tr>
      </thead>
      <tbody v-if="hasOngoingTradeEvents" class="two-row">
        <template v-for="instruction in ongoingTradeEvents" :key="instruction.id">
          <RecentTradeEventRow :instruction="instruction" />
        </template>
      </tbody>
    </q-markup-table>
    <p v-if="!hasOngoingTradeEvents" class="text-small">Det finns inga ägarbyten</p>
  </div>
</template>
<script lang="ts">
import store from '@/store';

import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { Instruction, ShareRegister, Entity, InstructionMainType } from '@/models';
import { findActiveInstructions } from '@/models/instruction/instructionService';

import RecentTradeEventRow from './RecentTradeEventRow.vue';

@Component({
  components: { RecentTradeEventRow },
})
export default class RecentTradeEventTable extends Vue {
  @Prop() entity: Entity;
  instructions: Array<Instruction> = null;
  shareRegister: ShareRegister = null;

  get activeEntity(): Entity {
    return store.activeEntity;
  }

  async created() {
    await this.loadData();
  }

  @Watch('entity')
  async loadData() {
    this.instructions = await findActiveInstructions(this.entity, InstructionMainType.TRADE_EVENT);
  }

  get ongoingTradeEvents() {
    return this.instructions.filter(instruction => {
      return instruction.calculatedState !== 'EXECUTED';
    });
  }

  get hasOngoingTradeEvents() {
    return this.ongoingTradeEvents.length !== 0;
  }

  get ready() {
    return this.instructions != null;
  }
}
</script>
<style lang="scss">
.RecentTradeEventTable {
  max-width: 100%;
  .q-table {
    max-width: 100%;
    width: $col-max;
  }
}
</style>
