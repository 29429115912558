<template>
  <q-page v-if="ready" class="TradeEventsPage">
    <h1>Ägarbyte</h1>
    <q-btn v-if="canEdit" v-bind="$defs.btn" type="button" @click="newTradeEvent">Nytt ägarbyte</q-btn>
    <q-separator />
    <TradeEventsTable
      @destroy="destroy"
      header="Pågående ägarbyten"
      subheader="Ta bort inaktuella registreringar som inte ska genomföras, genom att klicka på krysset"
      :instructions="ongoingTradeEvents"
    />
    <TradeEventsTable @destroy="destroy" header="Genomförda ägarbyten" :instructions="executedTradeEvents" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { Instruction, Entity } from '@/models';
import TradeEventsTable from './TradeEventsTable.vue';
import { findTradeEvents } from '@/models/instruction/instructionService';
import { remove } from '@/utils';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { store } from '@shared/web/store';
import { orderBy, reverse } from 'lodash';

@Component({
  components: { TradeEventsTable },
})
export default class TradeEventsPage extends Vue {
  instructions: Array<Instruction> = null;
  entity: Entity = null;
  async created() {
    const entity = await findEntityWithRouteParams(this.$route.params);
    const instructions = reverse(orderBy(await findTradeEvents({ entity }), 'updatedAt'));

    Object.assign(this, { instructions, entity });
  }

  get ongoingTradeEvents() {
    return this.instructions.filter(instruction => {
      return instruction.calculatedState !== 'EXECUTED';
    });
  }

  get executedTradeEvents() {
    return this.instructions.filter(instruction => {
      return instruction.calculatedState === 'EXECUTED';
    });
  }

  get ready() {
    return this.instructions != null;
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  destroy(instruction) {
    remove(this.instructions, instruction);
  }

  async newTradeEvent() {
    await this.$router.push({ path: `${this.entity.baseUrl}/agarbyte/nytt` });
  }
}
</script>
<style lang="scss">
.TradeEventsPage {
  .step-btn {
    width: 200px;
  }
}
</style>
