<template>
  <tr class="InstrumentSourceRow">
    <td>
      {{ source.instrument.viewName }}
    </td>

    <td class="text-right marked-bg">
      {{ formatNumber(shareData.totalQuantity) }}
    </td>
    <td class="text-right marked-bg">
      {{ formatNumber(shareData.totalCapital) }}
    </td>
    <td class="text-right">
      <a-input-number
        v-bind="$defs.filledInput"
        input-class="text-right new-share"
        v-model="source.quantity"
        :readonly="readonly"
        :fractionDigits="0"
        :allowOnlyNumber="true"
        @update:modelValue="$emit('change')"
      />
    </td>
    <td class="text-right">{{ ratio }}</td>
    <td class="text-right validation" :class="{ valid: sourceValidation.validQuantity }">
      {{ formatNumber(sourceValidation.totalQuantity) }}
    </td>
    <td class="text-right">{{ formatNumber(sourceValidation.totalCapitalForCurrentQuota, 2) }}</td>
    <td class="text-right">
      {{ formatNumber(sourceValidation.totalCapitalDifference, 2) }}
    </td>
  </tr>
</template>

<script lang="ts">
import { Instruction, ShareRegister, InstrumentSource } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({})
export default class InstrumentSourceRow extends Vue {
  @Prop() source: InstrumentSource;
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop({ default: () => false }) readonly: boolean;
  @Prop() validator;

  get sourceValidation() {
    return this.validator.forInstrumentSource(this.source.instrument);
  }

  get shareData() {
    return this.source.instrument.shareData.getHistoricVersion(this.shareRegister.settleDate);
  }

  get ratio() {
    const ratio = this.sourceValidation.ratio;
    if (ratio == null) {
      return null;
    }
    return `1 : ${ratio}`;
  }
}
</script>

<style lang="scss">
.InstrumentSourceRow {
}
</style>
