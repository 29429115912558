import { Model } from '../base';
import { Entity } from '@shared/web/models';

import { IGeneralMeetingParticipation, IOwnerParticipation, IEntity } from '@shared/models/modelTypes';
import { find } from 'lodash';
import { isSameModel } from '@shared/utils';
export class GeneralMeetingParticipation extends Model implements IGeneralMeetingParticipation {
  entity: Entity;
  owners: Array<OwnerParticipation> = [];

  static define = () => ({
    entity: {
      model: Entity,
    },
    owners: {
      embeddedModel: [OwnerParticipation],
      serializeChanges: true,
    },
  });

  getOwnerParticipation(owner: IEntity) {
    const ownerParticipation = find(this.owners, record => isSameModel(record.owner, owner));
    if (ownerParticipation) {
      return ownerParticipation;
    }

    this.owners.push(OwnerParticipation.newModel({ owner }));
    return find(this.owners, record => isSameModel(record.owner, owner));
  }
}

export class OwnerParticipation extends Model implements IOwnerParticipation {
  owner: Entity;
  signed = false;
  participates = false;

  static define = () => ({
    owner: {
      model: Entity,
    },
  });
}
