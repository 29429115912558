/* eslint-disable @typescript-eslint/no-explicit-any */
import Model from './Model';
import * as _ from 'lodash';
import { getBackup } from '@shared/web/store';

function serializedValue(value, def) {
  if (value == null) {
    return value;
  }
  if (def.serialize instanceof Function) {
    return def.serialize(value);
  }

  if (def.model) {
    if (value instanceof Array) {
      return value.map(v => (v instanceof Model ? v.id : v));
    }
    if (value instanceof Model) {
      return value.id;
    }
  }
  if (def.embeddedModel) {
    if (def.embeddedModel instanceof Array) {
      return value.map(v => serialize(v));
    }
    if (def.embeddedModel.prototype instanceof Model) {
      return serialize(value);
    }
  }

  return value;
}

export function serialize<T extends Model>(instance: T) {
  const body: any = { ...instance };

  const M = instance.constructor as typeof Model;
  if (M.define) {
    _.forOwn(M.define(), (def, attr) => {
      if (def.serialize === false) {
        delete body[attr];
      }
      body[attr] = serializedValue(body[attr], def);
    });
  }

  return body;
}

export function serializedChanges<T extends Model>(instance: T, original?: T) {
  const serialized = serialize(instance);
  const backup = original ? serialize(original) : getBackup(instance.id);

  const M = instance.constructor as typeof Model;

  return serializedObjectChanges(serialized, backup, M);
}

function serializedObjectChanges(serialized, backup, M) {
  const serializeAlways = { id: serialized.id };
  const define = (M && M.define && M.define()) || {};
  _.forOwn(serialized, function (val, a) {
    if (_.isEqual(backup[a], serialized[a])) {
      if (define[a]?.serializeAlways) {
        serializeAlways[a] = serialized[a];
      }
      delete serialized[a];
      return;
    }
    if (define[a] && define[a].serializeChanges === true) {
      if (serialized[a] instanceof Array) {
        serialized[a] = serializedArrayChanges(serialized[a], backup[a]);
      } else if (serialized[a] instanceof Object) {
        serialized[a] = serializedObjectChanges(serialized[a], backup[a], define[a] && define[a].embeddedModel);
      }
    }
  });
  if (_.isEmpty(serialized)) {
    return {};
  }
  return { ...serializeAlways, ...serialized };
}

function serializedArrayChanges(instance: Array<any>, original: Array<any>): Array<any> {
  const originalsWithoutId = original && original.filter(el => el.id == null);
  let nextIndexWithoutId = 0;

  function findOriginalWithId(id) {
    return _.find(original, el => el.id === id);
  }

  function findNextOriginalWithoutId() {
    return originalsWithoutId[nextIndexWithoutId++];
  }

  const result = instance.map(element => {
    const originalEl = element.id ? findOriginalWithId(element.id) : findNextOriginalWithoutId();
    return serializedChanges(element, originalEl || {});
  });

  return result.filter(change => !_.isEmpty(change));
}
