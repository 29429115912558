<template>
  <div class="section CompanyOwner">
    <fieldset class="legalEntity">
      <h5>Företag</h5>
      <div class="row q-gutter-x-lg">
        <q-input
          class="col"
          v-model="entity.legalEntity.name"
          :rules="validation.required('Namn')"
          v-bind="$defs.input"
          label="Företagsnamn"
          :readonly="readonly"
        ></q-input>
        <q-input
          class="col"
          v-model="contactInfo.email"
          hint="Frivillig uppgift"
          :rules="validation.email"
          v-bind="$defs.input"
          label="E-post"
          lazy-rules
        ></q-input>
        <q-input
          class="col"
          v-model="contactInfo.phone"
          hint="Frivillig uppgift"
          v-bind="$defs.input"
          label="Telefon"
        ></q-input>
      </div>
      <div class="row q-gutter-x-lg q-pb-md">
        <div class="col" />
        <q-input
          class="col"
          v-bind="$defs.input"
          v-model="contactInfo.firstName"
          hint="Frivillig uppgift"
          label="Förnamn"
        />
        <q-input
          class="col"
          v-bind="$defs.input"
          v-model="contactInfo.surName"
          hint="Frivillig uppgift"
          label="Efternamn"
        />
      </div>
      <div class="row q-gutter-x-lg">
        <SelectCountry
          v-bind="$defs.input"
          :rules="validation.required('Land')"
          class="col"
          v-model="entity.countryCode"
          label="Land"
          :readonly="readonly"
        />
        <q-input
          class="col"
          v-model="entity.nationalId"
          hint="Frivillig uppgift"
          v-bind="$defs.input"
          label="Organisationsnummer"
          :readonly="readonly"
        ></q-input>
        <q-input
          class="col"
          v-model="entity.legalEntity.LEI"
          hint="Frivillig uppgift"
          v-bind="$defs.input"
          label="LEI"
          :readonly="readonly"
        ></q-input>
      </div>
    </fieldset>
    <q-checkbox name="showInvestorManager" v-model="showInvestorManager" label="Visa investeringsansvarig" />
    <fieldset v-if="showInvestorManager" class="investorManager">
      <h5>Investeringsansvarig</h5>
      <div class="row q-gutter-x-lg">
        <SelectCountry
          v-bind="$defs.input"
          class="col"
          v-model="investorManagerCountryCode"
          :rules="validation.required('Land')"
          label="Land"
          :readonly="readonly"
        />
        <div class="col">
          <q-input
            v-if="investorManagerIsReady"
            v-model="entity.legalEntity.investorManager.addresses[0].email"
            hint="Frivillig uppgift"
            :rules="validation.email"
            v-bind="$defs.input"
            label="E-post"
            lazy-rules
          />
        </div>
        <div class="col">
          <q-input
            v-if="investorManagerIsReady"
            v-model="entity.legalEntity.investorManager.addresses[0].phone"
            hint="Frivillig uppgift"
            v-bind="$defs.input"
            label="Telefon"
          />
        </div>
      </div>
      <div class="row q-gutter-x-lg q-pb-md">
        <div class="col">
          <FindEntity v-if="investorManagerIsSE" v-model="entity.legalEntity.investorManager" />
          <q-input
            v-if="!investorManagerIsSE && investorManagerIsReady"
            class="col"
            v-bind="$defs.input"
            v-model="entity.legalEntity.investorManager.nationalId"
            label="Personnummer"
            hint="Frivillig uppgift"
            :readonly="readonly"
          ></q-input>
        </div>
        <div class="col">
          <q-input
            v-if="investorManagerIsReady"
            v-bind="$defs.input"
            v-model="entity.legalEntity.investorManager.person.firstName"
            :rules="validation.required('Förnamn')"
            label="Förnamn"
            :disable="investorManagerIsSE"
          />
        </div>
        <div class="col">
          <q-input
            v-if="investorManagerIsReady"
            v-bind="$defs.input"
            v-model="entity.legalEntity.investorManager.person.surName"
            :rules="validation.required('Efternamn')"
            label="Efternamn"
            :disable="investorManagerIsSE"
          />
        </div>
      </div>
    </fieldset>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import SelectCountry from '@shared/components/SelectCountry.vue';
import { Address, ContactInfo, Entity, Person } from '@shared/web/models';
import { validation } from '@shared/common';
import FindEntity from './FindEntity.vue';

@Component({
  components: { SelectCountry, FindEntity },
})
export default class CompanyOwner extends Vue {
  @Prop() entity: Entity;
  @Prop() contactInfo: ContactInfo;
  @Prop() readonly: boolean;

  showInvestorManager = false;
  investorManagerCountryCode: string = null;

  mounted() {
    this.showInvestorManager = !!this.entity.legalEntity.investorManager;
    this.investorManagerCountryCode = this.entity.legalEntity.investorManager?.countryCode;
  }

  @Watch('entity.legalEntity.investorManager')
  onSetInvestorManager(investorManager) {
    if (investorManager?.addresses?.length === 0) {
      investorManager.addresses.push(Address.newModel({}));
    }
  }

  @Watch('investorManagerCountryCode')
  onSetInvestorManagerCountryCode(countryCode) {
    const { investorManager } = this.entity.legalEntity;
    if (this.investorManagerIsSE) {
      if (investorManager?.countryCode !== 'SE') {
        this.entity.legalEntity.investorManager = null;
      }
    } else {
      if (!investorManager) {
        this.entity.legalEntity.investorManager = Entity.newModel({
          countryCode,
          isPerson: true,
          person: Person.newModel({}),
        });
      } else {
        investorManager.countryCode = countryCode;
      }
    }
  }

  get investorManagerIsSE() {
    return this.investorManagerCountryCode === 'SE';
  }

  get investorManagerIsReady() {
    return !!this.entity.legalEntity?.investorManager?.addresses?.length;
  }

  get validation() {
    return validation;
  }
}
</script>
<style lang="scss">
.CompanyOwner {
  fieldset {
    border: none;
    padding: 0;
    margin: 0;
    &.investorManager {
      margin-top: 1.4em;
    }
  }
}
</style>
