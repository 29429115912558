import CheckboxGroup from './CheckBoxGroup.vue';
import ListItem from '@shared/components/ListItem.vue';
import Select from './Select.vue';
import InputFormatNumber from '@shared/components/NumberInput.vue';
import aBtnLink from '@shared/components/ABtnLink.vue';
import aInfoBtn from '@shared/components/aInfoBtn.vue';
import InputDate from '@shared/components/InputDate.vue';
import { formatPercent, formatNumber } from '@/utils';
import { formatDay } from '@shared/utils';
import { getName } from 'country-list';
import _ from 'lodash';
import { formatNationalId } from '@shared/common';

export default function init(app) {
  app.component('a-checkbox-group', CheckboxGroup);
  app.component('a-list-item', ListItem);
  app.component('a-select', Select);
  app.component('a-input-number', InputFormatNumber);
  app.component('a-btn-link', aBtnLink);
  app.component('a-info-btn', aInfoBtn);
  app.component('a-input-date', InputDate);

  Object.assign(app.config.globalProperties, {
    toBooleanLabel: value => {
      return value ? 'Ja' : 'Nej';
    },
    formatPercent: number => {
      return formatPercent(number);
    },
    formatNumber: (number, min = 0, max = 2) => {
      return formatNumber(number, min, max);
    },
    formatDay: date => {
      return formatDay(date);
    },
    countryName: countryCode => {
      return getName(countryCode);
    },
    onlyProps: attrs => {
      // @vue3 när v-bind="$attrs" används följer även event med, exempelvis change. Det var inte fallet i vue 2, då kom endast attrs med, ej event. Genom att använda denna metod filtreras event bort
      return _.pickBy(attrs, attr => !_.isFunction(attr));
    },
    formatNationalId: entity => {
      return formatNationalId(entity);
    },
  });
}
