<template>
  <q-btn class="uploadbtn q-mt-lg" v-bind="$defs.btn" color="secondary" icon="svguse:/svg-icons.svg#icon-upload"
    >Välj fil</q-btn
  >
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class UploadFileButton extends Vue {}
</script>
<style lang="scss">
.uploadbtn {
  padding: 0px 14px;
  min-height: 24px;
  font-size: 12px;
  border-radius: 12px;
  font-weight: 400;
  .q-btn__content {
    padding-right: 5px;
  }

  .q-btn__wrapper {
    padding: 0;
    min-height: auto;
  }
  .q-icon {
    margin-right: 3px;
    font-size: 14px;
  }
}
</style>
