import { Vue, Component } from 'vue-facing-decorator';

@Component
export default class DraggableComponent extends Vue {
  target = null;
  nodeDragg = null;
  initPosition = true;

  onShow() {
    const dialog = this.$refs.dialog as any;
    this.target = dialog.$el.querySelector('.q-dialog__inner');
    this.nodeDragg = this.target.querySelector('.position-model-header, .cursor-move');
    this.nodeDragg.addEventListener('mousedown', this.onGrab);
  }
  onHide() {
    document.removeEventListener('mousemove', this.onDrag);
    document.removeEventListener('mouseup', this.onLetGo);
    this.nodeDragg.removeEventListener('mousedown', this.onGrab);
    this.$emit('onHide');
  }
  onDrag(e) {
    const targetModal = this.target.firstElementChild;
    const originalStyles = window.getComputedStyle(targetModal);
    targetModal.style.position = 'absolute';
    targetModal.style.left = parseInt(originalStyles.left) + e.movementX + 'px';
    targetModal.style.top = parseInt(originalStyles.top) + e.movementY + 'px';
  }

  onLetGo() {
    document.removeEventListener('mousemove', this.onDrag);
    document.removeEventListener('mouseup', this.onLetGo);
  }

  onGrab() {
    document.addEventListener('mousemove', this.onDrag);
    document.addEventListener('mouseup', this.onLetGo);
  }
}
