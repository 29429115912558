<template>
  <q-option-group
    class="CheckBoxGroup flex"
    type="checkbox"
    emit-value
    map-options
    :options="options"
    size="24px"
    @update:modelValue="$emit('update:modelValue', $event)"
    :modelValue="modelValue"
  />
</template>

<script lang="ts">
import { Component, Vue, Prop, Ref } from 'vue-facing-decorator';

@Component
export default class CheckBoxGroup extends Vue {
  @Prop() options;
  @Prop() modelValue;
  @Prop() rules;
  @Prop() readonly;
  @Ref() readonly input;
  validate() {
    return this.input.validate();
  }
}
</script>
<style lang="scss">
.CheckBoxGroup {
  .q-checkbox__bg {
    border-radius: 0;
    border-width: 1px;
    border-color: $grey-3;
    background: white;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
  }
  .q-checkbox__label {
    padding-left: 4px;
  }
  .q-checkbox[aria-checked='true'] .q-checkbox__bg {
    border-color: $primary;
  }
}
</style>
