import { AxiosInstance } from 'axios';
import { Products } from '..';

export enum PDFType {
  complete = 'complete',
  legal = 'legal',
  transactions = 'transactions',
}

export enum PDFCategory {
  SHA = 'SHA',
  PAYSHA = 'PAYSHA',
  RIGHTS = 'RIGHTS',
  DEBT = 'DEBT',
}

export type PDFProps = {
  type?: PDFType;
  category?: PDFCategory;
  entity?: string;
  custodian?: string;
  instrument?: string;
  settleDate?: string;
  dateType?: string;
  from?: string;
  to?: string;
  instrumentProduct?: Products;
};

export class DownloadPDFService {
  constructor(private readonly axios: AxiosInstance) {}

  /**
   * Hämtar PDF-URL från en angiven resurs-URL.
   * @param url Resurs-URL:en för den genererade PDF:en.(t.ex. BO | WEB)
   * @param headers Valbara headers för GET-begäran.
   * @returns Ett löfte som löser ut till URL:en för PDF:en.
   */
  private async fetchPdfUrl(url: string, headers: Record<string, string>): Promise<string> {
    const response = await this.axios.get(url, { headers });
    return response.data.url;
  }

  /**
   * Laddar ner en PDF från en angiven URL.
   * @param url URL:en för att ladda ned PDF:en från.
   * @param options Konfigurationsalternativ som headers och filnamn.
   */
  public async download(
    url: string,
    options: { headers?: Record<string, string>; filename?: string } = {},
  ): Promise<void> {
    const { headers = {}, filename = 'download' } = options;
    const s3PdfURL = await this.fetchPdfUrl(url, headers);
    await this.downloadPdf(s3PdfURL, filename);
  }

  /**
   * Skapar ett ankarelement för att utlösa en nedladdning.
   * @param objectUrl Objekt-URL:en skapad från blobben.
   * @param filename Filnamnet för den nedladdade filen.
   */
  private createAndTriggerDownloadLink(objectUrl: string, filename: string): void {
    const downloadLink = document.createElement('a');
    downloadLink.href = objectUrl;
    downloadLink.download = `${filename}.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  /**
   * Initierar en nedladdning av en PDF från en URL.
   * @param s3PdfUrl URL:en för PDF:en som ska laddas ner från S3.
   * @param filename Namnet på filen som ska sparas som.
   */
  private async downloadPdf(s3PdfUrl: string, filename: string): Promise<void> {
    const response = await fetch(s3PdfUrl);
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    this.createAndTriggerDownloadLink(objectUrl, filename);

    // Rensa upp objekt-URL:en för att förhindra minnesläckor
    URL.revokeObjectURL(objectUrl);
  }
}
