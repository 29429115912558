import { Model } from '../base/';
import { Entity, Position, Instrument, InstrumentCategory, Instruction, PositionHistory } from '@shared/web/models';
import { getUniqueOwnersAndInvestors, getUniqueOwners } from '@shared/shareRegister/utils';
import { getOwnerSummaries, getPositionGroups } from '@shared/shareRegister/utils';
import TableSettings from './TableSettings';
import { addById } from '@shared/utils';
import { getHistoricIssuerData } from '@shared/models/historicShareData';
import { maxBy } from 'lodash';
export default class ShareRegister extends Model {
  positions: Array<Position> = [];
  entity: Entity;
  instruments: Array<Instrument> = [];
  settleDate: Date = null;
  positionHistoryList: Array<PositionHistory>;
  activeInstructions: Array<Instruction> = [];
  static define = () => ({
    entity: {
      model: Entity,
    },
    instruments: {
      model: [Instrument],
    },
    positions: {
      embeddedModel: [Position],
      serializeChanges: true,
    },
    positionHistoryList: { embeddedModel: [PositionHistory] },
    settleDate: {
      type: 'date',
    },
  });
  static storeModel = false;

  addPosition(position: Position) {
    addById(this.positions, position);
  }

  getUniqueOwners() {
    return getUniqueOwners(this.positions);
  }

  getUniqueOwnersAndInvestors() {
    return getUniqueOwnersAndInvestors(this.positions);
  }
  getPositionGroups(tableSettings: TableSettings) {
    return getPositionGroups(this.positions, this.instruments, tableSettings);
  }

  getOwnerSummaries() {
    return getOwnerSummaries(this);
  }

  get totalQuantity(): number {
    return this.shares.reduce((previousValue, instrument) => previousValue + instrument.shareData.totalQuantity, 0);
  }

  get totalVotes(): number {
    return this.shares.reduce(
      (previousValue, instrument) =>
        previousValue + instrument.shareData.totalQuantity * instrument.shareData.votingPower,
      0,
    );
  }

  get totalCapital(): number {
    return this.shares.reduce((previousValue, instrument) => previousValue + instrument.shareData.totalCapital, 0);
  }
  get quotaValue(): number {
    return this.totalQuantity !== 0 ? this.totalCapital / this.totalQuantity : 0;
  }

  get shares(): Instrument[] {
    return this.instruments.filter(instrument => (instrument.category = InstrumentCategory.SHA));
  }

  get updatedAt(): Date | null {
    if (!this.positions?.length) {
      return null;
    }
    return new Date(maxBy(this.positions, 'settleFrom').settleFrom);
  }

  getHistoricIssuerData() {
    if (this.settleDate == null) {
      return this.entity.issuerData;
    }
    const result = this.entity.issuerData.copy();

    Object.assign(result, getHistoricIssuerData(this.instruments, this.settleDate));
    return result;
  }
}
