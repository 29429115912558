import axios from 'axios';
import { Entity, Instrument, Transaction } from '@/models';

const baseUrl = '/webapi/transaction';

export type TransactionResponse = { entity?: Entity; instruments?: Instrument[]; transactions?: Transaction[] };

export async function findTransactions(searchQuery: {
  entity?: string;
  custodian?: string;
  instrument?: string;
  owner?: string;
  ownerOrInvestor?: string;
  dateType?: 'registrationDate' | 'settleDate' | 'tradeDate';
  from?: string;
  to?: string;
}): Promise<TransactionResponse> {
  const {
    data: { entity, instruments, transactions },
  } = await axios.post(`${baseUrl}/find`, searchQuery);
  return {
    entity: entity && Entity.newModel(entity, true),
    instruments: instruments.length ? instruments.map(instrument => Instrument.newModel(instrument, true)) : [],
    transactions: transactions.length ? transactions.map(transaction => Transaction.newModel(transaction, true)) : [],
  };
}
