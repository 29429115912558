<template>
  <q-page v-if="ready" class="SubmittedTradeEventPage">
    <h1>Ägarbyte</h1>
    <Stepper pageType="tradeEvent" :currentstep="4" :isDone="true" :urlId="instruction.id" />
    <q-separator />
    <h4>Ägarbytet är nu inskickat</h4>
    <p class="p2">
      Vi kommer nu att verifiera ägarbytet. Normal väntetid är 1-2 arbetsdagar. <br />Du kommer att bli kontaktad på
      {{ email }} när ägarbytet är genomfört och aktieboken är uppdaterad.
    </p>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { ShareRegister, Instruction, EntityAdmin } from '@/models';
import Stepper from '@/components/Stepper.vue';
import { findInstruction } from '@/models/instruction/instructionService';
import { findCurrentEntityAdmin } from '@/models/entityAdmin/entityAdminService';
import { isToNewPage } from '@/utils';
@Component({
  components: { Stepper },
})
export default class SubmittedTradeEventPage extends Vue {
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;
  entityAdmin: EntityAdmin = null;
  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;
    const { shareRegister, instruction } = await findInstruction(id);
    const entityAdmin = await findCurrentEntityAdmin(shareRegister.entity);
    Object.assign(this, { shareRegister, instruction, entityAdmin });
  }

  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }

  get email() {
    return this.entityAdmin?.email;
  }
}
</script>
<style lang="scss">
.SubmittedTradeEventPage {
  .p2 {
    max-width: 730px;
  }
}
</style>
