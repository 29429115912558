import { Entity } from '@shared/web/models';
import { Model } from '../base';

export default class PersonRole extends Model {
  type: string;
  legalEntity: Entity;

  static define = () => ({
    legalEntity: {
      model: Entity,
    },
  });
}
