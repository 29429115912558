<template>
  <div class="InstrumentTable">
    <h4 class="table-header">Aktier</h4>
    <q-markup-table flat class="standard-table position-table" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Namn</th>
          <th class="text-left">ID</th>
          <th class="text-left">Aktieslag</th>
          <th class="text-left">Grupp</th>
          <th class="text-right">Aktiekapital</th>
          <th class="text-right">Aktieandel</th>
          <th class="text-right">Antal</th>
          <th class="text-right">Röstandel</th>
        </tr>
      </thead>
      <tbody class="two-row">
        <template v-for="instrument in instruments" :key="instrument.id">
          <InstrumentRow :instrument="instrument" :shareRegister="shareRegister" />
        </template>
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { ShareRegister } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import InstrumentRow from './InstrumentRow.vue';

@Component({
  components: { InstrumentRow },
})
export default class InstrumentTable extends Vue {
  @Prop() shareRegister: ShareRegister;

  get instruments() {
    return this.shareRegister.instruments;
  }
}
</script>
