<template>
  <div class="debenture-details">
    <div class="row q-col-gutter-md items-end">
      <div class="col-12 col-md-4">
        <label>
          Namn på aktieägartillskott
          <q-input
            v-model="rightsData.name"
            :disable="readonly"
            :readonly="readonly"
            v-bind="$defs.input"
            @change="$emit('change')"
          />
        </label>
      </div>
      <div class="col-12 col-md-4">
        <label
          >Underliggande aktie
          <q-select
            :modelValue="rightsData.underlyingInstrumentName"
            :options="underlyingInstrumentOptions"
            use-input
            hide-selected
            fill-input
            :disable="readonly"
            input-debounce="0"
            v-bind="$defs.input"
            @input-value="setUnderlyingInstrument"
          />
        </label>
      </div>
      <div class="col-12 col-md-2">
        <span>Typ</span>
        <q-select
          :modelValue="rightsData.conditional"
          :options="[
            { label: 'Villkorat', value: true },
            { label: 'Ovillkorat', value: false },
          ]"
          map-options
          emit-value
          use-input
          hide-selected
          fill-input
          :disable="readonly"
          input-debounce="0"
          v-bind="$defs.input"
          @update:modelValue="setConditional"
        />
      </div>
      <div class="col-12 col-md-2">
        <span>Registreringsdatum</span>
        <a-input-date
          v-model="rightsData.issueDate"
          v-bind="$defs.input"
          :disable="readonly"
          :readonly="readonly"
          @change="$emit('change')"
        />
      </div>
      <label class="col-12 col-md-9">
        Frivillig tilläggsinformation avseende aktieägartillskottets villkor
        <q-input
          type="text"
          v-model="rightsData.note"
          v-bind="$defs.input"
          :disable="readonly"
          :readonly="readonly"
          @change="$emit('change')"
          @update:modelValue="$emit('input')"
          placeholder="Fritext"
        />
      </label>
      <div class="col-12 col-md-2">
        <div>Totalt belopp</div>
        <div>
          <NumberInput
            readonly
            disable
            v-model="destinationsAmount"
            v-bind="$defs.input"
            class="number"
            input-class="test-nominalAmountTotal"
          />
        </div>
      </div>
      <label class="col-12 col-md-1">
        Valuta
        <SelectCurrency
          v-model="rightsData.currency"
          v-bind="$defs.input"
          :disable="readonly"
          :readonly="readonly"
          @change="$emit('change')"
          @update:modelValue="$emit('input')"
        />
      </label>
    </div>
  </div>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { Instruction, Instrument } from '@shared/web/models';
import SelectCurrency from '@shared/components/SelectCurrency.vue';
import DistributionDetails from '@shared/components/DistributionDetails.vue';
import InputDate from '@shared/components/InputDate.vue';
import NumberInput from '@shared/components/NumberInput.vue';

@Component({
  components: { SelectCurrency, DistributionDetails, 'a-input-date': InputDate, NumberInput },
})
export default class DebentureDetails extends Vue {
  @Prop() instruction: Instruction;
  @Prop() instrument: Instrument;
  @Prop() destinationsAmount: number;
  @Prop({ default: true }) readonly: boolean;

  @Watch('destinationsAmount')
  updateRightsDataAmount(amount) {
    this.rightsData.nominalAmountTotal = amount;
  }

  created() {
    this.updateRightsDataAmount(this.destinationsAmount);
  }

  get rightsData() {
    if (this.instruction) {
      return this.instruction.corporateEvent.rightsData;
    }
    if (this.instrument) {
      return this.instrument.rightsData;
    }
    throw 'Cannot find rightsData';
  }

  get underlyingInstrumentOptions() {
    const shareOptions = (this.instruction.entity?.instruments || [])
      .filter(instrument => instrument.category === 'SHA')
      .map(option => option.viewName);

    if (shareOptions.includes(this.rightsData.underlyingInstrumentName)) {
      return shareOptions;
    } else {
      return [...shareOptions, this.rightsData.underlyingInstrumentName];
    }
  }

  setUnderlyingInstrument(instrumentName) {
    const instrument =
      this.instruction.entity.instruments.find(instrument => instrument.viewName === instrumentName) || null;
    if (this.rightsData.underlyingInstrument !== instrument) {
      this.rightsData.underlyingInstrument = instrument;
      this.rightsData.underlyingInstrumentName = instrumentName;
      this.$emit('change');
    }
  }

  setConditional(conditional) {
    this.rightsData.conditional = conditional;
    this.$emit('change');
  }
}
</script>
