const errors = {
  invalidQuantity: {
    key: 'invalidQuantity',
    text: 'Antal är större än innehavet',
  },
  invalidDestinationQuantity: {
    key: 'invalidDestinationQuantity',
    text: 'Antal stämmer inte överens mellan säljare och köpare',
  },
  invalidDestinationAmount: {
    key: 'invalidDestinationAmount',
    text: 'Belopp stämmer inte överens mellan säljare och köpare',
  },
  noMatchingPosition: {
    key: 'noMatchingPosition',
    text: 'Det finns ingen position som överenstämmer med säljaren',
  },
  multipleMatchingPositions: {
    key: 'multipleMatchingPositions',
    text: 'Det finns flera positioner som överenstämmer med säljaren',
  },
  shareRegisterState: {
    key: 'shareRegisterState',
    text: 'Aktieboken är inte låst',
  },
  tradeDateAfterToday: {
    key: 'tradeDateAfterToday',
    text: 'Affärsdag är ett framtida datum',
  },
  settleDateNull: {
    key: 'settleDateNull',
    text: 'Införd i aktieboken måste anges',
  },
  tradeDateNull: {
    key: 'tradeDateNull',
    text: 'Affärsdag måste anges',
  },
  recordDateNull: {
    key: 'recordDateNull',
    text: 'Avstämningsdag måste anges',
  },
  settleDateAfterToday: {
    key: 'settleDateAfterToday',
    text: 'Införd i aktieboken är ett framtida datum',
  },

  settleDateBeforeTradeDate: {
    key: 'settleDateBeforeTradeDate',
    text: 'Införd i aktieboken är före affärsdag',
  },
  futureSettleDate: {
    key: 'settleDateBeforeTradeDate',
    text: 'Införd i aktieboken får inte vara ett framtida datum',
  },
  settleDateBeforeRecordDate: {
    key: 'settleDateBeforeRecordDate',
    text: 'Införd i aktieboken får inte vara före avstämningsdag',
  },
  settleDateNotAfterInterimResgistrationDate: {
    key: 'settleDateNotAfterInterimResgistrationDate',
    text: 'Införd i aktieboken måste vara efter BTA registreringsdatum',
  },
  settleDateBeforeShareRegisterCreated: {
    key: 'settleDateBeforeShareRegisterCreated',
    text: 'Införd i aktieboken måste vara efter att aktieboken skapades',
  },
  invalidInstrumentSourceQuantity: {
    key: 'invalidInstrumentSourceQuantity',
    text: 'Alla aktier är inte tecknade',
  },
  overlap: {
    key: 'numberserie.overlap',
    text: 'Nummerserien är överlappande',
  },
  missingShareNumbers: {
    key: 'numberserie.missingShareNumbers',
    text: 'Aktienummer saknas',
  },
  invalidShareNumbers: {
    key: 'numberserie.invalidShareNumbers',
    text: 'Aktienummer är felaktiga',
  },
  inValidPrice: {
    key: 'numberserie.invalidprice',
    text: 'Teckningskurs får inte vara lägre än kvotvärde',
  },
  invalidCount: {
    key: 'numberserie.invalidCount',
    text: 'Nummerserien stämmer inte överens med antal aktier',
  },
  duplicatePosition: {
    key: 'duplicatePosition',
    text: 'Det finns redan en position med samma ägare / investerare',
  },
  invalidSplitFactor: {
    key: 'invalidSplitFactor',
    text: 'Splitfaktorn är felaktig',
  },
  unknownError: {
    key: 'unknownError',
    text: 'Det finns fel i aktieboken',
  },
  invalidShares: {
    key: 'invalidSharesError',
    text: 'Validering av instrument misslyckades',
  },
  invalidPositions: {
    key: 'invalidPositionsError',
    text: 'Validering av positioner misslyckades',
  },
  failedEntityValidation: {
    key: 'failedEntityValidationError',
    text: 'Validering mot bolagsinformation misslyckades',
  },
};

export function validationError(text, path?) {
  return {
    key: 'validationError',
    text,
    path,
  };
}

export default errors;
