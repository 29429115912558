<template>
  <q-page v-if="ready" class="SplitPage">
    <h1>Split</h1>
    <Stepper pageType="rightsIssue" :currentstep="1" :isDone="isDone" :urlId="instruction.id" v-if="isEditable" />
    <q-separator />

    <h4 class="table-header">Skapa split</h4>
    <q-form class="" ref="form">
      <TransactionsDetails
        :shareRegister="shareRegister"
        :instruction="instruction"
        @change="save"
        :readonly="!isEditable || !canEdit"
        :validator="validator"
      />
      <div v-if="instruction.warning" class="warning-text q-mt-lg">
        <p class="q-pl-md p2">Det finns pågående ägarbyten med införd i aktieboken efter avstämningsdagen.</p>
      </div>
      <InstrumentSourceTable
        :shareRegister="shareRegister"
        :instruction="instruction"
        :validator="validator"
        :readonly="!isEditable || !canEdit"
        @change="save"
      />

      <InstructionSaveRow
        v-if="isEditable"
        :disable="!isDone || !canEdit"
        :is-saving="isSaving"
        :has-unsaved-changes="isSaving"
        @proceed="proceed"
      />
    </q-form>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch, Ref } from 'vue-facing-decorator';
import { ShareRegister, Instruction } from '@/models';
import Stepper from '@/components/Stepper.vue';
import PageBanner from '@/components/PageBanner.vue';
import TransactionsDetails from './TransactionsDetails.vue';
import InstrumentSourceTable from './InstrumentSourceTable.vue';
import { makeShareRegisterValidation } from '@shared/web/models/shareRegister/shareRegisterService';
import { saveInstruction, makeInstructionValidator, findInstruction } from '@/models/instruction/instructionService';
import { store } from '@shared/web/store';
import InstructionSaveRow from '@/components/InstructionSaveRow.vue';
import { isToNewPage } from '@/utils';
import { debounce } from 'lodash';

@Component({
  components: {
    InstructionSaveRow,
    Stepper,
    PageBanner,
    TransactionsDetails,
    InstrumentSourceTable,
  },
})
export default class SplitPage extends Vue {
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;
  isSaving = false;
  debouncedSave: () => Promise<void> = null;

  @Ref() readonly form;

  async created() {
    this.debouncedSave = debounce(this._save, 500);
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;
    const { shareRegister, instruction } = await findInstruction(id);

    Object.assign(this, { shareRegister, instruction });
  }

  get isEditable() {
    return this.instruction.isEditable;
  }
  private async _save() {
    this.isSaving = true;
    await saveInstruction(this.instruction);
    this.isSaving = false;
  }

  async save() {
    this.debouncedSave();
  }

  get validator() {
    return this.ready && makeInstructionValidator(this.instruction, this.shareRegister);
  }

  get shareRegisterValidation() {
    return makeShareRegisterValidation(this.shareRegister);
  }

  get isDone() {
    return this.validator.isValid();
  }

  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  clear(instruction) {
    this.instruction = instruction;
  }

  async proceed() {
    const valid = await this.form.validate();
    if (!valid) {
      return false;
    }
    this.$router.push({
      path: `${this.instruction.entity.baseUrl}/split/${this.instruction.id}/dokument`,
    });
  }
}
</script>
<style lang="scss">
.SplitPage {
  .warning-text {
    height: 72px;
    background-color: white;
    p {
      line-height: 72px;
    }
  }
  .table-header {
    display: flex;
    justify-content: space-between;
    align-content: flex-end;
    h4 {
      margin-block-end: 6px;
    }
  }
}
</style>
