import { IEntity, IInstruction, IInstructionParty, IInstrument, IInstrumentSource, IPosition } from '../modelTypes';
import { ApplicationError, InstructionState, InstrumentCategory, InstrumentGroup, TransactionType } from '../types';
import { asDay, asModelId, currentDate } from '../../utils';
import { differenceInDays, isBefore, isAfter } from 'date-fns';
import * as _ from 'lodash';
import shareRegisterValidator from './shareRegisterValidator';
import { getHistoricIssuerData, getHistoricShareData } from '../historicShareData';
import { default as errors, validationError } from './errors';
import { isTradeEvent } from '../../instruction/utils';

type InstructionValidatorParams = {
  instruction: IInstruction;
  entity: IEntity;
  instruments: Array<IInstrument>;
  positions: Array<IPosition>;
  settleDate?: Date;
  activeInstructions?: Array<IInstruction>;
  isSameModel: <Type>(a: Type, b: Type) => boolean;
};

export default function instructionValidator({
  instruction,
  entity,
  instruments,
  positions,
  settleDate,
  activeInstructions,
  isSameModel = (a, b) => a === b,
}: InstructionValidatorParams) {
  settleDate = settleDate || instruction.settleDate;

  const { tradeEvent, corporateEvent } = instruction;

  function invalidSourcePositionSpec() {
    return !instruction.tradeEvent?.sourcePositions?.every(({ position, quantity, amount }) => {
      if (position.quantity) {
        return quantity >= 0 && quantity <= position.quantity;
      }
      return amount >= 0 && amount <= position.amount;
    })
      ? validationError('Felaktigt definierat antal/summa för källposition')
      : null;
  }

  function invalidDestinationQuantity() {
    if (getDestinationQuantity() !== tradeEvent.source.quantity) {
      return errors.invalidDestinationQuantity;
    }
  }

  function invalidDestinationAmount() {
    if (getDestinationAmount() !== tradeEvent.source.amount) {
      return errors.invalidDestinationAmount;
    }
  }

  function getDestinationQuantity() {
    return _.sumBy(instruction.destinations, destination => destination.quantity);
  }

  function getDestinationAmount() {
    return _.sumBy(instruction.destinations, destination => destination.amount);
  }

  function dayIsAfter(d1, d2) {
    return differenceInDays(d1, d2) > 0;
  }

  function invalidSettleDate() {
    const { settleDate, state } = instruction;

    if (settleDate == null) {
      return errors.settleDateNull;
    }

    if ([TransactionType.RIGHTS_ISSUE, TransactionType.ISSUE_SHARE].includes(instruction.type)) {
      if (differenceInDays(corporateEvent.recordDate, settleDate) > 0) {
        return errors.settleDateBeforeRecordDate;
      }
      if (dayIsAfter(instruction.settleDate, asDay(new Date()))) {
        return errors.futureSettleDate;
      }
      if (state === InstructionState.EXECUTED && isBefore(settleDate, corporateEvent.interimRegistrationDate)) {
        return errors.settleDateNotAfterInterimResgistrationDate;
      }
    }

    if (isTradeEvent(instruction.type)) {
      if (dayIsAfter(tradeEvent.tradeDate, settleDate)) {
        return errors.settleDateBeforeTradeDate;
      }

      if (
        tradeEvent.source.instrument.group === InstrumentGroup.WARRANT &&
        isAfter(settleDate, tradeEvent.source.instrument.rightsData?.dueDate)
      ) {
        return validationError('Teckningsoptionen har förfallit');
      }
    }
  }

  function invalidTradeEventDate() {
    const { tradeDate } = tradeEvent;
    const now = currentDate();

    if (tradeDate == null) {
      return errors.tradeDateNull;
    }
    if (dayIsAfter(tradeDate, now)) {
      return errors.tradeDateAfterToday;
    }
  }

  function invalidCorporateEventDates() {
    if (instruction.corporateEvent.recordDate == null) {
      return errors.recordDateNull;
    }
  }

  function matchingPosition() {
    const positions = tradeEvent.sourcePositions
      .map(sourcePosition => sourcePosition.position)
      .filter(pos => pos != null);
    if (positions.length === 0) {
      return errors.noMatchingPosition;
    }
    if (positions.length > 1 && !entity.issuerData.hasShareNumbers) {
      return errors.multipleMatchingPositions;
    }
    const totalQuantity = _.sumBy(positions, pos => pos.quantity);
    if (tradeEvent.source.quantity > totalQuantity) {
      return errors.invalidQuantity;
    }
  }

  function shareRegisterState() {
    const { shareRegisterSettleDate, shareRegisterState } = entity.issuerData;
    if (shareRegisterState !== InstructionState.EXECUTED) {
      return errors.shareRegisterState;
    }
    // When shareRegisterSettleDate is populated for all existing issuers, the check if shareRegisterSettleDate is defined can be removed
    if (shareRegisterSettleDate && isBefore(settleDate, shareRegisterSettleDate)) {
      return errors.settleDateBeforeShareRegisterCreated;
    }
  }

  function getUniqueParties(): Array<IInstructionParty> {
    const uniquePositions = _.uniqWith(positions, isSameParty);
    return uniquePositions;
  }

  function isSameParty(party1: IInstructionParty, party2: IInstructionParty) {
    return (
      isSameModel(party1.instrument, party2.instrument) &&
      isSameModel(party1.owner, party2.owner) &&
      isSameModel(party1.investor, party2.investor) &&
      isSameModel(party1.insuranceNumber, party2.insuranceNumber) &&
      isSameModel(party1.custodian, party2.custodian) &&
      isSameModel(party1.custodianAccountNumber, party2.custodianAccountNumber)
    );
  }

  function forParty(party: IInstructionParty) {
    const partyPositions = getMatchingPositions(party);
    return {
      totalQuantity: _.sumBy(partyPositions, pos => pos.quantity),
    };
  }

  function quotaValue({ totalCapital, totalQuantity }: { totalCapital?: number; totalQuantity?: number }) {
    if (!totalQuantity) {
      return 0;
    }
    return totalCapital / totalQuantity;
  }

  function inValidPrice() {
    if (entity.issuerData.quotaValue > Number(instruction.price)) {
      return errors.inValidPrice;
    }
  }

  function invalidExercisePrice() {
    if (entity.issuerData.quotaValue > Number(instruction.corporateEvent.rightsData.exercisePrice)) {
      return errors.inValidPrice;
    }
  }

  function getSourceQuantity(source: IInstrumentSource) {
    return source.quantity != null ? source.quantity : source.instrument.shareData.totalQuantity;
  }

  function forSplitInstrumentSource(sourceInstrument: IInstrument) {
    const instrument = _.find(instruments, instrument => isSameModel(instrument, sourceInstrument));
    const shareData = getHistoricShareData(instrument.shareData, settleDate);
    const splitFactor = instruction.corporateEvent.splitFactor;
    const validSplitFactor = splitFactor == null || splitFactor > 1;
    const newTotalQuantity = shareData.totalQuantity * splitFactor;
    return {
      splitFactor,
      newTotalQuantity,
      addedQuantity: (splitFactor - 1) * shareData.totalQuantity,
      newQuoteValue: shareData.totalCapital / newTotalQuantity,
      error: !validSplitFactor ? errors.invalidSplitFactor : null,
    };
  }

  function forInstrumentSource(sourceInstrument: IInstrument) {
    const instrument = _.find(instruments, instrument => isSameModel(instrument, sourceInstrument));
    const shareData = getHistoricShareData(instrument.shareData, settleDate);
    const source = _.find(instruction.corporateEvent.instrumentSources, source =>
      isSameModel(source.instrument, instrument),
    );
    const sourceQuantity = getSourceQuantity(source);
    const destinations = instruction.destinations.filter(dest => isSameModel(dest.instrument, instrument));
    const totalQuantity = _.sumBy(destinations, dest => dest.quantity);
    const validQuantity = totalQuantity === sourceQuantity;

    const totalCapitalForCurrentQuota = totalQuantity * quotaValue(shareData);
    const totalCapital = totalQuantity * instruction.price;

    return {
      ratio: sourceQuantity > 0 ? shareData.totalQuantity / sourceQuantity : null,
      totalQuantity,
      validQuantity: totalQuantity === sourceQuantity,
      error: !validQuantity ? errors.invalidInstrumentSourceQuantity : null,
      totalCapital,
      totalCapitalForCurrentQuota,
      totalCapitalDifference: totalCapital - totalCapitalForCurrentQuota,
    };
  }

  function instrumentSourcesError(): ApplicationError {
    const error = instruction.corporateEvent.instrumentSources.reduce((error, source) => {
      return error || forInstrumentSource(source.instrument).error;
    }, null) as ApplicationError;
    return error;
  }

  function splitInstrumentSourcesError(): ApplicationError {
    const error = instruction.corporateEvent.instrumentSources.reduce((error, source) => {
      return error || forSplitInstrumentSource(source.instrument).error;
    }, null) as ApplicationError;
    return error;
  }

  function forEntity() {
    const totalQuantity = _.sumBy(instruction.corporateEvent.instrumentSources, source => source.quantity);
    const totalCapital = totalQuantity * instruction.price;
    const issuerData = getHistoricIssuerData(instruments, settleDate);
    const totalCapitalForCurrentQuota = totalQuantity * quotaValue(issuerData);
    return {
      totalCapital,
      totalQuantity,
      totalCapitalForCurrentQuota,
      totalCapitalDifference: totalCapital - totalCapitalForCurrentQuota,
    };
  }

  function getMatchingPositions(party: IInstructionParty): Array<IPosition> {
    return _.filter(positions, pos => isSameParty(pos, party));
  }
  function getPartlyMatchingPositions(query: Partial<IInstructionParty>): Array<IPosition> {
    return _.filter(positions, pos => {
      return (
        (query.instrument === undefined || isSameModel(query.instrument, pos.instrument)) &&
        (query.owner === undefined || isSameModel(query.owner, pos.owner)) &&
        (query.investor === undefined || isSameModel(query.investor, pos.investor)) &&
        (query.custodian === undefined || isSameModel(query.custodian, pos.custodian)) &&
        (query.insuranceNumber === undefined || isSameModel(query.insuranceNumber, pos.insuranceNumber)) &&
        (query.custodianAccountNumber === undefined ||
          isSameModel(query.custodianAccountNumber, pos.custodianAccountNumber))
      );
    });
  }

  function invalidWarrant() {
    const {
      dueDate,
      issueDate,
      exercisePeriodFrom,
      exercisePeriodTo,
      premium,
      contractSize,
      exercisePrice,
      underlyingInstrument,
      totalQuantity,
    } = instruction.corporateEvent.rightsData;

    if (issueDate == null) {
      return validationError('Utgivningsdatum saknas', 'issueDate');
    }
    if (dueDate == null) {
      return validationError('Slutdag saknas', 'dueDate');
    }
    if (exercisePeriodFrom == null || exercisePeriodTo == null) {
      return validationError('Teckningsperiod saknas', 'exercisePeriodFrom');
    }
    if (premium == null) {
      return validationError('Pris saknas', 'premium');
    }
    if (contractSize == null) {
      return validationError('Antal underliggande saknas', 'contractSize');
    }
    if (exercisePrice == null) {
      return validationError('Teckningskurs saknas', 'exercisePrice');
    }
    const invalidExercisePriceError = invalidExercisePrice();
    if (invalidExercisePriceError) {
      return validationError(invalidExercisePriceError.text, 'exercisePrice');
    }
    if (underlyingInstrument == null) {
      return validationError('Underliggande instrument saknas', 'underlyingInstrument');
    }

    if (isBefore(exercisePeriodFrom, issueDate)) {
      return validationError('Teckningsperiod startar före utgivningsår', 'exercisePeriodFrom');
    }
    if (isBefore(exercisePeriodTo, exercisePeriodFrom)) {
      return validationError('Teckningsperioden är felaktig', 'exercisePeriodFrom');
    }
    if (isBefore(dueDate, exercisePeriodTo)) {
      return validationError('Slutdag är före teckningsperiod', 'dueDate');
    }

    const destinationQuantity = _.sumBy(instruction.destinations, dest => dest.quantity);
    if (totalQuantity !== destinationQuantity) {
      return validationError('Totalt antal stämmer inte med antal utgivna optioner', 'totalQuantity');
    }
  }

  function findLastShareNumber() {
    const instrumentPositions = positions.filter(pos => pos.instrument.category === InstrumentCategory.SHA);
    const max = _.maxBy(instrumentPositions, pos => pos.shareNumberTo);
    return (max && max.shareNumberTo) || 0;
  }

  function futureExecutedTradeEvents() {
    const { settleDate } = instruction;
    let executedFutureEvents = 0;
    for (const { interimShare } of instruction.corporateEvent.instrumentSources) {
      if (interimShare) {
        const tradeEventInstruction = activeInstructions.find(currentInstruction => {
          const instrument = currentInstruction.tradeEvent?.source?.instrument;
          return (
            instrument &&
            isTradeEvent(currentInstruction.type) &&
            currentInstruction.state === InstructionState.EXECUTED &&
            asModelId(instrument).toString('utf8') === asModelId(interimShare).toString('utf8') &&
            dayIsAfter(currentInstruction.settleDate, settleDate)
          );
        });
        if (tradeEventInstruction) {
          executedFutureEvents += 1;
        }
      }
    }
    if (executedFutureEvents) {
      return validationError(
        `Det finns ${executedFutureEvents} affärer genomförda efter valt datum för införd i aktieboken`,
        'futureExecutedTradeEvents',
      );
    }
  }

  function pendingTradeEvents() {
    let pendingEvents = 0;
    for (const { interimShare } of instruction.corporateEvent.instrumentSources) {
      if (interimShare) {
        const tradeEventInstruction = activeInstructions.find(currentInstruction => {
          const instrument = currentInstruction.tradeEvent?.source?.instrument;
          return (
            instrument &&
            isTradeEvent(currentInstruction.type) &&
            [InstructionState.SUBMITTED, InstructionState.REVIEW, InstructionState.REQUEST_CHANGE].includes(
              currentInstruction.state,
            ) &&
            asModelId(instrument).toString('utf8') === asModelId(interimShare).toString('utf8')
          );
        });
        if (tradeEventInstruction) {
          pendingEvents += 1;
        }
      }
    }
    if (pendingEvents) {
      return validationError(`Det finns ${pendingEvents} pågående ägarbyten för instrumentet`, 'pendingTradeEvents');
    }
  }

  function error(): ApplicationError {
    const { type } = instruction;

    if (type === TransactionType.RIGHTS_ISSUE) {
      return (
        invalidCorporateEventDates() ||
        instrumentSourcesError() ||
        shareRegisterState() ||
        invalidSettleDate() ||
        futureExecutedTradeEvents() ||
        pendingTradeEvents()
      );
    }

    if (type === TransactionType.ISSUE_SHARE) {
      if (instruction.state === InstructionState.EXECUTED) {
        if (!instruction.corporateEvent.issueShareData.underlyingInstrument) {
          return validationError('Underliggande instrument saknas', 'missingUnderlyingInstrument');
        }
      }

      return (
        invalidCorporateEventDates() ||
        shareRegisterState() ||
        invalidSettleDate() ||
        futureExecutedTradeEvents() ||
        pendingTradeEvents()
      );
    }
    if (type === TransactionType.SHAREREGISTER_CREATED) {
      const validator = shareRegisterValidator(
        entity,
        instruments,
        positions,
        isSameModel,
        settleDate,
        instruction.skipIssuerDataValidation,
      );
      return validator.error();
    }

    if (type === TransactionType.ISSUE_WARRANT) {
      return invalidWarrant();
    }

    if (type === TransactionType.SPLIT) {
      return (
        invalidCorporateEventDates() || splitInstrumentSourcesError() || shareRegisterState() || invalidSettleDate()
      );
    }

    if (type === TransactionType.EXERCISE_WARRANT) {
      return (
        invalidCorporateEventDates() ||
        shareRegisterState() ||
        invalidSettleDate() ||
        futureExecutedTradeEvents() ||
        pendingTradeEvents()
      );
      return;
    }

    if (
      [
        TransactionType.ISSUE_CONVERTIBLE,
        TransactionType.EXERCISE_CONVERTIBLE,
        TransactionType.ISSUE_BOND,
        TransactionType.EXERCISE_BOND,
        TransactionType.ISSUE_DEBENTURE,
        TransactionType.EXERCISE_DEBENTURE,
        TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION,
      ].includes(type)
    ) {
      return;
    }

    if (isTradeEvent(type)) {
      const isShare = instruction.tradeEvent.source.instrument.category === InstrumentCategory.SHA;
      const invalidAmountOrQuantity = isDistributedByValue()
        ? invalidDestinationAmount()
        : invalidDestinationQuantity();
      return (
        (isShare && shareRegisterState()) ||
        matchingPosition() ||
        invalidAmountOrQuantity ||
        invalidTradeEventDate() ||
        invalidSettleDate() ||
        invalidSourcePositionSpec()
      );
    }
  }

  function preValidRightsIssue() {
    const sumSourceQuantity = _.sumBy(instruction.corporateEvent.instrumentSources, element => element.quantity);
    const destinationQuantity = instruments.map(instrument => forInstrumentSource(instrument));
    const validDestinationQuantity = destinationQuantity.every(element => element.validQuantity);
    return sumSourceQuantity > 0 && validDestinationQuantity;
  }

  function preValidPledge(): boolean {
    return allBuyersSameAsSeller() && allDestinationsHasPledgeBuyer();
  }

  function allBuyersSameAsSeller(): boolean {
    return instruction.destinations
      .map(destination => {
        return destination.owner.id;
      })
      .every(id => id === instruction.tradeEvent.source.owner.id);
  }

  function allDestinationsHasPledgeBuyer(): boolean {
    return instruction.destinations.every(destination => {
      return destination.pledgeOwner;
    });
  }

  function isDistributedByValue() {
    if (isTradeEvent(instruction.type)) {
      return instruction?.tradeEvent?.source?.instrument?.rightsData?.distributeByValue === true;
    }
    return instruction?.corporateEvent?.rightsData?.distributeByValue === true;
  }

  function isPreValid() {
    if (instruction.type === TransactionType.RIGHTS_ISSUE) {
      return preValidRightsIssue();
    }

    if (isTradeEvent(instruction.type)) {
      if (isDistributedByValue()) {
        return !(matchingPosition() || invalidDestinationAmount() || invalidSourcePositionSpec());
      } else {
        return !(matchingPosition() || invalidDestinationQuantity() || invalidSourcePositionSpec());
      }
    }

    if (instruction.type === TransactionType.PLEDGE) {
      return preValidPledge();
    }

    return true;
  }

  function isValid() {
    return !error();
  }

  return {
    error,
    isValid,
    getMatchingPositions,
    getPartlyMatchingPositions,
    getUniqueParties,
    forParty,
    forInstrumentSource,
    forSplitInstrumentSource,
    forEntity,
    findLastShareNumber,
    getDestinationQuantity,
    getDestinationAmount,
    invalidCorporateEventDates,
    isPreValid,
    invalidTradeEventDate,
    invalidSettleDate,
    inValidPrice,
    allBuyersSameAsSeller,
    allDestinationsHasPledgeBuyer,
  };
}
