<template>
  <OnboardingPage :wide="true">
    <div class="onboarding-box flex flex-center q-pa-lg">
      <div v-if="loginFailed" class="text-center">
        <h2>Inloggning misslyckades</h2>
        <div>Kontakta kundservice om du behöver hjälp</div>
      </div>
      <div v-if="loginInProgress" class="flex justify-center text-h1">Loggar in...</div>
      <div v-else-if="urlHasEmailLoginToken && !loginInProgress" class="text-center">
        <div v-if="emailTokenValid === null">
          <h2>Länken kontrolleras... <q-spinner size="24" color="primary" /></h2>
        </div>
        <div v-if="emailTokenValid === true">
          <h2 class="q-mb-md">Välkommen tillbaka</h2>
          <q-btn label="Fortsätt till Kapclear &rarr;" @click="loginWithTokenIfPossible" />
        </div>
        <div v-if="emailTokenValid === false">
          <h2>Länken är ogiltig</h2>
          <div>Kontakta kundservice om du behöver hjälp</div>
        </div>
      </div>
      <div class="header" v-else>
        <a-btn-link
          v-on:click="closeLogin"
          icon-color="black"
          :rounded="true"
          class="goback arrow icon-left q-mt-xl q-pt-md"
          size="lg"
          v-if="!isLoginPage"
          aria-label="Gå tillbaka till startsida"
        ></a-btn-link>
        <h2 class="text-black q-mt-lg q-mb-lg text-center">{{ queryText }} hos Kapclear</h2>
      </div>
      <div class="wrapper" :style="!isLoginPage ? 'display: flex; flex-direction: column; align-items: center;' : ''">
        <LogInBankID @done="onLogin" />
        <div class="q-my-auto">
          <div v-if="tokenSentSuccess">
            <b>Inloggningslänken har skickats!</b><br />
            Kolla din skräppost om du inte hittar mejlet i din inbox.
          </div>
          <div v-if="isLoginPage" class="email-login-wrapper q–w-full">
            <q-form @submit="sendLoginLink" class="q-form">
              <div class="top">
                <p>
                  Inloggning med epost är endast tillgänglig för personer utan bank-id som har blivit registrerade av
                  emittenten för epost-inlogg. För epost-inloggning kontakta emittenten.
                </p>
                <q-input
                  label="Din e-postadress"
                  v-bind="$defs.input"
                  v-model="email"
                  :rules="validation.requiredEmail"
                  lazy-rules
                  class="email-input test-email-input"
                ></q-input>
              </div>
              <q-btn
                :loading="sendingToken"
                label="Skicka inloggningslänk"
                class="email-submit-btn q-mt-sm test-email-submit-btn"
                type="submit"
              />
            </q-form>
            <div v-if="!sendingToken && tokenSentFailed" class="q-mt-md">
              <b>E-postanvändaren hittades inte.</b><br />
              Kontakta emittenten för att få tillgång via e-post eller logga in med bank-id.
            </div>
          </div>
        </div>
      </div>
    </div>
  </OnboardingPage>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import LogInBankID from './auth/LogInBankID.vue';
import { loadProfile } from '@/models/profileService';
import { findEntityAdminsForUser } from '@/models/entityAdmin/entityAdminService';
import OnboardingPage from '@/views/OnboardingPage.vue';
import { Intent } from '@/router/types';
import axios from 'axios';
import { setToken } from '@shared/web/authentication';
import { validation } from '@shared/common';

@Component({
  components: {
    LogInBankID,
    OnboardingPage,
  },
})
export default class Home extends Vue {
  mobileBankIdMethod = true;
  email = '';
  sendingToken = false;
  tokenSentSuccess = false;
  tokenSentFailed = false;
  emailTokenValid = null;
  loginInProgress = false;
  loginFailed = false;

  get validation() {
    return validation;
  }

  get isLoginPage() {
    return this.$route.name === 'Login' ? true : false;
  }

  get queryText() {
    return this.$route.meta ? this.$route.meta.title : 'Bli kund';
  }

  get queryParams() {
    return Object.fromEntries(new URLSearchParams(window.location.search));
  }

  get urlHasBoLoginToken() {
    return this.queryParams.uid && this.queryParams.token;
  }

  get urlHasEmailLoginToken() {
    return this.queryParams.uid && this.queryParams.emailToken;
  }

  get returnTo() {
    return this.$route.query?.tillbakaTill;
  }

  async loginWithTokenIfPossible() {
    if (!this.urlHasBoLoginToken && !this.urlHasEmailLoginToken) {
      return;
    }
    this.loginFailed = false;
    this.loginInProgress = true;
    const { uid } = this.queryParams;
    const token = this.urlHasBoLoginToken ? this.queryParams.token : this.queryParams.emailToken;
    const jwtUrl = `/webapi/auth/${this.urlHasBoLoginToken ? 'bo' : 'email-token'}-login/${uid}/${token}`;
    try {
      const { data: jwt } = await axios.get(jwtUrl);
      setToken(jwt);
      await new Promise(resolve => setTimeout(resolve, 1000));
      await this.onLogin();
    } catch (e) {
      this.loginFailed = true;
    } finally {
      this.loginInProgress = false;
    }
  }

  async mounted() {
    this.isLoginPage;
    if (this.urlHasBoLoginToken) {
      return this.loginWithTokenIfPossible();
    }
    if (this.urlHasEmailLoginToken) {
      const { uid, emailToken } = this.queryParams;
      axios
        .get(`/webapi/auth/email-token-validation/${uid}/${emailToken}`)
        .then(() => (this.emailTokenValid = true))
        .catch(() => (this.emailTokenValid = false));
    }
  }

  closeLogin() {
    this.$router.push({ path: '/' });
  }

  async onLogin() {
    const { intent: currentIntent } = this.$route.meta;
    await loadProfile();
    const entityAdmins = await findEntityAdminsForUser();
    const returnTo = this.returnTo;
    if (typeof returnTo === 'string' && returnTo) {
      await this.$router.push({ path: returnTo });
    } else if (entityAdmins.length === 0 && currentIntent === Intent.REGISTER) {
      await this.$router.push({ path: 'registrerabolag' });
    } else {
      await this.$router.push({ path: 'valjbolag' });
    }
  }

  async sendLoginLink() {
    try {
      this.sendingToken = true;
      await new Promise(resolve => setTimeout(resolve, 500));
      const returnTo = this.returnTo;
      const res = await axios.post(`/webapi/auth/send-login-link`, {
        email: this.email.replace(/\s/g, ''),
        returnTo,
      });
      if (res.data === 'sent') {
        this.tokenSentSuccess = true;
        this.$q.notify('Inloggningslänken har skickats!');
      }
    } catch (err) {
      if (err.response.status === 404) {
        this.tokenSentFailed = true;
      }
    } finally {
      this.sendingToken = false;
    }
  }
  get query() {
    const returnTo = this.$route.query?.tillbakaTill;
    if (returnTo) {
      return `tillbakaTill=${returnTo}`;
    } else {
      return '';
    }
  }
}
</script>
<style lang="scss">
.onboarding-box {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.5em;
    .email-submit-btn {
      width: 100%;
      align-self: end;
    }
    .email-login-wrapper {
      .q-form {
        min-height: 418px;
        display: grid;
        padding: 2em;
        align-content: space-evenly;
        justify-content: center;
        justify-items: stretch;
      }
    }

    a {
      color: $primary;
    }
  }
  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    position: relative;
    .a-btn-link {
      &.icon-left {
        padding-left: 24px;
        padding-top: 8px;
      }
      &.arrow {
        &:before {
          content: '\e5c4';
          font-size: x-large;
        }
      }
    }
    button {
      display: flex;
      align-items: center;
      position: absolute;
      left: -16px;
      top: -48px;
      .q-btn__content {
        font-size: medium;
      }
    }
  }
}
</style>
