import { Model } from '../base';
import { Address, Entity } from '@shared/web/models';
import { IContactInfo } from '@shared/models/modelTypes';
export default class ContactInfo extends Model implements IContactInfo {
  email: string = null;
  phone: string = null;
  address: Address;
  firstName: string = null;
  surName: string = null;
  wantEmails?: boolean | null;
  role: string = null;

  entity: Entity;

  // @vue3 måste göra Object.assign i konstruktorn, och inte låta det sättas i superklassen. Properties definerierade i denna klass skrivs över annars
  constructor(attr = {}) {
    super(attr);
    Object.assign(this, attr);
  }

  static define = () => ({
    entity: {
      model: Entity,
      serializeAlways: true,
    },
    address: {
      embeddedModel: Address,
    },
  });

  get fullName() {
    if (this.entity?.viewName) {
      return this.entity.viewName;
    }

    if (this.firstName && this.surName) {
      return this.firstName + ' ' + this.surName;
    }

    return this.firstName || this.surName || '';
  }
}
