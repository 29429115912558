<template>
  <q-page class="ShareRegisterPage">
    <div class="row justify-between">
      <h1>Aktiebok</h1>
    </div>
    <div class="row q-col-gutter-md q-my-md">
      <div class="col-6 col-md-4 col-lg-3">
        <a-info-btn>Välj datum som du vill se aktieboken för</a-info-btn>
        Per datum:<br />
        <a-input-date v-bind="$defs.input" v-model="settleDate" />
      </div>
      <div class="col-6 col-md-4 col-lg-3">
        <a-info-btn>Kom ihåg kontrollera att alla affärer har blivit registrerade och genomförda i Kapclear</a-info-btn>
        Senaste transaktion:<br />
        <q-skeleton width="100%" height="48px" v-if="loading" />
        <q-input
          v-if="shareRegister && !loading"
          v-bind="$defs.input"
          disable
          readonly
          :modelValue="formatDay(shareRegister.updatedAt) || `Inga transaktioner för detta datum`"
        />
      </div>
    </div>
    <div class="row q-col-gutter-md">
      <div class="col-12 col-lg-6">
        <SharesInfo :loading="loading" :show-header="false" :shareRegister="shareRegister" :readonly="true" />
      </div>
    </div>
    <div v-if="shareRegister" class="relative-position" :style="loading ? 'max-height:400px;overflow:hidden;' : ''">
      <ShareRegisterView :shareRegister="shareRegister" :readonly="true" stage="view" />
      <q-inner-loading :showing="loading" />
    </div>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { Entity, InstrumentCategory, ShareRegister } from '@/models';
import SharesInfo from '@shared/views/shareRegister/SharesInfo.vue';
import ShareRegisterView from '@shared/views/shareRegister/ShareRegisterView.vue';
import LastEntityUpdate from '@shared/components/LastEntityUpdate.vue';

import { findShareRegister, makeShareRegisterValidation } from '@shared/web/models/shareRegister/shareRegisterService';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { isToNewPage } from '@/utils';
import { Products } from '@shared/index';

@Component({
  components: { SharesInfo, ShareRegisterView, LastEntityUpdate },
})
export default class ShareRegisterPageView extends Vue {
  shareRegister: ShareRegister = null;
  entity: Entity;
  settleDate: Date = new Date();
  loading = true;

  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }

    this.loading = true;
    this.entity = await findEntityWithRouteParams(this.$route.params);
    await this.loadShareRegister();
    this.loading = false;
  }

  @Watch('settleDate')
  async handleDateChange() {
    if (!this.settleDate) {
      this.settleDate = new Date();
    }
    this.settleDate.setHours(23);
    this.settleDate.setMinutes(59);
    this.settleDate.setSeconds(59);
    this.loading = true;
    await this.loadShareRegister();
    this.loading = false;
  }

  async loadShareRegister() {
    this.shareRegister = await findShareRegister({
      entity: this.entity,
      settleDate: this.settleDate,
      instrumentCategory: InstrumentCategory.SHA,
      instrumentProduct: Products.KAPCLEAR,
    });
  }

  get shareRegisterValidation() {
    return makeShareRegisterValidation(this.shareRegister);
  }
}
</script>
