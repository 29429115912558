<template>
  <div class="InstrumentSourceTable">
    <q-markup-table flat class="standard-table position-table" separator="horizontal">
      <caption>
        Innan split
      </caption>
      <thead>
        <tr>
          <th class="text-left">Aktie</th>
          <th class="text-right bg-secondary text-white">Antal aktier</th>
          <th class="text-right bg-secondary text-white">Kvotvärde</th>
          <th class="text-right bg-secondary text-white">Aktiekapital</th>
          <th class="text-right">Splitvillkor</th>
          <th class="text-right">Antal aktier</th>
          <th class="text-right">Kvotvärde</th>
          <th class="text-right">Aktiekapital</th>
        </tr>
      </thead>
      <tbody class="two-row">
        <InstrumentSourceRow
          v-for="source in instrumentSources"
          :source="source"
          :shareRegister="shareRegister"
          :instruction="instruction"
          :readonly="readonly"
          :validator="validator"
          :key="source.instrument.id"
          @change="$emit('change')"
        />
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { ShareRegister, Instruction } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import InstrumentSourceRow from './InstrumentSourceRow.vue';

@Component({
  components: { InstrumentSourceRow },
})
export default class InstrumentSourceTable extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop() validator;
  @Prop({ default: () => false }) readonly: boolean;

  get instrumentSources() {
    return this.instruction.corporateEvent.instrumentSources;
  }
}
</script>
<style lang="scss">
.InstrumentSourceTable {
  margin-bottom: 40px;
  .position-table {
    overflow: visible;
    caption {
      position: relative;
      left: 19%;
      text-align: left;
      margin-top: 37px;
      font-size: 12px;
      font-weight: 600;
      line-height: 28px;
    }
    .pro-rata {
      position: absolute;
      right: 0;
      bottom: -30px;
    }
  }

  .q-table {
    tr td {
      padding: 8px 16px;
      line-height: 22px;
    }
    .q-field__control {
      height: 40px;
      min-height: 40px;
    }
  }
}
</style>
