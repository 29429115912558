import { Model } from '../base/';
import { Instrument, Entity, Instruction } from '@shared/web/models';
import { TransactionType } from '@shared/models/types';

export default class Transaction extends Model {
  instrument: Instrument;
  instruction: Instruction;
  owner: Entity;
  investor: Entity;
  custodian: Entity;
  quantity: number;
  amount: number;
  price: number;
  tradeDate: Date;
  registrationDate: Date;
  settleDate: Date;
  debit: boolean;
  transactionType: TransactionType;

  static storeModel = false;

  static define = () => ({
    instrument: {
      model: Instrument,
    },
    instruction: {
      model: Instruction,
    },
    owner: {
      model: Entity,
    },
    investor: {
      model: Entity,
    },
    custodian: {
      model: Entity,
    },
    tradeDate: {
      type: 'date',
    },
    registrationDate: {
      type: 'date',
    },
    settleDate: {
      type: 'date',
    },
  });
}
