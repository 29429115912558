<template>
  <div v-if="showMenuSectionTitle" class="separator">
    <span>{{ title }}</span>
  </div>
  <q-separator v-else />
  <li>
    <q-item
      v-for="item in menuItems"
      :class="`q-gutter-none q-py-xs ${item.testId || ''}`"
      :key="item.title"
      clickable
      :disable="item.disabled"
      v-show="item.show"
      :to="item.url"
      no-ripple
      active-class="is-active"
    >
      <q-item-section avatar class="q-pl-sm q-pr-none">
        <q-icon v-bind:name="'svguse:/svg-icons.svg#' + item.icon" />
      </q-item-section>
      <q-item-section class="text-subtitle1">{{ item.title }}</q-item-section>
    </q-item>
  </li>
</template>

<script lang="ts">
import { MenuItem } from '@/models/MenuItem';
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component
export default class LeftPanelMenuSection extends Vue {
  @Prop() title: string;
  @Prop() showMenuSectionTitle: boolean;
  @Prop() menuItems: MenuItem[];
}
</script>
<style lang="scss">
.LeftPanel {
  .separator {
    width: 100%;
    position: inherit;
    border-bottom: 2px solid $grey-2;
    line-height: 0em;
    margin: 10px 0 10px;
  }

  .separator span {
    background: $grey-0;
    padding: 0 5px;
    margin-left: 18px;
    font-size: 12px;
    font-weight: bold;
  }

  .q-item:last-child {
    margin-bottom: 18px;
  }
}
</style>
