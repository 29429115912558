import axios from 'axios';
import { setToken } from '@/authentication';
import { setUserDataToSentry } from '../sentry/sentryService';

const baseUrl = '/webapi/bankid';
export const login = async () => {
  const { data } = await axios.post(`${baseUrl}/login`);
  return data;
};

export const sign = async (type: string, payload: Record<string, unknown>) => {
  const { data } = await axios.post(`${baseUrl}/sign`, { type, payload });
  return data;
};

export const getStatus = async (orderRef: string) => {
  const { data } = await axios.post(`${baseUrl}/status`, { orderRef });
  if (data.token) {
    setToken(data.token);
    setUserDataToSentry();
  }
  return data;
};
