import { map, sortBy } from 'lodash';
import svCurrencies from '@piotrgorecki/i18n-currency-name/src/langs/sv.json';
import svCountryNames from 'i18n-iso-countries/langs/sv.json';
// @vue3 det gick inte att importera i18n-iso-countries eftersom den använder commonJs
export const countryOptions = sortBy(
  map(svCountryNames.countries, (name, code) => {
    return { label: name, value: code };
  }),
  ['label'],
);

export const currencyOptions = sortBy(
  map(svCurrencies, (currencyName, currencyCode) => {
    return { label: currencyName, value: currencyCode };
  }),
  ['code'],
);
