<template>
  <div class="q-gutter-x-sm pageBanner" v-show="showBanner" v-if="localStorageBanner">
    <q-banner class="bg-white q-py-lg q-my-none">
      <slot />
      <template v-slot:action>
        <q-btn flat color="black" icon="close" @click="closeBanner" />
      </template>
    </q-banner>
    <q-separator />
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({})
export default class PageBanner extends Vue {
  @Prop() page: string;
  showBanner = true;

  get localStorageBanner() {
    const inLocalStore = localStorage.getItem(this.page);

    if (inLocalStore === null || inLocalStore === 'true') {
      localStorage.setItem(this.page, 'true');
      this.showBanner = true;
      return true;
    }
    return false;
  }

  closeBanner() {
    localStorage.setItem(this.page, 'false');
    this.showBanner = false;
  }
}
</script>
<style lang="scss">
.pageBanner {
  .q-banner {
    position: relative;
    .q-banner__content {
      max-width: 650px;
    }
    .q-banner__actions {
      position: absolute;
      top: 0;
      right: 0;
      .q-btn__wrapper {
        padding: 14px;
      }
    }
  }
}
</style>
