<template>
  <q-form
    ref="form"
    @submit.stop="save"
    @reset="initEntity"
    @input="onInput"
    v-if="entity && user"
    class="UserSettingsComponent"
  >
    <div class="row">
      <div class="col col-12 col-lg-8">
        <q-card flat class="q-pa-md q-mt-md q-mb-sm bg-blue-2" v-if="actionRequired">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="float-left q-mr-md"
            style="width: 25px"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <div class="q-mt-xs">
            För att bli medlem i <span v-if="isKapnet">Kapnet</span><span v-else>Kapclear</span> behöver du
            <b>verifiera dina kontaktuppgifter</b> och
            <b>godkänna användarvillkoren</b>
            nedan. Som medlem i <span v-if="isKapnet">Kapnet</span><span v-else>Kapclear</span> kommer du få e-post från
            oss när nya erbjudanden läggs upp. Du kan självklart logga in när du vill och ta del av erbjudandena.
          </div>
        </q-card>
        <div class="input-container row q-col-gutter-x-lg q-col-gutter-y-none q-mt-md">
          <q-input
            square
            bg-color="white"
            label="Ditt fullständiga namn enligt folkbokföringen"
            v-bind="$defs.input"
            disable
            v-model="roaringDataName"
          />
        </div>
        <div class="input-container row q-col-gutter-x-lg q-col-gutter-y-none">
          <q-input
            square
            bg-color="white"
            label="Ditt namn hos oss - förnamn"
            ref="firstName"
            v-bind="$defs.input"
            v-model="entity.person.firstName"
          />
          <q-input
            square
            bg-color="white"
            label="Ditt namn hos oss - efternamn"
            ref="surName"
            v-bind="$defs.input"
            v-model="entity.person.surName"
          />
        </div>
        <div class="input-container row q-col-gutter-x-lg q-col-gutter-y-none">
          <q-input
            class="test-phone"
            square
            bg-color="white"
            label="Telefon"
            ref="phone"
            v-bind="$defs.input"
            :rules="validation.phone"
            v-model="entity.addresses[0].phone"
          />
          <q-input
            class="test-email"
            square
            bg-color="white"
            label="E-post"
            ref="email"
            v-bind="$defs.input"
            :rules="validation.requiredEmail"
            v-model="entity.addresses[0].email"
          />
        </div>
        <div class="q-mb-lg cb">
          <q-item v-ripple tag="label" v-if="!isKapnet">
            <q-item-section :side="true">
              <q-checkbox class="test-consent" v-model="shareDataConsent" :disable="shareDataConsent" :val="true" />
            </q-item-section>
            <q-item-section>
              <q-item-label>
                <span class="test-policy">Jag godkänner Kapclear AB användarvillkor</span>
                <a-info-btn>
                  Jag samtycker till att Kapclear AB delar mina personuppgifter till andra Kaptenabolag för att kunna
                  förse mig med information om Kapclearanslutna bolag jag har intressen i, till exempel nyemissioner och
                  information och investeringsmöjligheter i bolag som Kaptenabolagen samarbetar med. För mer information
                  om hur vi behandlar dina personuppgifter, se
                  <a href="https://kaptena.se/cookies-integritet-och-sekretess/" target="_blank" rel="noreferrer"
                    >vår personuppgiftspolicy</a
                  >. Med Kaptenabolag avses Kapclear AB, Kaptena Sverige AB och Kaptena Försäkringsförmedling AB och
                  andra bolag som ingår i Kaptena-koncernen.
                </a-info-btn>
              </q-item-label>
            </q-item-section>
          </q-item>

          <q-item v-ripple tag="label">
            <q-item-section :side="true">
              <q-checkbox class="test-kapnetLicenseConsent" v-model="kapnetLicenseConsent" :val="true" />
            </q-item-section>
            <q-item-section>
              <q-item-label>
                <span class="test-policy-kapnet">Jag godkänner Kapnet AB användarvillkor</span>
                <a-info-btn>
                  Jag samtycker till att bli medlem i Kapnet – Kaptenas investerarnätverk. Genom Kapnet får du
                  möjligheten att vara bland de första som får ta del av spännande investeringsmöjligheter och
                  nyemissioner från Kaptenas nätverk. Jag samtycker också till att Kapnet delar mina personuppgifter
                  till andra Kaptenabolag. Med Kaptenabolag avses Kapclear AB, Kaptena Sverige AB och Kaptena
                  Försäkringsförmedling AB och andra bolag som ingår i Kaptena-koncernen. För mer information om hur vi
                  behandlar dina personuppgifter, se vår:
                  <a-btn-link href="https://kaptena.se/cookies-integritet-och-sekretess/" target="_blank"
                    >Personuppgiftspolicy</a-btn-link
                  >
                  och våra:
                  <a-btn-link @click="fullUserConditionsnModal"> Fullständiga kapnetvillkor. </a-btn-link>
                </a-info-btn>
              </q-item-label>
            </q-item-section>
          </q-item>
        </div>
        <div class="text-center q-mb-lg">
          <q-btn
            v-if="!settingsReviewedAtOriginal && returnTo"
            :disable="!kapnetLicenseConsent"
            v-bind="$defs.btn"
            type="submit"
            label="Ser bra ut, fortsätt →"
            lass="q-mr-md"
          />
          <q-btn
            v-bind="$defs.btn"
            type="submit"
            :disable="isNotKapnetAndIsUnchecked || isKapnetAndIsUnchecked"
            label="Spara"
            lass="q-mr-md"
          />
        </div>
      </div>
    </div>
  </q-form>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { FormComponent } from '@shared/mixins';
import { serializedChanges } from '@/models/base';
import { Entity, RoaringData, Address } from '@/models/';
import { saveEntity } from '@/models/entity/entityService';
import { validation } from '@shared/common';
import store from '@/store';
import { isEmpty } from 'lodash';
import { IssuerInfo } from '@/models';
import { ContactInfo, EntityAdmin } from '@shared/web/models';
import FullUserConditionsModal from '@shared/components/FullUserConditionsModal.vue';

@Component({
  mixins: [FormComponent],
})
export default class UserSettingsComponent extends Vue {
  @Prop({ default: false }) isKapnet;
  entity: Entity = null;
  dirty = false;
  returnTo = '';
  issuerInfos: IssuerInfo[] = [];
  userEntities: Entity[] = [];
  entityAdmins: EntityAdmin[] = [];
  kapnetLicenseConsent = false;
  shareDataConsent = false;
  settingsReviewedAtOriginal: null | Date = null;

  get isNotKapnetAndIsUnchecked() {
    return !this.isKapnet && !this.shareDataConsent;
  }
  get isKapnetAndIsUnchecked() {
    return this.isKapnet && !this.kapnetLicenseConsent;
  }
  get user() {
    return store?.profile?.user;
  }

  get actionRequired() {
    return this.returnTo && (!this.settingsAlreadyReviewed || !this.kapnetLicenseConsent || !this.shareDataConsent);
  }

  get entityAdminsRows() {
    return this.entityAdmins.map(entityAdmin => ({
      contactInfo: ContactInfo.newModel({ email: entityAdmin.email, phone: entityAdmin.phone }),
      sourceEntity: entityAdmin.entity,
      sourceLabel: entityAdmin.entity.viewName,
      source: 'admin',
      ...entityAdmin,
    }));
  }

  get userEntity(): Entity | null {
    return this.userEntities[0];
  }

  mounted() {
    this.settingsReviewedAtOriginal = store.profile.user?.settingsReviewedAt;
    this.initEntity();
    this.kapnetLicenseConsent = !!this.entity.kapnetLicenseConsentedAt;
    this.shareDataConsent = !!this.entity.shareDataConsentedAt;
  }

  get validation() {
    return validation;
  }

  get settingsAlreadyReviewed() {
    return !!store.profile.user.settingsReviewedAt;
  }

  get roaringDataName() {
    const {
      surName = '',
      firstName = '',
      middleName = '',
    } = this.entity?.roaringData?.person ?? this.entity?.person ?? {};
    return `${firstName} ${middleName || ''} ${surName}`.trim().replace(/\s+/g, ' ');
  }

  get kapnetLicenseConsentedChanged() {
    return Boolean(this.kapnetLicenseConsent) !== Boolean(this.user.kapnetLicenseConsentedAt);
  }
  get shareDataConsentedChanged() {
    return Boolean(this.shareDataConsent) !== Boolean(this.user.shareDataConsentedAt);
  }

  @Watch('kapnetLicenseConsent')
  handleLicenseConsentCbClick() {
    if (this.kapnetLicenseConsent) {
      this.entity.kapnetLicenseConsentedAt = new Date();
    } else {
      this.entity.kapnetLicenseConsentedAt = null;
    }
    this.onInput();
  }
  @Watch('shareDataConsent')
  handleShareDataConsentCbClick() {
    if (this.shareDataConsent) {
      this.entity.shareDataConsentedAt = new Date();
    } else {
      this.entity.shareDataConsentedAt = null;
    }
    this.onInput();
  }

  onInput() {
    this.dirty =
      !isEmpty(serializedChanges(this.entity)) || this.shareDataConsentedChanged || this.kapnetLicenseConsentedChanged;
  }

  initEntity() {
    if (typeof this.$route.query.tillbakaTill === 'string') {
      this.returnTo = this.$route.query.tillbakaTill;
    }

    this.dirty = false;
    const entity = store.profile.entity.copy();

    if (!entity.addresses.length) {
      entity.addresses.push(
        Address.newModel(
          {
            countryCode: entity.countryCode,
            firstName: entity.person.firstName,
            surName: entity.person.surName,
          },
          false,
        ),
      );
    }

    if (!entity.roaringData?.person) {
      entity.roaringData = entity.roaringData || RoaringData.newModel({});
      entity.roaringData.person = entity.person.copy();
    }

    this.entity = entity;
  }

  async save() {
    await this.submitForm(async () => {
      if (store.profile?.entity) {
        store.profile.entity.kapnetLicenseConsentedAt = this.entity.kapnetLicenseConsentedAt;
        store.profile.entity.shareDataConsentedAt = this.entity.shareDataConsentedAt;
      }
      this.settingsReviewedAtOriginal = store.profile.user.settingsReviewedAt;
      await saveEntity(this.entity);
      this.initEntity();
      if (this.returnTo) {
        await this.$router.push(this.returnTo);
      }
    });
  }

  fullUserConditionsnModal() {
    this.$q.dialog({
      component: FullUserConditionsModal,
    });
  }
}
</script>
<style lang="scss">
.UserSettingsComponent {
  .cb .q-item__section--side {
    padding-right: 0;
  }
  p {
    margin-top: 16px;
    margin-bottom: 40px;
  }
  .q-btn:not(:first-of-type) {
    margin-left: 24px;
  }
  .input-container {
    overflow: hidden;
    > * {
      flex: 0 0 50%;
    }
    .q-input {
      padding-bottom: 24px;
    }
    .q-field__control {
      padding: 0 8px;
    }
    .q-field__bottom {
      padding-top: 5px;
    }
  }
}
</style>
