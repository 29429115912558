<template>
  <div class="col-auto QuantityAndShareNumbers">
    <p class="B1Small-label" v-if="!isBo">
      Aktienummer
      <a-info-btn>
        <p>
          Registrera aktiepostens aktienummer. Om ni lägger upp aktieboken för första gången och inte har tidigare
          aktienummer i aktieboken så kan ni börja från början. Om det finns en aktiebok sedan tidigare, så registrerar
          ni aktiepostens aktienummer för varje ägare.
        </p>
        <p>Antalet aktier kommer upp automatiskt när aktienummer registreras, exempelvis,</p>
        <p>Aktienummer 1 till 500 = 500st</p>
        <p>Aktienummer 501 till 1000 = 500st</p>
      </a-info-btn>
    </p>

    <dev :class="{ 'bo-wrapper q-px-lg': isBo }">
      <InputFormatNumber
        class="col q-px-sm"
        v-model="position.quantity"
        v-bind="defs.input"
        @update:modelValue="shareNumberInput"
        label="Antal"
        :error="numberSerieError != null"
        :readonly="readonly"
        input-class="test-quantity"
      />
      <div class="flex" style="width: 28ch">
        <InputFormatNumber
          class="col q-px-sm number-serie-input"
          v-model="position.shareNumberFrom"
          v-bind="defs.input"
          @update:modelValue="shareNumberInput"
          label="Från"
          :error="numberSerieError != null"
          :error-message="numberSerieError"
          :readonly="readonly"
        />
        <InputFormatNumber
          class="col q-px-sm"
          v-model="position.shareNumberTo"
          v-bind="defs.input"
          label="Till"
          :error="numberSerieError != null"
          :readonly="true"
        />
      </div>
    </dev>
  </div>
</template>
<script lang="ts">
import { Position } from '@shared/web/models';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import InputFormatNumber from '@shared/components/NumberInput.vue';

@Component({
  components: {
    InputFormatNumber,
  },
})
export default class QuantityAndShareNumbers extends Vue {
  @Prop() position: Position;
  @Prop() shareNumberInput: () => void;
  @Prop() numberSerieError = null;
  @Prop() defs;
  @Prop() readonly = false;
  @Prop({ default: false, type: Boolean }) isBo = false;
  @Prop({ type: Number }) nextShareNumberTo;
  created() {
    this.position.shareNumberFrom = this.position.shareNumberFrom
      ? this.position.shareNumberFrom
      : this.nextShareNumberTo;
  }
}
</script>

<style lang="scss">
.QuantityAndShareNumbers {
  .bo-wrapper {
  }
}
</style>
