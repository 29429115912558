import { Model } from '../base';
import { Entity, ContactInfo } from '@shared/web/models';
import { IIssuerInfo } from '@shared/models/modelTypes';
import { isSameModel } from '@shared/utils';
export default class IssuerInfo extends Model implements IIssuerInfo {
  contacts: Array<ContactInfo> = [];
  roles: Array<ContactInfo> = [];
  entity: Entity;

  static define = () => ({
    contacts: {
      embeddedModel: [ContactInfo],
      serializeChanges: true,
    },
    roles: {
      embeddedModel: [ContactInfo],
      serializeChanges: true,
    },
    entity: {
      embeddedModel: Entity,
    },
  });

  getContactInfo(entity: Entity) {
    if (entity == null) {
      return new ContactInfo();
    }

    let result = this.contacts.find(contactInfo => isSameModel(contactInfo.entity, entity));
    if (result == null) {
      result = new ContactInfo({ entity: entity });
      this.contacts.push(result);
    }
    return result;
  }

  getRoleInfo(entity: Entity) {
    if (entity == null) {
      return new ContactInfo();
    }
    let result = this.roles.find(contactInfo => isSameModel(contactInfo.entity, entity));
    if (result == null) {
      result = new ContactInfo({ entity: entity });
      this.roles.push(result);
    }
    return result;
  }
}
