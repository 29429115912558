<template>
  <q-page v-if="ready" class="SubmittedSplitPage">
    <h1>Split</h1>
    <Stepper pageType="split" :currentstep="4" :isDone="true" :urlId="instruction.id" />
    <h4>Split är nu inskickad</h4>
    <p class="p2">Vi kommer nu att verifiera split. Normal väntetid är 1-2 arbetsdagar.</p>

    <p class="p2">Du kommer att bli kontaktad på {{ email }} när split är genomförd och aktieboken är uppdaterad.</p>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { ShareRegister, Instruction, EntityAdmin } from '@/models';
import Stepper from '@/components/Stepper.vue';
import { findInstruction } from '@/models/instruction/instructionService';
import { findCurrentEntityAdmin } from '@/models/entityAdmin/entityAdminService';
import { isToNewPage } from '@/utils';
@Component({
  components: { Stepper },
})
export default class SubmittedSplitPage extends Vue {
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;
  entityAdmin: EntityAdmin = null;
  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;
    const { shareRegister, instruction } = await findInstruction(id);
    const entityAdmin = await findCurrentEntityAdmin(shareRegister.entity);
    Object.assign(this, { shareRegister, instruction, entityAdmin });
  }

  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }

  get email() {
    return this.entityAdmin?.email;
  }
}
</script>
