<template>
  <div class="convertible-details">
    <div class="row q-col-gutter-md items-end">
      <div class="col-md-9">
        <div class="row q-col-gutter-md items-end">
          <div class="col-12 col-md-4">
            <label
              >Underliggande aktie

              <q-select
                :modelValue="rightsData.underlyingInstrumentName"
                :options="underlyingInstrumentOptions"
                use-input
                hide-selected
                fill-input
                :disable="readonly"
                input-debounce="0"
                v-bind="$defs.input"
                @input-value="setUnderlyingInstrument"
              />
            </label>
          </div>
          <label class="col-12 col-md-2">
            <span>Utgivningsdag</span>
            <a-input-date
              v-model="rightsData.issueDate"
              v-bind="$defs.input"
              :disable="readonly"
              @change="$emit('change')"
              input-class="test-issueDate"
            />
          </label>
          <div class="col-12 col-md-2">
            <label>Teckningsperiod från</label>
            <a-input-date
              v-model="rightsData.exercisePeriodFrom"
              v-bind="$defs.input"
              :disable="readonly"
              @change="$emit('change')"
              input-class="test-exercisePeriodFrom"
            />
          </div>
          <div class="col-12 col-md-2">
            <label>Teckningsperiod till</label>
            <a-input-date
              v-model="rightsData.exercisePeriodTo"
              v-bind="$defs.input"
              :disable="readonly"
              @change="$emit('change')"
              input-class="test-exercisePeriodTo"
            />
          </div>
          <div class="col-12 col-md-2">
            <label>Slutdag</label>
            <a-input-date
              v-model="rightsData.dueDate"
              v-bind="$defs.input"
              :disable="readonly"
              @change="$emit('change')"
              input-class="test-dueDate"
            />
          </div>
        </div>
      </div>

      <div class="col-12 col-md-3">
        <label>
          Namn på konvertibeln
          <q-input :modelValue="convertibleName" :disable="true" v-bind="$defs.input" />
        </label>
      </div>

      <div class="col-12 col-md-6">
        <DistributionDetails :rightsData="rightsData" :readonly="readonly" @change="$emit('change')" />
      </div>

      <label class="col-12 col-md-1">
        Valuta
        <SelectCurrency
          v-model="rightsData.currency"
          v-bind="$defs.input"
          :disable="readonly"
          @change="$emit('change')"
          @update:modelValue="$emit('input')"
        />
      </label>

      <label class="col-12 col-md-5">
        Frivillig tilläggsinformation avseende konvertibelns villkor
        <q-input
          type="text"
          v-model="rightsData.note"
          v-bind="$defs.input"
          :disable="readonly"
          @change="$emit('change')"
          @update:modelValue="$emit('input')"
          placeholder="Fritext"
        />
      </label>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Instruction, Entity, Instrument } from '@shared/web/models';
import { createConvertibleName } from '@shared/rightsIssue/utils';
import SelectCurrency from '@shared/components/SelectCurrency.vue';
import DistributionDetails from '@shared/components/DistributionDetails.vue';
import InputDate from '@shared/components/InputDate.vue';

@Component({
  components: { SelectCurrency, DistributionDetails, 'a-input-date': InputDate },
  inheritAttrs: false,
})
export default class ConvertibleDetails extends Vue {
  @Prop() instruction: Instruction;
  @Prop() instrument: Instrument;
  @Prop({ required: true }) entity: Entity;
  @Prop({ default: true }) readonly: boolean;

  get underlyingInstrumentOptions() {
    const shareOptions = (this.entity?.instruments || [])
      .filter(instrument => instrument.category === 'SHA')
      .map(option => option.viewName);

    if (shareOptions.includes(this.rightsData.underlyingInstrumentName)) {
      return shareOptions;
    } else {
      return [...shareOptions, this.rightsData.underlyingInstrumentName];
    }
  }

  get rightsData() {
    if (this.instruction) {
      return this.instruction.corporateEvent.rightsData;
    }
    if (this.instrument) {
      return this.instrument.rightsData;
    }
    throw 'Cannot find rightsData';
  }

  get convertibleName() {
    return createConvertibleName(this.rightsData);
  }

  setUnderlyingInstrument(instrumentName) {
    const instrument = this.entity.instruments.find(instrument => instrument.viewName === instrumentName) || null;
    if (this.rightsData.underlyingInstrument !== instrument) {
      this.rightsData.underlyingInstrument = instrument;
      this.rightsData.underlyingInstrumentName = instrumentName;
      this.$emit('change');
    }
  }
}
</script>
