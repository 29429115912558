import { IAddress } from '@shared/models/modelTypes';
import { Model } from './base';
export default class Address extends Model implements IAddress {
  firstName: string;
  surName: string;
  address1: string;
  address2: string;
  zip: string;
  city: string;
  countryCode: string;
  email: string;
  phone: string;
}
