<template>
  <div class="ShareRegisterSummary">
    <div class="panel-main">
      <table border="0" cellpadding="15" class="boxes primary" v-if="isSha">
        <tr>
          <td width="20%">
            <strong>Antal Aktier</strong>
            <hr />
            {{ totalQuantity }}
          </td>
          <td width="20%">
            <strong>Kvotvärde</strong>
            <hr />
            {{ quotaValue }}
          </td>
          <td width="20%">
            <strong>Aktiekapital</strong>
            <hr />
            {{ totalCapital }}
          </td>
          <td width="20%">
            <strong>Valuta</strong>
            <hr />
            {{ entity.issuerData.currencyCode }}
          </td>
          <td width="20%">
            <strong>{{ isLegal ? 'Antal ägare' : 'Antal investerare' }}</strong>
            <hr />
            {{ isLegal ? ownerCount : investorCount }}
          </td>
        </tr>
      </table>
    </div>
    <div class="panel-main owner-details-container">
      <dl class="owner-details">
        <template v-if="ceo">
          <dt>VD:</dt>
          <dd>{{ ceo && ceo.viewName }}</dd>
        </template>
        <template v-if="address">
          <dt>Säte:</dt>
          <dd>{{ address.city }}</dd>
        </template>
        <template v-if="entity.nationalId">
          <dt>Org.nr:</dt>
          <dd>{{ entity.nationalId }}</dd>
        </template>
      </dl>
      <dl class="owner-details" v-if="boardMembers.length > 0">
        <dt>Styrelse:</dt>
        <dd v-for="member in boardMembers" :key="member.id">
          {{ member.viewName }}
        </dd>
      </dl>
      <dl class="owner-details" v-if="address">
        <dt>Adress:</dt>
        <dd>
          {{ address.address1 }}<br v-if="address.address2" />{{ address.address2 }}<br />{{ address.zip }}
          {{ address.city }}
        </dd>
      </dl>
    </div>
    <div class="panel-main">
      <hr class="separator short" />
      <KapclearStamp />
    </div>
  </div>
</template>
<script lang="ts">
import { ShareRegister } from '@/models';
import { InstrumentCategory, RoleType } from '@shared/models/types';
import { PDFType } from '@shared/utils/pdf';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import KapclearStamp from './KapclearStamp.vue';
import { formatNumber } from '@shared/utils';

@Component({ components: { KapclearStamp } })
export default class CoverPagePDF extends Vue {
  InstrumentCategory: typeof InstrumentCategory = InstrumentCategory;

  @Prop() shareRegister: ShareRegister;
  @Prop({ required: false }) category: InstrumentCategory;
  @Prop() pdfType: PDFType;

  get isSha() {
    return this.category === InstrumentCategory.SHA;
  }

  get isLegal() {
    return this.pdfType === PDFType.legal;
  }

  get ownerCount() {
    return this.shareRegister.getUniqueOwners().length;
  }

  get investorCount() {
    return this.shareRegister.getUniqueOwnersAndInvestors().length;
  }

  get totalQuantity() {
    return formatNumber(this.shareRegister.getHistoricIssuerData().totalQuantity);
  }
  get totalCapital() {
    return formatNumber(this.shareRegister.getHistoricIssuerData().totalCapital);
  }
  get quotaValue() {
    return formatNumber(this.shareRegister.quotaValue);
  }

  get ceo() {
    const role = this.entity.legalEntity.roles.find(role => role.type === RoleType.CEO);
    return role && role.person;
  }

  get boardMembers() {
    const roles = this.entity.legalEntity.roles.filter(role => role.type === RoleType.BoardMember);
    return roles.map(role => role.person);
  }

  get entity() {
    return this.shareRegister.entity;
  }

  get address() {
    return this.entity.addresses[0];
  }
}
</script>

<style lang="scss">
@media print {
  .MessageBanner {
    display: none;
  }
}

.ShareRegisterSummary {
  dl {
    padding: 0;
    margin: 0;
    align-items: baseline;
    dt,
    dd {
      padding: 0;
      float: left;
      white-space: nowrap;
      margin: 2px 0;
      text-align: left;
    }
    dt {
      font-weight: bold;
    }
  }

  .owner-details-container {
    margin-bottom: 20px;

    dl.owner-details {
      font-size: 14px;
      width: 300px;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      align-items: baseline;
      page-break-inside: avoid;

      dt {
        width: 95px;
        margin-left: 35px;
        &:first-child {
          margin-top: 18px;
          & + dd {
            margin-top: 18px;
          }
        }
      }
      dd {
        width: 170px;
        margin-left: auto;
      }
    }
  }

  table.boxes {
    width: calc(100% + 20px);
    border-spacing: 10px;
    margin: 0;
    border-collapse: separate;
    margin: 5px -10px 10px;
    page-break-inside: avoid;

    td {
      border: 2px solid lightgray;
      height: 100px;
      text-align: center;
      vertical-align: top;
      padding: 0;
      font-size: 18px;
      height: 50px;
      line-height: 32px;

      hr {
        border: 0;
        padding: 0;
        margin: 0;
        height: 1px;
        background: lightgray;
      }
      strong {
        font-size: 14px;
        line-height: 22px;
        overflow: hidden;
        display: block;
        padding-top: 2px;
      }
    }
    &.primary {
      td {
        border-color: #2d612c;
        hr {
          background: #2d612c;
        }
        strong {
          color: #2d612c;
        }
      }
    }
  }

  .separator {
    border: 0;
    height: 2px;
    background: #2d612c;
    margin: 10px auto;
    &.short {
      width: 49%;
    }
  }
}
</style>
