import { Entity } from '@shared/web/models';
import { Model } from '../base';
import { RoleType } from '@shared/models/types';
export default class Role extends Model {
  type: RoleType;
  person: Entity;

  static define = () => ({
    person: {
      model: Entity,
    },
  });
}
