import { ShareRegister, Instruction, InstructionParty, TransactionType } from '@/models';
import { formatNationalId } from '@shared/common';
import { makeInstructionValidator } from '@/models/instruction/instructionService';
import { formatPhoneNumber } from '@shared/excel/utils';
import { findIssuerInfo } from '@shared/web/models/entity/issuerInfoService';

const getAllocatedQuantity = ({ totalQuantity, destination, validator, instruction }) => {
  const { instrument, allocatedQuantity } = destination;

  if (!instrument) {
    return null;
  }

  const sourceValidation = validator.forInstrumentSource(instrument);
  if (instruction.type === TransactionType.EXERCISE_WARRANT) {
    return allocatedQuantity;
  }

  if (sourceValidation.ratio == null) {
    return null;
  }

  return totalQuantity / sourceValidation.ratio;
};

export default function createRightsIssueExporter(shareRegister: ShareRegister, instruction: Instruction) {
  return async function rightsIssueExporter() {
    const { destinations = [], entity } = instruction;
    const validator = makeInstructionValidator(instruction, shareRegister);
    const issuerInfo = await findIssuerInfo(entity);

    const records = destinations
      .map((destination: InstructionParty) => {
        const { owner, investor, custodian, instrument, pledgeOwner } = destination;

        const { totalQuantity } = validator.forParty(destination);
        const ownerInfo = issuerInfo.getContactInfo(owner);
        const investorInfo = issuerInfo.getContactInfo(investor);
        const pledgeOwnerInfo = issuerInfo.getContactInfo(pledgeOwner);
        const ownerAddress = owner?.addresses[0];
        const investorAddress = investor?.addresses[0];
        const ownerPhone = formatPhoneNumber(ownerInfo.phone);
        const investorPhone = formatPhoneNumber(investorInfo.phone);

        return {
          ...(instrument ? { Aktie: instrument?.name } : {}),
          Ägare: owner.viewName,
          Försäkringsägare: investor ? `${investor.viewName}, ${formatNationalId(investor)}` : '',
          ...(instrument
            ? {
                'Befintligt Antal': totalQuantity,
                'Nya aktier, pro rata': getAllocatedQuantity({ totalQuantity, destination, validator, instruction }),
              }
            : {}),
          tecknade: destination.quantity,
          Depåförvar: custodian?.viewName || '',
          Depånummer: destination.custodianAccountNumber,
          'Ägare mail': ownerInfo?.email,
          'Ägare telefon': ownerPhone,
          'Ägare gatuadress': ownerAddress?.address1,
          'Ägare stad': ownerAddress?.city,
          'Ägare postnummer': ownerAddress?.zip,
          'Investerare mail': investorInfo?.email,
          'Investerare telefon': investorPhone,
          'Investerare gatuadress': investorAddress?.address1,
          'Investerare stad': investorAddress?.city,
          'Investerare postnummer': investorAddress?.zip,
          Pantägare: destination.pledgeOwner?.viewName,
          'Pantägare mail': pledgeOwnerInfo?.email,
          'Pantägare telefon': formatPhoneNumber(pledgeOwnerInfo?.phone),
        };
      })
      .flat();

    return {
      name: shareRegister.entity.viewName,
      records,
    };
  };
}
