<template>
  <div class="InsurerAccountTable">
    <div class="heading-container">
      <ExportLinks>
        <ExportExcel :exporter="excelExporter" />
      </ExportLinks>
    </div>
    <q-markup-table flat class="two-row standard-table" separator="horizontal">
      <thead>
        <tr>
          <th></th>
          <th class="text-left">Försäkringsägare</th>
          <th class="text-left">Investeringsansvarig</th>
          <th class="text-left">Försäkringsnummer</th>
          <th class="text-left">Depåinstitut</th>
          <th class="text-left">Depånummer</th>
          <th class="text-right">Totalt antal</th>
          <th class="text-right">Nominellt belopp</th>
        </tr>
      </thead>
      <tbody class="two-row">
        <template v-for="item in groups" :key="item.key">
          <tr class="holder-row" @click="toggle(item.key)">
            <td>
              <q-icon
                size="sm"
                name="arrow_right"
                :class="{ 'q-mr-xs': true, collapsed: !expanded.includes(item.key) }"
              />
            </td>
            <td>{{ viewName(item.investor) }}</td>
            <td>{{ toInvestmentManager(item.investor) }}</td>
            <td>{{ item.insuranceNumber }}</td>
            <td>{{ viewName(item.custodian) }}</td>
            <td>{{ item.custodianAccountNumber }}</td>
            <td class="text-right">{{ formatNumber(item.quantity) }}</td>
            <td class="text-right">
              <template v-if="item.amount">{{ formatNumber(item.amount) }}</template>
            </td>
          </tr>
          <tr
            :class="{
              large: true,
              InvestorRow: true,
              collapsed: !expanded.includes(item.key),
            }"
            v-for="position in item.positions"
            :key="position.id"
          >
            <td colspan="3"><div></div></td>
            <td>
              <div>{{ position.insuranceNumber }}</div>
            </td>
            <td>
              <div>{{ viewName(position.custodian) }}</div>
            </td>
            <td>
              <div>{{ position.custodianAccountNumber }}</div>
            </td>
            <td class="text-right">
              <div>{{ formatNumber(position.quantity) }}</div>
            </td>
            <td class="text-right">
              <div>{{ formatNumber(position.amount) }}</div>
            </td>
          </tr>
        </template>
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
// @TODO kolla arguments på toInvestmentManager
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Instrument, Position, Entity } from '@/models';
import { formatNationalId } from '@shared/common';
import ExportLinks from '@shared/components/ExportLinks.vue';
import ExportExcel from '@shared/views/excel/ExportExcel.vue';
import { groupBy, sumBy, sortBy } from 'lodash';

const toInvestmentManager = ({ investor, owner }): { investor?: Entity; owner?: Entity } => {
  if (investor) {
    const { isPerson, legalEntity } = investor;
    return isPerson ? '' : legalEntity.investorManager?.viewName;
  } else if (owner) {
    const { isPerson, legalEntity } = owner;
    return isPerson ? '' : legalEntity.investorManager?.viewName;
  }
};

@Component({
  components: { ExportLinks, ExportExcel },
})
export default class InsurerAccountTable extends Vue {
  @Prop() positions: Position[];
  @Prop() instrument: Instrument;
  @Prop() settleDate: Date;
  @Prop() custodians: string[];
  @Prop() allCustodians: boolean;

  viewName(entity) {
    return entity && entity.viewName;
  }

  toInvestmentManager(investor) {
    return toInvestmentManager(investor);
  }

  expanded = [];

  toggle(key: string) {
    if (!this.expanded.includes(key)) {
      this.expanded.push(key);
    } else {
      this.expanded.splice(this.expanded.indexOf(key), 1);
    }
  }

  isExpanded(key: string) {
    this.expanded.includes(key);
  }

  get groups() {
    if (this.instrument) {
      const filter = position => {
        if (position.instrument.id !== this.instrument.id) return false;
        if (!this.allCustodians) {
          return this.custodians.includes(position.custodian?.id);
        }
        return true;
      };
      const positions = this.positions.filter(filter);

      const grouped = groupBy(positions, position => {
        return `${position.investor?.id}${position.insuranceNumber}${position.custodianAccountNumber}`;
      });

      const transformed = Object.values(grouped).map(positions => {
        const { investor, custodian, custodianAccountNumber, insuranceNumber } = positions[0];
        return {
          key: `${investor?.id}${insuranceNumber || '-'}${custodianAccountNumber || '-'}`,
          investor,
          custodian,
          positions: positions,
          quantity: sumBy(positions, 'quantity'),
          amount: sumBy(positions, 'amount') || null,
          insuranceNumber,
          custodianAccountNumber,
        };
      });

      return sortBy(transformed, 'investor.viewName');
    }

    return [];
  }

  async excelExporter() {
    const positions = this.groups.map(group => group.positions).flat();

    return {
      name: this.instrument?.name || 'Förvar',
      records: positions.map(position => ({
        Försäkringsägare: position.investor?.viewName,
        Investeringsansvarig: toInvestmentManager(position),
        'Försäkringsägare person-/orgnr.': formatNationalId(position.investor),
        Försäkringsnummer: position.insuranceNumber,
        Depånummer: position.custodianAccountNumber,
        Antal: position.quantity,
        'Nominellt belopp': position.amount,
      })),
    };
  }
}
</script>
<style lang="scss">
.InsurerAccountTable {
  .heading-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    .text-small {
      margin-bottom: 8px;
    }
  }
  .q-table {
    tbody {
      td {
        border-bottom: solid 2px $grey-1;
      }
    }
  }
  .holder-row {
    cursor: pointer;
    i {
      transform: rotate(90deg);
      transition: transform 300ms ease-in-out;
      &.collapsed {
        transform: rotate(0deg);
      }
    }
  }
  .InvestorRow {
    td {
      transition: all 300ms ease-in-out;
      div {
        overflow: hidden;
        transition: max-height 300ms ease-in-out;
        max-height: 150px;
      }
    }
    &.collapsed {
      td {
        border: 0;
        border-color: transparent;
        height: 0px !important;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        div {
          max-height: 0px;
        }
      }
    }
  }
  small {
    display: block;
    color: $grey-4;
  }
}
</style>
