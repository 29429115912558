import axios from 'axios';
import { UserInvestments, Transaction } from '@/models';
import TransactionSearchQuery from '@shared/web/models/transaction/TransactionSearchQuery';

const baseUrl = '/webapi/user-investments';

export async function finduserInvestments(): Promise<UserInvestments> {
  const { data: investments } = await axios.post(baseUrl + '/list');

  return UserInvestments.newModel({ investments });
}

export async function findUserTransactions(searchQuery?: TransactionSearchQuery): Promise<Transaction[]> {
  const { data } = await axios.post(baseUrl + '/transactions', searchQuery && searchQuery.serialize());
  return data.map(transaction => Transaction.newModel(transaction, true));
}
