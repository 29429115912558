import { isSameYear, formatDate } from '../utils/date';
import { IInstrument, IRightsData } from '../models/modelTypes';
import { format } from 'date-fns';

function getWarrantSeriesNumber(instruments) {
  const today = new Date();

  return instruments.reduce((seriesCount, instrument) => {
    const issueDate = instrument.rightsData?.issueDate;
    if (issueDate && isSameYear(today, issueDate)) {
      return seriesCount + 1;
    }
    return seriesCount;
  }, 1);
}

export function createWarrantName(rightsData: IRightsData, instruments: IInstrument[]) {
  const { underlyingInstrument, issueDate, dueDate } = rightsData;
  const warrantSeriesNumber = getWarrantSeriesNumber(instruments);

  if (underlyingInstrument) {
    const issueDateFormatted = issueDate ? formatDate(issueDate, 'yy') : '';
    const dueDateFormatted = dueDate ? `/${formatDate(dueDate, 'yy')}` : '';
    return `${underlyingInstrument.name} TO ${warrantSeriesNumber} ${issueDateFormatted}${dueDateFormatted}`;
  }
  return '';
}

export function createConvertibleName(rightsData: IRightsData) {
  if (rightsData.underlyingInstrument || rightsData.underlyingInstrumentName) {
    const { issueDate } = rightsData;
    const issueDateFormatted = issueDate ? format(issueDate, 'yyyy-MM-dd') : '';
    const instrumentName = rightsData.underlyingInstrumentName || rightsData.underlyingInstrument.name;
    return instrumentName + ' KONV ' + issueDateFormatted;
  }
  return '';
}
