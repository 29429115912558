<template>
  <tr class="holder-row">
    <td colspan="3">{{ instrument.viewName }}</td>
    <td colspan="3"></td>

    <q-td class="text-right">
      {{ formatNumber(shareData.totalQuantity) }}
    </q-td>

    <td class="text-right">{{ formatPercent(totals.totalShare) }}</td>
    <td class="text-right">{{ formatPercent(totals.votingPowerQuota) }}</td>
    <td v-if="canEdit"></td>
  </tr>
</template>

<script lang="ts">
import { Instrument, ShareRegister } from '@shared/web/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { makeShareRegisterValidation } from '@shared/shareRegister/shareRegisterService';
import { store } from '@shared/web/store';
import { isBackOffice } from '@shared/web/utils';

@Component({})
export default class InstrumentRow extends Vue {
  @Prop() instrument: Instrument;
  @Prop() shareRegister: ShareRegister;
  @Prop({ default: () => false }) readonly: boolean;

  get issuerData() {
    return this.shareRegister.entity.issuerData;
  }

  get shareData() {
    return this.instrument.shareData.getHistoricVersion(this.shareRegister.settleDate);
  }

  get totals() {
    const totals = makeShareRegisterValidation(this.shareRegister);
    return totals.forInstrument(this.instrument);
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false || isBackOffice();
  }
}
</script>
