<template>
  <div class="OwnerSummaryPDF">
    <h1>Aktieägaröversikt</h1>
    <table class="OwnersSummary">
      <thead>
        <tr>
          <th class="text-left">Ägare</th>
          <th class="text-right">Antal</th>
          <th class="text-right">
            <span class="slutref">
              Kapitalandel
              <sup><a href="#slutnot-1">1</a></sup>
            </span>
          </th>
          <th class="text-right">Antal röster</th>
          <th class="text-right">Röstandel</th>
          <th v-if="canEdit"></th>
        </tr>
      </thead>
      <template v-for="(record, i) in owners" :key="`tbody-${i}`">
        <tbody>
          <tr :key="i + record.owner.viewName" class="owner-row text-bold">
            <td class="text-left">
              {{ record.owner.viewName }}
              <div class="nationalid-small text-weight-light">{{ formatNationalId(record.owner) }}</div>
            </td>
            <td class="text-right">{{ formatNumber(record.totalQuantity) }}</td>
            <td class="text-right">{{ formatPercent(record.capitalShare) }}</td>
            <td class="text-right">{{ formatNumber(record.votes) }}</td>
            <td class="text-right">{{ formatPercent(record.voteShare) }}</td>
          </tr>
          <template v-for="instrument in record.instruments">
            <tr :key="i + instrument.name" v-if="instrument.quantity" class="instrument-row">
              <td class="text-left">{{ instrument.name }}</td>
              <td class="text-right">{{ formatNumber(instrument.quantity) }}</td>
              <td class="text-right">{{ formatPercent(instrument.capital / totalCapital) }}</td>
              <td class="text-right">{{ formatNumber(instrument.votes) }}</td>
              <td class="text-right">{{ formatPercent(instrumentVoteShare(record, instrument)) }}</td>
            </tr>
          </template>
        </tbody>
      </template>
    </table>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { formatNumber } from '@/utils';
import { ShareRegister } from '@/models';
import { store } from '@shared/web/store';
import { OwnerRegisterRecord } from '@shared/shareRegister/utils';
import type {
  InstrumentPositionSummary,
  OwnerRegisterRecord as OwnerRegisterRecordShared,
} from '@shared/shareRegister/utils';

const votingPowerSorter = (a: OwnerRegisterRecord, b: OwnerRegisterRecord): number => {
  return b.voteShare - a.voteShare;
};

@Component({})
export default class OwnerSummaryPDF extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop({ default: () => false }) readonly: boolean;
  @Prop({ default: null }) settleDate: string;

  formatNumber(val) {
    return formatNumber(val);
  }

  created() {
    this.shareRegister.settleDate = this.settleDate ? new Date(this.settleDate) : new Date();
  }

  get owners() {
    return this.shareRegister.getOwnerSummaries().sort(votingPowerSorter);
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  get totalCapital() {
    return this.shareRegister.getHistoricIssuerData().totalCapital;
  }

  instrumentVoteShare(record: OwnerRegisterRecordShared, instrument: InstrumentPositionSummary) {
    return (record.voteShare * instrument.votes) / record.votes;
  }
}
</script>
<style lang="scss">
.OwnerSummaryPDF {
  position: relative;
  display: flex;
  flex-direction: column;
  page-break-before: always;
  align-items: center;
  h1 {
    font-size: 40px;
    font-weight: 200;
    line-height: 200%;
    color: $primary;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    tbody {
      page-break-inside: avoid;
      page-break-before: auto;
    }
    tr {
      &.owner-row {
        td {
          padding-top: 0.2em;
          border-top: solid 1px $grey-4;
        }
      }
      &.instrument-row {
        td {
          padding-left: 1em;
        }
      }
      th {
        border-bottom: solid 2px $primary;
      }
      td,
      th {
        font-size: 12px !important;
      }
    }
  }
}
</style>
