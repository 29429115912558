import { Entity } from '@shared/web/models';
import { Model } from '../base';
import { EntityConnectionType } from '@shared/models/types';
export default class EntityConnection extends Model {
  type: EntityConnectionType;
  entity: Entity;

  static define = () => ({
    entity: {
      model: Entity,
    },
  });
}
