<template>
  <tr :class="`large user-investment-row test-position-row ${collapsed ? 'collapsed hidden' : ''}`">
    <td></td>
    <td class="text-left wrap-text">
      <div>{{ position.instrument.name }}</div>
      <div class="text-small">{{ position.instrument.instrumentId }}</div>
    </td>
    <td class="text-left">
      <div>{{ toInstrumentGroupLabel(position.instrument.group) }}</div>
    </td>
    <td class="text-right">
      <div v-for="reservation in position.reservations" :key="reservation">
        {{ toReservationLabel(reservation) }}
      </div>
      <div class="text-small" v-if="position.pledgeOwner">Position är pantsatt</div>
    </td>
    <td class="text-right number">
      <div>{{ formatPositionValue(position) }}</div>
      <div class="text-small" v-if="position.shareNumberFrom && position.shareNumberTo">
        {{ formatNumber(position.shareNumberFrom) }} - {{ formatNumber(position.shareNumberTo) }}
      </div>
      <div class="text-small" v-if="position.certificateIssued">Aktiebrev utfärdat</div>
    </td>
    <td class="text-right number">
      <div>{{ formatPercent(positionCapitalShare(position)) }}</div>
    </td>
    <td class="text-right number">
      <div>{{ formatPercent(positionPowerQuota(position)) }}</div>
    </td>
    <td class="text-left wrap-text">
      <div>{{ insuranceCompany(position) }}</div>
      <div class="text-small">
        {{ position.insuranceNumber }}
      </div>
    </td>
    <td class="text-left wrap-text">
      <div>{{ position.custodianName }}</div>
      <div class="text-small">
        {{ position.custodianAccountNumber }}
      </div>
    </td>
  </tr>
</template>
<script lang="ts">
import { Position } from '@/models';
import { formatPositionValue, toCapitalShare, toVotingPowerQuota } from '@shared/web/models/position/common';
import { toReservationLabel } from '@shared/common';
import { toInstrumentGroupLabel } from '@shared/common';
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({})
export default class UserInvestmentsTableRow extends Vue {
  @Prop() position: Position;
  @Prop() instrumentTypePositions: Position[];
  @Prop({ default: true }) collapsed;

  insuranceCompany(position: Position) {
    if (position.owner && position.owner !== null && position.owner.isInsuranceCompany) {
      return position.owner.viewName;
    }
  }

  toInstrumentGroupLabel(group) {
    return toInstrumentGroupLabel(group);
  }

  formatPositionValue(position) {
    return formatPositionValue(position);
  }

  toReservationLabel(reservation) {
    return toReservationLabel(reservation);
  }

  positionCapitalShare(position: Position) {
    return toCapitalShare(position);
  }

  positionPowerQuota(position: Position) {
    return toVotingPowerQuota(position);
  }
}
</script>
<style lang="scss">
tr.user-investment-row td,
tr.user-investment-row td div {
  max-height: 200px;
  transition: all 0.3s ease;
  transition-property: max-height, border-bottom-width, padding;
  height: auto !important;
}
tr.user-investment-row.collapsed td,
tr.user-investment-row.collapsed td div {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: 0 !important;
  max-height: 0 !important;
  overflow: hidden;
}
</style>
