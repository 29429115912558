<template>
  <q-page v-if="ready" class="CheckSplitPage">
    <h1>Split</h1>
    <Stepper pageType="split" :currentstep="3" :isDone="isDone" :urlId="instruction.id" />
    <q-separator />
    <h4 class="table-header">Skapa split</h4>
    <TransactionsDetails :shareRegister="shareRegister" :instruction="instruction" @change="save" :readonly="true" />
    <InstrumentSourceTable
      :shareRegister="shareRegister"
      :instruction="instruction"
      :validator="validator"
      :readonly="true"
    />

    <q-btn v-bind="$defs.btn" type="button" class="step-btn" :disable="!isDone || !canEdit" @click="proceed"
      >Gå vidare</q-btn
    >
    <a-btn-link v-on:click="goBack" class="arrow icon-left">Gå tillbaka</a-btn-link>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { ShareRegister, Instruction, InstructionState } from '@/models';
import Stepper from '@/components/Stepper.vue';
import TransactionsDetails from './TransactionsDetails.vue';
import InstrumentSourceTable from './InstrumentSourceTable.vue';

import { saveInstruction, makeInstructionValidator, findInstruction } from '@/models/instruction/instructionService';
import { store } from '@shared/web/store';
import { isToNewPage } from '@/utils';
@Component({
  components: { Stepper, TransactionsDetails, InstrumentSourceTable },
})
export default class CheckSplitPage extends Vue {
  @Prop({ default: true }) readonly: boolean;
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;
    const { shareRegister, instruction } = await findInstruction(id);

    Object.assign(this, { shareRegister, instruction });
  }

  async save() {
    await saveInstruction(this.instruction);
  }

  get isDone() {
    return true;
  }

  get validator() {
    return this.shareRegister && makeInstructionValidator(this.instruction, this.shareRegister);
  }

  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }

  async proceed() {
    this.instruction.state = InstructionState.SUBMITTED;
    await saveInstruction(this.instruction);
    this.$router.push({
      path: `${this.instruction.entity.baseUrl}/split/${this.instruction.id}/skickain`,
    });
  }
  goBack() {
    this.$router.push({
      path: `${this.instruction.entity.baseUrl}/split/${this.instruction.id}/dokument`,
    });
  }
}
</script>
