<template>
  <q-page v-if="ready" class="IssueWarrantsPage">
    <h1 class="q-mb-lg">Emittera värdepapper</h1>
    <div>
      <q-btn-dropdown label="Emittera värdepapper" v-if="canEdit">
        <q-list>
          <q-item clickable v-close-popup @click="newIssueWarrantInstruction">
            <q-item-section>
              <q-item-label>Emittera teckningsoption</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup @click="newIssueConvertibleInstruction">
            <q-item-section>
              <q-item-label>Emittera konvertibel</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup @click="newIssueBondInstruction">
            <q-item-section>
              <q-item-label>Emittera obligation</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup @click="newIssueDebentureInstruction">
            <q-item-section>
              <q-item-label>Emittera vinstandelslån</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </div>
    <q-separator />
    <SecuritiesTable
      @destroy="destroy"
      header="Pågående program"
      subheader="Ta bort inaktuella registreringar som inte ska genomföras, genom att klicka på krysset"
      :value="ongoingInstructions"
    />
    <SecuritiesTable
      @destroy="destroy"
      header="Genomförda program"
      :value="executedInstructions"
      :exerciseInstructions="exerciseInstructions"
    />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Instruction, TransactionType, Entity } from '@/models';
import SecuritiesTable from './SecuritiesTable.vue';
import { findInstructions } from '@/models/instruction/instructionService';
import { remove } from '@/utils';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { store } from '@shared/web/store';

@Component({
  components: { SecuritiesTable },
})
export default class SecuritiesPage extends Vue {
  @Prop({ default: true }) readonly: boolean;
  issueWarrantInstructions: Array<Instruction> = null;
  exerciseWarrantInstructions: Array<Instruction> = null;
  issueConvertibleInstructions: Instruction[] = null;
  exerciseConvertibleInstructions: Instruction[] = null;
  issueBondInstructions: Instruction[] = null;
  exerciseBondInstructions: Instruction[] = null;
  issueDebentureInstructions: Instruction[] = null;
  exerciseDebentureInstructions: Instruction[] = null;
  entity: Entity = null;

  async created() {
    const entity = await findEntityWithRouteParams(this.$route.params);
    const issueWarrantInstructions = await findInstructions({ entity, type: TransactionType.ISSUE_WARRANT });
    const issueConvertibleInstructions = await findInstructions({ entity, type: TransactionType.ISSUE_CONVERTIBLE });
    const issueBondInstructions = await findInstructions({ entity, type: TransactionType.ISSUE_BOND });
    const exerciseWarrantInstructions = await findInstructions({ entity, type: TransactionType.EXERCISE_WARRANT });
    const exerciseConvertibleInstructions = await findInstructions({
      entity,
      type: TransactionType.EXERCISE_CONVERTIBLE,
    });
    const exerciseBondInstructions = await findInstructions({
      entity,
      type: TransactionType.EXERCISE_BOND,
    });
    const issueDebentureInstructions = await findInstructions({ entity, type: TransactionType.ISSUE_DEBENTURE });
    const exerciseDebentureInstructions = await findInstructions({
      entity,
      type: TransactionType.EXERCISE_DEBENTURE,
    });
    Object.assign(this, {
      issueWarrantInstructions,
      exerciseWarrantInstructions,
      entity,
      issueConvertibleInstructions,
      exerciseConvertibleInstructions,
      issueBondInstructions,
      exerciseBondInstructions,
      issueDebentureInstructions,
      exerciseDebentureInstructions,
    });
  }

  get exerciseInstructions() {
    return [
      ...this.exerciseWarrantInstructions,
      ...this.exerciseConvertibleInstructions,
      ...this.exerciseBondInstructions,
      ...this.exerciseDebentureInstructions,
    ];
  }

  get ongoingInstructions() {
    return [
      ...this.issueWarrantInstructions,
      ...this.issueConvertibleInstructions,
      ...this.issueBondInstructions,
      ...this.issueDebentureInstructions,
    ].filter(instruction => {
      return instruction.calculatedState !== 'EXECUTED';
    });
  }

  get executedInstructions() {
    return [
      ...this.issueWarrantInstructions,
      ...this.issueConvertibleInstructions,
      ...this.issueBondInstructions,
      ...this.issueDebentureInstructions,
    ].filter(instruction => {
      return instruction.calculatedState === 'EXECUTED';
    });
  }

  get ready() {
    return (
      this.issueWarrantInstructions !== null &&
      this.issueConvertibleInstructions !== null &&
      this.issueBondInstructions !== null &&
      this.issueDebentureInstructions !== null
    );
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  destroy(instruction) {
    remove(this.issueWarrantInstructions, instruction);
    remove(this.issueConvertibleInstructions, instruction);
    remove(this.issueBondInstructions, instruction);
    remove(this.issueDebentureInstructions, instruction);
  }
  async newIssueWarrantInstruction() {
    await this.$router.push({ path: `${this.entity.baseUrl}/optionsprogram/nytt` });
  }

  async newIssueConvertibleInstruction() {
    await this.$router.push({ path: `${this.entity.baseUrl}/konvertibler/ny` });
  }

  async newIssueBondInstruction() {
    await this.$router.push({ path: `${this.entity.baseUrl}/obligationer/ny` });
  }

  async newIssueDebentureInstruction() {
    await this.$router.push({ path: `${this.entity.baseUrl}/vinstandelslan/ny` });
  }
}
</script>
