import { Instruction } from '@shared/web/models';

import { modelHandler } from '../base';

const baseUrl = '/webapi/instruction';
const { saveModel } = modelHandler(Instruction, baseUrl);

export async function saveInstruction(instruction: Instruction) {
  const result = await saveModel(instruction);

  return result;
}
