<template>
  <q-btn
    class="a-btn-link"
    :ripple="false"
    :outline="false"
    flat
    unelevated
    :rounded="rounded || false"
    :glossy="false"
    :to="to"
    :icon="icon"
    :size="size"
    no-padding
    color="black"
    :text-color="iconColor || 'secondary'"
    dense
    :href="href"
    :target="target"
    type="button"
    :round="false"
    :loading="loading"
    :disable="loading || disable"
    @click="clickEvent"
  >
    <slot></slot>
  </q-btn>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({})
export default class ABtnLink extends Vue {
  @Prop({ default: null }) to: object;
  @Prop({ default: null }) icon: string;
  @Prop({ default: null }) size: string;
  @Prop({ default: null }) iconColor: string;
  @Prop({ default: false }) rounded: boolean;
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: undefined }) href: string | undefined;
  @Prop({ default: undefined }) target: string | undefined;

  clickEvent() {
    //this.$emit('click'); // @Vue3 borttagen. q-btn skickar redan ett click event
  }
  @Prop({ default: false }) disable;
}
</script>
<style lang="scss">
.a-btn-link {
  font-weight: 400 !important;
  font-size: 12px !important;
  display: table !important;

  &.q-btn--dense .q-btn__wrapper {
    padding-right: 0;
    padding-left: 0;
  }

  .q-icon {
    width: auto;

    &:empty {
      display: none !important;
    }
  }
  .q-icon {
    font-size: 14px;
    margin-right: 3px;
  }
  &:before,
  &:after {
    font-family: 'Material Icons';
    display: block;
    position: absolute;
    top: calc(50% - 12px);
    left: 0;
    height: 16px;
    font-size: 16px;
    width: 16px;
  }
  &.icon-left {
    padding-left: 16px;
  }
  &.arrow {
    &:before {
      content: '\e5c4';
    }
  }
  &.change {
    &:before {
      content: '\ef4a';
    }
    &:after {
      content: '\e8d4';
      color: #fff;
      font-size: 12px;
    }
  }
  &.align-right {
    float: right;
    & + .a-btn-link {
      margin-right: 18px;
    }
  }
}
</style>
