import { TransactionType } from '../models/types';

export const tradeEventTypes = [
  TransactionType.TRADE,
  TransactionType.GIFT,
  TransactionType.INHERITED,
  TransactionType.INSURANCE_PURCHASE,
  TransactionType.INSURANCE_REPURCHASE,
  TransactionType.PLEDGE,
  TransactionType.EXERCISE_PLEDGE,
  TransactionType.SECURITIES_DIVIDEND,
];

export const kapsureBookingTypes = [
  TransactionType.KAPSURE_BOOKING,
  TransactionType.KAPSURE_RIGHTS_ISSUE,
  TransactionType.KAPSURE_DVP_BUY,
];

export const kapsureSettlementTypes = [
  TransactionType.KAPSURE_SETTLEMENT,
  TransactionType.KAPSURE_BANKRUPTCY,
  TransactionType.KAPSURE_DVP_SELL,
];

export const kapsureEventTypes = [...kapsureBookingTypes, ...kapsureSettlementTypes];

export const corporateEventTypes = [
  TransactionType.RIGHTS_ISSUE,
  TransactionType.ISSUE_SHARE,
  TransactionType.ISSUE_CONVERTIBLE,
  TransactionType.SHAREREGISTER_CREATED,
  TransactionType.ISSUE_WARRANT,
  TransactionType.EXERCISE_WARRANT,
  TransactionType.SPLIT,
  TransactionType.ISSUE_CONVERTIBLE,
  TransactionType.EXERCISE_CONVERTIBLE,
  TransactionType.ISSUE_BOND,
  TransactionType.EXERCISE_BOND,
  TransactionType.KAPSURE_ISSUER_CREATED,
  TransactionType.KAPSURE_ISSUER_REMOVED,
  TransactionType.ISSUE_DEBENTURE,
  TransactionType.EXERCISE_DEBENTURE,
  TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION,
];

export function isKapsureEvent(type: TransactionType) {
  return kapsureEventTypes.includes(type);
}

export function isKapsureBooking(type: TransactionType) {
  return kapsureBookingTypes.includes(type);
}

export function isKapsureSettlement(type: TransactionType) {
  return kapsureSettlementTypes.includes(type);
}

export function isTradeEvent(type: TransactionType) {
  return tradeEventTypes.includes(type);
}

export function isCorporateEvent(type: TransactionType) {
  return corporateEventTypes.includes(type);
}
