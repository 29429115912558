<template>
  <q-page class="InvestmentOffersViewPage">
    <InvestmentOfferDetail class="investment_offer-text-editor_style" :id="$route.params.id" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import InvestmentOfferDetail from '@shared/views/investmentOffer/InvestmentOfferDetails.vue';
@Component({
  components: { InvestmentOfferDetail },
})
export default class InvestmentOffersView extends Vue {}
</script>
