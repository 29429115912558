export default {
  btn: {
    color: 'primary',
    unelevated: true,
  },
  input: {
    square: true,
    filled: true,
    'bg-color': 'white',
  },
};
