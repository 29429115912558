<template>
  <tr>
    <td class="text-left">
      <p>{{ formatDay(instruction.settleDate) }}</p>
    </td>
    <td class="text-left">
      <p>{{ type }}</p>
    </td>
    <td class="text-right">
      <p>{{ formatNumber(instructionQuantity) }}</p>
    </td>

    <td class="text-left">
      <p>{{ calculatedState }}</p>
    </td>
  </tr>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import * as _ from 'lodash';

import { toInstructionStateLabel, toTransactionTypesLabel } from '@shared/common';
import { Instruction } from '@/models';

@Component({})
export default class RecentRightsIssueRow extends Vue {
  @Prop() instruction: Instruction;

  get type() {
    return toTransactionTypesLabel(this.instruction.type);
  }

  get instructionQuantity() {
    const { instruction } = this;
    return (
      instruction.quantity ||
      instruction.corporateEvent.issueShareData?.totalQuantity ||
      _.sumBy(instruction.corporateEvent.instrumentSources, source => source.quantity)
    );
  }

  get calculatedState() {
    return toInstructionStateLabel(this.instruction.calculatedState);
  }
}
</script>
