<template>
  <div class="TradeEventsTable">
    <h4 class="table-header">{{ header }}</h4>
    <p v-if="subheader && instructions.length">{{ subheader }}</p>
    <q-markup-table flat class="standard-table position-table" separator="horizontal">
      <thead class="has-background">
        <tr>
          <th class="text-left">Senast ändrad</th>
          <th class="text-left">Säljare</th>
          <th class="text-left">Köpare</th>
          <th class="text-left">Status</th>
          <th class="text-left">Hantera</th>
          <th v-if="canEdit" class="text-center narrow">Ta bort</th>
        </tr>
      </thead>
      <tbody class="two-row">
        <tr v-for="instruction in instructions" :key="instruction.id">
          <td class="text-left">{{ formatDate(instruction.updatedAt) }}</td>
          <td class="text-left wrap-text">
            <p>{{ instruction.tradeEvent.source.owner && instruction.tradeEvent.source.owner.viewName }}</p>
            <p class="nationalid-small">{{ formatNationalId(instruction.tradeEvent.source.owner) }}</p>
          </td>
          <td>
            {{ renderDestinations(instruction) }}
          </td>
          <td class="text-left">{{ instructionState(instruction.calculatedState) }}</td>
          <td class="text-left">
            <a-btn-link
              :to="{
                path: `${instruction.entity.baseUrl}/agarbyte/${instruction.id}`,
              }"
              >{{ instruction.isEditable && canEdit ? 'Ändra' : 'Detaljer' }}</a-btn-link
            >
          </td>
          <td class="text-center v-mid" v-if="canEdit">
            <q-btn
              class="delete"
              flat
              round
              dense
              :disable="!instruction.isDeleteable"
              icon="svguse:/svg-icons.svg#icon-closedot"
              @click="deleteInstruction(instruction)"
              :class="{ inactive: !instruction.isDeleteable }"
            />
          </td>
        </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { Instruction, InstructionParty } from '@/models';
import { formatDate } from '@shared/utils';
import { uniqBy } from 'lodash';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { toInstructionStateLabel } from '@shared/common';
import RemoveInstructionModal from '@/views/editInstructionModal/RemoveInstructionModal.vue';
import { store } from '@shared/web/store';

@Component({
  components: {},
})
export default class TradeEventsTable extends Vue {
  @Prop() instructions: Array<Instruction>;
  @Prop() header: string;
  @Prop({ default: '' }) subheader;

  formatDate(date) {
    return formatDate(date, 'yyyy-MM-dd, HH:mm');
  }

  uniqBy(list, iteratee) {
    return uniqBy(list, iteratee);
  }

  renderDestinations(instruction: Instruction): string | null {
    const destinations = instruction?.destinations;
    if (!destinations?.length) {
      return null;
    }
    if (destinations.length === 1) {
      return destinations[0].owner?.viewName;
    }
    return destinations.length + ' ägare';
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  deleteInstruction(instruction) {
    this.$q
      .dialog({
        component: RemoveInstructionModal,
        componentProps: {
          value: instruction,
          modalName: 'ägarbytet',
        },
      })
      .onOk(() => {
        this.$emit('destroy', instruction);
      });
  }

  instructionState(state) {
    return toInstructionStateLabel(state);
  }
}
</script>
<style lang="scss">
.TradeEventsTable {
  max-width: 100%;
  .q-table {
    td {
      .nationalid-small {
        display: block;
      }
    }
  }
}
</style>
