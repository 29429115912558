<template>
  <InstrumentPDF :instrument="instrument">
    <dl>
      <dt>Underliggande aktie:</dt>
      <dd>{{ underlyingInstrumentName }}</dd>
    </dl>
    <dl>
      <dt>Utgivningsår:</dt>
      <dd>{{ formatDate(instrument.rightsData.issueDate) }}</dd>
    </dl>
    <dl>
      <dt>Teckningsperiod:</dt>
      <dd>
        {{ formatDate(instrument.rightsData.exercisePeriodFrom) }}
        -
        {{ formatDate(instrument.rightsData.exercisePeriodTo) }}
      </dd>
    </dl>
    <dl>
      <dt>Slutdag:</dt>
      <dd>{{ formatDate(instrument.rightsData.dueDate) }}</dd>
    </dl>
    <dl>
      <dt>Totalt tecknat belopp:</dt>
      <dd>{{ formatNumber(instrument.rightsData.nominalAmountTotal) }} {{ instrument.currencyCode }}</dd>
    </dl>
    <dl v-if="!instrument.rightsData.distributeByValue">
      <dt>Nom.bel/st</dt>
      <dd>{{ formatNumber(instrument.rightsData.nominalAmountPerPiece) }} {{ instrument.currencyCode }}</dd>
    </dl>
    <dl>
      <dt>Tecknat belopp:</dt>
      <dd>{{ formatNumber(totalAmount) }} {{ instrument.currencyCode }}</dd>
    </dl>
    <dl v-if="!instrument.rightsData.distributeByValue">
      <dt>Tecknade:</dt>
      <dd>{{ formatNumber(totalQuantity) }}</dd>
    </dl>
    <dl>
      <dt>Frivillig tilläggsinformation avseende konvertibelns villkor:</dt>
      <dd>{{ instrument.rightsData.note }} %</dd>
    </dl>
  </InstrumentPDF>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Instrument, ShareRegister } from '@/models';
import { formatDate } from '@shared/utils';
import InstrumentPDF from './InstrumentPDF.vue';

@Component({
  components: { InstrumentPDF },
})
export default class InstrumentRightsPDF extends Vue {
  @Prop() instrument: Instrument;
  @Prop() shareRegister: ShareRegister;

  formatDate(date) {
    return formatDate(date, 'yyyy-MM-dd');
  }

  get underlyingInstrumentName() {
    return this.instrument.rightsData.underlyingInstrument?.name;
  }

  get totalQuantity(): number {
    return this.shareRegister.positions
      .filter(position => position.instrument.id === this.instrument.id)
      .reduce((previousValue, position) => previousValue + position.quantity, 0);
  }

  get totalAmount(): number {
    return this.shareRegister.positions
      .filter(position => position.instrument.id === this.instrument.id)
      .reduce((previousValue, position) => previousValue + position.amount, 0);
  }
}
</script>
