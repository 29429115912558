<template>
  <q-form @submit="login" class="LogInBankID">
    <div v-if="mobileBankIdMethod" class="center">
      <img v-if="!errorMessage" class="qrImage" ref="qrImage" :src="qrImage" />
    </div>
    <div class="center" v-if="errorMessage !== null">
      <p class="error-text">{{ errorMessage }}</p>
      <q-btn flat class="q-mt-sm" @click="refreshMobileBankID" label="Försök igen" />
    </div>
    <p v-else>{{ methodInfo.text }}</p>
    <q-btn class="open-bankid-on-file" color="primary" @click="switchMethod" :disabled="bankidSameUnitIsLodading">
      <img src="@/assets/bankid.png" alt="BankID Icon" class="q-mr-sm" :label="methodInfo.btnLabel" />
      <q-inner-loading :showing="bankidSameUnitIsLodading" />
      <span v-if="methodInfo.btnLabel">{{ mobileBankIdMethod ? 'BankID på samma enhet' : ' Mobilt BankID' }}</span>
    </q-btn>
  </q-form>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { login, getStatus } from '@/models/auth/bankIdService';

@Component({
  components: {},
})
export default class LogInBankID extends Vue {
  status = null;
  orderRef = null;
  loading = false;
  bankidSameUnitIsLodading: boolean = false;
  errorMessage = null;
  mobileBankIdMethod = true;
  cancelled = false;
  qrImage = null;

  async renderQRCode(qrImageUrl) {
    const imageEl: Partial<HTMLImageElement> = this.$refs.qrImage;
    if (!this.qrImage) imageEl.src = qrImageUrl;
    this.qrImage = qrImageUrl;
  }

  async loginMethod(loginResponse) {
    if (this.mobileBankIdMethod) {
      this.bankidSameUnitIsLodading = false;
      await this.renderQRCode(loginResponse.qrImage);
    } else {
      this.bankidSameUnitIsLodading = true;
      return (window.location.href = `bankid:///?autostarttoken=${loginResponse.autoStartToken}&redirect=null`);
    }
  }

  mounted() {
    this.login();
  }

  refreshMobileBankID() {
    this.errorMessage = null;
    this.login();
  }
  get methodInfo() {
    return this.mobileBankIdMethod
      ? {
          text: 'Starta Mobilt BankID och skanna QR-koden',
          btnLabel: 'BankID på samma enhet',
        }
      : {
          text: 'Starta BankID-appen.',
          btnLabel: 'BankID på en annan enhet',
        };
  }

  async login() {
    this.errorMessage = null;
    try {
      const logInResponse = await login();
      this.status = logInResponse.status;
      this.orderRef = logInResponse.orderRef;
      await this.loginMethod(logInResponse);
    } catch (error) {
      this.loading = false;
      console.log('error from login: ', error);
      this.errorMessage = error.text;
    }

    if (this.status === 'pending') {
      this.setStatus();
    }
  }

  async setStatus() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    let count = 0;
    try {
      while (this.status === 'pending') {
        await new Promise(resolve => {
          this.renderQRCode(`${this.qrImage.split('?')[0]}?${count++}`);
          setTimeout(resolve, 1000);
        });
        if (this.cancelled === true) return;
        const logInStatus = await getStatus(this.orderRef);
        this.status = logInStatus.status;
      }
    } catch (error) {
      this.bankidSameUnitIsLodading = false;
      this.errorMessage = error.text;
    }

    if (this.status === 'complete') {
      this.$emit('done');
    }
    this.loading = false;
  }

  switchMethod() {
    this.mobileBankIdMethod = !this.mobileBankIdMethod;
    this.login();
  }

  unmounted() {
    this.cancelled = true;
  }
}
</script>
<style lang="scss">
.LogInBankID {
  min-height: 418px;
  display: grid;
  padding: 2em;
  align-content: space-evenly;
  justify-content: center;
  justify-items: stretch;
  .qrImage {
    width: 200px;
    min-height: 200px;
  }
  p {
    margin: 27px 0 0;
    text-align: center;
    line-height: 18px;
  }
  .center {
    text-align: center;
  }

  a {
    text-decoration: none;
    font-size: 12px;
  }
  .open-bankid-on-file {
    align-self: end;
    justify-self: center;
    width: 100%;
  }
}
</style>
