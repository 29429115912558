<template>
  <div>
    <div class="row q-col-gutter-sm">
      <div :class="fullWidthInputs ? 'col-12' : 'col-6'">
        <q-input
          v-bind="$defs.input"
          square
          bg-color="white"
          label="Personnummer"
          ref="personalNumber"
          :model-value="personalNumber"
          @update:model-value="v => $emit('update:personalNumber', v)"
          :disable="!isCreate || !isFullAccess"
          :rules="validation.optionalPersonalNumber"
        />
      </div>
      <div :class="fullWidthInputs ? 'col-12' : 'col-6'">
        <q-input
          v-bind="$defs.input"
          square
          bg-color="white"
          :model-value="email"
          type="email"
          @update:model-value="v => $emit('update:email', v.toString().toLocaleLowerCase().trim())"
          label="E-post"
          :rules="validation.requiredEmail"
          lazy-rules
          ref="email"
          :disable="!isFullAccess && !isMe"
        />
      </div>
      <div :class="fullWidthInputs ? 'col-12' : 'col-6'">
        <q-input
          v-bind="$defs.input"
          square
          bg-color="white"
          :model-value="phone"
          @update:model-value="v => $emit('update:phone', v)"
          :rules="validation.phone"
          label="Telefon"
          ref="phone"
          :disable="!isFullAccess && !isMe"
        />
      </div>
      <div :class="fullWidthInputs ? 'col-12' : 'col-6'">
        <q-select
          v-bind="$defs.input"
          ref="role"
          class="role-select"
          placeholder="Ange roll"
          label="Roll"
          map-options
          emit-value
          :rules="validation.required('Roll')"
          :model-value="role"
          @update:model-value="v => $emit('update:role', v)"
          :options="entityAdminRoleOptions"
          :disable="!isFullAccess"
        />
      </div>
      <div class="col-12">
        <q-list>
          <q-item-label header>Rättigheter</q-item-label>
          <q-item v-ripple tag="label" :disable="!isFullAccess">
            <q-item-section avatar>
              <q-radio
                :model-value="access"
                val="limited"
                @update:model-value="v => $emit('update:access', v)"
                :disable="!isFullAccess"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>Begränsad åtkomst</q-item-label>
              <q-item-label caption>Innebär att personen endast kan se information om bolaget</q-item-label>
            </q-item-section>
          </q-item>
          <q-item v-ripple tag="label" :disable="!isFullAccess">
            <q-item-section avatar>
              <q-radio
                :model-value="access"
                val="full"
                @update:model-value="v => $emit('update:access', v)"
                :disable="!isFullAccess"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>Full åtkomst</q-item-label>
              <q-item-label caption>Innebär att personen även kan administrera bolaget i Kapclear</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
      <div v-if="isBackOffice" class="col-12">
        <q-list>
          <q-item-label header>Inloggningsalternativ</q-item-label>
          <q-item v-ripple tag="label">
            <q-item-section avatar>
              <q-checkbox
                :model-value="canLoginWithEmail"
                @update:model-value="v => $emit('update:canLoginWithEmail', v)"
                :val="true"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>E-post inloggning</q-item-label>
              <q-item-label caption>Tillåt inloggning med e-post</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
      <q-list>
        <q-item-label header>E-postalternativ</q-item-label>
        <q-item v-ripple tag="label">
          <q-item-section avatar>
            <q-checkbox
              :model-value="wantEmails !== false"
              @update:model-value="v => $emit('update:wantEmails', v)"
              :val="true"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label>Skicka e-post notiser</q-item-label>
            <q-item-label caption>Skicka automatiska e-post meddelanden</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { hasFullAccess, isBackOffice } from '@shared/web/utils';
import { store } from '@shared/web/store';
import { Entity, EntityAdmin } from '@shared/web/models';
import validation from '@shared/utils/validation';
import { EntityAdminRole, EntityType } from '@shared/models/types';
import { entityAdminRoleLabels } from '@shared/common';

@Component
export default class EntityAdminForm extends Vue {
  @Prop({ required: true }) isCreate: boolean;
  @Prop({ required: true }) entityAdmin: EntityAdmin;
  @Prop({ required: true }) entity: Entity;
  @Prop() personalNumber: string;
  @Prop() phone: string;
  @Prop() email: string;
  @Prop() role: EntityAdminRole;
  @Prop() canLoginWithEmail: boolean;
  @Prop() wantEmails;
  @Prop() access: 'limited' | 'full';

  get validation() {
    return validation;
  }
  get isMe() {
    return this.entityAdmin && this.entityAdmin.id === store.entityAdmin?.id;
  }
  get isFullAccess() {
    return hasFullAccess();
  }
  get isBackOffice() {
    return isBackOffice();
  }
  get fullWidthInputs() {
    return isBackOffice();
  }
  get entityAdminRoleOptions() {
    const isCustodian = this.entity.types.includes(EntityType.participant);
    const roles = isCustodian
      ? Object.values(EntityAdminRole)
      : Object.values(EntityAdminRole).filter(role => role !== EntityAdminRole.CustodianAdmin);

    return roles.map(role => ({
      value: role,
      label: entityAdminRoleLabels[role],
    }));
  }
}
</script>
