<template>
  <InstrumentPDF :instrument="instrument">
    <dl>
      <dt>Underliggande aktie:</dt>
      <dd>{{ instrument.rightsData.underlyingInstrument.name }}</dd>
    </dl>
    <dl>
      <dt>Premie:</dt>
      <dd>{{ formatNumber(instrument.rightsData.premium) }}</dd>
    </dl>
    <dl>
      <dt>Utgivningsår:</dt>
      <dd>{{ formatDate(instrument.rightsData.issueDate) }}</dd>
    </dl>
    <dl>
      <dt>Teckningsperiod:</dt>
      <dd>
        {{ formatDate(instrument.rightsData.exercisePeriodFrom) }}
        -
        {{ formatDate(instrument.rightsData.exercisePeriodTo) }}
      </dd>
    </dl>
    <dl>
      <dt>Slutdag:</dt>
      <dd>{{ formatDate(instrument.rightsData.dueDate) }}</dd>
    </dl>
    <dl>
      <dt>Antal utgivna teckningsoptioner:</dt>
      <dd>{{ formatNumber(instrument.rightsData.totalQuantity) }}</dd>
    </dl>
    <dl>
      <dt>Tecknade:</dt>
      <dd>{{ formatNumber(totalQuantity) }}</dd>
    </dl>
    <dl>
      <dt>Antal underliggande per option:</dt>
      <dd>{{ formatNumber(instrument.rightsData.contractSize) }}</dd>
    </dl>
    <dl>
      <dt>Totalt antal vid full teckning:</dt>
      <dd>{{ formatNumber(instrument.rightsData.shareQuantity) }}</dd>
    </dl>
    <dl>
      <dt>Teckningskurs:</dt>
      <dd>{{ formatNumber(instrument.rightsData.exercisePrice, 2) }}</dd>
    </dl>
  </InstrumentPDF>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Instrument, ShareRegister } from '@/models';
import { formatDate } from '@shared/utils';
import InstrumentPDF from './InstrumentPDF.vue';

@Component({
  components: { InstrumentPDF },
})
export default class InstrumentRightsPDF extends Vue {
  @Prop() instrument: Instrument;
  @Prop() shareRegister: ShareRegister;

  formatDate(date) {
    return formatDate(date, 'yyyy-MM-dd');
  }

  get totalQuantity(): number {
    return this.shareRegister.positions
      .filter(position => position.instrument.id === this.instrument.id)
      .reduce((previousValue, position) => previousValue + position.quantity, 0);
  }
}
</script>
