<template>
  <div class="KapclearStamp" />
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component({})
export default class KapClearStamp extends Vue {}
</script>
<style>
.KapclearStamp {
  background-image: url('/pdf/kapclear-stamp.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 140px;
  height: 140px;
  margin: 50px auto 0;
}
</style>
