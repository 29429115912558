export class FileUpload {
  file;
  parent;
  filename;
  constructor(file, fileParent) {
    this.file = file;
    this.parent = fileParent;
  }

  getName() {
    return this.filename || this.file.name || 'Image';
  }

  getBlob() {
    return this.file.slice ? this.file.slice() : this.file;
  }

  isImage() {
    return this.file.type.indexOf('image') > -1;
  }
}

export default FileUpload;
