<template>
  <div class="relative-position" v-if="entitiesMissingEmail.length && canEdit">
    <h4 class="table-header">Saknade e-postadresser:</h4>
    <q-table :columns="columns" :rows="rows" v-if="ready" :hide-pagination="rows.length <= 5" flat :hide-no-data="true">
      <template v-slot:body-cell-email="props">
        <td>
          <div class="row q-col-gutter-sm">
            <div class="col" v-if="emailNeededForRole(props.row)">
              <q-input
                label="E-postadress (Bolagsföreträdare)"
                v-bind="$defs.input"
                v-model="newRoleEmails[props.row.id]"
                :disable="!canEdit"
              />
            </div>
          </div>
          <div class="row q-mt-sm">
            <div class="col" v-if="emailNeededForContact(props.row)">
              <q-input
                label="E-postadress (Investerare)"
                v-bind="$defs.input"
                v-model="newContactEmails[props.row.id]"
                :disable="!canEdit"
              />
            </div>
          </div>
        </td>
      </template>
      <template v-slot:body-cell-buttons="props">
        <td>
          <div class="row">
            <div class="col text-right">
              <q-btn
                color="secondary"
                dense
                padding="sm"
                size="md"
                class="q-ml-sm"
                outline
                @click="saveNewEmails"
                :disable="savingEmails || !canEdit"
                :loading="savingEmails"
              >
                <q-icon name="save" size="sm" class="q-mr-xs" />
                <span>Spara</span>
              </q-btn>
              <q-btn color="grey" dense padding="sm" size="md" class="q-ml-sm" outline @click="hideEmailRow(props.row)">
                <q-icon name="visibility_off" size="sm" class="q-mr-xs" />
                <span>Dölj</span>
              </q-btn>
            </div>
          </div>
        </td>
      </template>
      <template v-slot:bottom-row v-if="ignoredEntities.length">
        <td colspan="999" class="text-center">
          <span>{{ ignoredEntities.length }} {{ ignoredEntities.length > 1 ? 'dolda' : 'dold' }}</span>
          <q-btn
            color="secondary"
            dense
            padding="sm"
            size="md"
            class="q-ml-sm"
            outline
            @click="showAll"
            :disable="savingEmails || !canEdit"
            :loading="savingEmails"
          >
            <q-icon name="visibility" size="sm" class="q-mr-xs" />
            <span>Visa alla</span>
          </q-btn>
        </td>
      </template>
    </q-table>
    <q-inner-loading :showing="!ready" />
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { QTableProps } from 'quasar';
import RolesTable from '@shared/views/rolesAdmin/RolesTable.vue';
import { Entity, IssuerInfo } from '@shared/web/models';
import { findIssuerInfo, saveIssuerInfo } from '@shared/services/issuerInfoService';
import { orderBy, uniqBy } from 'lodash';
import { store } from '@shared/web/store';
import { findShareRegister } from '@shared/web/models/shareRegister/shareRegisterService';
import { ShareRegister } from '@shared/models/modelTypes';
import { asModelId, isSameModel } from '@shared/utils';
import { EntityType } from '@shared/models/types';

const localStorageIgnoredEmailsItemName = 'ignoredMissingEntityEmails';

@Component({
  components: { RolesTable },
})
export default class MissingContactInfoTable extends Vue {
  @Prop({ required: true }) entity: Entity;
  issuerInfo: IssuerInfo = null;
  shareRegister: ShareRegister = null;
  ready = false;
  savingEmails = false;
  newRoleEmails: Record<string, string> = {};
  newContactEmails: Record<string, string> = {};
  ignoredEntities = [];

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  get rolesEntities(): Entity[] {
    return this.entity.legalEntity.roles.map(role => role.person);
  }

  get positionsOwnersEntities(): Entity[] {
    return (
      this.shareRegister?.positions
        .filter(position => !position.settleTo)
        .map(position => [position.owner, position.investor])
        .flat() || []
    ).filter(entity => !!entity) as Entity[];
  }

  get entitiesMissingEmail(): Entity[] {
    return uniqBy([...this.rolesEntities, ...this.positionsOwnersEntities], 'id').filter(
      entity => entity && this.needsEmailInfo(entity),
    );
  }

  get columns(): QTableProps['columns'] {
    return [
      { align: 'left', label: 'För- och efternamn', field: row => row.viewName, name: 'name' },
      { align: 'left', label: 'Roll', field: row => this.getEntityRoles(row).join(', '), name: 'role' },
      { align: 'left', label: 'E-postadress', name: 'email', field: ' email' },
      { align: 'right', label: '', name: 'buttons', field: 'buttons' },
    ];
  }

  async mounted() {
    this.issuerInfo = await findIssuerInfo(this.entity);
    this.shareRegister = await findShareRegister({ entity: this.entity });
    this.ignoredEntities = JSON.parse(localStorage.getItem(localStorageIgnoredEmailsItemName)) || [];
    this.ready = true;
  }

  get rows() {
    return orderBy(
      this.entitiesMissingEmail.filter(entity => !this.ignoredEntities.includes(asModelId(entity))),
      entity => entity.viewName,
    );
  }

  needsEmailInfo(entity: Entity) {
    if (entity?.types && entity.types.includes(EntityType.insuranceCompany)) {
      return false;
    }
    return this.emailNeededForRole(entity) || this.emailNeededForContact(entity);
  }

  emailNeededForRole(entity: Entity) {
    const role = this.entity.legalEntity.roles.find(role => isSameModel(role.person, entity));
    const roleEmail = this.issuerInfo?.roles.find(contact => isSameModel(contact.entity, entity))?.email;
    return role && !roleEmail;
  }

  emailNeededForContact(entity: Entity) {
    const position = this.shareRegister?.positions.find(
      ({ owner, investor, settleTo }) => !settleTo && (isSameModel(owner, entity) || isSameModel(investor, entity)),
    );
    const issuerInfoEmail = this.issuerInfo?.contacts.find(contact => isSameModel(contact.entity, entity))?.email;
    return position && !issuerInfoEmail;
  }

  getEntityInfo(entityId) {
    return (
      this.rolesEntities.find(({ id }) => id === entityId) ||
      this.positionsOwnersEntities.find(({ id }) => id === entityId)
    );
  }

  addNewRoleEmailsToIssuerInfo() {
    for (const entityId in this.newRoleEmails) {
      this.issuerInfo.getRoleInfo(this.getEntityInfo(entityId)).email = this.newRoleEmails[entityId];
    }
  }

  addNewContactEmailsToIssuerInfo() {
    for (const entityId in this.newContactEmails) {
      this.issuerInfo.getContactInfo(this.getEntityInfo(entityId)).email = this.newContactEmails[entityId];
    }
  }

  async saveNewEmails() {
    this.savingEmails = true;
    this.addNewRoleEmailsToIssuerInfo();
    this.addNewContactEmailsToIssuerInfo();
    await saveIssuerInfo(this.issuerInfo);
    this.newRoleEmails = {};
    this.newContactEmails = {};
    this.savingEmails = false;
  }

  isEntityInvestor(entity: Entity) {
    return !!this.shareRegister?.positions.find(
      position => isSameModel(position.owner, entity) || isSameModel(position.investor, entity),
    );
  }

  isEntityCompanyRepresentative(entity: Entity) {
    return !!this.entity.legalEntity.roles.find(role => isSameModel(role.person, entity));
  }

  getEntityRoles(entity: Entity) {
    const roles = [];
    if (this.isEntityCompanyRepresentative(entity)) {
      roles.push('Bolagsföreträdare');
    }
    if (this.isEntityInvestor(entity)) {
      roles.push('Investerare');
    }
    return roles;
  }

  hideEmailRow(entity: Entity) {
    this.ignoredEntities.push(asModelId(entity));
    localStorage.setItem(localStorageIgnoredEmailsItemName, JSON.stringify([...new Set(this.ignoredEntities)]));
  }

  showAll() {
    this.ignoredEntities = [];
    localStorage.removeItem(localStorageIgnoredEmailsItemName);
  }
}
</script>
