<template>
  <div class="ShareRegisterView">
    <div class="export-link-box" v-if="stage === 'view' && isExecuted">
      <q-tabs class="table-toggle" align="left" v-model="listing">
        <q-tab :name="listings.SHARE_REGISTER">Aktiebok</q-tab>
        <q-tab :name="listings.OWNER_LISTING">Aktieägaröversikt</q-tab>
      </q-tabs>
      <ExportLinks v-if="listing === listings.SHARE_REGISTER">
        <ExportExcel :exporter="shareRegisterExcel" v-if="ready" />
        <PdfDownload
          :entity="shareRegister.entity"
          :settleDate="shareRegister.settleDate"
          instrumentProduct="KAPCLEAR"
          documentType="complete"
        >
          Aktiebok, komplett / Pdf
        </PdfDownload>
        <PdfDownload
          :entity="shareRegister.entity"
          :settleDate="shareRegister.settleDate"
          instrumentProduct="KAPCLEAR"
          documentType="legal"
        >
          Aktiebok, legal / Pdf
        </PdfDownload>
        <EmailAll :register="shareRegister" :entities="mailableEntities" label="Skapa mejl, till alla aktieägare" />
      </ExportLinks>
      <ExportLinks v-if="listing === listings.OWNER_LISTING">
        <ExportExcel :exporter="ownerRegisterExcel" />
      </ExportLinks>
    </div>
    <q-tab-panels v-model="listing">
      <q-tab-panel :name="listings.SHARE_REGISTER">
        <PositionsTable :shareRegister="shareRegister" :readonly="readonly" />
      </q-tab-panel>
      <q-tab-panel :name="listings.OWNER_LISTING">
        <OwnersTable :shareRegister="shareRegister" :readonly="readonly" />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>
<script lang="ts">
// @ts-nocheck
import { ShareRegister, Position, InstructionState, IssuerInfo } from '@shared/web/models';
import PositionsTable from './PositionsTable.vue';
import OwnersTable from './OwnersTable.vue';
import EditPositionModal from '@shared/views/editPositionModal/EditPositionModal.vue';
import { positionGrouping } from '@shared/shareRegister/utils';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import ExportLinks from '@shared/components/ExportLinks.vue';
import ExportExcel from '@shared/views/excel/ExportExcel.vue';
import shareRegisterExcel from '@shared/excel/shareRegisterExport';
import ownerRegisterExcel from '@shared/excel/ownerRegisterExport';

import PdfDownload from '@shared/components/PdfDownload.vue';
import EmailAllButton from '@shared/components/EmailAllButton.vue';
import { findIssuerInfo } from '@shared/web/models/entity/issuerInfoService';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';

enum views {
  CREATE = 'CREATE',
  CHECK = 'CHECK',
  VIEW = 'VIEW',
}

enum listings {
  SHARE_REGISTER = 'SHARE_REGISTER',
  OWNER_LISTING = 'OWNER_LISTING',
}

@Component({
  components: {
    EmailAll: EmailAllButton,
    ExportLinks,
    ExportExcel,
    PdfDownload,
    PositionsTable,
    OwnersTable,
  },
})
export default class ShareRegisterView extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop({ default: () => false }) readonly: boolean;
  @Prop({ default: () => views.VIEW }) stage: views;

  listings = listings;
  listing: listings = listings.SHARE_REGISTER;
  issuerInfo: IssuerInfo = null;

  get ready(): boolean {
    return !!this.issuerInfo;
  }

  get positionGrouping() {
    return positionGrouping(this.shareRegister.instruments, this.sortedPositions);
  }

  get mailableEntities() {
    return this.shareRegister.positions
      .filter(position => position.instrument.isShare)
      .map(position => [position.owner, position.investor])
      .flat()
      .filter(entity => !!entity);
  }

  get isExecuted() {
    const state = this.shareRegister.entity.issuerData.shareRegisterState;
    return state === InstructionState.EXECUTED;
  }

  get sortedPositions() {
    return this.shareRegister.positions.sort((a, b) => a.shareNumberFrom - b.shareNumberFrom);
  }

  get shareRegisterExcel() {
    return shareRegisterExcel(this.shareRegister, this.issuerInfo, new TableSettings());
  }

  get ownerRegisterExcel() {
    return ownerRegisterExcel(this.shareRegister, this.issuerInfo);
  }

  async mounted() {
    this.issuerInfo = await findIssuerInfo(this.shareRegister.entity);
  }

  addPosition(holder) {
    const entity = this.shareRegister.entity;
    const data = { instrument: holder, entity };

    this.$q.dialog({
      component: EditPositionModal,
      componentProps: {
        positionClass: Position,
        value: data,
        label: 'ägare',
        shareRegister: this.shareRegister,
      },
    });
  }
}
</script>
<style lang="scss">
.ShareRegisterView {
  position: relative;
  .export-link-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .q-tab-panel {
    padding: 0;
  }
  .q-tab__content {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }
}
</style>
