<template>
  <div class="SharesInfo">
    <h4 v-if="shareRegister && showHeader" class="table-header">
      {{ instrumentsHeading }} i {{ shareRegister.entity.viewName }}
    </h4>
    <div class="row">
      <div class="col-12">
        <q-markup-table flat class="standard-table" separator="horizontal">
          <thead class="has-background">
            <tr>
              <th class="text-left">{{ instrumentsHeading }}</th>
              <th class="text-right">{{ distributionLabel }}</th>
              <th v-if="!readonly" class="text-right">Kvar att fördela</th>
            </tr>
          </thead>
          <tbody v-if="loading">
            <tr>
              <td colspan="999">
                <q-skeleton type="rect" />
              </td>
            </tr>
          </tbody>
          <tbody v-if="shareRegister && !loading">
            <tr v-for="instrument in shareRegister.instruments" :key="instrument.id">
              <td class="text-left bold wrap-text">
                {{ instrument.viewName }}
              </td>
              <td class="text-right bold">
                <template v-if="instrument.isDebt">
                  {{ formatNumber(instrument.rightsData.nominalAmountTotal) }} {{ instrument.rightsData.currency }}
                </template>
                <template v-else>
                  {{ formatNumber(shareData(instrument).totalQuantity) }}
                </template>
              </td>
              <td
                v-if="!readonly"
                class="text-right validation"
                id="number-distribution"
                :class="{ valid: instrumentTotals(instrument).positionsValid }"
              >
                {{ formatNumber(getLeftToDistribute(instrument)) }}
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </div>
    </div>
    <template v-if="shareRegisterUnderConstruction">
      <div class="row q-mt-sm">
        <div class="col-md-5">
          <p class="info">
            Har bolaget genomfört en emission som ännu inte är registrerad hos Bolagsverket. Lägg upp aktieboken som den
            var innan emissionen, välj då ett tidigare "skapa datum" för aktieboken . Klicka sedan på Nyemission i
            vänsternavigationen och skapa nyemission, där anger du avstämningsdag och "skapa datum" som är efter datumet
            för skapande av aktieboken
          </p>
        </div>
      </div>
      <div class="row items-center q-mt-sm">
        <div class="col-md-5">
          <p class="label">Datum då aktieboken startar i Kapclear</p>
        </div>
        <div class="col-md-3">
          <a-input-date
            v-model="instruction.settleDate"
            v-bind="$defs.input"
            placeholder="yyyy-mm-dd"
            :readonly="readonly"
            @change="onChange"
          />
        </div>
      </div>
      <div class="row items-center q-mt-sm hidden">
        <!-- TODO: Aktivera när kompletterat instruktioner för historisk aktiebok -->
        <div class="col-md-5">
          <p>
            Om du ska lägga upp en historisk aktiebok och inte vill validera mot bolagsverket under tiden kan du välja
            det genom att kryssa för Historisk aktiebok
          </p>
        </div>
        <div class="col-md-3">
          <q-checkbox label="Historisk aktiebok" v-model="instruction.skipIssuerDataValidation" @click="onChange" />
        </div>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { ShareRegister, Instruction } from '@shared/web/models';
import { makeShareRegisterValidation } from '@shared/shareRegister/shareRegisterService';
import { Instrument } from '@shared/web/models';
import ShareData from '@shared/web/models/instrument/ShareData';

@Component({ inheritAttrs: false })
export default class SharesInfo extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction?: Instruction;
  @Prop({ default: 'Aktier' }) instrumentsHeading;
  @Prop({ default: 'Antal' }) distributionLabel;
  @Prop() readonly: boolean;
  @Prop({ default: true }) showHeader;
  @Prop({ default: false }) loading;

  get shareRegisterUnderConstruction() {
    return this.instruction && !this.instruction.executed;
  }

  get shareRegisterValidation() {
    return makeShareRegisterValidation(this.shareRegister);
  }

  get instrumentTotalsMap() {
    return this.shareRegisterValidation.getInstrumentMap();
  }

  onChange() {
    this.$emit('change');
  }

  shareData(instrument: Instrument): ShareData {
    return instrument.shareData.getHistoricVersion(this.shareRegister.settleDate);
  }

  instrumentTotals(instrument) {
    return this.instrumentTotalsMap.get(instrument);
  }

  getLeftToDistribute(instrument) {
    const totals = this.instrumentTotals(instrument);
    return instrument.shareData.totalQuantity - totals.totalQuantity;
  }
}
</script>
<style lang="scss">
.SharesInfo {
  .settle-date {
    display: flex;
    .label {
      margin: 12px 20px 0 0;
    }
    .q-input {
      max-width: 300px;
    }
  }

  .info {
    margin: 0 0 16px;
  }

  .q-table {
    th {
      &:nth-child(1) {
        min-width: auto;
      }
    }
    .bold {
      font-weight: 700;
      color: $grey-5;
    }
  }
}
</style>
