<template>
  <q-select
    :options="options"
    emit-value
    map-options
    v-model="model"
    v-bind="$attrs"
    use-input
    fill-input
    input-debounce="0"
    hide-selected
    @filter="filterFnAutoselect"
    ref="input"
    dropdown-icon="expand_more"
  >
  </q-select>
</template>
<script lang="ts">
import { Component, Vue, Prop, Ref } from 'vue-facing-decorator';
import { countryOptions } from '../utils/localeInfo';

@Component({})
export default class SelectCountry extends Vue {
  @Ref() readonly input;
  @Prop() modelValue: string;

  get model() {
    return this.modelValue;
  }
  set model(val) {
    this.$emit('update:modelValue', val);
  }

  options = countryOptions;

  get validate() {
    return this.input.validate();
  }

  filterFnAutoselect(val, update) {
    update(
      () => {
        this.options = filterOptions(val);
      },

      ref => {
        if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
          ref.moveOptionSelection(1, true);
          ref.toggleOption(ref.options[ref.optionIndex], true);
        }
      },
    );
  }
}

function filterOptions(val) {
  if (val === '') {
    return countryOptions;
  }
  const needle = val.toLowerCase();
  return countryOptions.filter(
    v => v.label.toLowerCase().indexOf(needle) > -1 || v.value.toLowerCase().indexOf(needle) > -1,
  );
}
</script>
