<template>
  <div class="CustodianAccountTable test-custodian-account-table">
    <div class="heading-container">
      <ExportLinks>
        <ExportExcel :exporter="excelExporter" />
      </ExportLinks>
    </div>
    <q-markup-table flat class="standard-table" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Innehavare</th>
          <th class="text-left">Investeringsansvarig</th>
          <th class="text-left">Försäkringsägare</th>
          <th class="text-left">Depånummer</th>
          <th class="text-right">Totalt antal</th>
          <th class="text-right">Nominellt belopp</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="record in records" :key="id(record)">
          <td>{{ viewName(record.owner) }}</td>
          <td>{{ toInvestmentManager(record) }}</td>
          <td>{{ viewName(record.investor) }}</td>
          <td>{{ record.custodianAccountNumber }}</td>
          <td class="text-right">{{ formatNumber(record.quantity) }}</td>
          <td class="text-right">{{ formatNumber(record.amount) }}</td>
        </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-facing-decorator';
import { formatNationalId } from '@shared/common';
import ExportLinks from '@shared/components/ExportLinks.vue';
import ExportExcel from '@shared/views/excel/ExportExcel.vue';

const toInvestmentManager = ({ investor, owner }) => {
  if (investor) {
    const { isPerson, legalEntity } = investor;
    return isPerson ? '' : legalEntity.investorManager?.viewName;
  } else {
    const { isPerson, legalEntity } = owner;
    return isPerson ? '' : legalEntity.investorManager?.viewName;
  }
};

@Component({
  components: { ExportLinks, ExportExcel },
})
export default class CustodianAccountTable extends Vue {
  @Prop() holdings;
  @Prop() instrument;
  settleDate = new Date();

  toInvestmentManager(record) {
    return toInvestmentManager(record);
  }

  viewName(entity) {
    return entity && entity.viewName;
  }

  id(record) {
    return `${record.instrument.id}-${record.owner?.id}-${record.investor?.id}-${record.custodianAccountNumber}`;
  }

  @Watch('settleDate')
  async onSetSettleDate() {
    this.$emit('setSettleDate', this.settleDate);
  }

  get records() {
    if (this.instrument) {
      const { holdings } = this.holdings;
      return holdings
        .filter(holding => holding.instrument.id === this.instrument.id)
        .sort((a, b) => (a.investor?.viewName?.trim() || '').localeCompare(b.investor?.viewName?.trim() || '', 'sv'))
        .sort((a, b) => (a.owner?.viewName?.trim() || '').localeCompare(b.owner?.viewName?.trim() || '', 'sv'));
    }
    return [];
  }

  async excelExporter() {
    return {
      name: this.instrument?.name || 'Förvar',
      records: this.records.map(record => ({
        Innehavare: record.owner?.viewName,
        Investeringsansvarig: toInvestmentManager(record),
        'Innehavare person-/orgnr.': formatNationalId(record.owner),
        Investerare: record.investor?.viewName,
        'Investerare person-/orgnr.': formatNationalId(record.investor),
        Depånummer: record.custodianAccountNumber,
        Antal: record.quantity,
        'Nominellt belopp': record.amount,
      })),
    };
  }
}
</script>
<style lang="scss">
.CustodianAccountTable {
  .heading-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    .text-small {
      margin-bottom: 8px;
    }
  }
  .q-table {
    tbody {
      td {
        border-bottom: solid 2px $grey-1;
      }
    }
  }
  small {
    display: block;
    color: $grey-4;
  }
}
</style>
