import { store } from '@shared/web/store';
import { isReactive, reactive } from 'vue';

export const isBackOffice = () => {
  return !!import.meta.env.VITE_APP_IS_BO;
};

export const hasFullAccess = () => {
  if (isBackOffice()) {
    return true;
  }
  return store?.entityAdmin?.limitedAccess === false;
};

export const isFullAccess = hasFullAccess;

export const makeReactive = obj => (isReactive(obj) ? obj : reactive(obj));
