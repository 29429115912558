<template>
  <div class="SplitsTable">
    <h4 class="table-header">{{ header }}</h4>
    <p v-if="subheader && instructions.length">{{ subheader }}</p>
    <q-markup-table flat class="standard-table position-table" separator="horizontal">
      <thead class="has-background">
        <tr>
          <th class="text-left">Senast ändrad</th>
          <th class="text-left">Händelse</th>
          <th class="text-left">Antal nya aktier</th>
          <th class="text-left">Se mer</th>
          <th class="text-center narrow" v-if="canEdit">Ta bort</th>
        </tr>
      </thead>
      <tbody class="two-row">
        <tr v-for="instruction in instructions" :key="instruction.id">
          <td class="text-left">{{ formatDate(instruction.updatedAt) }}</td>
          <td class="text-left">{{ transactionType(instruction.type) }}</td>
          <td class="text-left">{{ formatNumber(instruction.quantity) }}</td>
          <td class="text-left">
            <a-btn-link :to="{ path: `${instruction.entity.baseUrl}/split/${instruction.id}` }">{{
              instruction.isEditable && canEdit ? 'Ändra' : 'Detaljer'
            }}</a-btn-link>
          </td>
          <td class="text-center v-mid" v-if="canEdit">
            <q-btn
              class="delete"
              flat
              round
              dense
              color="white"
              icon="svguse:/svg-icons.svg#icon-closedot"
              @click="deleteInstruction(instruction)"
              :disable="!instruction.isEditable"
              :class="{ inactive: !instruction.isEditable }"
            />
          </td>
        </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { Instruction } from '@/models';
import { formatDate } from '@shared/utils';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { toTransactionTypesLabel } from '@shared/common';
import RemoveInstructionModal from '@/views/editInstructionModal/RemoveInstructionModal.vue';
import { store } from '@shared/web/store';

@Component({})
export default class SplitsTable extends Vue {
  @Prop() value: Array<Instruction>;
  @Prop() header: string;
  @Prop({ default: '' }) subheader;

  formatDate(date) {
    return formatDate(date, 'yyyy-MM-dd, HH:mm');
  }

  get instructions() {
    return this.value.filter(instruction => instruction.type !== 'SHAREREGISTER_CREATED');
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  deleteInstruction(instruction) {
    this.$q
      .dialog({
        component: RemoveInstructionModal,
        componentProps: {
          value: instruction,
          modalName: 'split',
        },
      })
      .onOk(() => {
        this.$emit('destroy', instruction);
      });
  }

  transactionType(state) {
    return toTransactionTypesLabel(state);
  }
}
</script>
<style lang="scss">
.SplitsTable {
  max-width: 100%;
  .q-table {
    width: $col-5;
  }
}
</style>
