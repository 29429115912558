<template>
  <div class="InvestmentOffersList standard-table">
    <h4 class="table-header">Aktuella</h4>
    <q-table
      class="my-sticky-header-table q-mt-lg"
      type="a"
      :rows="currentOffersRow"
      row-key="id"
      :columns="columns"
      :pagination="{ rowsPerPage: 0 }"
      :hide-pagination="true"
      data-cy="active-offers-list"
    >
      <template v-if="isEditView" v-slot:top-row>
        <TableRowBtn @click="createNewInvestmentOffer" label="Nytt erbjudande" class="test-add-new-offer" />
      </template>
      <template v-slot:body-cell-title="props">
        <q-td :props="props" class="primary test-existing-active-offer">
          <router-link
            class="title-link text-primary"
            :to="{ name: 'InvestmentOfferDetails', params: { id: props.row.id } }"
          >
            {{ props.row?.title }}
          </router-link>
        </q-td>
      </template>

      <template v-if="currentOffersRow.length === 0" v-slot:no-data>
        <q-tr>
          <q-td colspan="100%" class="test-active-offers-list-empty">Inga erbjudanden</q-td>
        </q-tr>
      </template>
    </q-table>

    <h4 class="table-header">Genomförda</h4>
    <q-table
      class="my-sticky-header-table test-completed-offers-list"
      :rows="endedInvestmentOffersRows"
      :columns="columns"
      :pagination="{ rowsPerPage: 0 }"
      :hide-pagination="true"
      row-key="id"
      flat
    >
      <template v-slot:body-cell-title="props">
        <q-td :props="props">
          <router-link
            class="title-link text-primary"
            :to="{ name: 'InvestmentOfferDetails', params: { id: props.row.id } }"
          >
            {{ props.row?.title }}
          </router-link>
        </q-td>
      </template>

      <template v-if="endedInvestmentOffersRows.length === 0" v-slot:no-data>
        <q-tr>
          <q-td colspan="100%" class="test-completed-offers-list-empty">Inga genomförda erbjudanden</q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { QTableProps } from 'quasar';
import { formatISO9075 } from 'date-fns';
import TableRowBtn from '../../components/TableRowButton.vue';

@Component({
  components: {
    TableRowBtn,
  },
  emits: ['setInvestmentOffer', 'changeComponent'],
})
export default class InvestmentsOfferList extends Vue {
  @Prop() investmentOffers;
  @Prop() createNewInvestmentOffer;
  @Prop({ type: Boolean, default: false }) isEditView;
  columns: QTableProps['columns'] = [
    {
      name: 'title',
      field: row => row.title,
      label: 'Rubrik',
      align: 'left',
      classes: 'q-table--col-auto-width',
    },
    {
      name: 'updatedAt',
      field: 'updatedAt',
      format: updatedAt => updatedAt && formatISO9075(new Date(updatedAt)),
      label: 'Senaste uppdatering',
      align: 'right',
      sortable: true,
    },
  ];

  get currentOffersRow() {
    return this.investmentOffers.filter(offer => !offer.archived && !offer.draft);
  }

  get endedInvestmentOffersRows() {
    return this.investmentOffers.filter(offer => offer.archived && !offer.draft);
  }
}
</script>
<style lang="scss">
.InvestmentOffersList {
  .title-link {
    padding: 4px 14px 8px 0;
  }
  .q-td {
    span {
      display: inline-block;
      color: var(--P3, #378109);
      /* P1 Small */
      font-family: Helvetica;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 133%;
    }
  }
}
</style>
