import { IEntity, IInstruction } from '../modelTypes';
import * as _ from 'lodash';
import { default as errors } from './errors';
export default function (instruction: IInstruction, entity: IEntity) {
  function getDestinationQuantity() {
    return _.sumBy(instruction.destinations, destination => destination.quantity);
  }

  function quotaValue({ totalCapital, totalQuantity }) {
    return totalCapital / totalQuantity;
  }

  function inValidPrice() {
    if (entity.issuerData.quotaValue > Number(instruction.price)) {
      return errors.inValidPrice;
    }
  }

  function getCapitalInfo() {
    const totalQuantity = getDestinationQuantity();
    const totalCapitalForCurrentQuota = totalQuantity * quotaValue(entity.issuerData);
    const totalCapital = totalQuantity * instruction.price;

    return {
      totalQuantity,
      validQuantity: totalQuantity === instruction.corporateEvent.issueShareData.totalQuantity,
      totalCapital,
      totalCapitalForCurrentQuota,
      totalCapitalDifference: totalCapital - totalCapitalForCurrentQuota,
    };
  }

  return {
    getCapitalInfo,
    inValidPrice,
  };
}
