<template>
  <div class="TransactionsPDF">
    <div class="page-break" v-if="shareRegister">
      <CoverPagePDF :title="title" :subtitle="subtitle">
        <hr class="separator short" />
        <KapclearStamp />
      </CoverPagePDF>
    </div>
    <ol class="instruments" v-if="data.records">
      <li :key="holder.id" v-for="{ holder, transactions } in data.records">
        <component :is="getPdfRenderComponent(holder)" :instrument="holder" :shareRegister="shareRegister" />
        <ol class="transactions">
          <li class="transaction" :key="transaction.id" v-for="transaction in transactions">
            <dl class="boxes">
              <dt>Införd i aktieboken</dt>
              <dd>{{ formatDay(transaction.settleDate) }}</dd>
              <dt>Transaktion</dt>
              <dd>{{ typeLabel(transaction) }}</dd>
              <dt>Parter</dt>
              <dd>
                <div>
                  <span>Köpare: </span><span>{{ transaction.owner.viewName }}</span>
                </div>
                <div v-if="transaction.previousOwner">
                  <span>Säljare:</span> <span>{{ transaction.previousOwner.viewName }}</span>
                </div>
              </dd>
              <dt>Antal</dt>
              <dd>{{ transaction.quantity }}</dd>
              <dt>Nominellt Belopp</dt>
              <dd>{{ transaction.amount }}</dd>
              <dt>Pris</dt>
              <dd>{{ transaction.price }}</dd>
            </dl>
            <dl class="complementary">
              <dt>Affärsdag</dt>
              <dd v-if="transaction.tradeDate">{{ formatDay(transaction.tradeDate) }}</dd>
              <dd v-else>-</dd>
              <template v-if="showCustodian(transaction)">
                <dt>Förvar</dt>
                <dd>{{ transaction.custodian.viewName }} {{ transaction.position.custodianAccountNumber }}</dd>
              </template>
            </dl>
          </li>
        </ol>
      </li>
    </ol>
  </div>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { setToken } from '@/authentication';
import { Entity, Instrument, Transaction, ShareRegister, InstrumentCategory } from '@/models';
import { findTransactions } from '@/models/transaction/transactionService';
import formatDate from 'date-fns/format';
import compareDescDate from 'date-fns/compareDesc';
import { findEntity } from '@/models/entity/entityService';
import CoverPagePDF from './CoverPagePDF.vue';
import ShareRegisterSummary from './ShareRegisterSummary.vue';
import InstrumentShaPDF from './InstrumentShaPDF.vue';
import InstrumentRightsPDF from './InstrumentRightsPDF.vue';
import KapclearStamp from './KapclearStamp.vue';
import { findShareRegisterByEntityIdForPDF } from '@shared/web/models/shareRegister/shareRegisterService';
import { mergeTransactions, ExtendedTransaction } from '@shared/web/models/transaction/utils';
import { isTradeEvent } from '@shared/instruction/utils';
import { toTransactionTypesLabel } from '@shared/common';

@Component({
  components: { CoverPagePDF, ShareRegisterSummary, InstrumentShaPDF, InstrumentRightsPDF, KapclearStamp },
})
export default class TransactionsPDF extends Vue {
  @Prop({ default: '' }) token;
  @Prop({ default: '' }) pdfType;
  @Prop({ default: '' }) pdfCategory;
  @Prop({ default: '' }) entity;
  @Prop({ default: '' }) owner;
  @Prop({ default: '' }) custodian;
  @Prop({ default: '' }) instrument;
  @Prop({ default: '' }) settleDate;
  @Prop({ default: '' }) dateType;
  @Prop({ default: '' }) from;
  @Prop({ default: '' }) to;

  typeLabel(transaction: ExtendedTransaction) {
    const { transactionType } = transaction;
    if (isTradeEvent(transactionType)) {
      return 'Ägarbyte';
    }
    return toTransactionTypesLabel(transactionType);
  }

  shareRegister: ShareRegister = null;
  data: {
    entity?: Entity;
    owner?: Entity;
    custodian?: Entity;
    records?: Array<{ holder: Instrument; transactions: ExtendedTransaction[] }>;
  } = {};
  stage = 0;

  get title() {
    switch (this.pdfCategory) {
      default:
        return 'Transaktioner';
    }
  }

  get subtitle() {
    let subtitles = this.data.custodian ? [this.data.custodian.viewName] : [];
    subtitles = this.data.entity ? [...subtitles, this.data.entity.viewName] : subtitles;
    return subtitles;
  }

  mounted() {
    if (this.token) {
      setToken(this.token);
    }
    this.load();
  }

  showCustodian(transaction) {
    return !!(transaction.position?.custodian && transaction.position?.custodianAccountNumber);
  }

  formatDate(date) {
    return formatDate(date, 'yyyyMMDD');
  }

  sortTransactionsByDate(transactions, dateType: 'settleDate' | 'registrationDate') {
    return transactions.sort((a, b) => compareDescDate(a[dateType], b[dateType]));
  }

  groupRecordsByHolder(
    transactions: Transaction[],
    holderType: 'instrument' | 'owner',
  ): Array<{ holder: Instrument; transactions: ExtendedTransaction[] }> {
    transactions = mergeTransactions(transactions);
    const groupedByInstrument = transactions.reduce((records, transaction) => {
      const holder = transaction[holderType];
      const record = records[holder.id] || { holder, transactions: [] };
      return {
        ...records,
        [holder.id]: {
          ...record,
          transactions: [...record.transactions, transaction],
        },
      };
    }, {});
    return Object.values(groupedByInstrument);
  }

  async load() {
    this.shareRegister = await findShareRegisterByEntityIdForPDF({
      entity: this.entity,
      ...(this.instrument ? { instrument: this.instrument } : {}),
      ...(this.custodian ? { custodian: this.custodian } : {}),
      ...(this.settleDate ? { settleDate: this.settleDate } : { settleDate: new Date().toISOString() }),
    });

    const { entity, transactions } = await findTransactions({
      entity: this.entity,
      ...(this.instrument ? { instrument: this.instrument } : {}),
      ...(this.owner ? { owner: this.owner } : {}),
      ...(this.custodian ? { custodian: this.custodian } : {}),
      ...(this.dateType ? { dateType: this.dateType } : {}),
      ...(this.from ? { from: this.from } : {}),
      ...(this.to ? { to: this.to } : {}),
    });
    const custodian = this.custodian && (await findEntity(this.custodian));
    const records = this.groupRecordsByHolder(transactions, 'instrument');
    this.data = { entity, custodian, records };
  }

  getPdfRenderComponent(holder) {
    if (holder.category === InstrumentCategory.SHA) {
      return InstrumentShaPDF;
    }
    return InstrumentRightsPDF;
  }
}
</script>
<style lang="scss">
@media print {
  .MessageBanner {
    display: none;
  }
}

.TransactionsPDF {
  padding: 0 15px;
  font-size: 12px;
  html,
  body {
    height: 100%;
    width: 100%;
  }
  ol {
    padding-inline: 0;
    &.transactions > li {
      margin-bottom: 3em;
    }
    .transaction {
      page-break-inside: avoid;
    }
  }
  dl {
    dd,
    dt {
      padding: 2px;
      text-align: center;
      display: inline-block;
    }
    dt {
      font-weight: bold;
    }
    dd {
      font-size: 14px;
    }
    &.boxes {
      display: grid;
      column-gap: 10px;
      grid-template-columns: 125px 100px auto 75px 75px;
      dt {
        grid-row-start: 1;
        border: solid 2px lightgrey;
        border-bottom: solid 1px lightgrey;
        font-weight: bold;
      }
      dd {
        border: solid 2px lightgrey;
        border-top: 0;
        div {
          display: flex;
          justify-content: flex-start;
          align-items: baseline;
          :first-child {
            font-weight: bold;
            font-size: 12px;
            width: 75px;
            text-align: left;
          }
        }
      }
    }
    &.complementary {
      margin-top: 10px;
      display: grid;
      column-gap: 10px;
      grid-template-columns: 175px 175px auto auto auto;
      dd,
      dt {
        text-align: left;
      }
      dt {
        grid-row-start: 1;
        font-weight: bold;
      }
      dd {
        grid-row-start: 2;
      }
    }
  }

  .separator {
    border: 0;
    height: 2px;
    background: #2d612c;
    margin: 10px auto;
    &.short {
      width: 49%;
    }
  }
}
</style>
