<template>
  <q-btn color="secondary" dense rounded flat class="a-info-btn" icon="info_outlined">
    <q-menu anchor="top start" self="bottom left" class="a-info-btn-menu" max-width="400px">
      <slot></slot>
    </q-menu>
  </q-btn>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';

@Component({})
export default class AInfoBtn extends Vue {}
</script>
<style lang="scss">
.q-btn.a-info-btn {
  font-size: 12px;
  font-weight: 400;
  .q-icon {
    font-size: 18px;
    display: inline-block !important;
  }
}
.a-info-btn-menu {
  padding: 10px;
}
</style>
