<template>
  <q-page v-if="ready" class="SubmitShareRegisterPage">
    <div class="row justify-between">
      <h1>Skapa aktiebok</h1>
      <LastEntityUpdate />
    </div>
    <Stepper pageType="shareRegister" :currentstep="4" :isDone="isDone" :urlId="urlId" />
    <h4>Snart klar!</h4>
    <p class="p2">
      Nu har du skapat en preliminär aktiebok och bifogat dokument som behövs.<br />
      Nu kommer Kapclear granska aktieboken och jämföra med bifogade dokumentationen för att kunna verifiera och
      säkerställa att aktieboken är korrekt, normalt tar detta 1-2 arbetsdagar.
    </p>
    <q-separator />
    <q-form ref="form">
      <template v-if="isNew">
        <div class="row q-gutter-x-lg">
          <q-input
            class="col"
            v-bind="$defs.input"
            v-model="userInfo.email"
            :rules="validation.requiredEmail"
            label="E-post"
            lazy-rules
          ></q-input>
          <q-input
            class="col"
            v-bind="$defs.input"
            v-model="userInfo.phone"
            :rules="validation.required('Telefon')"
            label="Telefon"
          ></q-input>
        </div>
      </template>
      <p v-if="errorMessage != null" class="error-text">
        {{ errorMessage.text }}
      </p>
      <q-btn class="step-btn" v-bind="$defs.btn" :disable="!isDone" @click="submit">
        {{ user.canLoginWithEmail ? 'Skicka in' : 'Signera och skicka in' }}
      </q-btn>
      <a-btn-link v-on:click="goBack" class="arrow icon-left">Gå tillbaka</a-btn-link>
    </q-form>
  </q-page>
</template>
<script lang="ts">
import { Component, Watch, Vue } from 'vue-facing-decorator';
import { ShareRegister, InstructionState } from '@/models';
import { FormComponent } from '@shared/mixins';
import Stepper from '@/components/Stepper.vue';
import LastEntityUpdate from '@shared/components/LastEntityUpdate.vue';
import { findShareRegisterByEntityId, submitShareRegister } from '@shared/web/models/shareRegister/shareRegisterService';
import { validation } from '@shared/common';
import BankIdSignModal from '@/views/auth/BankIdSignModal.vue';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { findCurrentEntityAdmin } from '@/models/entityAdmin/entityAdminService';
import { isToNewPage } from '@/utils';
import store from '@/store';

@Component({
  components: { Stepper, LastEntityUpdate },
  mixins: [FormComponent],
})
export default class SubmitShareRegisterPageView extends Vue {
  shareRegister: ShareRegister = null;
  userInfo = {
    email: null,
    phone: null,
  };
  error = null;

  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const entity = await findEntityWithRouteParams(this.$route.params);
    this.shareRegister = await findShareRegisterByEntityId(entity);
    const entityAdmin = await findCurrentEntityAdmin(entity);
    const { email, phone } = entityAdmin;
    Object.assign(this.userInfo, { email, phone });
  }

  get ready() {
    return this.shareRegister != null;
  }
  get validation() {
    return validation;
  }

  get isNew() {
    return this.shareRegister.entity.issuerData.shareRegisterState === InstructionState.NEW;
  }

  get isDone() {
    if (!this.isNew) {
      return true;
    }
    const { email, phone } = this.userInfo;
    return email != null && phone != null;
  }

  get urlId() {
    return this.shareRegister.entity.countryCode + '/' + this.shareRegister.entity.nationalId;
  }

  get user() {
    return store.profile.user;
  }

  goBack() {
    this.$router.push({
      path: `${this.shareRegister.entity.baseUrl}/skapa-aktiebok/granska`,
    });
  }

  signBankIDModal() {
    this.$q.dialog({
      component: BankIdSignModal,
      componentProps: {
        payload: { entity: this.shareRegister.entity.id },
        type: 'CREATE_SHAREREGISTER',
        onSign: async () => {
          await this.submitShareRegisterAndGoToDashboard();
        },
      },
    });
  }

  async submitShareRegisterAndGoToDashboard() {
    await submitShareRegister({ userInfo: this.userInfo, entity: this.shareRegister.entity.id });
    this.$router.push({
      path: `${this.shareRegister.entity.baseUrl}/dashboard`,
    });
  }

  async submit() {
    this.errorMessage = null;

    await this.submitForm(async () => {
      if (this.user.canLoginWithEmail) {
        await this.submitShareRegisterAndGoToDashboard();
      } else {
        this.signBankIDModal();
      }
    });
  }
}
</script>
<style lang="scss">
.SubmitShareRegisterPage {
  .q-form {
    max-width: 632px;
    margin-top: 40px;
  }
  .q-field {
    margin-bottom: 2px;
  }
}
</style>
