import {
  IEntity,
  IInstrument,
  IPosition,
  ShareRegister,
  TableSettings,
  IOwnerParticipation,
} from '../models/modelTypes';
import * as _ from 'lodash';
import { getHistoricShareData } from '@shared/models/historicShareData';
import { InstrumentGroup } from '..';
import { GeneralMeetingParticipation } from '@shared/web/models';
import { sumBy } from 'lodash';
type Holder = IInstrument | IEntity;

type TablePosition = {
  quantity?: number;
  instrument: IInstrument;
  owner: IEntity;
  investor?: IEntity;
  absoluteInvestor?: IEntity;
  absoluteInvestorManager?: IEntity;
  shareNumberFrom?: number;
  shareNumberTo?: number;
  insuranceNumber?: string;
  custodianAccountNumber?: string;
  custodian?: IEntity;
  custodianName?: string;
  certificateIssued?: boolean;
  pledgeOwner?: IEntity;
};

export type InstrumentPositionSummary = {
  name: string;
  quantity: number;
  capital: number;
  votes: number;
};

export type OwnerRegisterRecord = {
  owner: IEntity;
  instruments: InstrumentPositionSummary[];
  totalQuantity: number;
  capitalShare: number;
  votes: number;
  voteShare: number;
};

export type GeneralMeetingRecord = OwnerRegisterRecord & {
  participation: IOwnerParticipation;
  participatingVotes: number;
  participatingVoteShare: number;
};

export type PositionGroup<T extends Holder = Holder> = {
  holder: T;
  positions: Array<TablePosition>;
};

function getInstrumentGroupOrder(instrument: IInstrument) {
  const max = 10;
  const order = {
    [InstrumentGroup.SHA]: 0,
    [InstrumentGroup.PREF]: 1,
    [InstrumentGroup.PAYSHA]: 2,
    [InstrumentGroup.BOND]: 3,
    [InstrumentGroup.CONVERTIBLE]: 4,
    [InstrumentGroup.DEBENTURE]: 5,
    [InstrumentGroup.WARRANT]: 6,
    [InstrumentGroup.SHAREHOLDER_CONTRIBUTION]: 7,
  };
  return order[instrument.group] || max;
}

function defaultInstrumentSorter(a: IInstrument, b: IInstrument) {
  const groupOrder = getInstrumentGroupOrder(b) - getInstrumentGroupOrder(a);
  return Math.sign(groupOrder !== 0 ? groupOrder : a.viewName.localeCompare(b.viewName));
}

export function positionGrouping(instruments: IInstrument[], positions: IPosition[]) {
  return instruments.sort(defaultInstrumentSorter).map(instrument => {
    const position = positions.filter(position => position.instrument === instrument);
    return {
      holder: instrument,
      positions: position,
    };
  });
}

type PositionFilter = {
  owner?: IEntity;
  investor?: IEntity;
  instrument?: IInstrument;
};

function stringComparator(a, b, sortDesc) {
  if (a === b) {
    return 0;
  }
  if (sortDesc) {
    return a < b ? 1 : -1;
  } else {
    return a < b ? -1 : 1;
  }
}

function intComparator(a, b, sortDesc) {
  return sortDesc ? b - a : a - b;
}

export function getPositionGroups(
  positions: Array<TablePosition>,
  instruments: Array<IInstrument>,
  tableSettings: TableSettings,
): Array<PositionGroup> {
  positions = positions || [];

  const { filterInstrument, groupProperty, sortProperty, sortDesc } = tableSettings;

  if (filterInstrument != null) {
    positions = positions.filter(pos => pos.instrument === filterInstrument);
    instruments = [filterInstrument];
  }
  if (groupProperty === 'instrument') {
    return getInstrumentPositionGroups(positions, instruments, sortProperty, sortDesc);
  }
  if (groupProperty === 'owner') {
    return getOwnerPositionGroups(positions, sortProperty, sortDesc);
  }
  if (groupProperty === 'absoluteInvestor') {
    return getAbsoluteInvestorManagerPositionGroups(positions, sortProperty, sortDesc);
  }
  throw new Error('groupProperty is not defined');
}

export function getInstrumentPositionGroups(
  positions: Array<TablePosition>,
  instruments: Array<IInstrument>,
  sortProperty: string,
  sortDesc = false,
): PositionGroup<IInstrument>[] {
  const sortedPositions = sortPositions(positions, sortProperty, sortDesc);
  const sortedInstruments = instruments.sort(defaultInstrumentSorter);
  return groupPositions(
    sortedPositions,
    sortedInstruments,
    instrument => position => position.instrument === instrument,
  );
}

function getAbsoluteInvestorManagerPositionGroups(
  positions: Array<TablePosition>,
  sortProperty: string,
  sortDesc: boolean,
): PositionGroup<IEntity>[] {
  positions = sortPositions(positions, sortProperty, sortDesc);
  const investors = getUniqueAbsoluteInvestorManagers(positions);

  const filter = investor => {
    return position => position.absoluteInvestorManager === investor;
  };
  return groupPositions(positions, investors, filter);
}

function groupPositions<T extends Holder = Holder>(positions: Array<TablePosition>, holders: Array<T>, filter) {
  if (holders.length === 0) {
    return [
      {
        holder: null,
        positions,
      },
    ];
  }
  return holders.map(holder => {
    return {
      holder,
      positions: positions.filter(filter(holder)),
    };
  });
}
function getUniqueAbsoluteInvestorManagers(positions: Array<TablePosition>) {
  return _.uniq(positions.map(pos => pos.absoluteInvestorManager));
}

export function getGeneralMeetingRecords(
  shareRegister: ShareRegister,
  generalMeetingParticipation: GeneralMeetingParticipation,
): GeneralMeetingRecord[] {
  const ownerSummaries = getOwnerSummaries(shareRegister);

  const records = ownerSummaries.map(ownerSummary => {
    return {
      ...ownerSummary,
      participation: generalMeetingParticipation.getOwnerParticipation(ownerSummary.owner),
      participatingVotes: null,
      participatingVoteShare: null,
    };
  });
  const totalParticipatingVotes = sumBy(records, record => (record.participation.participates ? record.votes : 0));

  records.forEach(record => {
    if (record.participation.participates) {
      record.participatingVotes = record.votes;
      record.participatingVoteShare = record.votes / totalParticipatingVotes;
    }
  });
  return records;
}

export function getOwnerSummaries(shareRegister: ShareRegister): OwnerRegisterRecord[] {
  const totalVotingPower = shareRegister.instruments.reduce((votes, instrument) => {
    return (
      votes +
      instrument.shareData.votingPower *
        getHistoricShareData(instrument.shareData, shareRegister.settleDate).totalQuantity
    );
  }, 0);

  const totalCapital = shareRegister.instruments.reduce((capital, instrument) => {
    return capital + getHistoricShareData(instrument.shareData, shareRegister.settleDate).totalCapital;
  }, 0);

  const groupByInstrument = (positions: TablePosition[]): PositionGroup[] => {
    const instrumentPositions = Object.values(
      positions.reduce((records, position) => {
        const record = records[position.instrument.id] || { holder: position.instrument, positions: [] };
        return {
          ...records,
          [position.instrument.id]: {
            ...record,
            positions: [...record.positions, position],
          },
        };
      }, {}),
    ) as PositionGroup[];
    return instrumentPositions;
  };

  const summarizeInstrumentGroup = (positionGroup: PositionGroup<IInstrument>): InstrumentPositionSummary => {
    const {
      name,
      shareData: { totalQuantity, totalCapital, votingPower },
    } = positionGroup.holder;

    const quantity = positionGroup.positions.reduce((quantity, position) => quantity + position.quantity, 0);
    const capital = (totalCapital / totalQuantity) * quantity;
    const votes = votingPower * quantity;

    return {
      name,
      quantity,
      capital,
      votes,
    };
  };

  return getOwnerPositionGroups(shareRegister.positions, 'owner', true).map(positionGroup => {
    const { holder: owner, positions } = positionGroup;
    const instrumentGroups = groupByInstrument(positions);
    const instrumentSummaries = instrumentGroups.map(summarizeInstrumentGroup);

    return {
      owner,
      instruments: instrumentSummaries,
      totalQuantity: instrumentSummaries.reduce((sum, summary) => sum + summary.quantity, 0),
      capitalShare: instrumentSummaries.reduce((sum, summary) => sum + summary.capital, 0) / totalCapital,
      votes: instrumentSummaries.reduce((sum, summary) => sum + summary.votes, 0),
      voteShare: totalVotingPower
        ? instrumentSummaries.reduce((sum, summary) => sum + summary.votes, 0) / totalVotingPower
        : null,
    };
  });
}

export function getOwnerPositionGroups(
  positions: Array<TablePosition>,
  sortProperty: string,
  sortDesc: boolean,
): PositionGroup<IEntity>[] {
  positions = sortPositions(positions, sortProperty, sortDesc);
  const owners = getUniqueOwners(positions);

  const filter = owner => {
    return position => position.owner === owner;
  };
  return groupPositions(positions, owners, filter);
}

export function sortPositions(
  positions: Array<TablePosition>,
  sortProperty: string,
  sortDesc: boolean,
): Array<TablePosition> {
  const comparator = getComparator(sortProperty, sortDesc);
  return positions.slice().sort(comparator);
}

export function getUniqueOwners(positions: Array<TablePosition>, instrument?: IInstrument): IEntity[] {
  const validPositions = filterPositions(positions, {
    instrument,
  });
  const owners = validPositions.filter(pos => pos.owner != null).map(pos => pos.owner);
  return _.uniq(owners);
}

export function getInvestorsByOwner(positions: Array<TablePosition>, owner: IEntity, instrument?: IInstrument) {
  if (!owner) {
    return [];
  }
  const validPositions = instrument
    ? filterPositions(positions, {
        instrument,
      })
    : positions;

  const asEntity = (owner: IEntity) => owner;
  const ownerPositions = validPositions.filter(pos => pos.owner != null && owner === pos.owner);
  const investors = ownerPositions.filter(pos => pos.investor != null).map(pos => pos.investor);
  return _.uniqBy(investors, asEntity);
}

export function getUniqueOwnersAndInvestors(positions: Array<TablePosition>) {
  const asEntity = (owner: IEntity) => owner;
  const owners = positions.filter(pos => pos.owner != null).map(pos => pos.owner);
  const investors = positions.filter(pos => pos.investor != null).map(pos => pos.investor);
  return _.uniqBy([...owners, ...investors], asEntity);
}

export function filterPositions(positions: Array<TablePosition>, filter: PositionFilter) {
  if (filter.instrument) {
    positions = positions.filter(position => position.instrument === filter.instrument);
  }
  if (filter.owner) {
    positions = positions.filter(position => position.owner === filter.owner);
  }
  if (filter.investor) {
    positions = positions.filter(position => position.investor === filter.investor);
  }
  return positions;
}

export function getComparator(property, sortDesc) {
  const comparators = {
    quantity: sortDesc => (a: TablePosition, b: TablePosition) => intComparator(a.quantity, b.quantity, sortDesc),
    owner: sortDesc => (a: TablePosition, b: TablePosition) =>
      stringComparator(a.owner?.viewName, b.owner?.viewName, sortDesc),
    investor: sortDesc => (a: TablePosition, b: TablePosition) =>
      stringComparator(a.investor?.viewName, b.investor?.viewName, sortDesc),
    instrument: sortDesc => (a: TablePosition, b: TablePosition) =>
      stringComparator(a.instrument.viewName, b.instrument.viewName, sortDesc),
    shareNumberFrom: sortDesc => (a: TablePosition, b: TablePosition) =>
      intComparator(a.shareNumberFrom, b.shareNumberFrom, sortDesc),
  };

  return comparators[property] && comparators[property](sortDesc);
}
