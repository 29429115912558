<template>
  <div class="EntityAdminTable">
    <h4 class="table-header">{{ title }}</h4>
    <q-markup-table flat class="standard-table position-table" separator="horizontal">
      <thead class="has-background">
        <tr>
          <th class="text-left">Namn</th>
          <th class="text-left">Personnummer</th>
          <th class="text-left">E-post</th>
          <th class="text-left">Telefon</th>
          <th class="text-left">Roll</th>
          <th class="text-center narrow"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="admin in entityAdmins" :key="admin.id">
          <td class="text-left">{{ admin.user.name }}</td>
          <td class="text-left wrap-text">
            {{ admin?.user?.personalNumber ? formatPersonalNumber(admin.user.personalNumber) : '' }}
          </td>
          <td class="text-left">{{ admin.email || '' }}</td>
          <td class="text-left">{{ admin.phone || '' }}</td>
          <td class="text-left">{{ toEntityAdminRoleLabel(admin.role) }}</td>
          <td class="q-table--col-auto-width more-menu">
            <q-btn flat round color="grey-4" icon="more_vert" size="sm">
              <q-menu self="center left" anchor="center left" :offset="[70, 0]" class="a-tooltip shadow-1">
                <q-list>
                  <q-item clickable v-close-popup @click="edit(admin)">
                    <q-item-section>{{ canEdit(admin) ? 'Ändra' : 'Visa' }}</q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="deleteAdmin(admin)" v-if="canDelete">
                    <q-item-section>Ta bort</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </td>
        </tr>
        <tr class="narrow-row" v-if="canEdit(null)">
          <td colspan="100%">
            <q-btn
              color="secondary"
              dense
              rounded
              flat
              @click="newAdministrator()"
              class="small test-add-admin"
              icon="add_circle"
              >Lägg till Administratör</q-btn
            >
          </td>
        </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { EntityAdmin, Entity } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { formatPersonalNumber } from '@shared/common';
import { asModelId } from '@/utils';
import { toEntityAdminRoleLabel } from '@shared/common';
import { store } from '@shared/web/store';

@Component({})
export default class EntityAdminTable extends Vue {
  @Prop() entityAdmins: Array<EntityAdmin>;
  @Prop() entity: Entity;
  @Prop({ default: 'Administratörer' }) title: string;

  formatPersonalNumber(val) {
    return formatPersonalNumber(val);
  }

  toEntityAdminRoleLabel(role) {
    return toEntityAdminRoleLabel(role);
  }

  canEdit(admin: EntityAdmin | null) {
    return store?.entityAdmin?.limitedAccess === false || admin?.id === store?.entityAdmin?.id;
  }

  get canDelete() {
    return store.entityAdmin?.limitedAccess === false;
  }

  edit(entityAdmin) {
    this.$router.push({ path: `${this.entity.baseUrl}/admin/editera/${entityAdmin.id}` });
  }
  deleteAdmin(entityAdmin) {
    this.$emit('delete', entityAdmin);
  }
  async newAdministrator() {
    this.$router.push({ path: `${this.entity.baseUrl}/admin/skapa` });
  }
}
</script>
<style lang="scss">
.EntityAdminTable {
  max-width: 100%;
  .q-table {
    max-width: 100%;
    width: $col-max;
  }
}
</style>
