import { Entity, Instrument, ShareRegister, Position } from '@shared/web/models';
import { InstrumentCategory, InstrumentGroup, Products } from '@shared/models/types';
import * as _ from 'lodash';
import axios from 'axios';
export { makeShareRegisterValidation } from '@shared/shareRegister/shareRegisterService';

import { asModelId, pickDefined } from '@shared/utils';

const baseUrl = '/webapi/share-register';

export async function findShareRegister({
  entity,
  instrument,
  instruments,
  settleDate,
  instrumentCategory,
  instrumentGroup,
  instrumentProduct,
  onlyPreliminary,
}: {
  entity: Entity;
  instrument?: Instrument;
  instruments?: Instrument[];
  settleDate?: Date;
  instrumentCategory?: InstrumentCategory | InstrumentCategory[];
  instrumentGroup?: InstrumentGroup | InstrumentGroup[];
  instrumentProduct?: Products;
  onlyPreliminary?: boolean;
}) {
  if (!entity) {
    return null;
  }
  const { data } = await axios.post(
    baseUrl + '/find',
    pickDefined({
      entity: asModelId(entity),
      instrument: instrument && asModelId(instrument),
      instruments,
      settleDate,
      instrumentCategory,
      instrumentGroup,
      instrumentProduct,
      onlyPreliminary,
    }),
  );
  return ShareRegister.newModel(data, true);
}

export async function findShareRegisterByEntityId(entity: Entity) {
  return findShareRegister({
    entity,
    instrumentCategory: InstrumentCategory.SHA,
    instrumentProduct: Products.KAPCLEAR,
    onlyPreliminary: !entity.issuerData.locked,
  });
}

export async function findShareRegisterByEntityIdForPDF(query: {
  entity: string;
  category?: string;
  instrument?: string;
  settleDate?: string;
  custodian?: string;
  owner?: string;
}) {
  const { data } = await axios.post('/webapi/pdf/loaddata', query);
  return ShareRegister.newModel(data, true);
}

export function foreignOwners(positions: Array<Position>) {
  const foreignPositions = positions.filter(position => !position.owner.isSE);
  return _.uniq(foreignPositions.map(foreignPosition => foreignPosition.owner));
}

export async function submitShareRegister(dto) {
  const { data } = await axios.post(baseUrl + '/submit', dto);
  return data;
}
