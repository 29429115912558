import { asModelId } from '@shared/utils';
import { Entity, GeneralMeetingParticipation } from '..';
import { modelHandler } from '../base';

const baseUrl = '/webapi/general-meeting';
const { findOneByQuery, saveModel } = modelHandler(GeneralMeetingParticipation, baseUrl);

export async function saveGeneralMeetingParticipation(generalMeetingParticipation) {
  return saveModel(generalMeetingParticipation);
}

export async function loadGeneralMeetingParticipation(entity: Entity) {
  return findOneByQuery({ entity: asModelId(entity) });
}
