<template>
  <q-page v-if="ready" class="UserInvestmentsPage">
    <div class="row">
      <h1>Mina innehav / Intressen</h1>
    </div>
    <q-separator />
    <p>
      Nedan visas innehav som du äger som privatperson eller innehav som ägs av bolag som du företrädare i egenskap av
      tex styrelseledamot och eller firmatecknare.
    </p>
    <UserInvestmentsTable :userInvestments="userInvestments" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { UserInvestments } from '@/models/';
import { finduserInvestments } from '@/models/userInvestments/userInvestmentsService';
import UserInvestmentsTable from './UserInvestmentsTable.vue';
import store from '@/store';
import { Entity } from '@shared/web/models';
import { isToNewPage } from '@/utils';
@Component({
  components: { UserInvestmentsTable },
})
export default class UserInvestmentsPage extends Vue {
  userInvestments: null | UserInvestments = null;

  async mounted() {
    if (await this.redirectToSettingsIfNeeded()) {
      return;
    }
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    this.userInvestments = await finduserInvestments();
  }

  get ready() {
    return this.userInvestments != null;
  }

  async redirectToSettingsIfNeeded() {
    const user = store?.profile?.user;
    const entity = store?.profile?.entity as Entity | undefined;
    if (user && (!user?.settingsReviewedAt || !entity?.shareDataConsentedAt)) {
      await this.$router.push({ path: '/mina-inst%C3%A4llningar', query: { tillbakaTill: this.$route.path } });
      return true;
    }
    return false;
  }
}
</script>
<style lang="scss">
.UserInvestmentsPage {
  > p {
    margin-bottom: 2em;
  }
}
</style>
