<template>
  <q-page class="SelectEntityPage test-select-entity-page">
    <div class="row q-col-gutter-lg">
      <div class="col col-12 col-md-12">
        <h1>Välkommen till Kapclear</h1>
        <p>
          Här har vi samlat information om värdepapper som du äger, direkt eller indirekt, i Kapclearanslutna bolag.
          Nedan visas också bolag som du är bolagsföreträdare för, både som är anslutna till Kapclear och de som ännu
          inte är anslutna. Äger du värdepapper i bolag som ännu inte är anslutna till Kapclear, kontakta bolaget och be
          dom ansluta sig. Då kommer du få en bättre överblick av dina onoterade innehav. Här kan du också bli medlem i
          Kapnet - Kaptenas investerarnätverk och vara bland de första att ta del av spännande investeringsmöjligheter
          och nyemmisioner från kaptenas nätverk.
        </p>
      </div>
      <div class="col col-12 col-md-12"></div>
      <div class="col col-12 col-md-4" v-if="showUserInvestmentsLink">
        <router-link to="/mina-innehav" class="tile-link test-user-investments">
          <q-card class="tile flex items-center justify-center">
            <div class="q-py-lg q-px-xs text-center text-black">
              <h4>Mina innehav</h4>
              <div class="q-my-sm">Få överblick över alla dina innehav</div>
              <q-btn @click="$router.push('/mina-innehav')">Visa</q-btn>
            </div>
          </q-card>
        </router-link>
      </div>
      <div class="col col-12 col-md-4" v-if="showUserInvestmentsLink">
        <router-link to="/investment-offer" class="tile-link test-user-investments">
          <q-card class="tile flex items-center justify-center">
            <div class="q-py-lg q-px-xs text-center text-black">
              <h4>Kapnet</h4>
              <h4>Mina erbjudanden</h4>
              <div class="q-my-sm">Bli medlem och ta del av aktuella erbjudanden</div>
              <q-btn @click="$router.push('/investment-offer')">Visa</q-btn>
            </div>
          </q-card>
        </router-link>
      </div>
    </div>
    <EntitiesGrid />
  </q-page>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import EntitiesGrid from '@/views/selectEntity/EntitiesGrid.vue';
import { getRepresentedPersonalEntity } from '@/models/profileService';
@Component({
  components: { EntitiesGrid },
})
export default class SelectEntityPage extends Vue {
  ready = false;

  get showUserInvestmentsLink() {
    return !!getRepresentedPersonalEntity();
  }
}
</script>
<style lang="scss">
.tile-link {
  text-decoration: none;
}
.tiles-row .q-card,
.tile {
  min-height: 130px;
  @media (min-width: 1024px) {
    min-height: 184px;
  }
}
</style>
