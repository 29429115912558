import { modelHandler } from '../base';
import { Position, ShareRegister } from '@/models';
import { removeById } from '@/utils';
import { findEntity } from '@/models/entity/entityService';

const baseUrl = '/webapi/position';

const { saveModel, destroyModel } = modelHandler(Position, baseUrl);

export async function savePosition(position: Position) {
  const result = await saveModel(position);
  await findEntity(result.model.entity);
  return result;
}

export async function deletePosition(position: Position, shareRegister: ShareRegister) {
  await destroyModel(position);
  removeById(shareRegister.positions, position);

  await findEntity(position.entity);
}
