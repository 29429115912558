<template>
  <InstrumentPDF :instrument="instrument">
    <dl>
      <dt>Aktier:</dt>
      <dd>{{ formatNumber(shareData.totalQuantity) }}</dd>
    </dl>
    <dl>
      <dt>Kvotvärde:</dt>
      <dd>{{ formatNumber(issuerData.quotaValue) }}</dd>
    </dl>
    <dl>
      <dt>Typ:</dt>
      <dd>{{ instrument.shareData.classType }}</dd>
    </dl>
    <dl>
      <dt>Totalt aktiekapital:</dt>
      <dd>{{ formatNumber(issuerData.totalCapital) }}</dd>
    </dl>
    <dl>
      <dt>Totalt antal röster:</dt>
      <dd>{{ formatPercent(totals.votingPowerQuota, 2) }}</dd>
    </dl>
  </InstrumentPDF>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Instrument, ShareRegister } from '@/models';
import { makeShareRegisterValidation } from '@shared/web/models/shareRegister/shareRegisterService';
import InstrumentPDF from './InstrumentPDF.vue';

@Component({
  components: { InstrumentPDF },
})
export default class InstrumentShaPDF extends Vue {
  @Prop() instrument: Instrument;
  @Prop() shareRegister: ShareRegister;

  get shareData() {
    return this.instrument.shareData.getHistoricVersion(this.shareRegister.settleDate);
  }

  get issuerData() {
    return this.shareRegister.entity.issuerData;
  }
  get totals() {
    const totals = makeShareRegisterValidation(this.shareRegister);
    return totals.forInstrument(this.instrument);
  }
}
</script>
