<template>
  <div class="UserInvestmentsTable">
    <ExportLinks v-if="ready">
      <ExportExcel :exporter="excelExporter" />
    </ExportLinks>
    <q-markup-table v-if="ready" flat class="standard-table" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Ägare</th>
          <th class="text-left">Värdepapper</th>
          <th class="text-left">Typ</th>
          <th class="text-right">Förbehåll</th>
          <th class="text-right">
            Antal / <br />
            Aktienummer
          </th>
          <th class="text-right">
            <div class="row no-wrap items-center">
              <span>Kapitalandel</span>
              <a-info-btn>
                <p>
                  Finns olika aktieslag rekommenderas att man kontrollerar bolagsordningen som visar hur vinster,
                  utdelningar etc fördelas mellan aktieslagen. Kapitalandelen som visas, avser andelen av bolagets
                  bundna aktiekapital
                </p>
              </a-info-btn>
            </div>
          </th>
          <th class="text-right">Röstandel</th>
          <th class="text-left">
            Kapitalförsäkring / <br />
            Försäkringsnummer
          </th>
          <th class="text-left">
            Förvar / <br />
            Depånummer
          </th>
        </tr>
      </thead>
      <tbody class="two-row">
        <template v-for="{ entity, positions } in investments" :key="`owner-${entity.id}`">
          <tr class="holder-row">
            <td colspan="10" class="test-table-group">
              {{ entity.viewName }}
            </td>
          </tr>
          <template v-for="(entityPositions, entityId) in groupPositionsByEntity(positions)">
            <template
              v-for="(instrumentTypePositions, instrumentType) in groupPositionsByInstrumentType(entityPositions)"
              :key="`${entity.id}-${entityId}-${instrumentType}`"
            >
              <tr class="holder-row-2 cursor-pointer" @click="toggleSection(entity.id, entityId, instrumentType)">
                <td class="text-center">
                  <q-icon
                    name="arrow_right"
                    size="sm"
                    :class="{
                      'q-mr-xs': true,
                      'rotate-90': isSectionOpen(entity.id, entityId, instrumentType),
                    }"
                  />
                </td>
                <td colspan="3">
                  {{ instrumentTypePositions[0].entity.viewName }} -
                  {{ instrumentType === 'SHA' ? 'Aktier' : 'Övriga värdepapper' }}

                  <div class="text-small" v-if="instrumentType === 'SHA'">
                    Totalt antal utgivna aktier:
                    {{ formatNumber(instrumentTypePositions[0].entity.issuerData.totalQuantity) }}
                  </div>
                </td>

                <template v-if="instrumentType === 'SHA'">
                  <td class="text-right">{{ formatNumber(positionsValue(instrumentTypePositions)) }}</td>
                  <td class="text-right number">
                    {{ formatPercent(positionsCapitalShare(instrumentTypePositions)) }}
                  </td>
                  <td class="text-right number">
                    {{ formatPercent(positionsPowerQuota(instrumentTypePositions)) }}
                  </td>

                  <td colspan="2" class="text-right">
                    <ExportLinks v-if="ready">
                      <PdfDownload :entity="instrumentTypePositions[0].entity" :owner="entity" documentType="complete">
                        Pdf
                      </PdfDownload>
                    </ExportLinks>
                  </td>
                </template>
                <td colspan="5" v-else></td>
              </tr>
              <template v-for="position in instrumentTypePositions" :key="position.id">
                <UserInvestmentsTableRow
                  :collapsed="!isSectionOpen(entity.id, entityId, instrumentType)"
                  :position="position"
                  :instrumentTypePositions="instrumentTypePositions"
                />
              </template>
            </template>
          </template>
        </template>
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
// @ts-nocheck
import store from '@/store';
import { InstrumentCategory, Position, UserInvestments } from '@/models';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import UserInvestmentsTableRow from './UserInvestmentsTableRow.vue';
import ExportLinks from '@shared/components/ExportLinks.vue';
import ExportExcel from '@shared/views/excel/ExportExcel.vue';
import excelExporter from '@/views/excel/userInvestmentsExport';
import { asModelId } from '@shared/utils';
import { groupBy, sum } from 'lodash';
import { positionsCapitalShare, positionsPowerQuota } from '@shared/web/models/position/common';
import PdfDownload from '@shared/components/PdfDownload.vue';

@Component({
  components: { UserInvestmentsTableRow, ExportLinks, ExportExcel, PdfDownload },
})
export default class UserInvestmentsTable extends Vue {
  @Prop() userInvestments: null | UserInvestments;
  openSections = null;

  get user() {
    return store.profile.user;
  }
  get ready() {
    return this.userInvestments != null;
  }
  get investments() {
    return this.userInvestments.investments || [];
  }

  get excelExporter() {
    return excelExporter(this.userInvestments);
  }

  groupPositionsByEntity(positions: Position[]) {
    return groupBy(positions, position => asModelId(position.entity));
  }

  groupPositionsByInstrumentType(positions: Position[]) {
    return groupBy(positions, position => this.instrumentType(position.instrument.category));
  }

  positionsValue(positions: Position[]) {
    return sum(positions.filter(p => p.instrument.isShare).map(p => p.quantity));
  }

  positionsCapitalShare(positions: Position[]) {
    return positionsCapitalShare(positions);
  }

  positionsPowerQuota(positions: Position[]) {
    return positionsPowerQuota(positions);
  }

  instrumentType(instrumentCategory: InstrumentCategory) {
    if (instrumentCategory !== InstrumentCategory.SHA) {
      return 'other';
    }
    return instrumentCategory;
  }

  isSectionOpen(ownerId: string, issuerId: string, instrumentType: string) {
    return !!this.openSections?.[`${ownerId}-${issuerId}-${instrumentType}`];
  }

  toggleSection(ownerId: string, issuerId: string, instrumentType: string) {
    const key = `${ownerId}-${issuerId}-${instrumentType}`;
    if (this.openSections === null) {
      this.openSections = {};
    }
    this.openSections[key] = !this.openSections?.[key];
    this.openSections = { ...this.openSections };
  }
}
</script>
<style lang="scss">
.UserInvestmentsTable {
  position: relative;
  .text-small {
    margin-top: 0.5rem;
  }
  .q-table thead th {
    min-width: 0;
  }
  tr,
  .q-icon {
    transition: all 0.2s ease;
  }
  .holder-row td {
    background-color: #ded !important;
    border-color: #cdc;
  }
}
</style>
