<template>
  <div class="FileList">
    <p class="q-mb-xs list" v-if="!hasFiles">Ingen fil vald</p>
    <div class="q-mb-xs row justify-between list" v-for="file in parent.files" :key="file.url">
      <a-btn-link icon="svguse:/svg-icons.svg#icon-pdf" color="secondary" type="a" @click="openFile(file)">{{
        file.name
      }}</a-btn-link>
      <q-btn
        class="delete"
        flat
        round
        dense
        color="white"
        icon="svguse:/svg-icons.svg#icon-closedot"
        @click="deleteFile(file)"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Instruction } from '@/models';
import axios from 'axios';
import { asModelId, remove } from '@/utils';
@Component({})
export default class FileList extends Vue {
  @Prop() parent: Instruction;
  async created() {
    const parent = this.parent;
    const { data } = await axios.get(`/webapi/file/list/${asModelId(parent.entity)}/${parent.id}`);
    this.parent.files = data;
  }
  get hasFiles() {
    return this.parent.hasFiles;
  }

  async openFile(file) {
    const { data } = await axios.get(file.url);
    window.open(data.url, '_blank');
  }
  async deleteFile(file) {
    const { data } = await axios.delete(file.url);
    if (data) {
      remove(this.parent.files, file);
      this.parent.hasFiles = data.hasFiles;
    }
  }
}
</script>
<style lang="scss">
.FileList {
  margin-bottom: 9px;
  .list {
    min-width: 232px;
    width: fit-content;
    background-color: white;
    height: 32px;
    padding: 1px 8px;
    line-height: 32px;
    font-size: 12px;
  }
  .q-btn {
    text-decoration: none;
    .q-icon {
      font-size: 18px;
      margin-right: 10px;
    }
    &.delete {
      .q-icon {
        font-size: 16px;
        margin-right: 0;
      }
      .q-btn__wrapper {
        min-width: auto;
      }
    }
    .q-btn__wrapper {
      min-height: 100%;
    }
  }
}
</style>
