import { createApp } from 'vue';
import { Quasar } from 'quasar';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import quasarConfig from './quasar';
import App from './App.vue';

import './axios';
import defaultStyle from './defaultStyle';
import initComponents from './components';
import errorHandler from './errorHandler';
import { createGtm } from '@gtm-support/vue-gtm';
import router from './router';
import { gtmConfig } from './gtmConfig';
import { setUserDataToSentry } from './models/sentry/sentryService';
import { getEnv } from './utils';

const app = createApp(App);

//Vue.config.productionTip = false;
app.config.globalProperties.$defs = defaultStyle;
app.config.errorHandler = errorHandler;
app.use(Quasar, quasarConfig);
initComponents(app);
app.use(createGtm(gtmConfig));
const sentryDsn = import.meta.env.VITE_APP_SENTRY_DSN;

if (sentryDsn) {
  Sentry.init({
    app,
    dsn: sentryDsn,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'dev.kapclear.se', 'staging.kapclear.se', 'kapclear.se', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    trackComponents: true,
    environment: getEnv() || import.meta.env.MODE,
    release: APP_VERSION,
  });

  setUserDataToSentry();
}

app.use(router);
app.mount('#app');
