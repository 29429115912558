<template>
  <q-card flat class="q-pa-none DestinationsTableCard">
    <q-card-section class="q-pa-none">
      <DestinationsTable
        :shareRegister="shareRegister"
        :tableSettings="tableSettings"
        :instruction="instruction"
        :validator="validator"
        :readonly="readonly"
        :disableAdd="disableAdd"
        @change="$emit('change')"
      />
    </q-card-section>
    <a-btn-link class="test-clear" v-if="!readonly" @click="clearInstruction">Rensa</a-btn-link>
  </q-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

import { ShareRegister, Instruction } from '@/models';

import DestinationsTable from './DestinationsTable.vue';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
import ClearInstructionModal from '@/views/editInstructionModal/ClearInstructionModal.vue';
@Component({
  components: { DestinationsTable },
})
export default class DestinationsTableCard extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop() disableAdd: boolean;
  @Prop({ default: () => false }) readonly: boolean;
  @Prop() validator;
  tableSettings = new TableSettings();

  clearInstruction() {
    this.$q
      .dialog({
        component: ClearInstructionModal,
        componentProps: {
          id: this.instruction.id,
          modalName: 'optionsprogrammet',
        },
      })
      .onOk(instruction => {
        this.$emit('clear', instruction);
      });
  }
}
</script>
<style lang="scss">
.DestinationsTableCard {
  background-color: transparent;
  .q-tabs {
    margin-bottom: 10px;
    .q-tab__content {
      padding: 0;
    }
  }
  .q-tab {
    min-height: 26px;
    padding: 0 16px 0 0;
  }
  .q-tab__indicator {
    color: $secondary;
  }
}
</style>
