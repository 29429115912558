<template>
  <q-page class="relative-position">
    <div v-if="ready">
      <h1>{{ instruction.processTitle }}</h1>
      <div class="q-mb-xl">
        <Stepper :pageType="instruction.type" :currentstep="1" :urlId="instruction.id" v-if="isInstructionEditable" />
      </div>
      <div
        class="q-mb-xl"
        v-if="instruction.state === InstructionState.REQUEST_CHANGE && instruction.requestedChangeMessage"
      >
        <q-banner
          v-if="instruction.state === InstructionState.REQUEST_CHANGE && instruction.requestedChangeMessage"
          class="q-mt-md bg-orange-1 bg-orange-2"
        >
          Emissionen behöver kompletteras.<br />
          Kommentar: {{ instruction.requestedChangeMessage }}
        </q-banner>
      </div>
      <div class="q-mb-xl">
        <ConvertibleDetails
          v-if="instruction.isConvertible"
          :instruction="instruction"
          :entity="instruction.entity"
          :readonly="!isDataEditable"
          @change="handleDetailsChange"
          @input="handleDetailsChange"
        />
        <BondDetails
          v-if="instruction.isBond"
          :instruction="instruction"
          :readonly="!isDataEditable"
          @change="handleDetailsChange"
          @input="handleDetailsChange"
        />
        <DebentureDetails
          v-if="instruction.isDebenture"
          :instruction="instruction"
          :readonly="!isDataEditable"
          @change="handleDetailsChange"
          @input="handleDetailsChange"
        />
        <ShareholderContributionDetails
          v-if="instruction.isShareholderContribution"
          :instruction="instruction"
          :readonly="!isDataEditable"
          :destinationsAmount="destinationsAmount"
          @change="handleDetailsChange"
          @input="handleDetailsChange"
        />
      </div>
      <DestinationsTableCard
        :shareRegister="shareRegister"
        :instruction="instruction"
        :readonly="!isDataEditable"
        :disableAdd="!isDataEditable || hasUnsavedChanges"
        @change="handleDestinationsChange"
      />
    </div>
    <InstructionSaveRow
      v-if="isInstructionEditable"
      :disable="isSaving || !canProceed"
      :is-saving="isSaving"
      :has-unsaved-changes="hasUnsavedChanges"
      @proceed="proceed"
    />
  </q-page>
</template>
<script lang="ts">
import { ref } from 'vue';
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { Instruction, ShareRegister, TransactionType } from '@/models';
import ConvertibleDetails from '@shared/views/convertible/ConvertibleDetails.vue';
import { findInstruction, getInstructionDefaults, saveInstruction } from '@/models/instruction/instructionService';
import Stepper from '@/components/Stepper.vue';
import BondDetails from '@shared/views/bond/BondsDetails.vue';
import DebentureDetails from '@shared/views/debenture/DebentureDetails.vue';
import ShareholderContributionDetails from '@shared/views/shareholderContribution/ShareholderContributionDetails.vue';
import DestinationsTableCard from '@/views/issueWarrant/DestinationsTableCard.vue';
import DebtInstructionValidator from '@shared/models/instructionValidator/DebtInstructionValidator';
import { debounce } from 'lodash';
import { looksLikeId } from '@shared/utils';
import { Entity } from '@shared/web/models';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { store } from '@shared/web/store';
import InstructionSaveRow from '@/components/InstructionSaveRow.vue';
import { InstructionState } from '@shared/models/types';

const instructionDirty = ref(false);

@Component({
  components: {
    InstructionSaveRow,
    DestinationsTableCard,
    BondDetails,
    Stepper,
    ConvertibleDetails,
    DebentureDetails,
    ShareholderContributionDetails,
  },
  provide: { instructionDirty },
})
export default class IssueSecurityPage extends Vue {
  isSaving = false;
  debouncedSave = null;
  instruction: Instruction = null;
  shareRegister: ShareRegister = null;
  hasUnsavedChanges = false;
  entity: Entity = null;

  @Watch('hasUnsavedChanges')
  handleHasUnsavedChangesChange(value) {
    instructionDirty.value = value;
  }

  get InstructionState() {
    return InstructionState;
  }

  get ready() {
    return this.instruction && this.shareRegister;
  }

  get isDataEditable() {
    if (store.entityAdmin?.limitedAccess === true) {
      return false;
    }
    if (this.instruction?.type === TransactionType.EXERCISE_CONVERTIBLE) {
      return false;
    }
    if (this.instruction?.type === TransactionType.EXERCISE_BOND) {
      return false;
    }
    return this.instruction?.isEditable;
  }

  get isInstructionEditable() {
    return this.instruction?.isEditable;
  }

  get canProceed() {
    return this.instruction?.isEditable && new DebtInstructionValidator(this.instruction).isValid();
  }

  get isNew() {
    const { id } = this.$route.params;
    return !looksLikeId(id);
  }

  get destinationsAmount() {
    return this.instruction.destinations.reduce<any>((amount, destination) => amount + destination.amount, 0);
  }

  created() {
    this.debouncedSave = debounce(async (syncFromBackend = false) => {
      this.isSaving = true;
      await this.save(syncFromBackend);
      this.isSaving = false;
    }, 1000);
  }

  async mounted() {
    const entity = await findEntityWithRouteParams(this.$route.params);
    const { shareRegister, instruction } = this.isNew
      ? await getInstructionDefaults({ type: this.$route.meta.transactionType, entity: entity.id })
      : await findInstruction(this.$route.params.id);

    this.shareRegister = shareRegister;
    this.instruction = instruction;
  }

  async save(syncFromBackend = false) {
    this.isSaving = true;
    const savedInstruction = (await saveInstruction(this.instruction)).model;
    if (!this.instruction.id) {
      window.history.pushState({}, '', savedInstruction.webUrl);
    }
    if (syncFromBackend) {
      this.instruction = savedInstruction;
    } else if (!this.instruction.id) {
      this.instruction.id = savedInstruction.id;
    }
    this.hasUnsavedChanges = false;
    this.isSaving = false;
  }

  async proceed() {
    await this.save(true);
    console.log('saved', `${this.instruction.webUrl}/dokument`);
    await this.$router.push({
      path: `${this.instruction.webUrl}/dokument`,
    });
  }

  async handleDetailsChange() {
    this.hasUnsavedChanges = true;
    this.recalculateDestinationsAmounts();
    await this.debouncedSave();
  }

  async handleDestinationsChange() {
    this.hasUnsavedChanges = true;
    this.recalculateDestinationsAmounts();
    // we need to re-sync here, otherwise we would send same destinations again and get dupes in the response from the backend
    await this.debouncedSave(true);
  }

  recalculateDestinationsAmounts() {
    if (this.instruction?.corporateEvent?.rightsData?.distributeByValue === false) {
      // recalculating destination amount
      this.instruction.destinations.forEach(dest => {
        dest.amount = dest.quantity * this.instruction?.corporateEvent?.rightsData?.nominalAmountPerPiece;
      });
    }
  }
}
</script>
