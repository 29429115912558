<template>
  <q-markup-table flat class="standard-table PositionsTable" separator="horizontal">
    <thead>
      <tr>
        <th class="text-left">Aktie</th>
        <th class="text-left">Ägare</th>
        <th class="text-left">Försäkringsägare</th>
        <th class="text-left">Förvar</th>
        <th class="text-left">Förbehåll</th>
        <th class="text-right">Aktienummer</th>
        <th class="text-right">Antal</th>
        <th class="text-right col justify-bottom">
          <div class="row no-wrap items-center">
            <span>Kapitalandel</span>
            <a-info-btn>
              <p>
                Finns olika aktieslag rekommenderas att man kontrollerar bolagsordningen som visar hur vinster,
                utdelningar etc fördelas mellan aktieslagen. Kapitalandelen som visas, avser andelen av bolagets bundna
                aktiekapital
              </p>
            </a-info-btn>
          </div>
        </th>
        <th class="text-right">Röstandel</th>
        <th v-if="canEdit"></th>
      </tr>
    </thead>
    <tbody class="two-row">
      <template v-for="{ holder, positions } in positionGrouping" :key="holder.id">
        <InstrumentRow :instrument="holder" :shareRegister="shareRegister" :readonly="readonly" />
        <template v-for="position in positions" :key="position.id">
          <PositionRow :position="position" :shareRegister="shareRegister" :readonly="readonly" />
        </template>
        <tr :key="'footer:' + holder.id" v-if="!readonly" class="narrow-row">
          <td></td>
          <td colspan="100">
            <q-btn
              color="secondary"
              dense
              rounded
              flat
              @click="addPosition(holder)"
              class="small test-add-owner"
              icon="add_circle"
              >Lägg till ägare</q-btn
            >
          </td>
        </tr>
      </template>
    </tbody>
  </q-markup-table>
</template>
<script lang="ts">
import { ShareRegister, Position } from '@shared/web/models';
import PositionRow from './PositionRow.vue';
import InstrumentRow from './InstrumentRow.vue';
import EditPositionModal from '@shared/views/editPositionModal/EditPositionModal.vue';
import { positionGrouping } from '@shared/shareRegister/utils';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { store } from '@shared/web/store';
import { isBackOffice } from '@shared/web/utils';

@Component({
  components: { PositionRow, InstrumentRow, EditPositionModal },
})
export default class PositionsTable extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop({ default: () => false }) readonly: boolean;

  get positionGrouping() {
    return positionGrouping(this.shareRegister.instruments, this.sortedPositions);
  }

  get sortedPositions() {
    return this.shareRegister.positions.sort((a, b) => a.shareNumberFrom - b.shareNumberFrom);
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false || isBackOffice();
  }

  addPosition(holder) {
    const entity = this.shareRegister.entity;
    const data = { instrument: holder, entity };

    this.$q.dialog({
      component: EditPositionModal,
      componentProps: {
        positionClass: Position,
        value: data,
        label: 'ägare',
        shareRegister: this.shareRegister,
      },
    });
  }
}
</script>
<style lang="scss">
.PositionsTable {
  position: relative;
}
</style>
