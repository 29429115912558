<template>
  <q-page v-if="ready" class="EntityAdminPage">
    <h1>Administratörer</h1>
    <EntityAdminTable
      :entityAdmins="fullAccessAdmins"
      :entity="entity"
      @delete="deleteEntityAdmin"
      title="Administratörer med full åtkomst"
    />
    <EntityAdminTable
      :entityAdmins="limitedAccessAdmins"
      :entity="entity"
      @delete="deleteEntityAdmin"
      title="Administratörer med begränsad åtkomst"
    />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import EntityAdminTable from './EntityAdminTable.vue';
import { EntityAdmin, Entity } from '@/models';
import { deleteAdmin, findEntityAdminsForEntity } from '@/models/entityAdmin/entityAdminService';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';

@Component({
  components: { EntityAdminTable },
})
export default class EntityAdminPage extends Vue {
  entityAdmins: Array<EntityAdmin> = null;
  entityId = null;
  entity: Entity;

  get limitedAccessAdmins() {
    return this.entityAdmins.filter(admin => admin.limitedAccess);
  }

  get fullAccessAdmins() {
    return this.entityAdmins.filter(admin => admin.limitedAccess === false);
  }

  async created() {
    const entity = await findEntityWithRouteParams(this.$route.params);
    const entityAdmins = await findEntityAdminsForEntity(entity);
    Object.assign(this, { entityAdmins, entity });
  }

  async deleteEntityAdmin(entityAdmin: EntityAdmin) {
    this.$q
      .dialog({
        title: 'Är du säker?',
        message: `Vill du ta bort användare ${entityAdmin.user.name}?`,
        ok: 'Ta bort',
        cancel: 'Avbryt',
      })
      .onOk(async () => {
        await deleteAdmin(entityAdmin).then(() => {
          this.entityAdmins = this.entityAdmins.filter(e => e.id !== entityAdmin.id);
        });
      });
  }

  get ready() {
    return this.entityAdmins != null;
  }
}
</script>
<style lang="scss">
.EntityAdminPage {
  .step-btn {
    width: 200px;
  }
}
</style>
