import { UserInvestments, Entity, Position } from '@/models';
import { toInstrumentGroupLabel } from '@shared/common';
import { toReservationLabel } from '@shared/common';
import { toCapitalShare, toVotingPowerQuota } from '@shared/web/models/position/common';
import { formatPercent } from '@/utils';

function getCapitalInsurance(position: Position) {
  const { owner } = position;
  if (owner && owner.isInsuranceCompany) {
    return owner.viewName;
  }
  return '';
}

function getShareNumbers(position: Position) {
  return position.shareNumberFrom && position.shareNumberTo
    ? `${position.shareNumberFrom} - ${position.shareNumberTo}`
    : '';
}

function mapPosition(entity: Entity) {
  return function (position: Position, index: number): Record<string, string | number> {
    const groupHeader = !index ? { Ägare: entity.viewName } : {};

    return {
      ...groupHeader,
      Värdepapper: position.instrument.name,
      Typ: toInstrumentGroupLabel(position.instrument.group),
      Förbehåll: position.reservations.map(toReservationLabel).join(', '),
      Antal: position.quantity,
      Aktienummer: getShareNumbers(position),
      Kapitalandel: formatPercent(toCapitalShare(position)),
      Röstandel: formatPercent(toVotingPowerQuota(position)),
      Kapitalförsäkring: getCapitalInsurance(position),
      Förvar: position.custodianName,
    };
  };
}

export default function createUserInvestmentsExporter(userInvestments: UserInvestments) {
  return async function userInvestmentsExporter() {
    const { investments } = userInvestments;
    const records = investments
      .map(({ entity, positions }) => {
        return positions.map(mapPosition(entity)).flat();
      })
      .flat();
    return {
      name: 'Mina investeringar',
      records,
    };
  };
}
