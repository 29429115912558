<template>
  <div class="TransactionsDetails">
    <h4 class="table-header">Transaktionsdetaljer</h4>
    <div class="row q-col-gutter-md">
      <div class="col-12 col-md-3">
        <p class="B1Small">Värdepapper</p>
        <a-select
          v-bind="$defs.input"
          v-model="tradeEvent.source.instrument"
          :options="shareRegister.instruments"
          :nullOption="false"
          placeholder="Välj"
          map-options
          option-label="viewName"
          @change="handleSourceInstrumentChange"
          :readonly="readonly"
          class="test-source-instrument"
        ></a-select>
      </div>
      <div class="col-12 col-md-3">
        <p class="B1Small-label">
          Typ
          <a-info-btn>
            <p>
              <b>Införsäljning försäkring</b> används när en aktieägare, säljer/flyttar in värdepapper till en
              kapitalförsäkring.
            </p>
            <p>
              <b>Återköp försäkring</b> används när en aktieägare köper/flyttar ut värdepapper från en kapitalförsäkring
              till sig själv, köparen måste vara samma person-/org.nummer som försäkringsägaren. Om köparen är annan än
              försäkringsägare, använd typen försäljning.
            </p>
          </a-info-btn>
        </p>
        <a-select
          v-bind="$defs.input"
          :nullOption="false"
          v-model="instruction.type"
          :options="typeOptions"
          placeholder="Välj"
          @change="change"
          map-options
          emit-value
          :readonly="readonly"
        ></a-select>
      </div>
      <div class="col-12 col-md-3">
        <p class="B1Small">Affärsdag</p>
        <a-input-date
          v-bind="$defs.input"
          placeholder="yyyy-mm-dd"
          v-model="tradeEvent.tradeDate"
          @change="handleTradeDateChange"
          :readonly="readonly"
          :rules="tradeDateRules"
          :options="tradeDateMax"
          :disable="tradeDateIsDisabled"
          lazy-rules
        ></a-input-date>
      </div>
      <div class="col-12 col-md-3">
        <p class="B1Small-label">
          Införd i aktieboken
          <a-info-btn>
            <p>Införd i aktiebok är samma som likviddag</p>
          </a-info-btn>
        </p>
        <a-input-date
          v-bind="$defs.input"
          placeholder="yyyy-mm-dd"
          v-model="instruction.settleDate"
          @change="change"
          :readonly="readonly"
          :rules="settleDateRules"
          :options="settleDateMin"
          :disable="settleDateIsDisabled"
          lazy-rules
        ></a-input-date>
      </div>
    </div>
    <div class="row q-col-gutter-md">
      <div class="col-6">
        <p class="B1Small">Anteckningar</p>
        <q-input
          v-model="instruction.notes"
          :readonly="readonly"
          class="notes"
          filled
          autogrow
          v-bind="$defs.input"
          @update:modelValue="$emit('input')"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Instruction, ShareRegister } from '@/models';
import { options } from '@shared/common';
import { isAfter, endOfYesterday, isBefore, endOfToday, format } from 'date-fns';

@Component({
  components: {},
})
export default class TransactionsDetails extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop() validator;
  @Prop({ default: () => false }) readonly: boolean;

  get options() {
    return options;
  }

  get tradeEvent() {
    return this.instruction.tradeEvent;
  }

  get tradeDateIsDisabled() {
    return !this.instruction.tradeEvent.source.instrument;
  }

  get tradeDateRules() {
    return [() => this.validator.invalidTradeEventDate()?.text];
  }

  get settleDateIsDisabled() {
    return !(this.instruction.tradeEvent.source.instrument && this.instruction.tradeEvent.tradeDate);
  }

  get settleDateRules() {
    return [
      () => this.validator.invalidSettleDate()?.text,
      () =>
        isAfter(this.instruction.settleDate, endOfYesterday()) || 'Införd i aktieboken kan inte vara före dagens datum',
    ];
  }

  get typeOptions() {
    return options.instructionTypeOptions;
  }

  handleSourceInstrumentChange() {
    this.resetSource();
    this.change();
  }

  handleTradeDateChange() {
    if (!this.instruction.settleDate) {
      this.instruction.settleDate = new Date(format(new Date(), 'yyyy-MM-dd'));
    }
    this.change();
  }

  change() {
    this.$emit('change');
  }

  resetSource() {
    this.instruction.tradeEvent.source.owner = null;
    this.instruction.tradeEvent.sourcePositions = [];
  }

  settleDateMin(date) {
    return isAfter(new Date(date), endOfYesterday());
  }

  tradeDateMax(date) {
    return isBefore(new Date(date), endOfToday());
  }
}
</script>
<style lang="scss">
.TransactionsDetails {
  .notes {
    .q-field__control {
      width: 100%;
      height: auto !important;
    }
  }
}
</style>
