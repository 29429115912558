<template>
  <div class="OtherInstrumentTable">
    <h4 class="table-header">Övriga värdepapper</h4>
    <q-markup-table flat class="standard-table position-table" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Namn</th>
          <th class="text-left">ID</th>
          <th class="text-left">Kategori</th>
          <th class="text-left">Grupp</th>
          <th class="text-left">Slutdatum</th>
          <th class="text-left">Antal</th>
        </tr>
      </thead>
      <tbody v-if="hasOtherInstruments" class="two-row">
        <template v-for="instrument in otherInstruments" :key="instrument.id">
          <OtherInstrumentRow :instrument="instrument"  />
        </template>
      </tbody>
    </q-markup-table>
    <p v-if="!hasOtherInstruments" class="text-small">Det finns inga övriga värdepapper</p>
  </div>
</template>
<script lang="ts">
import { Instrument } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';

import OtherInstrumentRow from './OtherInstrumentRow.vue';

@Component({
  components: { OtherInstrumentRow },
})
export default class OtherInstrumentTable extends Vue {
  @Prop() instruments: Array<Instrument>;

  get otherInstruments() {
    return this.instruments.filter(instrument => !instrument.isShare);
  }

  get hasOtherInstruments() {
    return this.otherInstruments.length !== 0;
  }
}
</script>
<style lang="scss">
.OtherInstrumentTable {
  td a {
    font-weight: 400;
  }
}
</style>
