import { Entity, Position, Instrument } from '@/models';
import { asModelId } from '@shared/utils';
import axios from 'axios';
import { groupBy, sumBy } from 'lodash';

const baseUrl = '/webapi/insurer';

export const findInsurerPositions = async (insurer: Entity, settleDate: Date = new Date()): Promise<Position[]> => {
  const { data } = await axios.post(baseUrl + '/positions', {
    insurer: asModelId(insurer),
    settleDate,
  });

  return Position.newModels(data);
};

export const groupAndFilterPositions = ({
  positions,
  filter = () => true,
}: {
  positions: Position[];
  filter?: (position: Position) => boolean;
}): { instrument: Instrument; quantity: number; amount: number; positions: Position[] }[] => {
  const filtered = Object.values(positions).filter(filter);
  const groups = groupBy(filtered, 'instrument.id');

  return Object.values(groups).map(([item, ...rest]) => {
    return {
      instrument: item['instrument'],
      quantity: sumBy([item, ...rest], 'quantity'),
      amount: sumBy([item, ...rest], 'amount'),
      positions: [item, ...rest],
    };
  });
};
