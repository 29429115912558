<template>
  <q-page v-if="ready" class="TradeEventPage">
    <div class="row justify-between">
      <h1>Ägarbyte</h1>
      <LastEntityUpdate :date="instruction.updatedAt" />
    </div>
    <Stepper pageType="tradeEvent" :currentstep="1" :isDone="isDone" v-if="isEditable" :urlId="instruction.id" />
    <div
      class="q-mb-xl"
      v-if="instruction.state === InstructionState.REQUEST_CHANGE && instruction.requestedChangeMessage"
    >
      <q-banner
        v-if="instruction.state === InstructionState.REQUEST_CHANGE && instruction.requestedChangeMessage"
        class="q-mt-md bg-orange-1 bg-orange-2"
      >
        Ägarbytet behöver kompletteras.<br />
        Kommentar: {{ instruction.requestedChangeMessage }}
      </q-banner>
    </div>
    <q-form class="" ref="form">
      <TransactionsDetails
        :shareRegister="shareRegister"
        :instruction="instruction"
        @change="handleChange"
        @input="handleChange"
        :readonly="!isEditable"
        :validator="validator"
      />
      <div v-if="instruction.warning" class="warning-text q-mt-lg">
        <p class="q-pl-md p2">Det finns pågående nyemission - med avstämningsdag före datum för införd i aktieboken.</p>
      </div>
      <SourceRow
        :shareRegister="shareRegister"
        :instruction="instruction"
        @change="handleChange"
        :readonly="!isEditable"
      />
      <InstructionDestinationTable
        :shareRegister="shareRegister"
        :instruction="instruction"
        :validator="validator"
        :readonly="!isEditable || isSaving"
        @change="handleChange(true)"
      />
      <InstructionSaveRow
        v-if="isEditable"
        :disable="!isDone || isSaving"
        :is-saving="isSaving"
        :has-unsaved-changes="hasUnsavedChanges"
        @proceed="proceed"
      />
    </q-form>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Ref } from 'vue-facing-decorator';
import { ShareRegister } from '@/models';
import Instruction from '@shared/web/models/instruction/Instruction';
import InstructionDestinationTable from './InstructionDestinationTable.vue';
import TransactionsDetails from './TransactionsDetails.vue';
import Stepper from '@/components/Stepper.vue';
import SourceRow from './SourceRow.vue';
import LastEntityUpdate from '@shared/components/LastEntityUpdate.vue';
import {
  makeInstructionValidator,
  saveInstruction,
  loadShareRegisterForInstruction,
  findInstruction,
  getInstructionDefaults,
} from '@/models/instruction/instructionService';
import { debounce } from 'lodash';
import { looksLikeId } from '@shared/utils';
import { Entity } from '@shared/web/models';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import InstructionSaveRow from '@/components/InstructionSaveRow.vue';
import { InstructionState } from '@shared/models/types';

@Component({
  components: {
    InstructionSaveRow,
    TransactionsDetails,
    SourceRow,
    InstructionDestinationTable,
    LastEntityUpdate,
    Stepper,
  },
})
export default class TradeEventPage extends Vue {
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;
  entity: Entity = null;
  debouncedSave = null;
  isSaving = false;
  hasUnsavedChanges = false;
  @Ref() readonly form;

  get InstructionState() {
    return InstructionState;
  }

  async save(syncFromBackend = false) {
    this.isSaving = true;
    const { changes, model: savedInstruction } = await saveInstruction(this.instruction);
    if (!this.instruction.id) {
      window.history.pushState({}, '', savedInstruction.webUrl);
    }
    if (syncFromBackend) {
      this.instruction = savedInstruction;
    } else if (!this.instruction.id) {
      this.instruction.id = savedInstruction.id;
    }
    if (changes.settleDate != null) {
      await this.loadShareRegister();
    }
    this.hasUnsavedChanges = false;
    this.isSaving = false;
  }

  get isEditable() {
    return this.isNew || this.instruction.isEditable;
  }

  get isNew() {
    return !looksLikeId(this.$route.params.id);
  }

  async created() {
    this.debouncedSave = debounce(async (syncFromBackend = false) => {
      await this.save(syncFromBackend);
    }, 1000);
    const { id } = this.$route.params;
    const { transactionType } = this.$route.meta;

    this.entity = await findEntityWithRouteParams(this.$route.params);
    const { shareRegister, instruction } = this.isNew
      ? await getInstructionDefaults({ type: transactionType, entity: this.entity.id })
      : await findInstruction(id);

    Object.assign(this, { shareRegister, instruction });
  }

  async loadShareRegister() {
    this.shareRegister = await loadShareRegisterForInstruction(this.instruction);
  }

  get isDone() {
    return this.validator.isPreValid();
  }

  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }
  get validator() {
    return this.ready && makeInstructionValidator(this.instruction, this.shareRegister);
  }

  handleChange(reSync = false) {
    this.hasUnsavedChanges = true;
    this.debouncedSave(reSync);
  }

  async proceed() {
    const valid = await this.form.validate();
    if (!valid) {
      return false;
    }
    await this.save(true);
    await this.$router.push({
      path: `${this.instruction.entity.baseUrl}/agarbyte/${this.instruction.id}/dokument`,
    });
  }
}
</script>
<style lang="scss">
.TradeEventPage {
  .warning-text {
    height: 72px;
    background-color: white;
    p {
      line-height: 72px;
    }
  }
}
</style>
