import { IInstrument, IShareData, ShareDataHistory } from './modelTypes';
import { InstrumentCategory } from './types';
import * as _ from 'lodash';
export function getHistoricIssuerData(instruments: Array<IInstrument>, settleDate: Date) {
  const shares = instruments.filter(instrument => instrument.category === InstrumentCategory.SHA);
  const { totalCapital, totalQuantity } = shares.reduce(
    (result, instrument) => {
      const shareData = getHistoricShareData(instrument.shareData, settleDate);
      return {
        totalQuantity: result.totalQuantity + shareData.totalQuantity,
        totalCapital: result.totalCapital + shareData.totalCapital,
      };
    },
    {
      totalCapital: 0,
      totalQuantity: 0,
    },
  );
  return {
    totalCapital,
    totalQuantity,
  };
}

export function getHistoricShareData(shareData: IShareData, settleDate: Date) {
  if (!settleDate || _.isEmpty(shareData.history)) {
    return shareData;
  }

  let history = _.findLast(shareData.history, history => {
    const historyDate = new Date(history.settleDate);
    return historyDate <= settleDate;
  }) as ShareDataHistory | null;

  if (!history) {
    history = {
      totalCapital: 0,
      totalQuantity: 0,
      settleDate: null,
    };
  }
  return history;
}
