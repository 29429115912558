<template>
  <div class="TransactionsDetails">
    <div class="flex row q-col-gutter-md">
      <div class="col-12 col-sm-6 col-md-2">
        <p class="B1Small-label">Avstämningsdag</p>
        <a-input-date
          v-bind="$defs.input"
          placeholder="yyyy-mm-dd"
          v-model="instruction.corporateEvent.recordDate"
          @change="$emit('change')"
          :readonly="readonly"
          :rules="recordDateRules"
        ></a-input-date>
      </div>
      <div class="col-12 col-sm-6 col-md-2">
        <p class="B1Small-label">
          Införd i aktieboken
          <a-info-btn>
            <p>Införd i aktieboken är den dagen deltagare i split kommer bli införda i aktieboken</p>
          </a-info-btn>
        </p>
        <a-input-date
          v-bind="$defs.input"
          placeholder="yyyy-mm-dd"
          v-model="instruction.settleDate"
          @change="$emit('change')"
          :readonly="readonly"
          :rules="settleDateRules"
        ></a-input-date>
      </div>

      <div class="col-12 col-md-2">
        <p class="B1Small-label">
          Splitvillkor
          <a-info-btn>
            <p>
              Splitvillkor uttryckts genom, nya aktier : gamla aktier, exempelvis 2:1 som då betyder att för varje
              gammal aktier får man 1 ny aktie, vilket gör att man efter spliten har 2 aktier totalt. Vid villkoren 2:1
              så har bolaget dubblerat antal utestående aktier och halverat kvotvärdet, förändringen i totalt antal
              aktier och kvotvärde visas i nedan tabell för varje aktieslag.
            </p>
          </a-info-btn>
        </p>
        <div class="splitvillkor">
          <InputFormatNumber
            label="Nya"
            v-bind="$defs.input"
            input-class="text-right test-split-factor"
            v-model="instruction.corporateEvent.splitFactor"
            :readonly="readonly"
            :rules="[v => /^[0-9\s]*$/.test(v) || 'Måste vara ett nummer']"
            @change="changeSplitFactor"
          />
          :
          <q-input
            label="Bef."
            v-bind="$defs.input"
            input-class="text-right test-split-factor"
            :model-value="1"
            :readonly="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Instruction, ShareRegister } from '@/models';
import InputFormatNumber from '@shared/components/NumberInput.vue';
import { sumBy } from 'lodash';

@Component({
  components: { InputFormatNumber },
})
export default class TransactionsDetails extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop({ default: () => false }) readonly: boolean;
  @Prop() validator;

  changeSplitFactor() {
    const { corporateEvent } = this.instruction;

    corporateEvent.instrumentSources.forEach(instrumentSource => {
      const sourceValidation = this.validator.forSplitInstrumentSource(instrumentSource.instrument);
      instrumentSource.quantity = sourceValidation.addedQuantity;
    });
    this.instruction.quantity = sumBy(corporateEvent.instrumentSources, instrumentSource => instrumentSource.quantity);
    this.$emit('change');
  }

  get issuerData() {
    return this.shareRegister.entity.issuerData;
  }

  get recordDateRules() {
    return [() => this.validator.invalidCorporateEventDates()?.text];
  }

  get settleDateRules() {
    return [() => this.validator.invalidSettleDate()?.text];
  }
}
</script>
<style lang="scss">
.TransactionsDetails {
  margin-bottom: -22px;
  .splitvillkor {
    display: flex;
    align-items: baseline;
    gap: 0.5em;
    input[readonly] {
      color: #b3afad !important;
    }
  }
}
</style>
