<template>
  <div class="ExportLinks">
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
@Component({})
export default class ExportLinks extends Vue {}
</script>
<style lang="scss">
.ExportLinks {
  display: block;
  right: 0;
  top: 3px;
  overflow: hidden;
}
</style>
