import { modelHandler } from '../base';
import { Position } from '@shared/web/models';
import { removeById } from '@shared/utils';
import { findEntity } from '@shared/web/entity/entityService';

const baseUrl = '/webapi/position';

const { saveModel, destroyModel } = modelHandler(Position, baseUrl);

export async function savePosition(position: Position) {
  const result = await saveModel(position);
  await findEntity(result.model.entity);
  return result;
}

export async function deletePosition(position: Position, shareRegister) {
  await destroyModel(position);
  removeById(shareRegister.positions, position);

  await findEntity(position.entity);
}
