<template>
  <a-select
    v-model="model"
    :options="options"
    map-options
    option-label="viewName"
    v-bind="$attrs"
    nullLabel="Kapclear"
  />
</template>
<script lang="ts">
import { Entity } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';

import { findCustodians } from '@/models/entity/entityService';
import { asModelId } from '@shared/utils';

@Component({
  components: {},
})
export default class SelectCustodian extends Vue {
  @Prop() modelValue: Entity;
  @Prop() owner: Entity;

  _custodians = [];

  get options() {
    if (!this.owner?.isInsuranceCompany) {
      return this._custodians;
    }
    return this._custodians.filter(custodian => {
      return !!custodian.legalEntity.entityConnections?.find(
        connection => asModelId(connection.entity) === asModelId(this.owner),
      );
    });
  }

  async created() {
    this._custodians = await findCustodians();
  }

  get model() {
    return this.modelValue;
  }
  set model(val) {
    if (!val) {
      this.$emit('update:modelValue', null);
    } else {
      this.$emit('update:modelValue', val);
    }
  }
}
</script>
