<template>
  <q-page v-if="ready" class="SplitsPage">
    <h1>Aktieägartillskott</h1>
    <q-btn v-if="canEdit" v-bind="$defs.btn" type="button" @click="newInstruction">Skapa nytt</q-btn>
    <ShareholderContributionsTable header="Pågående" :instructions="ongoingInstructions" />
    <ShareholderContributionsTable header="Registrerade" :instructions="executedInstructions" />
  </q-page>
</template>
<script lang="ts">
import { reverse, sortBy } from 'lodash';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Instruction, TransactionType, Entity, InstructionState } from '@/models';
import { findInstructions, makeNewInstruction } from '@/models/instruction/instructionService';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { store } from '@shared/web/store';
import { remove } from '@/utils';
import ShareholderContributionsTable from './ShareholderContributionsTable.vue';

@Component({ components: { ShareholderContributionsTable } })
export default class SplitsPage extends Vue {
  @Prop({ default: true }) readonly: boolean;
  instructions: Array<Instruction> = null;
  entity: Entity = null;

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  async created() {
    this.entity = await findEntityWithRouteParams(this.$route.params);
    this.instructions = reverse(
      sortBy(
        await findInstructions({ entity: this.entity, type: TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION }),
        'updatedAt',
      ),
    );
  }

  get ongoingInstructions() {
    return this.instructions.filter(instruction => {
      return instruction.calculatedState !== InstructionState.EXECUTED;
    });
  }

  get executedInstructions() {
    return this.instructions.filter(instruction => {
      return instruction.calculatedState === InstructionState.EXECUTED;
    });
  }

  get ready() {
    return this.instructions != null;
  }

  destroy(instruction) {
    remove(this.instructions, instruction);
  }
  async newInstruction() {
    const instruction = await makeNewInstruction({
      entity: this.entity,
      type: TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION,
    });
    this.$router.push({ path: `${this.entity.baseUrl}/aktieagartillskott/${instruction.id}` });
  }
}
</script>
