<template>
  <div class="TransactionsTable">
    <q-markup-table v-if="ready" flat class="standard-table" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Värdepapper</th>
          <th class="text-left">Typ</th>
          <th class="text-left">Säljare</th>
          <th class="text-left">Köpare</th>
          <th class="text-left">Affärsdag</th>
          <th class="text-left">Införd i aktieboken</th>
          <th class="text-right">Antal</th>
          <th class="text-right">Nominellt belopp</th>
          <th class="text-right">Pris</th>
        </tr>
      </thead>
      <tbody v-for="(transactions, instrumentId) in groupedTransactions" :key="instrumentId">
        <tr class="holder-row">
          <td colspan="100">{{ transactions[0].instrument.viewName }}</td>
        </tr>
        <TransactionsTableRow v-for="transaction in transactions" :key="transaction.id" :transaction="transaction" />
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import store from '@/store';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import TransactionsTableRow from './TransactionsTableRow.vue';
import { Transaction } from '@/models';
import { groupBy } from 'lodash';
import { ExtendedTransaction, mergeTransactions } from '@shared/web/models/transaction/utils';

@Component({
  components: { TransactionsTableRow },
})
export default class TransactionsTable extends Vue {
  @Prop() transactions: Transaction[];

  get user() {
    return store.profile.user;
  }
  get ready() {
    return this.transactions != null;
  }
  get groupedTransactions(): { [index: string]: ExtendedTransaction[] } {
    const mergedTransactions = mergeTransactions(this.transactions);
    return groupBy(mergedTransactions, 'instrument.id');
  }
}
</script>
<style lang="scss">
.TransactionsTable {
  position: relative;
}
</style>
