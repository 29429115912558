import * as _ from 'lodash';
import { format } from 'date-fns';
import { Model } from '@shared/web/models/base';

export default class SearchQuery extends Model {
  asRequestParams() {
    return asRequestParams(this.serialize());
  }
  isEmpty() {
    return _.isEmpty(this.query);
  }

  get empty() {
    return this.isEmpty();
  }

  limit: number | null;
  query: string;
}

function asRequestParams(searchQuery: any) {
  const params = _.pickBy(searchQuery, param => param != null && param !== '');
  const result = { ...params };
  _.map(params, (value: any, key) => {
    if (value instanceof Date) {
      result[key] = format(value, 'yyyy-MM-dd');
    }
  });

  return result;
}
