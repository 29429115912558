import { Entity } from '@/models';
import { toTransactionTypesLabel } from '@shared/common';
import { ExtendedTransaction } from '@shared/web/models/transaction/utils';
import { formatDay } from '@shared/utils';

const getTypeLabel = (transaction, isDebit) => toTransactionTypesLabel(transaction.transactionType, isDebit);

const getIsDebit = (user, previousOwner: Entity) => {
  return !(previousOwner?.id === user.id);
};

const getCounterPart = (transaction, isDebit) => {
  if (!isDebit) {
    return transaction.owner.viewName;
  }
  return transaction.previousOwner?.viewName || '';
};

export default function createUserTransactionsExporter(user: Entity, transactions: ExtendedTransaction[]) {
  return async function userTransactionsExporter() {
    return {
      name: 'Mina transaktioner',
      records: transactions.map(transaction => {
        const { instrument, owner, custodian, previousOwner } = transaction;
        const isDebit = getIsDebit(user, previousOwner);
        return {
          Bolag: instrument.entity.viewName,
          Värdepapper: instrument.name,
          Typ: getTypeLabel(transaction, isDebit),
          Motpart: getCounterPart(transaction, isDebit),
          Affärsdag: formatDay(transaction.tradeDate),
          Registreringsdag: formatDay(transaction.registrationDate),
          'Införd i aktieboken': formatDay(transaction.settleDate),
          Antal: transaction.quantity,
          Pris: transaction.price,
          ...(owner.isInsuranceCompany ? { Kapitalförsäkring: owner.viewName } : {}),
          ...(custodian ? { Förvar: custodian.viewName } : {}),
        };
      }),
    };
  };
}
