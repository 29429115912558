<template>
  <q-page class="DocumentLibrary">
    <h1>Malldokument</h1>
    <q-list bordered separator>
      <q-item
        v-for="(item, index) of library"
        :class="`test-pdf-download-${index}`"
        clickable
        @click="downloadItem(item)"
        :key="item.url"
      >
        <q-tooltip anchor="center middle" self="center middle"> Ladda ner </q-tooltip>
        <q-item-section avatar>
          <q-icon color="primary" :name="`svguse:/svg-icons.svg#${item.icon}`" />
        </q-item-section>
        <q-item-section>{{ item.description }} </q-item-section>
      </q-item>
    </q-list>
    <p v-if="errorMessage !== null" class="error-text">
      {{ errorMessage }}
    </p>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { exportFile } from 'quasar';
import { library } from './documentLibrary';

@Component({})
export default class DocumentLibraryPage extends Vue {
  errorMessage: string = null;

  async downloadItem(libraryItem) {
    try {
      const doc = await fetch(libraryItem.url);
      if (doc.status !== 200) {
        throw new Error(`Error downloading file: ${doc.status}`);
      }
      const blob = await doc.blob();
      if (!exportFile(libraryItem.filename, blob)) {
        throw new Error('Download disallowed by client');
      }
    } catch (e) {
      this.errorMessage = 'Nedladdning kunde inte initieras!';
      console.error(e);
      setTimeout(() => {
        this.errorMessage = null;
      }, 5000);
    }
  }
  get library() {
    return library;
  }
}
</script>
<style lang="scss">
.DocumentLibrary {
  h1 {
    margin-bottom: 40px;
  }
}
</style>
