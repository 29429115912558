import { Vue, Component } from 'vue-facing-decorator';
import _ from 'lodash';
import '@/styles/fixedTheadTable.scss';

@Component
export default class SyncScrollMixin extends Vue {
  tablePosition: 'static' | 'fixed' = 'static';
  headerWidth: number = 0;
  originalTableHeadWidth: number = 0;
  headerColumnWidths: number[] = [];
  private clonedHeader: HTMLElement | null = null;
  currentScrollXTablePosition: number = 0;

  mounted(): void {
    const scrollContainer = this.$el.querySelector('.fixed-thead-table');
    if (scrollContainer) {
      this.originalTableHeadWidth = scrollContainer.clientWidth;
    }
    scrollContainer.addEventListener('scroll', this.handleClonedTbodyTheadSyncScroll);
    window.addEventListener('scroll', () => {
      this.handleVisibilityOfClonedThead();
      this.updateClonedHeaderVisibility();
    });
    window.addEventListener('resize', this.setHeaderColumnWidth);
  }
  beforeUnmount(): void {
    window.removeEventListener('scroll', () => {
      this.handleVisibilityOfClonedThead();
      this.updateClonedHeaderVisibility();
    });

    window.removeEventListener('resize', this.setHeaderColumnWidth);

    const scrollContainer = this.$el.querySelector('.fixed-thead-table');
    if (scrollContainer) {
      scrollContainer.removeEventListener('scroll', this.handleClonedTbodyTheadSyncScroll);
    }
    this.removeClonedHeader();
  }
  setHeaderColumnWidth() {
    const tbody = this.$el.querySelector('tbody');
    if (!tbody || tbody.rows.length === 0) return;
    const tbodyRows: HTMLCollectionOf<HTMLTableRowElement> = tbody.getElementsByTagName('tr');
    this.headerColumnWidths = Array.from(tbodyRows[0].children).map((child, index): number => {
      const maxWidth = Array.from(tbodyRows).reduce((maxWidth, row) => {
        const cellWidth = (row.children[index] as HTMLElement).scrollWidth;
        return Math.max(maxWidth, cellWidth);
      }, 0);
      return maxWidth;
    });
    if (this.headerColumnWidths.length > 0) {
      this.headerWidth = _.sum(this.headerColumnWidths);
    }
  }
  handleClonedTbodyTheadSyncScroll(event): void {
    const THead = this.$el.querySelector('.is-clone');
    if (!THead) return;
    const scrollXTable = Math.round(event.target.scrollLeft);
    THead.scrollLeft = scrollXTable;
  }
  handleVisibilityOfClonedThead() {
    const qMarkupTableContainer = this.$el.querySelector('.fixed-thead-table');
    if (!qMarkupTableContainer) return;
    const tableElement = qMarkupTableContainer.getBoundingClientRect();
    this.tablePosition = tableElement.top <= 0 && tableElement.bottom >= 100 ? 'fixed' : 'static';
    if (this.tablePosition === 'fixed') {
      this.currentScrollXTablePosition = this.$el.querySelector('.fixed-thead-table').scrollLeft;
    }
  }

  prepareClonedHeader(): HTMLElement | null {
    const originalThead = this.$el.querySelector('.fixed-thead-table').querySelector('thead');
    if (!originalThead) return null;

    const clonedThead = originalThead.cloneNode(true) as HTMLElement;
    clonedThead.classList.add('is-clone', 'is-sticky');
    Object.assign(clonedThead.style, {
      maxWidth: `${this.originalTableHeadWidth}px`,
      marginLeft: '0',
      overflowX: 'hidden',
      msOverflowStyle: 'none',
    });
    return clonedThead;
  }

  updateClonedHeaderVisibility(): void {
    const tableContainer = this.$el.querySelector('.fixed-thead-table');
    if (!tableContainer || this.tablePosition !== 'fixed') {
      this.removeClonedHeader();
      return;
    }

    const originalThead = tableContainer.querySelector('thead');
    if (!originalThead) return;

    let clonedThead = this.clonedHeader;
    if (!clonedThead) {
      clonedThead = this.prepareClonedHeader();
      originalThead.parentNode?.insertBefore(clonedThead, originalThead.nextSibling);
    }

    this.setHeaderColumnWidth();
    const row = clonedThead.querySelector('tr');
    const ths = clonedThead.querySelectorAll('th');

    Object.assign(row.style, {
      display: 'flex',
      height: '76px',
      alignItems: 'flex-end',
    });

    ths.forEach((th, index) => {
      const width = this.headerColumnWidths[index];
      if (width != null) {
        Object.assign(th.style, {
          width: `${width}px`,
          display: 'block',
        });
      }
    });

    clonedThead.scrollLeft = this.$el.querySelector('.fixed-thead-table').scrollLeft;
    this.clonedHeader = clonedThead;
  }

  removeClonedHeader() {
    const theadClone = this.$el.querySelector('thead.is-clone');
    if (theadClone) {
      theadClone.parentNode?.removeChild(theadClone);
      this.clonedHeader = null;
    }
  }
}
