<template>
  <q-page v-if="ready" class="CheckShareRegisterPage">
    <div class="row justify-between">
      <h1>Skapa aktiebok</h1>
      <LastEntityUpdate />
    </div>
    <Stepper pageType="shareRegister" :currentstep="3" :isDone="isDone" :urlId="urlId" />
    <h4 class="table-header">Kontrollera att aktieboken stämmer</h4>
    <ShareRegisterView :shareRegister="shareRegister" :readonly="true" stage="CHECK" />
    <q-btn @click="proceed" v-bind="$defs.btn" type="button" :disable="!isDone" class="step-btn">Gå vidare</q-btn>
    <a-btn-link v-on:click="goBack" class="arrow icon-left">Gå tillbaka</a-btn-link>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { ShareRegister } from '@/models';
import { FormComponent } from '@shared/mixins';
import ShareRegisterView from '@shared/views/shareRegister/ShareRegisterView.vue';
import Stepper from '@/components/Stepper.vue';
import { findShareRegisterByEntityId } from '@shared/web/models/shareRegister/shareRegisterService';
import LastEntityUpdate from '@shared/components/LastEntityUpdate.vue';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { isToNewPage } from '@/utils';
@Component({
  components: { ShareRegisterView, Stepper, LastEntityUpdate },
  mixins: [FormComponent],
})
export default class CheckShareRegisterPageView extends Vue {
  shareRegister: ShareRegister = null;
  isDone = true;

  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const entity = await findEntityWithRouteParams(this.$route.params);
    this.shareRegister = await findShareRegisterByEntityId(entity);
  }

  get ready() {
    return this.shareRegister != null;
  }
  get urlId() {
    return this.shareRegister.entity.countryCode + '/' + this.shareRegister.entity.nationalId;
  }
  proceed() {
    this.$router.push({
      path: `${this.shareRegister.entity.baseUrl}/skapa-aktiebok/skickain`,
    });
  }
  goBack() {
    this.$router.push({
      path: `${this.shareRegister.entity.baseUrl}/skapa-aktiebok/dokument`,
    });
  }
}
</script>
<style lang="scss">
.CheckShareRegisterPage {
  .q-form {
    max-width: 632px;
  }
}
</style>
