import { deleteToken } from '@/authentication';
import axios from 'axios';
import { deleteUserDataForSentry } from '../sentry/sentryService';

export default {
  fetchProfile: async () => {
    try {
      const { data } = await axios.get('/webapi/auth/profile');
      return data;
    } catch (error) {
      return { loggedIn: false };
    }
  },

  logout: async () => {
    await axios.get('/webapi/auth/logout');
    deleteToken();
    deleteUserDataForSentry();
  },
};
