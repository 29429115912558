<template>
  <component v-bind="PDFRenderer.props" :is="PDFRenderer.component" />
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { setToken } from '@/authentication';
import { PDFType, PDFCategory } from '@shared/utils/pdf';
import ShareRegisterPDF from './ShareRegisterPDF.vue';
import TransactionsPDF from './TransactionsPDF.vue';

@Component({
  components: { ShareRegisterPDF, TransactionsPDF },
})
export default class SavePDF extends Vue {
  mounted() {
    if (this.token) {
      setToken(this.token);
    }
  }

  get token() {
    return this.queryToString(this.$route.query.token);
  }

  private queryToString(query: string | string[]) {
    return query && `${query}`;
  }

  get pdfType() {
    return this.queryToString(this.$route.query.type) as PDFType;
  }

  get PDFCommonProps() {
    return {
      token: this.token,
      pdfType: this.pdfType as PDFType,
      entity: this.$route.params.id,
      custodian: this.queryToString(this.$route.query.custodian),
      owner: this.queryToString(this.$route.query.owner),
      pdfCategory: this.queryToString(this.$route.query.category) as PDFCategory,
      instrument: this.queryToString(this.$route.query.instrument),
    };
  }

  get PDFRenderer() {
    switch (this.pdfType) {
      case PDFType.transactions:
        return {
          component: TransactionsPDF,
          props: {
            ...this.PDFCommonProps,
            owner: this.queryToString(this.$route.query.owner),
            dateType: this.queryToString(this.$route.query.dateType),
            from: this.queryToString(this.$route.query.from),
            to: this.queryToString(this.$route.query.to),
          },
        };
      case PDFType.complete:
      case PDFType.legal:
      default:
        return {
          component: ShareRegisterPDF,
          props: {
            ...this.PDFCommonProps,
            settleDate: this.queryToString(this.$route.query.settleDate),
          },
        };
    }
  }
}
</script>
