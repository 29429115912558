<template>
  <q-select
    v-model="model"
    use-input
    :options="filteredOptions"
    input-debounce="0"
    map-options
    hide-selected
    fill-input
    option-label="viewName"
    class="SelectEntity"
    v-bind="$attrs"
    ref="input"
    @filter="filterOptions"
    :readonly="readonly"
  >
    <template #option="scope">
      <q-list bordered separator class="select-pnr">
        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
          <q-item-label v-html="scope.opt.viewName" />
          <q-item-label class="q-pl-sm" caption>{{ formatNationalId(scope.opt) }} </q-item-label>
        </q-item>
      </q-list>
    </template>
    <template v-if="model == null" v-slot:selected>
      <div class="text-grey-6">{{ placeholder }}</div>
    </template>
  </q-select>
</template>
<script lang="ts">
// @ts-nocheck
import { Entity } from '@/models';
import { Component, Vue, Prop, Ref } from 'vue-facing-decorator';

@Component({})
export default class SelectEntity extends Vue {
  @Prop() modelValue: Entity;
  @Prop({ default: () => [] }) options: Array<Entity>;
  @Prop() placeholder: string;
  @Ref() readonly input;
  @Prop({ default: () => false }) readonly: boolean;

  phrase = '';

  get model() {
    return this.modelValue;
  }

  set model(val) {
    this.$emit('update:modelValue', val);
    this.$emit('change', val);
  }

  get filteredOptions() {
    return this.options.filter(
      entity =>
        entity.viewName.toLowerCase().includes(this.phrase) || entity.nationalId?.toLowerCase().includes(this.phrase),
    );
  }

  filterOptions(phrase: string, done: () => {}) {
    this.phrase = phrase.toLowerCase();
    done();
  }

  validate() {
    return this.input.validate();
  }
}
</script>
<style lang="scss"></style>
