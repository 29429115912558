import { GeneralMeetingRecord, getGeneralMeetingRecords, OwnerRegisterRecord } from '../shareRegister/utils';
import { ShareRegister } from '../models/modelTypes';
import { formatPercent } from '../utils/index';
import { formatNationalId } from '@shared/common';
import { IssuerInfo, GeneralMeetingParticipation } from '@shared/web/models';
import { Entity } from '@shared/web/models';
import { createInstrumentColums } from './ownerRegisterExport';

export default function createGeneralMeetingExporter(
  shareRegister: ShareRegister,
  generalMeetingParticipation: GeneralMeetingParticipation,
  issuerInfo: IssuerInfo,
) {
  return async function generalMeetingExporter() {
    const { entity } = shareRegister;

    return {
      name: entity.viewName,
      records: getGeneralMeetingRecords(shareRegister, generalMeetingParticipation)
        .sort((a: OwnerRegisterRecord, b: OwnerRegisterRecord): number => {
          return b.voteShare - a.voteShare;
        })
        .map((record: GeneralMeetingRecord) => {
          const contactInfo = issuerInfo.getContactInfo(record.owner as Entity);
          return {
            Ägare: record.owner?.viewName,
            'Org-/Personnr': `${formatNationalId(record.owner)}`,
            'Anmäld till bolagsstämma': record.participation.signed ? 'X' : '',
            Deltar: record.participation.participates ? 'X' : '',
            'Närvarande röster': record.participatingVotes,
            'Andel av närvarande = Röstlängd': formatPercent(record.participatingVoteShare),
            'Fullmakt / Ombud': '',
            ...createInstrumentColums(shareRegister, record),
            'Totalt antal': record.totalQuantity,
            Kapitalandel: formatPercent(record.capitalShare),
            'Antal röster': record.votes,
            Röstandel: formatPercent(record.voteShare),
            'E-post': contactInfo.email,
            'Telefonnr.': contactInfo.phone,
            Adress: record.owner.addresses[0]?.address1,
            Postnr: record.owner.addresses[0]?.zip,
            Ort: record.owner.addresses[0]?.city,
          };
        }),
    };
  };
}
