import { isNumber } from '@shared/utils/utils';

export const roaringRoles = [
  { code: 0, sv: 'Innehavare', en: 'Holder' },
  { code: 1, sv: 'Arbetstagarrepresentant', en: 'Employee representatives' },
  { code: 2, sv: 'Extern firmatecknare', en: 'External signatory' },
  { code: 3, sv: 'Extern verkställande direktör', en: 'External CEO' },
  { code: 4, sv: 'Extern vice verkställande direktör', en: 'External Deputy CEO' },
  { code: 5, sv: 'Ledamot', en: 'Commissioner' },
  { code: 6, sv: 'Likvidator', en: 'Receiver in liquidation liquidator' },
  { code: 7, sv: 'Likvidatorssuppleant', en: 'Alternate Deputy Liquidator' },
  { code: 8, sv: 'Revisor', en: 'Auditor' },
  { code: 9, sv: 'Revisorssuppleant', en: 'Deputy Auditor' },
  { code: 10, sv: 'Suppleant', en: 'Substitute' },
  { code: 12, sv: 'Verkställande direktör', en: 'CEO' },
  { code: 13, sv: 'Vice verkställande direktör', en: 'Deputy CEO' },
  { code: 14, sv: 'Bolagsman', en: 'Member' },
  { code: 15, sv: 'Kommanditdelägare', en: 'Limited partners' },
  { code: 16, sv: 'Komplementär', en: 'General Partner' },
  { code: 18, sv: 'Ordförande', en: 'Chairman' },
  { code: 20, sv: 'Prokurist', en: 'Procuration Holder' },
  { code: 21, sv: 'Huvudansvarig revisor', en: 'Chief Auditor' },
  { code: 22, sv: 'Lekmannarevisor', en: 'General examiner lay auditor' },
  { code: 23, sv: 'Lekmannarevisorssuppleant', en: 'General examiner lay auditor alternate deputy substitute' },
  { code: 24, sv: 'Ställföreträdande VD', en: 'Alternate deputy substitute CEO' },
  { code: 25, sv: 'Verkställande ledamot', en: 'Executive member' },
  { code: 26, sv: 'Vice ordförande', en: 'Vice Chairman' },
  { code: 27, sv: 'Särskild delgivningsmottagare', en: 'Special service recipient' },
  { code: 28, sv: 'Aktuarie', en: 'Actuarial' },
  { code: 29, sv: 'Utlandsbosatt inom EES', en: 'Abroad resident in the EEA' },
  { code: 30, sv: 'Utlandsbosatt utanför EES', en: 'Abroad resident outside the EEA' },
  { code: 31, sv: 'Försäkringsrepresentant', en: 'Insurance Representative' },
  { code: 100, sv: 'Innehavare', en: 'Holder' },
];
const signingRoleCodes = [5, 2, 3, 4];

export function singingRoles() {
  return roaringRoles.filter(r => signingRoleCodes.includes(r.code));
}

export function signingRolesCodes() {
  return singingRoles().map(r => r.code);
}

export function roleCanSign(role: string | number) {
  if (isNumber(role)) {
    return signingRolesCodes().includes(Number(role));
  }
  return !!singingRoles().find(r => r.sv === role || r.en === role);
}
export function hasSigningRole(roles: string[] | any[]) {
  return !!roles.find(r => roleCanSign(r));
}
