<template>
  <div class="InsurerInstrumentsTable relative-position">
    <ExportLinks v-if="ready">
      <ExportExcel :exporter="excelExporter" />
    </ExportLinks>
    <q-markup-table v-if="ready && !loading" flat class="standard-table" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Instrument</th>
          <th class="text-left">Typ</th>
          <th class="text-right">Totalt antal</th>
          <th class="text-right">Nominellt belopp</th>
        </tr>
      </thead>
      <tbody v-for="grouping in instrumentGroups" :key="grouping.instrument.id">
        <tr class="large">
          <td class="text-left">
            <a-btn-link @click="viewInstrument(grouping)">{{ grouping.instrument.name }}</a-btn-link>
            <div class="text-small">{{ toInstrumentId(grouping.instrument) }}</div>
          </td>
          <td class="text-left">{{ toInstrumentGroupLabel(grouping.instrument.group) }}</td>
          <td class="text-right">{{ grouping.quantity ? formatNumber(grouping.quantity) : '' }}</td>
          <td class="text-right">{{ grouping.amount ? formatNumber(grouping.amount) : '' }}</td>
        </tr>
      </tbody>
    </q-markup-table>
    <q-inner-loading :showing="loading" />
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { formatDate, formatNumber } from '@shared/utils';
import { Entity, Position } from '@/models';
import { groupAndFilterPositions } from '@/models/insurer/insurerService';
import { toInstrumentGroupLabel, toInstrumentId } from '@shared/common';
import ExportLinks from '@shared/components/ExportLinks.vue';
import ExportExcel from '@shared/views/excel/ExportExcel.vue';
import { sortBy } from 'lodash';

@Component({
  components: { ExportLinks, ExportExcel },
})
export default class InsurerInstrumentsTable extends Vue {
  @Prop() insurer: Entity;
  @Prop() positions: Position[];
  @Prop() custodians: string[];
  @Prop() allCustodians: boolean;
  @Prop() settleDate: Date;
  @Prop({ default: false }) loading;

  formatNumber(num) {
    return formatNumber(num);
  }

  toInstrumentGroupLabel(instrumentGroup) {
    return toInstrumentGroupLabel(instrumentGroup);
  }

  toInstrumentId(instrument) {
    return toInstrumentId(instrument);
  }

  get instrumentGroups() {
    const positionFilter = position => {
      if (!this.allCustodians) {
        return this.custodians.includes(position.custodian?.id);
      }
      return true;
    };

    const grouped = groupAndFilterPositions({
      positions: this.positions,
      filter: positionFilter,
    });
    return sortBy(grouped, 'instrument.name');
  }

  get ready() {
    return this.insurer && this.positions;
  }

  viewInstrument(grouping) {
    const {
      instrument: { id },
    } = grouping;
    this.$router.push(`${this.insurer.baseUrl}/insurer/${id}/instrument`);
  }

  async excelExporter() {
    const name = `Innehav ${formatDate(this.settleDate, 'yyyy-MM-dd')}`;

    return {
      name,
      records: this.instrumentGroups.map(({ instrument, quantity, amount }) => {
        return {
          Instrument: instrument.name,
          ID: toInstrumentId(instrument),
          Type: toInstrumentGroupLabel(instrument.group),
          'Totalt antal': quantity,
          'Nominellt belopp': amount,
        };
      }),
    };
  }
}
</script>
<style lang="scss">
.InsurerInstrumentsTable {
  position: relative;
  .q-table {
    tbody {
      td {
        border-bottom-width: 2px;
      }
    }
  }
}
</style>
