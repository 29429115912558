import * as Sentry from '@sentry/vue';
import { getTokenData } from '@/authentication';

export const setUserDataToSentry = (): void => {
  const tokenData = getTokenData();
  if (tokenData === null) {
    return;
  }
  Sentry.setUser({ id: tokenData.id });
};

export const deleteUserDataForSentry = (): void => {
  Sentry.setUser(null);
};
