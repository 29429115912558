<template>
  <q-markup-table flat class="standard-table DestinationsTable" separator="horizontal">
    <thead>
      <tr>
        <th class="text-left">Ägare</th>
        <th class="text-left">Försäkringsägare</th>
        <th class="text-left">Förvar</th>
        <th class="text-right number">Tecknade</th>
        <th></th>
      </tr>
    </thead>
    <tbody class="two-row">
      <template v-for="destination in instruction.destinations" :key="destination.id">
        <DestinationRow
          :destination="destination"
          :instruction="instruction"
          :shareRegister="shareRegister"
          @change="$emit('change')"
          :readonly="readonly"
        />
      </template>
      <tr v-if="!readonly" class="narrow-row">
        <td colspan="100">
          <q-btn
            color="secondary"
            id="test-add-owner"
            dense
            rounded
            flat
            @click="addPosition()"
            class="small"
            icon="add_circle"
            >Lägg till deltagare</q-btn
          >
        </td>
      </tr>
    </tbody>
  </q-markup-table>
</template>

<script lang="ts">
// @ts-nocheck
import { Component, Vue, Prop } from 'vue-facing-decorator';

import { ShareRegister, Instruction, InstructionParty } from '@/models';
import DestinationRow from '../../issueWarrant/DestinationRow.vue';
import EditPositionModal from '@shared/views/editPositionModal/EditPositionModal.vue';

@Component({
  components: { DestinationRow },
})
export default class DestinationsTable extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop({ default: () => false }) readonly: boolean;

  addPosition(instrument) {
    const entity = this.shareRegister.entity;
    const data = { instrument, entity };

    this.$q
      .dialog({
        component: EditPositionModal,
        componentProps: {
          positionClass: InstructionParty,
          value: data,
          label: 'deltagare',
          tradeEventForm: true,
          instruction: this.instruction,
          shareRegister: this.shareRegister,
        },
      })
      .onOk(() => {
        this.$emit('change');
      });
  }
}
</script>
<style lang="scss">
.DestinationsTable {
  .q-input.number {
    width: 80px;
    margin-left: auto;
  }
}
</style>
