<template>
  <div class="RightsInfo">
    <h4 class="table-header">Optioner i {{ shareRegister.entity.viewName }}</h4>
    <p>
      Vid lösen eller utnyttjande av TO programmet kontakta Kapclear på
      <a :href="'mailto:' + contacts.kapclearEmail">{{ contacts.kapclearEmail }}</a> eller
      <a :href="'tel:' + contacts.kapclearPhone"> {{ contacts.kapclearPhoneDisplay }}</a>
    </p>
    <q-markup-table flat class="standard-table" separator="horizontal">
      <thead class="has-background">
        <tr>
          <th class="text-left">Option</th>
          <th class="text-right">Antal</th>
          <th class="text-right">Lösenpris</th>
          <th class="text-right">Antal underliggande</th>
          <th class="text-left">Lösenperiod</th>
          <th class="text-left">Slutdag</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="instrument in shareRegister.instruments" :key="instrument.id">
          <td class="text-left bold wrap-text">
            {{ instrument.viewName }}
          </td>
          <td class="text-right bold">{{ formatNumber(instrument.rightsData.totalQuantity) }}</td>
          <td class="text-right bold">{{ formatNumber(instrument.rightsData.exercisePrice) }}</td>
          <td class="text-right bold">{{ formatNumber(instrument.rightsData.contractSize) }}</td>
          <td class="text-right bold">
            {{ formatDate(instrument.rightsData.exercisePeriodFrom) }} -
            {{ formatDate(instrument.rightsData.exercisePeriodTo) }}
          </td>
          <td class="text-right bold">{{ formatDate(instrument.rightsData.dueDate) }}</td>
        </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { ShareRegister } from '@shared/web/models';
import { formatDate } from '@shared/utils';
import { KaptenaContact } from '@shared/models/contactInformation';

@Component({})
export default class RightsInfo extends Vue {
  @Prop() shareRegister: ShareRegister;

  formatDate(date) {
    return formatDate(date, 'yyyy-MM-dd');
  }

  get contacts() {
    return KaptenaContact;
  }
}
</script>
<style lang="scss">
.RightsInfo {
  margin-bottom: 40px;

  .q-table {
    max-width: $col-6;

    th {
      &:nth-child(1) {
        min-width: auto;
      }
    }

    .bold {
      font-weight: 700;
      color: $grey-5;
    }
  }
}
</style>
