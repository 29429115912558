import { Instrument } from '@shared/web/models';
import { SortProperty, GroupProperty } from '@shared/models/modelTypes';
import { ViewType } from '@shared/shareRegister/tableSettings';

export default class TableSettings {
  sortProperty: SortProperty = 'owner';
  sortDesc = false;
  groupProperty: GroupProperty = 'instrument';
  filterInstrument: Instrument = null;
  viewType: ViewType = 'shareRegister';

  get isInvestorRegister() {
    return this.viewType === 'investorRegister';
  }
}
