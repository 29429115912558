<template>
  <div class="InstructionDestinationTable">
    <h4 class="table-header">Fördela Värdepapper</h4>
    <q-markup-table flat class="standard-table position-table" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Köpare</th>
          <th class="text-left">Försäkringsägare</th>
          <th class="text-left">Förvar</th>
          <th class="text-right">{{ isValueDistributed ? 'Nominellt belopp' : 'Antal' }}</th>
          <th class="text-right">Total likvid</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="two-row">
        <template v-for="destination in destinations" :key="destination.id">
          <InstructionDestinationRow
            :destination="destination"
            :shareRegister="shareRegister"
            :instruction="instruction"
            :readonly="readonly"
            @change="$emit('change')"
          />
        </template>
        <tr class="narrow-row">
          <td colspan="100%">
            <q-btn
              color="secondary"
              id="test-add-owner"
              dense
              rounded
              :disable="readonly"
              flat
              @click="addPosition()"
              class="small"
              icon="add_circle"
              >Lägg till köpare</q-btn
            >
          </td>
        </tr>
        <tr class="narrow-row sum-row">
          <td colspan="3">Kvar att fördela</td>
          <td class="text-right validation" :class="{ valid: validDestinationQuantity }">
            <span v-if="isValueDistributed">{{ formatNumber(remainingAmount) }}</span>
            <span v-else>{{ formatNumber(remainingQuantity) }}</span>
          </td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </q-markup-table>
    <a-btn-link class="test-clear" :disable="readonly" @click="clearInstruction">Rensa</a-btn-link>
  </div>
</template>
<script lang="ts">
import { ShareRegister, Instruction, InstructionParty } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import InstructionDestinationRow from '../issueWarrant/DestinationRow.vue';
import EditPositionModal from '@shared/views/editPositionModal/EditPositionModal.vue';
import ClearInstructionModal from '@/views/editInstructionModal/ClearInstructionModal.vue';
import { saveInstruction } from '@/models/instruction/instructionService';

@Component({
  components: { InstructionDestinationRow, EditPositionModal },
})
export default class InstructionDestinationTable extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop() validator;
  @Prop({ default: () => false }) readonly: boolean;

  get destinations() {
    return this.instruction.destinations;
  }

  get remainingQuantity() {
    return this.instruction.tradeEvent.source.quantity - this.validator.getDestinationQuantity();
  }

  get remainingAmount() {
    return this.instruction.tradeEvent.source.amount - this.validator.getDestinationAmount();
  }

  get validDestinationQuantity() {
    return this.remainingQuantity === 0;
  }

  get isValueDistributed() {
    return this.instruction?.tradeEvent?.source?.instrument?.rightsData?.isValueDistribution();
  }

  async addPosition() {
    if (!this.instruction.id) {
      this.instruction.id = (await saveInstruction(this.instruction)).model.id;
    }
    const entity = this.shareRegister.entity;
    const data = { instrument: this.instruction.tradeEvent.source.instrument, entity };

    this.$q
      .dialog({
        component: EditPositionModal,
        componentProps: {
          positionClass: InstructionParty,
          value: data,
          label: 'köpare',
          tradeEventForm: true,
          instruction: this.instruction,
          shareRegister: this.shareRegister,
        },
      })
      .onOk(() => {
        this.$emit('change');
      });
  }

  clearInstruction() {
    this.$q
      .dialog({
        component: ClearInstructionModal,
        componentProps: {
          id: this.instruction.id,
          modalName: 'ägarbyten',
        },
      })
      .onOk(instruction => {
        this.$emit('clear', instruction);
      });
  }
}
</script>
<style lang="scss">
.InstructionDestinationTable {
  max-width: 100%;
  .q-table {
    width: $col-5;
  }
}
</style>
