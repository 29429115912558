import Position from './Position';

import axios from 'axios';
import { serializedChanges } from '../base';
const baseUrl = '/api/position';
export async function updateWithSettlePosition(position: Position, settleDate: Date) {
  const update = serializedChanges(position);

  const { data } = await axios.put(baseUrl + '/update-with-settle-position', { settleDate, update });

  return Position.newModel(data, true);
}
