<template>
  <tr class="position-row">
    <td class="text-left">
      <p>{{ instrument.viewName }}</p>
    </td>
    <td class="text-left">
      <p>{{ id }}</p>
    </td>
    <td class="text-left">
      <p>{{ classTypeLabel }}</p>
    </td>
    <td class="text-left reservations">
      <p>{{ groupLabel }}</p>
    </td>
    <td class="text-right">
      <p>{{ formatNumber(instrument.shareData.totalCapital) }}</p>
    </td>
    <td class="text-right">
      <p>{{ formatPercent(totals.totalShare) }}</p>
    </td>
    <td class="text-right">
      <p>{{ formatNumber(instrument.shareData.totalQuantity) }}</p>
    </td>
    <td class="text-right">
      <p>{{ formatPercent(totals.votingPowerQuota) }}</p>
    </td>
  </tr>
</template>
<script lang="ts">
import { ShareRegister, Instrument } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { toShareClassTypeLabel, toInstrumentGroupLabel } from '@shared/common';
import { makeShareRegisterValidation } from '@shared/web/models/shareRegister/shareRegisterService';

@Component({})
export default class InstrumentRow extends Vue {
  @Prop() instrument: Instrument;
  @Prop() shareRegister: ShareRegister;

  get classTypeLabel() {
    return toShareClassTypeLabel(this.instrument.shareData.classType);
  }

  get groupLabel() {
    return toInstrumentGroupLabel(this.instrument.group);
  }

  get totals() {
    const totals = makeShareRegisterValidation(this.shareRegister);
    return totals.forInstrument(this.instrument);
  }

  get id() {
    const kapID = this.instrument.KapID;
    const ISIN = this.instrument.ISIN;
    if (kapID) {
      return kapID;
    }
    if (ISIN) {
      return ISIN;
    }
    return null;
  }
}
</script>
