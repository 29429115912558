<template>
  <q-page class="UserSettingsPage">
    <h1>Inställningar</h1>
    <UserSettingsComponent />
    <div class="row">
      <div class="col-12">
        <q-card flat>
          <q-list class="q-pa-md q-mb-lg">
            <q-item-label header>
              Nedan visas vilka Kapclear anslutna bolag som du har intressen i, direkt (privatperson) eller indirekt
              genom ett bolag som du företräder och den rollen du har i bolaget visas också.
            </q-item-label>
            <div class="q-mb-md q-mx-md">
              <q-table dense flat :rows="rows" :columns="columns" row-key="name" :rows-per-page-options="[100]" />
            </div>
            <div class="text-grey-4 q-px-md q-mb-sm text-caption">
              Det är bolaget som ansvarar för korrektheten i kontaktuppgifterna ovan.<br />
              Vill du ändra dem så måste du kontakta bolaget.
            </div>
          </q-list>
        </q-card>
      </div>
    </div>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { Entity, RoaringData, Address } from '@/models/';
import store from '@/store';
import { flatMap, sortBy } from 'lodash';
import axios from 'axios';
import { IssuerInfo } from '@/models';
import { ContactInfo, EntityAdmin } from '@shared/web/models';
import { toRoleTypeLabel } from '@shared/common';
import { QTableProps } from 'quasar';
import UserSettingsComponent from '@/views/userKapnetConsent/UserSettingsComponent.vue';

@Component({
  components: {
    UserSettingsComponent,
  },
})
export default class UserSettingsPage extends Vue {
  entity: Entity = null;
  returnTo = '';
  issuerInfos: IssuerInfo[] = [];
  userEntities: Entity[] = [];
  entityAdmins: EntityAdmin[] = [];
  settingsReviewedAtOriginal: null | Date = null;

  get columns(): QTableProps['columns'] {
    return [
      { label: 'Bolag', field: row => row.sourceLabel, align: 'left', name: 'company' },
      {
        label: 'Ägare/roll',
        field: this.ownerInfo,
        align: 'left',
        name: 'owner',
      },
      { label: 'E-postadress', field: row => row.contactInfo.email || '-', align: 'left', name: 'mail' },
      { label: 'Telefonnummer', field: row => row.contactInfo.phone || '-', align: 'left', name: 'phone' },
    ];
  }

  get user() {
    return store?.profile?.user;
  }

  get issuerInfoRows() {
    return flatMap(this.issuerInfos, issuerInfo => {
      return [
        ...issuerInfo.contacts.map(contact => ({
          contactInfo: contact,
          sourceEntity: issuerInfo.entity,
          sourceLabel: issuerInfo.entity.viewName,
          source: 'contact',
        })),
        ...issuerInfo.roles.map(role => ({
          contactInfo: role,
          sourceEntity: issuerInfo.entity,
          sourceLabel: issuerInfo.entity.viewName,
          source: 'role',
        })),
      ];
    });
  }

  get entityAdminsRows() {
    return this.entityAdmins.map(entityAdmin => ({
      contactInfo: ContactInfo.newModel({ email: entityAdmin.email, phone: entityAdmin.phone }),
      sourceEntity: entityAdmin.entity,
      sourceLabel: entityAdmin.entity.viewName,
      source: 'admin',
      ...entityAdmin,
    }));
  }

  get rows() {
    return sortBy([...this.issuerInfoRows, ...this.entityAdminsRows], 'sourceLabel');
  }

  get userEntity(): Entity | null {
    return this.userEntities[0];
  }

  ownerInfo(row) {
    if (row.source === 'admin') {
      return row.sourceEntity.legalEntity?.name + ' / administratör';
    }
    let ownerInfo = row.contactInfo?.entity?.viewName;
    if (this.roleForEntity(row.contactInfo?.entity)) {
      ownerInfo += ' / ' + this.roleForEntity(row.contactInfo.entity);
    }
    return ownerInfo;
  }

  roleForEntity(entity: Entity) {
    const role = entity?.legalEntity?.roles.find(role => role.person.id === this.userEntity.id);
    if (role) {
      return toRoleTypeLabel(role.type);
    }
    return null;
  }

  mounted() {
    this.fetchIssuerInfos();
    this.settingsReviewedAtOriginal = store.profile.user?.settingsReviewedAt;
    this.markAsReviewedIfNeeded();
    this.initEntity();
  }

  get settingsAlreadyReviewed() {
    return !!store.profile.user.settingsReviewedAt;
  }

  get roaringDataName() {
    const {
      surName = '',
      firstName = '',
      middleName = '',
    } = this.entity?.roaringData?.person ?? this.entity?.person ?? {};
    return `${firstName} ${middleName || ''} ${surName}`.trim().replace(/\s+/g, ' ');
  }

  async fetchIssuerInfos() {
    const { issuerInfos, userEntities, entityAdmins } = (await axios.get('/webapi/auth/issuer-info-contacts')).data;
    this.issuerInfos = IssuerInfo.newModels(issuerInfos);
    this.userEntities = Entity.newModels(userEntities);
    this.entityAdmins = EntityAdmin.newModels(entityAdmins);
  }

  async markAsReviewedIfNeeded() {
    const user = store.profile.user;
    if (user && !user.settingsReviewedAt) {
      user.settingsReviewedAt = (await axios.put('/webapi/auth/mark-settings-as-reviewed')).data.settingsReviewedAt;
    }
  }

  initEntity() {
    if (typeof this.$route.query.tillbakaTill === 'string') {
      this.returnTo = this.$route.query.tillbakaTill;
    }

    const entity = store.profile.entity.copy();

    if (!entity.addresses.length) {
      entity.addresses.push(
        Address.newModel(
          {
            countryCode: entity.countryCode,
            firstName: entity.person.firstName,
            surName: entity.person.surName,
          },
          false,
        ),
      );
    }

    if (!entity.roaringData?.person) {
      entity.roaringData = entity.roaringData || RoaringData.newModel({});
      entity.roaringData.person = entity.person.copy();
    }

    this.entity = entity;
  }
}
</script>
<style lang="scss">
.UserSettingsPage {
  ul.view-information {
    margin-top: 30px;
    li {
      margin-bottom: 21px;
      strong {
        display: block;
        line-height: 13px;
      }
    }
  }
}
</style>
