const DEFAULT_URL = '/pdf/overlatelseavtal/v1/' as const;

const FILENAMES = {
  TRANSFER: 'overlatelse.pdf',
  TRANSFER_T_F: 'overlatelse_t_fors.pdf',
  TRANSFER_FR_F: 'overlatelse_fr_fors.pdf',
  TRANSFER_F: 'overlatelse_fors.pdf',
  PROTOCOL_YEAR: 'arstamma_protokoll.docx',
  PROTOCOL_CALL: 'arstamma_kallelse.docx',
  DECLARATION_GUIDE: 'deklarationsguide.pdf',
} as const;

const ICONS = {
  PDF: 'icon-pdf',
  DOWNLOAD: 'icon-download',
} as const;

export const library = [
  {
    description: 'Överlåtelseavtal av innehav mellan privatpersoner/bolag',
    url: DEFAULT_URL + FILENAMES.TRANSFER,
    filename: FILENAMES.TRANSFER,
    icon: ICONS.PDF,
  },
  {
    description: 'Överlåtelseavtal av innehav från privatperson/bolag till kapitalförsäkring',
    url: DEFAULT_URL + FILENAMES.TRANSFER_T_F,
    filename: FILENAMES.TRANSFER_T_F,
    icon: ICONS.PDF,
  },
  {
    description: 'Överlåtelseavtal av innehav från kapitalförsäkring till privatperson/bolag',
    url: DEFAULT_URL + FILENAMES.TRANSFER_FR_F,
    filename: FILENAMES.TRANSFER_FR_F,
    icon: ICONS.PDF,
  },
  {
    description: 'Överlåtelseavtal av innehav mellan kapitalförsäkringar',
    url: DEFAULT_URL + FILENAMES.TRANSFER_F,
    filename: FILENAMES.TRANSFER_F,
    icon: ICONS.PDF,
  },
  {
    description: 'Deklarationsguide',
    url: DEFAULT_URL + FILENAMES.DECLARATION_GUIDE,
    filename: FILENAMES.DECLARATION_GUIDE,
    icon: ICONS.PDF,
  },
  {
    description: 'Protokoll till årstämma',
    url: DEFAULT_URL + FILENAMES.PROTOCOL_YEAR,
    filename: FILENAMES.PROTOCOL_YEAR,
    icon: ICONS.DOWNLOAD,
  },
  {
    description: 'Kallelse till årsstämma i bolag',
    url: DEFAULT_URL + FILENAMES.PROTOCOL_CALL,
    filename: FILENAMES.PROTOCOL_CALL,
    icon: ICONS.DOWNLOAD,
  },
];
