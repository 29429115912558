<template>
  <div class="Terms">
    <OnboardingPage>
      <div class="wrapper">
        <div class="term-header">
          <div class="q-p-md">
            <h1>Användarvillkor</h1>
            <p v-if="isGetConsent">
              För att fortsätta behöver du acceptera Kapclear användarvillkor. <br />Klicka annars
              <router-link to="/valjbolag">här</router-link> för att komma tillbaks till startsidan.
            </p>
            <p v-else>Klicka <router-link to="/valjbolag">här</router-link> för att komma tillbaks till startsidan.</p>
          </div>
          <UserTerms class="markdown q-m-lg text-left" />
          <div v-if="isGetConsent" class="controls q-py-md q-px-sm row items-center q-gutter-md">
            <label class="row items-center">
              <q-checkbox class="test-terms" v-model="consent" />
              Jag accepterar</label
            ><q-btn class="col test-accept" :disable="!consent" @click="onAccept"> Fortsätt</q-btn>
          </div>
          <div v-else class="controls q-py-md q-px-sm row items-center q-gutter-md">
            <q-btn class="col test-accept" @click="onClose"
              ><q-icon name="cancel" size="sm" class="q-mr-xs" />Stäng
            </q-btn>
          </div>
        </div>
      </div>
    </OnboardingPage>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import OnboardingPage from '@/views/OnboardingPage.vue';
import { Entity, EntityAdmin } from '@shared/web/models';
import { saveEntity, findEntity } from '@/models/entity/entityService';
import { findEntityAdmin } from '@/models/entityAdmin/entityAdminService';
import { TermsAccepted } from '@shared/web/models';
import UserTerms from './UserTerms.md';

@Component({
  components: {
    OnboardingPage,
    UserTerms,
  },
})
export default class Terms extends Vue {
  isGetConsent = false;
  consent = false;
  activeEntity: Entity = null;
  entityAdmin: EntityAdmin = null;
  nextUrl: string = null;

  async mounted() {
    try {
      const { entity, entityAdmin, nextUrl } = this.$route.query;
      if (entity && entityAdmin && nextUrl) {
        this.isGetConsent = true;
        this.activeEntity = await findEntity(entity as string);
        this.entityAdmin = await findEntityAdmin(entityAdmin);
        this.nextUrl = nextUrl as string;
      }
    } catch (e) {
      this.$router.push('/valjbolag');
    }
  }

  onClose() {
    this.$router.push('/valjbolag');
  }

  async onAccept() {
    this.activeEntity.termsAccepted = TermsAccepted.newModel({
      at: new Date(),
      by: this.entityAdmin.id,
    });
    await saveEntity(this.activeEntity);
    this.$router.push(this.nextUrl);
  }
}
</script>
<style lang="scss">
.Terms {
  @import './markdown.scss';
  .onboarding-content {
    width: unset;
  }
  .wrapper {
    width: 100vw;
    height: 80vh;
    display: flex;
    max-width: $col-max;
    background: $grey-0;
    flex-direction: column;
    > * {
      padding: 1em 0.5em;
      overflow: hidden;
    }
  }
  .term-header {
    background: white;
    min-height: 175px;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    p {
      margin-bottom: 1.5em;
    }
  }

  @media (min-width: $breakpoint-sm) {
    .wrapper {
      width: 80vw;
    }
  }
}
</style>
