import InvestmentOffer from '@shared/web/models/investmentOffer/InvestorOffer';
import { modelHandler } from '../base';
import axios from 'axios';

const BASE_URL = '/api/investment-offer';
const { findModel } = modelHandler(InvestmentOffer, BASE_URL);

/**
 * Fetch all investment offers
 * @returns InvestmentOffer[]
 */
export async function fetchInvestmentOffers(): Promise<InvestmentOffer[]> {
  const { data } = await axios.get(BASE_URL);
  return InvestmentOffer.newModels(data);
}

/**
 * @param id
 * @returns InvestmentOffer
 */
export async function fetchInvestmentOfferById(id: string): Promise<InvestmentOffer> {
  return await findModel(id);
}
