import { toTransactionTypesLabel } from '@shared/common';
import { formatDay } from '@shared/utils';
import { ExtendedTransaction } from '@shared/web/models/transaction/utils';

const getTypeLabel = transaction => toTransactionTypesLabel(transaction.transactionType, transaction.debit);

export default function createTransactionsExporter(transactions: ExtendedTransaction[]) {
  return async function transactionsExporter() {
    return {
      name: 'Transaktioner',
      records: transactions.map(transaction => {
        const { instrument, owner, custodian, previousOwner } = transaction;
        return {
          Bolag: instrument.entity.viewName,
          Värdepapper: instrument.name,
          Säljare: previousOwner?.viewName,
          Köpare: owner.viewName,
          Typ: getTypeLabel(transaction),
          Registreringsdag: formatDay(transaction.registrationDate),
          Affärsdag: formatDay(transaction.tradeDate),
          'Införd i aktieboken': formatDay(transaction.settleDate),
          Antal: transaction.quantity,
          'Nominellt belopp': transaction.amount,
          Pris: transaction.price,
          ...(custodian ? { Förvar: custodian.viewName } : {}),
        };
      }),
    };
  };
}
