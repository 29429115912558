<template>
  <q-page class="UserLicenseAggrementPage">
    <h1>Inställningar</h1>
    <UserSettingsComponent :isKapnet="true" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import UserSettingsComponent from './UserSettingsComponent.vue';
@Component({
  components: {
    UserSettingsComponent,
  },
})
export default class UserKapnetConsentPage extends Vue {}
</script>
