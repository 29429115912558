import { InstrumentCategory, InstrumentGroup } from '@shared/models/types';
import { Instrument } from '@shared/web/models';

const groupInfo = {
  [InstrumentGroup.SHA]: { label: 'Stamaktie', category: InstrumentCategory.SHA },
  [InstrumentGroup.PAYSHA]: { label: 'Betald Teckningsaktie', category: InstrumentCategory.SHA },
  [InstrumentGroup.PREF]: { label: 'Preferensaktie', category: InstrumentCategory.SHA },
  [InstrumentGroup.WARRANT]: { label: 'Teckningsoption', category: InstrumentCategory.RIGHTS },
  [InstrumentGroup.CONVERTIBLE]: { label: 'Konvertibel', category: InstrumentCategory.DEBT },
  [InstrumentGroup.BOND]: { label: 'Obligation', category: InstrumentCategory.DEBT },
  [InstrumentGroup.DEBENTURE]: { label: 'Vinstandelslån', category: InstrumentCategory.DEBT },
  [InstrumentGroup.SYNTHETIC_SHARE]: { label: 'Syntetisk aktie', category: InstrumentCategory.SHA },
  [InstrumentGroup.FUND_SHARE]: { label: 'Fondandel', category: InstrumentCategory.SHA },
  [InstrumentGroup.SHAREHOLDER_CONTRIBUTION]: {
    label: 'Aktieägartillskott',
    category: InstrumentCategory.SHAREHOLDER_CONTRIBUTION,
  },
};

export function toInstrumentGroupLabel(instrumentGroup: InstrumentGroup) {
  return groupInfo[instrumentGroup].label;
}

export const instrumentGroupOptions = Object.values(InstrumentGroup).map(instrumentGroup => ({
  value: instrumentGroup,
  label: groupInfo[instrumentGroup].label,
  category: groupInfo[instrumentGroup].category,
}));

export function toInstrumentId(instrument: Instrument) {
  const { ISIN, Did, KapID, id } = instrument;
  return ISIN || Did || KapID || id;
}
