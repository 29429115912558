<template>
  <q-layout view="hhh lpr lfr" :class="layout.class" v-if="ready">
    <Navbar v-if="layout.header" @sidebar="sidebarOpen = !sidebarOpen" />
    <LeftPanel v-if="layout.leftDrawer" :open="sidebarOpen" @hide="sidebarOpen = false" />
    <q-page-container>
      <router-view />
    </q-page-container>
    <PageFooter v-if="layout.footer" />
    <MessageBanner />
    <CookiesBanner />
  </q-layout>
</template>

<script lang="ts">
// @ts-nocheck
import store from '@/store';
import Navbar from '@/components/Navbar.vue';
import LeftPanel from '@/components/LeftPanel.vue';
import MessageBanner from '@/components/MessageBanner.vue';
import PageFooter from '@/components/Footer.vue';
import CookiesBanner from '@/components/CookiesBanner.vue';
export default {
  name: 'LayoutDefault',
  components: { CookiesBanner, Navbar, LeftPanel, PageFooter, MessageBanner },
  data() {
    return {
      sidebarOpen: false,
    };
  },
  computed: {
    ready() {
      return this.$route.name !== null;
    },
    profile() {
      return store.profile;
    },
    layout() {
      const layout = this.$route.meta.layout || 'standard';
      if (layout === 'onboarding') {
        return {
          leftDrawer: false,
          header: false,
          footer: false,
          class: {
            'layout-onboarding': true,
          },
        };
      }
      if (layout === 'pdf') {
        return {
          leftDrawer: false,
          header: false,
          footer: false,
          class: {
            'layout-pdf': true,
          },
        };
      }
      if (layout === 'public') {
        return {
          leftDrawer: false,
          header: true,
          footer: true,
          class: {
            'layout-public': true,
          },
        };
      }
      return {
        leftDrawer: true,
        header: true,
        footer: true,
        class: {
          'layout-standard': true,
        },
      };
    },
  },
};
</script>

<style lang="scss">
.layout-standard {
  background: $grey-1;

  .q-drawer--left {
    background: $grey-0;
  }
  .q-footer {
    z-index: 0;
  }
  @media (min-width: 1360px) {
    .q-page-container,
    .q-drawer--left,
    .q-footer {
      margin-left: calc(50vw - 680px);
    }
    .q-page-container {
      max-width: 1360px;
    }
    .q-footer {
      max-width: 1160px;
    }
  }
}
.layout-public {
  @media (min-width: 1360px) {
    .q-footer {
      .q-toolbar {
        max-width: 1160px;
        margin: 0 auto;
      }
    }
  }
}

.layout-onboarding {
  background: black;
}
</style>
