<template>
  <div class="instruction-save-row flex justify-end items-center q-py-sm">
    <div class="flex" style="gap: 5px">
      <template v-if="isSaving || hasUnsavedChanges"> <q-spinner size="1.4em" />Sparar </template>
      <template v-else> <q-icon name="cloud_done" size="1.4em" />Sparat </template>
    </div>
    <q-btn v-bind="$defs.btn" type="button" class="step-btn" :disable="disable || isSaving" @click="$emit('proceed')">
      Gå vidare
    </q-btn>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component
export default class InstructionSaveRow extends Vue {
  @Prop({ default: false, required: true }) disable;
  @Prop({ default: false, required: true }) isSaving;
  @Prop({ default: false, required: true }) hasUnsavedChanges;
}
</script>
<style lang="scss">
.instruction-save-row {
  gap: 2rem;
  position: sticky;
  bottom: 0;
  background-color: transparentize($grey-1, 0.2);
  button.step-btn {
    margin: 0;
    transition: background-color 0.2s linear;
  }
}
</style>
