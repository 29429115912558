<template>
  <q-markup-table flat class="standard-table DestinationsTable" separator="horizontal">
    <thead>
      <tr>
        <th class="text-left">Ägare</th>
        <th class="text-left">Försäkringsägare</th>
        <th class="text-left">Förvar</th>
        <th class="text-right number" v-if="isExerciseWarrant">Tillgängliga optioner</th>
        <th class="text-right number" v-if="showQuantity && isConvertible">Antal Konvertibler</th>
        <th class="text-right number" v-else-if="showQuantity && isExerciseWarrant">Nyttjade TO</th>
        <th class="text-right number" v-else-if="showQuantity">Tecknade</th>
        <th class="text-right number" v-if="isConvertible || isBond">Nom. belopp</th>
        <th class="text-right number" v-if="isExerciseWarrant">Antal nya aktier</th>
        <th></th>
      </tr>
    </thead>
    <tbody class="two-row">
      <template v-for="destination in instruction.destinations" :key="destination.id">
        <DestinationRow
          :destination="destination"
          :instruction="instruction"
          :validator="validator"
          :shareRegister="shareRegister"
          :tableSettings="tableSettings"
          @change="$emit('change')"
          :readonly="readonly"
        />
      </template>
      <tr v-if="!readonly && (instruction.isConvertible || instruction.isBond)" class="narrow-row">
        <td :colspan="3"></td>
        <td :class="leftToDistribute !== 0 ? 'text-red' : ''">Kvar att fördela: {{ leftToDistribute }}</td>
        <td :colspan="3"></td>
      </tr>
      <tr v-if="!readonly" class="narrow-row">
        <td colspan="100">
          <q-btn
            :disable="disableAdd"
            color="secondary"
            id="test-add-owner"
            dense
            rounded
            flat
            @click="addPosition()"
            class="small"
            icon="add_circle"
            >Lägg till deltagare</q-btn
          >
        </td>
      </tr>
    </tbody>
  </q-markup-table>
</template>

<script lang="ts">
// @ts-nocheck
import { Component, Inject, Prop, Vue } from 'vue-facing-decorator';
import { ShareRegister, Instruction, InstructionParty, TransactionType } from '@/models';
import { getPositionGroups } from '@shared/shareRegister/utils';
import DestinationRow from './DestinationRow.vue';
import HolderRow from './HolderRow.vue';
import EditPositionModal from '@shared/views/editPositionModal/EditPositionModal.vue';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
import { sumBy } from 'lodash';

@Component({
  components: { HolderRow, DestinationRow },
})
export default class DestinationsTable extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() tableSettings: TableSettings;
  @Prop() instruction: Instruction;
  @Prop() disableAdd: boolean;
  @Prop() validator;
  @Prop({ default: () => false }) readonly: boolean;
  @Inject({ from: 'instructionDirty', default: { value: false } }) instructionDirty;

  get rightsData() {
    return this.instruction?.corporateEvent?.rightsData;
  }

  get leftToDistribute() {
    if (this.rightsData?.distributeByValue) {
      return this.rightsData?.nominalAmountTotal - sumBy(this.instruction.destinations, d => d.amount);
    } else {
      return this.rightsData?.totalQuantity - sumBy(this.instruction.destinations, d => d.quantity);
    }
  }

  get showQuantity() {
    return this.instruction?.corporateEvent?.rightsData?.distributeByValue !== true;
  }

  get positionGroups() {
    return getPositionGroups(this.instruction.destinations, this.shareRegister.instruments, this.tableSettings);
  }

  get isConvertible() {
    return this.instruction.isConvertible;
  }

  get isBond() {
    return this.instruction.isBond;
  }

  get isExerciseWarrant() {
    return this.instruction.type === TransactionType.EXERCISE_WARRANT;
  }

  async addPosition(instrument) {
    const entity = this.shareRegister.entity;
    const data = { instrument, entity };

    this.$q
      .dialog({
        component: EditPositionModal,
        componentProps: {
          positionClass: InstructionParty,
          value: data,
          label: 'deltagare',
          tradeEventForm: true,
          instruction: this.instruction,
          shareRegister: this.shareRegister,
          instructionDirty: this.instructionDirty,
        },
      })
      .onOk(() => {
        this.$emit('change');
      });
  }
}
</script>
<style lang="scss">
.DestinationsTable {
  .q-input.number {
    width: 80px;
    margin-left: auto;
  }
  .holder-name-th {
    width: 100px;
  }
}
</style>
