<template>
  <tr>
    <td class="text-left">
      <p>
        <router-link :to="instrument.issueInstructionUrl" v-if="instrument.issueInstructionUrl"
          >{{ instrument.viewName }}
        </router-link>
        <template v-else>{{ instrument.viewName }}</template>
      </p>
    </td>
    <td class="text-left">
      <p>{{ formatNationalId(instrument.entity) }}</p>
    </td>
    <td class="text-left">
      <p>{{ category }}</p>
    </td>
    <td class="text-left">
      <p>{{ group }}</p>
    </td>
    <td class="text-left">
      <p>{{ formatDay(instrument.rightsData.dueDate) }}</p>
    </td>
    <td class="text-left">
      <p>{{ formatNumber(totalQuantity) }}</p>
    </td>
  </tr>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Instrument } from '@/models';
import { toInstrumentGroupLabel, toInstrumentCategoryLabel } from '@shared/common';

@Component({})
export default class OtherInstrumentRow extends Vue {
  @Prop() instrument: Instrument;

  get category() {
    return toInstrumentCategoryLabel(this.instrument.category);
  }

  get totalQuantity() {
    return this.instrument.shareData?.totalQuantity;
  }

  get group() {
    return toInstrumentGroupLabel(this.instrument.group);
  }
}
</script>
