<template>
  <q-page v-if="ready" class="TransactionsPage">
    <h1>Transaktioner</h1>
    <TransactionSearch :searchQuery="searchQuery" :filters="filters" @change="change" />
    <ExportLinks>
      <ExportExcel :exporter="excelExporter" />
    </ExportLinks>
    <TransactionsTable :transactions="transactions" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { Transaction } from '@/models';

import TransactionsTable from './TransactionsTable.vue';
import TransactionSearchQuery from '@shared/web/models/transaction/TransactionSearchQuery';
import TransactionFilters from '@/models/transaction/TransactionFilters';
import { findTransactions } from '@/models/transaction/transactionService';
import TransactionSearch from './TransactionsSearch.vue';
import ExportLinks from '@shared/components/ExportLinks.vue';
import ExportExcel from '@shared/views/excel/ExportExcel.vue';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import createExcelExporter from '@/views/excel/transactionsExport';
import { Products } from '@shared/index';

@Component({
  components: { TransactionsTable, TransactionSearch, ExportLinks, ExportExcel },
})
export default class UserTransactionsPage extends Vue {
  transactions: Array<Transaction> = null;
  searchQuery: TransactionSearchQuery;
  filters: TransactionFilters;
  async created() {
    await this.loadData();
  }

  async loadData() {
    const entity = await findEntityWithRouteParams(this.$route.params);

    this.searchQuery = TransactionSearchQuery.newModel({ entity, instrumentProduct: Products.KAPCLEAR });
    this.transactions = await this.findTransactions();
    this.filters = new TransactionFilters(this.transactions);
  }

  async change(updatedQuery: TransactionSearchQuery) {
    this.searchQuery = updatedQuery;
    this.transactions = await this.findTransactions();
  }

  async findTransactions() {
    const { transactions } = await findTransactions(this.searchQuery.serialize());
    return transactions;
  }

  get ready() {
    return this.transactions != null && this.filters != null;
  }

  get excelExporter() {
    return createExcelExporter(this.transactions);
  }
}
</script>
<style lang="scss">
.TransactionsPage {
  h1 {
    margin-bottom: 40px;
  }
}
</style>
