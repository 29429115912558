<template>
  <q-select
    :options="options"
    emit-value
    map-options
    v-model="model"
    v-bind="$attrs"
    option-label="value"
    use-input
    fill-input
    input-debounce="0"
    hide-selected
    @filter="filterFnAutoselect"
    ref="input"
    dropdown-icon="expand_more"
  >
    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
        <q-item-section>
          <q-item-label>{{ scope.opt.value }} </q-item-label>
          <q-item-label caption>{{ scope.opt.label }} </q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Prop, Ref, Vue } from 'vue-facing-decorator';
import { currencyOptions } from '../utils/localeInfo';

@Component({})
export default class SelectCurrency extends Vue {
  @Prop() modelValue: string;
  @Ref() readonly input;
  get model() {
    return this.modelValue;
  }
  set model(val) {
    this.$emit('update:modelValue', val);
  }
  options = null;
  data() {
    return {
      options: currencyOptions,
    };
  }

  get validate() {
    return this.input.validate();
  }

  filterFnAutoselect(val, update) {
    update(
      () => {
        this.options = filterOptions(val);
      },

      ref => {
        if (val !== '' && ref.options.length > 0 && ref.optionIndex === -1) {
          ref.moveOptionSelection(1, true);
          ref.toggleOption(ref.options[ref.optionIndex], true);
        }
      },
    );
  }
}

function filterOptions(val) {
  if (val === '') {
    return currencyOptions;
  }
  const needle = val.toLowerCase();
  return currencyOptions
    .filter(v => v.label.toLowerCase().indexOf(needle) > -1 || v.value.toLowerCase().indexOf(needle) > -1)
    .sort((a, b) => {
      if (a === b) {
        return 0;
      }
      if (a.value.toLowerCase().indexOf(needle) > -1) {
        return -1;
      }
      if (b.value.toLowerCase().indexOf(needle) > -1) {
        return 1;
      }
      return a.value > b.value ? 1 : -1;
    });
}
</script>
