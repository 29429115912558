<template>
  <div class="OwnersTable">
    <q-markup-table flat class="standard-table OwnersListing" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Ägare</th>
          <th class="text-left">Person-/Orgnr.</th>
          <th class="text-right" :key="instrument.name" v-for="instrument in shareRegister.instruments">
            {{ instrument.name }}
          </th>
          <th class="text-right">Totalt Antal</th>
          <th class="text-right">Kapitalandel</th>
          <th class="text-right">Antal röster</th>
          <th class="text-right">Röstandel</th>
          <th v-if="canEdit"></th>
        </tr>
      </thead>
      <tbody>
        <tr :key="i" v-for="(record, i) in owners">
          <td class="text-left">{{ record.owner.viewName }}</td>
          <td class="text-left">{{ formatNationalId(record.owner) }}</td>
          <td class="text-right" :key="instrument.name" v-for="instrument in shareRegister.instruments">
            {{ formatNumber(getInstrumentQuantity(instrument.name, record)) }}
          </td>
          <td class="text-right">{{ formatNumber(record.totalQuantity) }}</td>
          <td class="text-right">{{ formatPercent(record.capitalShare) }}</td>
          <td class="text-right">{{ formatNumber(record.votes) }}</td>
          <td class="text-right">{{ formatPercent(record.voteShare) }}</td>
        </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { ShareRegister } from '@shared/web/models';
import PositionRow from './PositionRow.vue';
import InstrumentRow from './InstrumentRow.vue';
import { OwnerRegisterRecord } from '@shared/shareRegister/utils';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { store } from '@shared/web/store';
import { isBackOffice } from '@shared/web/utils';

const votingPowerSorter = (a: OwnerRegisterRecord, b: OwnerRegisterRecord): number => {
  return b.voteShare - a.voteShare;
};

@Component({
  components: { PositionRow, InstrumentRow },
})
export default class OwnersTable extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop({ default: () => false }) readonly: boolean;

  getInstrumentQuantity(name, record) {
    return record.instruments.find(instrument => instrument.name === name)?.quantity || 0;
  }

  get owners() {
    return this.shareRegister.getOwnerSummaries().sort(votingPowerSorter);
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false || isBackOffice();
  }
}
</script>
<style lang="scss">
.OwnersTable {
  position: relative;
}
</style>
