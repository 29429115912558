<template class="Select">
  <q-select
    :options="extendedOptions"
    v-model="model"
    v-bind="$attrs"
    ref="input"
    :option-label="toLabel"
    :rules="rules"
    dropdown-icon="expand_more"
    popup-content-class="shadow-1"
  >
    <template v-if="model == null" v-slot:selected>
      <div class="text-grey-6" v-if="placeholder">{{ placeholder }}</div>
      <div v-else-if="nullOption">{{ nullLabel }}</div>
    </template>
  </q-select>
</template>
<script lang="ts">
import { Component, Vue, Prop, Ref } from 'vue-facing-decorator';

@Component({})
export default class Select extends Vue {
  @Prop() options: Array<any>;
  @Prop() modelValue;
  @Prop() readonly optionLabel;
  @Prop({ default: () => 'Ingen vald' }) readonly nullLabel;
  @Prop({ default: () => true }) nullOption: boolean;
  @Prop() placeholder: string;
  @Prop() rules;
  @Ref() readonly input;

  get extendedOptions() {
    const options = this.options;
    if (options == null) {
      return null;
    }

    if (this.nullOption) {
      return [null, ...this.options];
    } else {
      return this.options;
    }
  }

  toLabel(item) {
    if (item == null) {
      return this.nullLabel;
    }
    const labelProp = this.optionLabel || 'label';

    return item[labelProp];
  }

  validate() {
    return this.input.validate();
  }

  get model() {
    return this.modelValue;
  }
  set model(val) {
    this.$emit('update:modelValue', val);
    this.$emit('change', val);
  }
}
</script>
