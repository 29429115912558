/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import * as _ from 'lodash';
import { newModel, initModel, copy, serialize } from '.';

export default class Model {
  id: string;

  static baseUrl: string = null;
  static storeModel = true;
  constructor(attributes = {}) {
    _.assign(this, attributes);
  }

  static define(): any {
    return {};
  }
  postInitModel(): void {}

  initModel() {
    return initModel(this);
  }
  serialize() {
    return serialize(this);
  }
  copy() {
    return copy(this);
  }
  static newModel<T extends typeof Model>(this: T, data, addToStore = false): InstanceType<T> {
    return newModel(data, this, addToStore);
  }
  static newModels<T extends typeof Model>(
    this: T,
    dataArray: Array<object>,
    addToStore = false,
  ): Array<InstanceType<T>> {
    return dataArray.map(data => newModel(data, this, addToStore));
  }
}
