<template>
  <q-page v-if="ready" class="CheckRightsIssuePage">
    <h1>{{ instruction.processTitle }}</h1>
    <Stepper pageType="rightsIssue" :currentstep="3" :isDone="isDone" :urlId="instruction.id" />
    <q-separator />
    <h4 class="table-header">Skapa emission</h4>
    <template v-if="isIssueShare">
      <IssueShareContent
        :instruction="instruction"
        :shareRegister="shareRegister"
        @change="save"
        :readonly="!isEditable"
      />
    </template>
    <template v-else>
      <TransactionsDetails :shareRegister="shareRegister" :instruction="instruction" @change="save" :readonly="true" />
      <InstrumentSourceTable
        :shareRegister="shareRegister"
        :instruction="instruction"
        :validator="validator"
        :readonly="true"
      />
      <h4 class="table-header">Teckna emission</h4>
      <DestinationsTableCard
        :shareRegister="shareRegister"
        v-if="shareRegister"
        :instruction="instruction"
        :validator="validator"
        :readonly="true"
      />
    </template>
    <q-btn v-bind="$defs.btn" type="button" class="step-btn" :disable="!isDone" @click="proceed">Gå vidare</q-btn>
    <a-btn-link v-on:click="goBack" class="arrow icon-left">Gå tillbaka</a-btn-link>
  </q-page>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';
import { ShareRegister, Instruction, InstructionState, TransactionType } from '@/models';
import Stepper from '@/components/Stepper.vue';
import TransactionsDetails from './TransactionsDetails.vue';
import InstrumentSourceTable from './InstrumentSourceTable.vue';
import DestinationsTableCard from './DestinationsTableCard.vue';
import IssueShareContent from './issueShare/IssueShareContent.vue';
import { saveInstruction, makeInstructionValidator, findInstruction } from '@/models/instruction/instructionService';
import { isToNewPage } from '@/utils';
@Component({
  components: { Stepper, TransactionsDetails, InstrumentSourceTable, DestinationsTableCard, IssueShareContent },
})
export default class CheckRightsIssuePage extends Vue {
  @Prop({ default: true }) readonly: boolean;
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;

  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;
    const { shareRegister, instruction } = await findInstruction(id);

    Object.assign(this, { shareRegister, instruction });
  }

  async save() {
    await saveInstruction(this.instruction);
  }

  get isDone() {
    return true;
  }

  get validator() {
    return this.shareRegister && makeInstructionValidator(this.instruction, this.shareRegister);
  }

  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }

  get isIssueShare() {
    return this.instruction.type === TransactionType.ISSUE_SHARE;
  }

  async proceed() {
    this.instruction.state = InstructionState.SUBMITTED;
    await saveInstruction(this.instruction);
    this.$router.push({
      path: `${this.instruction.entity.baseUrl}/nyemission/${this.instruction.id}/skickain`,
    });
  }
  goBack() {
    this.$router.push({
      path: `${this.instruction.entity.baseUrl}/nyemission/${this.instruction.id}/dokument`,
    });
  }
}
</script>
