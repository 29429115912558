<template>
  <tbody>
    <tr v-for="contact in contacts" v-bind:key="contact.id">
      <td class="q-pr-none" style="padding-right: 0">
        <q-checkbox
          :val="contact.email"
          :disable="!contact.email"
          :modelValue="isSelected(contact.email)"
          @update:modelValue="val => selectEmail(contact.email, val)"
        />
      </td>
      <td style="white-space: initial; width: 35%">
        <span v-if="contact.fullName">{{ contact.fullName }}</span>
        <span v-else class="text-grey">Ingen namn</span>
      </td>
      <td>
        <EmailAllModalContactsEmail :contact="contact" @emailChange="$emit('emailChange')" />
      </td>
    </tr>
  </tbody>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { ContactInfo } from '@shared/web/models';
import EmailAllModalContactsEmail from '@shared/components/EmailAllModalContactsEmail.vue';

@Component({
  components: { EmailAllModalContactsEmail },
})
export default class EmailAllModalContacts extends Vue {
  @Prop({ required: true })
  contacts: ContactInfo[];
  @Prop({ default: [] })
  selectedEmails: string[];

  isSelected(email) {
    if (!email) {
      return false;
    }
    return this.selectedEmails.some(e => e === email);
  }

  selectEmail(email: string, isChecked: boolean) {
    this.$emit('emailSelected', email, isChecked);
  }
}
</script>
