<template>
  <q-input
    v-bind="onlyProps($attrs)"
    :modelValue="formattedValue"
    @update:modelValue="onInput"
    @blur="onBlur"
    @keyup.enter="$event.target.blur()"
    unmasked-value
    no-error-icon
    :rules="rules"
    ref="input"
  />
</template>
<script lang="ts">
import { QInput } from 'quasar';
import { Component, Vue, Prop, Ref, Watch } from 'vue-facing-decorator';
import {nextTick} from 'vue'
import { formatNumber, isNumber, toNumber } from '../utils/index';

function getInputElement(qInput: QInput): HTMLInputElement | null {
  return qInput?.$refs?.input as HTMLInputElement;
}

function countSpaces(str: string) {
  const matches = str.match(/\s/g);
  return matches ? matches.length : 0;
}

@Component({inheritAttrs: false})
export default class NumberInput extends Vue {
  @Ref() readonly input;
  @Prop()
  modelValue: number;
  @Prop()
  minimumFractionDigits: number;
  @Prop()
  maximumFractionDigits: number;
  @Prop()
  fractionDigits: number;
  @Prop()
  allowOnlyNumber: boolean;
  @Prop() rules;

  val?: number;
  decimalFormat = true;
  selectionStart = 0;


  @Watch('modelValue')
  changeValue(val) {
    this.val = val;
  }

  @Watch('formattedValue')
  updateCaretPosition(formattedValue, previousValue) {
    const previousToCaret = previousValue?.substr?.(0, this.selectionStart) || '';
    const currentToCaret = formattedValue?.substr?.(0, this.selectionStart) || '';
    const spacediff = countSpaces(currentToCaret) - countSpaces(previousToCaret);

    const insertionPoint = this.selectionStart + spacediff;

    nextTick(() => {
      // const inputElement = getInputElement(this.$refs.input as QInput); @TODO readd, hitta rätt inputElement
      // inputElement.setSelectionRange(insertionPoint, insertionPoint);
    });
  }

  get formattedValue() {
    const { decimalFormat, minFractionDigits, maxFractionDigits, modelValue } = this;
    if (decimalFormat === false) {
      return formatNumber(modelValue, 0, maxFractionDigits);
    }
    return formatNumber(modelValue, minFractionDigits, maxFractionDigits);
  }

  get minFractionDigits() {
    const { fractionDigits, minimumFractionDigits } = this;
    if (fractionDigits != null) {
      return fractionDigits;
    }
    return minimumFractionDigits;
  }

  get maxFractionDigits() {
    const { fractionDigits, maximumFractionDigits } = this;
    if (fractionDigits != null) {
      return fractionDigits;
    }
    return maximumFractionDigits;
  }

  validate() {
    return this.input.validate();
  }

  onInput(val) {
    this.decimalFormat = false;
    // const inputElement = getInputElement(this.$refs.input as QInput); @TODO readd
    // this.selectionStart = inputElement.selectionStart;
    this.val = toNumber(val, this.maxFractionDigits);
    return this.$emit('update:modelValue', this.val);
  }

  onBlur() {
    if (this.val === undefined) {
      return;
    }
    this.decimalFormat = true;
    const emitZero = this.allowOnlyNumber && !isNumber(this.val);
    const result = emitZero ? 0 : this.val;

    this.$emit('update:modelValue', result);
    this.$emit('change', result);
  }
}
</script>
