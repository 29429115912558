<template>
  <q-page>
    <h1 class="q-mb-lg">Mina bolagsengagemang</h1>
    <EntitiesGrid />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import EntitiesGrid from '@/views/selectEntity/EntitiesGrid.vue';
@Component({
  components: { EntitiesGrid },
})
export default class UserEngagementsPage extends Vue {}
</script>
