import { Model } from '../base';
import { Instrument } from '@shared/web/models';

export default class InstrumentSource extends Model {
  quantity: number;
  instrument: Instrument = null;
  originInstrument: Instrument = null;
  interimShare: Instrument = null;
  interimShareKapID?: string;
  interimShareNumeralId?: number;
  static define = () => ({
    instrument: {
      model: Instrument,
    },
    interimShare: {
      model: Instrument,
    },
    originInstrument: {
      model: Instrument,
    },
  });
}
