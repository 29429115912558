<template>
  <q-page v-if="ready" class="IssuerDashboard">
    <div class="row q-col-gutter-md">
      <div class="col-12 col-md-9">
        <h1>Dashboard</h1>
        <DashboardHeader v-if="isIssuerAndReady" :shareRegister="shareRegister" />
      </div>
      <div class="col-12 col-md-3">
        <CompanyInformation :entity="entity" @change="loadData" />
      </div>
    </div>
    <template v-if="isIssuerAndReady && !locked && !(entity.issuerData && entity.issuerData.onlyWarrantCompany)">
      <q-separator />
      <CreateShareRegisterInfo :shareRegister="shareRegister" />
    </template>

    <q-separator />
    <MissingContactInfoTable :entity="entity" />
    <template v-if="isIssuerAndReady">
      <InstrumentTable :shareRegister="shareRegister" />
      <OtherInstrumentTable :instruments="shareRegister.entity.instruments" />
      <RecentTradeEventTable :entity="shareRegister.entity" />
      <RecentRightsIssueTable :entity="shareRegister.entity" />
    </template>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import DashboardHeader from './DashboardHeader.vue';
import CreateShareRegisterInfo from './CreateShareRegisterInfo.vue';
import InstrumentTable from './InstrumentTable.vue';
import CompanyInformation from './CompanyInformation.vue';
import OtherInstrumentTable from './OtherInstrumentTable.vue';
import RecentTradeEventTable from './RecentTradeEventTable.vue';
import RecentRightsIssueTable from './RecentRightsIssueTable.vue';
import { ShareRegister, Entity } from '@/models';
import { findShareRegisterByEntityId } from '@shared/web/models/shareRegister/shareRegisterService';
import { loadEntityWithRouteParams } from '@shared/web/entity/entityService';
import MissingContactInfoTable from '@/views/dashboard/MissingContactInfoTable.vue';
import { EntityType } from '@shared/models/types';
import { isToNewPage } from '@/utils';

@Component({
  components: {
    MissingContactInfoTable,
    DashboardHeader,
    CreateShareRegisterInfo,
    InstrumentTable,
    CompanyInformation,
    OtherInstrumentTable,
    RecentTradeEventTable,
    RecentRightsIssueTable,
  },
})
export default class IssuerDashboard extends Vue {
  entity: Entity = null;
  shareRegister: ShareRegister = null;
  ready = false;

  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    this.entity = await loadEntityWithRouteParams(this.$route.params);
    if (this.isIssuer) {
      this.shareRegister = await findShareRegisterByEntityId(this.entity);
    }
    this.ready = true;
  }

  get locked() {
    return this.shareRegister?.entity?.issuerData?.locked;
  }

  get isIssuer() {
    return !!this.entity?.types?.includes(EntityType.issuer);
  }
  get isIssuerAndReady() {
    return this.isIssuer && this.shareRegister;
  }
}
</script>
<style lang="scss">
.IssuerDashboard {
  .row > .col-9 {
    max-width: 814px;
  }
  .q-markup-table {
    & + .text-small {
      margin: 5px 0;
    }
  }
}
</style>
