<template>
  <q-item class="label-item">
    <q-item-section side class="label">
      <div class="flex items-center">
        <q-icon v-if="icon" :name="icon" color="grey" size="24px" class="q-mr-sm" />
        {{ label }}
      </div>
    </q-item-section>
    <q-item-section><slot /></q-item-section>
  </q-item>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
@Component
export default class ListItem extends Vue {
  @Prop() icon: string;
  @Prop() label: string;
}
</script>
