import { Model } from '../base';
import { Entity, Position } from '@/models';

class Investments extends Model {
  static define = () => ({
    entity: { model: Entity },
    positions: {
      embeddedModel: [Position],
    },
  });
  entity: Entity;
  positions: Position[];
}

export default class UserInvestments extends Model {
  static define = () => ({
    investments: {
      embeddedModel: [Investments],
    },
  });
  static storeModel = false;
  investments: Investments[];
}
