<template>
  <q-page v-if="ready" class="RightsIssuesPage">
    <h1>Nyemission</h1>
    <q-btn-dropdown
      v-if="canEdit"
      color="primary"
      dropdown-icon="expand_more"
      label="Skapa nyemission"
      v-bind="$defs.btn"
    >
      <q-list>
        <q-item clickable v-close-popup @click="newRightsIssue">
          <q-item-section avatar>
            <q-icon name="add" />
          </q-item-section>

          <q-item-section>
            <q-item-label>I befintligt aktieslag / aktieserie</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup @click="newIssueShare">
          <q-item-section avatar>
            <q-icon name="add" />
          </q-item-section>

          <q-item-section>
            <q-item-label>I nytt aktieslag / aktieserie</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>

    <q-separator />
    <RightsIssueTable
      @destroy="destroy"
      header="Pågående nyemissioner"
      subheader="Ta bort inaktuella registreringar som inte ska genomföras, genom att klicka på krysset"
      :value="ongoingRightsIssue"
    />
    <RightsIssueTable @destroy="destroy" header="Genomförda nyemissioner" :value="executedRightsIssue" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Instruction, TransactionType, Entity } from '@/models';
import RightsIssueTable from './RightsIssueTable.vue';
import { findInstructions } from '@/models/instruction/instructionService';
import { remove } from '@/utils';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { transactionTypes } from '@shared/common';
import { store } from '@shared/web/store';
import { reverse, sortBy } from 'lodash';

@Component({
  components: { RightsIssueTable },
})
export default class RightsIssuesPage extends Vue {
  @Prop({ default: true }) readonly: boolean;
  instructions: Array<Instruction> = null;
  entity: Entity = null;

  async created() {
    this.entity = await findEntityWithRouteParams(this.$route.params);
    const instructions = await findInstructions({
      entity: this.entity,
      type: [TransactionType.RIGHTS_ISSUE, TransactionType.ISSUE_SHARE],
    });
    this.instructions = reverse(sortBy(instructions, 'updatedAt'));
  }

  get ongoingRightsIssue() {
    return this.instructions.filter(instruction => {
      return instruction.calculatedState !== 'EXECUTED';
    });
  }

  get executedRightsIssue() {
    return this.instructions.filter(instruction => {
      return instruction.calculatedState === 'EXECUTED';
    });
  }

  get ready() {
    return this.instructions != null;
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  destroy(instruction) {
    remove(this.instructions, instruction);
  }
  async newIssueShare() {
    const type = transactionTypes.ISSUE_SHARE.toLowerCase();
    await this.$router.push({
      path: `${this.entity.baseUrl}/nyemission/${type.replace(/\s/gi, '-')}`,
    });
  }
  async newRightsIssue() {
    const type = transactionTypes.RIGHTS_ISSUE.toLowerCase();
    await this.$router.push({
      path: `${this.entity.baseUrl}/nyemission/${type.replace(/\s/gi, '-')}`,
    });
  }
}
</script>
