import { getOwnerSummaries, OwnerRegisterRecord } from '../shareRegister/utils';
import { ShareRegister } from '../models/modelTypes';
import { formatPercent } from '../utils/index';
import { formatNationalId } from '@shared/common';
import { IssuerInfo } from '@shared/web/models';
import { Entity } from '@shared/web/models';

export const createInstrumentColums = (shareRegister, record) => {
  const getInstrumentQuantity = (name, record) => {
    return record.instruments.find(instrument => instrument.name === name)?.quantity || 0;
  };

  return shareRegister.instruments.reduce((columns, instrument) => {
    return {
      ...columns,
      [instrument.name]: getInstrumentQuantity(instrument.name, record),
    };
  }, {});
};

export default function createOwnerRegisterExporter(shareRegister: ShareRegister, issuerInfo: IssuerInfo) {
  return async function ownerRegisterExporter() {
    const { entity } = shareRegister;

    return {
      name: entity.viewName,
      records: getOwnerSummaries(shareRegister)
        .sort((a: OwnerRegisterRecord, b: OwnerRegisterRecord): number => {
          return b.voteShare - a.voteShare;
        })
        .map((record: OwnerRegisterRecord) => {
          const contactInfo = issuerInfo.getContactInfo(record.owner as Entity);
          return {
            Ägare: record.owner?.viewName,
            'Org-/Personnr': `${formatNationalId(record.owner)}`,
            ...createInstrumentColums(shareRegister, record),
            'Totalt antal': record.totalQuantity,
            Kapitalandel: formatPercent(record.capitalShare),
            'Antal röster': record.votes,
            Röstandel: formatPercent(record.voteShare),
            'E-post': contactInfo.email,
            'Telefonnr.': contactInfo.phone,
            Adress: record.owner.addresses[0]?.address1,
            Postnr: record.owner.addresses[0]?.zip,
            Ort: record.owner.addresses[0]?.city,
          };
        }),
    };
  };
}
