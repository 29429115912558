<template>
  <q-page class="InsurerPositions">
    <div class="row q-col-gutter-lg">
      <h1 class="col-9">
        {{ (instrument && instrument.name) || 'Värdepapper' }}
        <small v-if="instrument">{{ toInstrumentId(instrument) }}</small>
      </h1>
    </div>
    <div class="row q-col-gutter-lg">
      <div class="col-3">
        <a-btn-link v-if="instrument" @click="back">&larr; Tillbaka till översikt</a-btn-link>
      </div>
    </div>
    <div class="row q-col-gutter-lg">
      <div class="col-5">
        <p class="B1Small">Välj avstämningsdag</p>
        <p>
          Observera, avstämningsdag är gårdagens datum, vilket då tar med transaktioner med likviddag tom igår. Om man
          vill stämma av dagens positioner så byter man datum.
        </p>
        <a-input-date v-model="settleDate" v-bind="$defs.input" />
      </div>
    </div>
    <div class="row q-col-gutter-lg q-pt-md">
      <div>
        <span class="text-bold q-pr-md">Depåinstitut </span>
        <q-checkbox
          @update:modelValue="toggleCustodians"
          :modelValue="allCustodiansChecked"
          class="q-pr-md"
          label="Alla"
        />
        <q-checkbox
          class="q-pr-md"
          v-for="custodian in custodians"
          v-model="custodianFilters"
          :key="custodian.id"
          :val="custodian.id"
          :label="custodian.legalEntity.name"
        />
      </div>
    </div>
    <br />
    <component
      v-if="positions"
      :is="component"
      :loading="loading"
      :insurer="insurer"
      :positions="positions"
      :instrument="instrument"
      :settleDate="settleDate"
      :custodians="custodianFilters"
      :allCustodians="!!allCustodiansChecked"
    />
  </q-page>
</template>
<script lang="ts">
import startOfYesterday from 'date-fns/startOfYesterday';
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { Entity, Position } from '@/models';
import { loadEntityWithRouteParams } from '@shared/web/entity/entityService';
import { findInsurerPositions } from '@/models/insurer/insurerService';
import { toInstrumentId } from '@shared/common';
import InsurerInstrumentsTable from './InsurerInstrumentsTable.vue';
import InsurerAccountTable from './InsurerAccountTable.vue';

@Component({
  components: { InsurerInstrumentsTable, InsurerAccountTable },
})
export default class InsurerPositionsPage extends Vue {
  insurer: Entity = null;
  positions: Position[] = null;
  settleDate = startOfYesterday();
  loading = false;
  custodianFilters = [];

  toInstrumentId(instrument) {
    return toInstrumentId(instrument);
  }

  async mounted() {
    this.loading = true;
    await this.loadData();
    this.loading = false;
  }

  @Watch('settleDate')
  async loadPositions(settleDate) {
    this.loading = true;
    this.positions = await findInsurerPositions(this.insurer, settleDate);
    this.loading = false;
  }

  async loadData() {
    this.insurer = await loadEntityWithRouteParams(this.$route.params);
    await this.loadPositions(this.settleDate);
    this.custodianFilters = this.custodians.map(c => c.id);
  }

  get instrument() {
    const { instrument: instrumentId } = this.$route.params;
    const instrument = this.positions?.find(({ instrument }) => instrument.id === instrumentId)?.instrument;
    return instrument;
  }

  toggleCustodians() {
    if ([null, false].includes(this.allCustodiansChecked)) {
      this.custodianFilters = this.custodians.map(custodian => custodian.id);
    } else {
      this.custodianFilters = [];
    }
  }

  get allCustodiansChecked() {
    if (this.custodianFilters.length === this.custodians.length) {
      return true;
    } else if (this.custodianFilters.length === 0) {
      return false;
    }
    return null;
  }

  get custodians(): Entity[] {
    if (this.positions) {
      return Object.values(
        this.positions.reduce((acc, position) => {
          if (this.instrument && position.instrument.id !== this.instrument.id) {
            return acc;
          } else if (position.custodian) {
            acc[position.custodian.id] = position.custodian;
          }
          return acc;
        }, {}),
      );
    }
    return [];
  }

  get component() {
    const { instrument } = this.$route.params;
    if (!instrument) {
      return InsurerInstrumentsTable;
    }
    return InsurerAccountTable;
  }

  back() {
    return this.$router.push(`/${this.$route.params.entity}/insurer`);
  }
}
</script>
<style lang="scss">
.InsurerPositions {
  h1 {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    small {
      color: $grey-4;
      font-size: 0.4em;
      line-height: 1em;
    }
  }
  .row > .col-9 {
    max-width: 814px;
  }
  .q-markup-table {
    & + .text-small {
      margin: 5px 0;
    }
  }
}
</style>
