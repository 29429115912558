import 'quasar/dist/quasar.sass';
import './styles/quasar.scss';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';

import { Dialog, Cookies, Notify } from 'quasar';
import langSe from 'quasar/lang/sv';

export default {
  config: {},
  lang: langSe,
  plugins: { Dialog, Cookies, Notify },
};
