<template>
  <div class="SourceRow">
    <h4 class="table-header">Säljare</h4>
    <q-markup-table flat class="standard-table position-table" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Ägare</th>
          <th class="text-left">Försäkringsägare</th>
          <th class="text-left">
            Förbehåll
            <a-info-btn>
              <p>
                <b>Obs</b> avser du att registrera en försäljning / överlåtelse värdepapper med förbehåll se till att
                korrekt dokumentation finns att bifoga i kommande steg som tillåter försäljning / överlåtelse av
                värdepapper med förbehåll
              </p>
            </a-info-btn>
          </th>
          <th class="text-left">Aktienummer</th>
          <th class="text-right">Innehav</th>
          <th class="text-right">{{ isDistributedByValue ? 'Nominellt belopp' : 'Antal som säljs' }}</th>
          <th class="text-right">{{ isDistributedByValue ? 'Pris' : 'Pris per värdepapper' }}</th>
          <th class="text-right">Total likvid</th>
        </tr>
      </thead>
      <tbody>
        <tr class="holder-row">
          <td>
            <SelectEntity
              v-model="source.owner"
              :options="owners"
              v-bind="$defs.input"
              placeholder="Välj"
              input-class="test-source-row-owner"
              :readonly="readonly"
              @change="changeOwner"
            />
          </td>
          <td class="text-left">
            <SelectEntity
              v-model="source.investor"
              :options="investors"
              v-bind="$defs.input"
              placeholder="Välj"
              v-if="investors.length > 1"
              :readonly="readonly"
              @change="changeInvestor"
            />
          </td>
          <td class="text-left reservations"></td>
          <td class="text-right"></td>
          <td class="text-right" v-if="isDistributedByValue">
            {{ formatNumber(available, 2, 2) }}
          </td>
          <td class="text-right" v-if="!isDistributedByValue">
            {{ formatNumber(available) }}
          </td>
          <td class="text-right" v-if="isDistributedByValue">
            {{ formatNumber(source.amount, 2, 2) }}
          </td>
          <td class="text-right" v-if="!isDistributedByValue">{{ formatNumber(source.quantity) }}</td>
          <td class="text-right">
            <a-input-number
              :minimumFractionDigits="2"
              :maximumFractionDigits="2"
              v-model="instruction.price"
              input-class="text-right test-source-row-price"
              v-bind="$defs.input"
              :readonly="readonly"
              @change="change"
            />
          </td>

          <td class="text-right">{{ formatNumber(totalAmount, 2, 2) }}</td>
        </tr>
        <tr v-for="sourcePosition in filteredSourcePositions" :key="sourcePosition.position.id">
          <td></td>
          <td>
            {{ sourcePosition.position.investor && sourcePosition.position.investor.viewName }}
          </td>
          <td class="text-left reservations">
            <div v-for="reservation in sourcePosition.position.reservations" :key="reservation">
              {{ toReservationLabel(reservation) }}
            </div>
          </td>
          <td class="text-right">
            {{ shareNumbers(sourcePosition.position) }}
            <PositionAdditionalInformationOnRowItem :position="sourcePosition.position" :useMargin="false" />
          </td>
          <td class="text-right" v-if="isDistributedByValue">
            {{ formatNumber(sourcePosition.position.amount, 2, 2) }}
          </td>
          <td class="text-right" v-if="!isDistributedByValue">{{ formatNumber(sourcePosition.position.quantity) }}</td>
          <td class="text-right">
            <a-input-number
              :minimumFractionDigits="0"
              :maximumFractionDigits="0"
              v-if="!isDistributedByValue"
              v-model="sourcePosition.quantity"
              input-class="text-right test-source-row-quantity-sold"
              v-bind="$defs.input"
              :readonly="readonly"
              @change="changeQuantity"
            />
            <a-input-number
              :minimumFractionDigits="2"
              :maximumFractionDigits="2"
              v-if="isDistributedByValue"
              v-model="sourcePosition.amount"
              :rules="[
                () =>
                  sourcePosition.amount <= sourcePosition.position.amount ||
                  `Kan inte vara större än ${sourcePosition.position.amount}`,
              ]"
              input-class="text-right test-source-row-quantity-sold"
              v-bind="$defs.input"
              :readonly="readonly"
              @change="changeAmount"
            />
          </td>
          <td colspan="10"></td>
        </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { Instruction, ShareRegister, Position, TransactionType } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { makeInstructionValidator } from '@/models/instruction/instructionService';
import { getInvestorsByOwner, getUniqueOwners } from '@shared/shareRegister/utils';
import { filterPositions } from '@shared/shareRegister/utils';
import { toReservationLabel } from '@shared/common';
import { formatNumber } from '@/utils';
import { SourcePosition } from '@shared/web/models/instruction/TradeEvent';
import SelectEntity from '@/views/shareRegister/SelectEntity.vue';
import PositionAdditionalInformationOnRowItem from '@shared/components/PositionAdditionalInformationOnRowItem.vue';

import * as _ from 'lodash';
import { Instrument } from '@shared/web/models';

@Component({
  components: { SelectEntity, PositionAdditionalInformationOnRowItem },
})
export default class SourceRow extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop({ default: () => false }) readonly: boolean;

  shareNumbers(position: Position) {
    const { shareNumberFrom, shareNumberTo } = position;
    if (shareNumberFrom && shareNumberTo) {
      return `${formatNumber(shareNumberFrom)}-${formatNumber(shareNumberTo)}`;
    }
    return null;
  }
  toReservationLabel(reservation) {
    return toReservationLabel(reservation);
  }

  get source() {
    return this.instruction.tradeEvent.source;
  }

  get tradeEvent() {
    return this.instruction.tradeEvent;
  }

  get totalAmount() {
    if (this.isDistributedByValue) {
      return this.instruction.price || this.source.quantity;
    }
    return this.source.quantity * this.instruction.price;
  }

  get available() {
    if (this.isDistributedByValue) {
      return _.sumBy(this.filteredSourcePositions, sourcePosition => sourcePosition.position.amount);
    }
    return _.sumBy(this.filteredSourcePositions, sourcePosition => sourcePosition.position.quantity);
  }

  get owners() {
    return getUniqueOwners(this.shareRegister.positions, this.source.instrument);
  }

  get investors() {
    return getInvestorsByOwner(this.shareRegister.positions, this.source.owner, this.source.instrument);
  }

  get validator() {
    return this.shareRegister && makeInstructionValidator(this.instruction, this.shareRegister);
  }

  get instrument(): Instrument {
    return this.instruction?.tradeEvent?.source?.instrument;
  }

  get isDistributedByValue() {
    return this.instrument?.rightsData?.distributeByValue === true;
  }

  get filteredSourcePositions() {
    let sourcePositions = this.tradeEvent.sourcePositions;
    if (this.instruction.type === TransactionType.EXERCISE_PLEDGE) {
      sourcePositions = sourcePositions.filter(sourcePosition => sourcePosition.position.pledgeOwner != null);
    }
    return sourcePositions;
  }

  changeOwner() {
    Object.assign(this.source, {
      investor: null,
      quantity: null,
    });

    this.changeInvestor();
  }
  changeInvestor() {
    const { instrument, owner, investor } = this.source;
    const positions = filterPositions(this.shareRegister.positions, { instrument, owner, investor });
    this.tradeEvent.sourcePositions = positions.map(position =>
      SourcePosition.newModel({ position, quantity: 0, amount: 0 }),
    );
  }

  changeAmount() {
    this.source.amount = _.sumBy(this.filteredSourcePositions, sourcePosition => sourcePosition.amount);
    this.$emit('change');
  }

  changeQuantity() {
    this.source.quantity = _.sumBy(this.filteredSourcePositions, sourcePosition => sourcePosition.quantity);
    this.$emit('change');
  }

  change() {
    this.$emit('change');
  }
}
</script>
<style lang="scss">
.SourceRow {
  max-width: 100%;
  margin-bottom: 38px;
  th *[role='button'] {
    vertical-align: sub;
  }
}
</style>
