/* eslint-disable @typescript-eslint/no-explicit-any */

import { reactive } from 'vue';
import { Model } from './models/base';

const backup = {};
const store = reactive({
  profile: null,
  globalError: null,
  activeEntity: null,
  newModelProps: null,
  entityAdmin: null,
  activeNavigationModel: null,
  appMode: null,
  currencyRates: null,
});
(window as any).store = store;

export { store };

export function getObject(id) {
  if (id == null) {
    return null;
  }
  return store[id];
}

export function addBackup(data) {
  if (data.id) {
    backup[data.id] = data;
  }
}
export function getBackup(id) {
  return backup[id] || {};
}

export function addModel<T extends Model>(instance: T, key = instance.id): void {
  const M = instance.constructor as typeof Model;
  if (key != null && M.storeModel) {
    store[key] = instance;
  }
}

export function removeModel<T extends Model>(instance: T): void {
  delete store[instance.id];
}

export function getModel<C extends typeof Model>(id: string, M: C): InstanceType<typeof M> {
  if (!M.storeModel) {
    return null;
  }
  const result = getObject(id);
  if (result == null) {
    return null;
  }

  if (!(result instanceof M)) {
    console.error(`Model with id ${id} not instance of ${M.name}`);
    return null;
  }
  return result as InstanceType<typeof M>;
}

export function addModels<T extends Model>(instances: Array<T>): void {
  instances.forEach(instance => addModel(instance));
}
