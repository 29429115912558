<template>
  <div class="section">
    <h5>Ägare</h5>
    <div class="row q-gutter-x-lg q-pb-md">
      <q-input
        class="col"
        v-bind="$defs.input"
        v-model="entity.person.firstName"
        :rules="validation.required('Förnamn')"
        label="Förnamn"
        :readonly="readonly"
        :disable="disable"
      ></q-input>
      <q-input
        class="col"
        v-bind="$defs.input"
        v-model="entity.person.surName"
        :rules="validation.required('Efternamn')"
        label="Efternamn"
        :readonly="readonly"
        :disable="disable"
      ></q-input>
      <SelectCountry
        class="col"
        v-bind="$defs.input"
        v-model="entity.countryCode"
        :rules="validation.required('Land')"
        label="Land"
        :readonly="readonly"
        :disable="disable"
      />
    </div>
    <div class="row q-gutter-x-lg">
      <q-input
        class="col"
        v-bind="$defs.input"
        v-model="entity.nationalId"
        label="Personnummer"
        hint="Frivillig uppgift"
        :readonly="readonly"
        :disable="disable"
      ></q-input>
      <q-input
        class="col"
        v-bind="$defs.input"
        v-model="contactInfo.email"
        hint="Frivillig uppgift"
        :rules="validation.email"
        label="E-post"
        lazy-rules
      ></q-input>
      <q-input
        class="col"
        v-bind="$defs.input"
        v-model="contactInfo.phone"
        hint="Frivillig uppgift"
        label="Telefon"
      ></q-input>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import SelectCountry from '@shared/components/SelectCountry.vue';
import { Entity, ContactInfo } from '@shared/web/models';
import { validation } from '@shared/common';

@Component({
  components: { SelectCountry },
})
export default class PrivateOwner extends Vue {
  @Prop() entity: Entity;
  @Prop() contactInfo: ContactInfo;
  @Prop() readonly: boolean;
  @Prop() disable: boolean;

  get validation() {
    return validation;
  }
}
</script>
