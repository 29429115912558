<template>
  <q-page class="UserInvestmentOffersPage">
    <div class="row">
      <h1>Investeringserbjudande</h1>
    </div>
    <q-separator />
    <InvestmentOffersList :investmentOffers="investmentOffers" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { fetchInvestmentOffers } from '@shared/web/models/investmentOffer/investmentOffersService';
import InvestmentOffer from '@shared/web/models/investmentOffer/InvestorOffer';
import InvestmentOffersList from '@shared/views/investmentOffer/InvestmentOffersList.vue';
import store from '@/store';
import { Entity } from '@/models';

@Component({
  components: { InvestmentOffersList },
})
export default class UserInvestmentOffersPage extends Vue {
  investmentOffers: InvestmentOffer[] = [];

  async mounted() {
    if (await this.redirectToKappnetAggrementPageIfNotAccepted()) {
      return;
    }
    this.investmentOffers = await fetchInvestmentOffers();
  }
  async redirectToKappnetAggrementPageIfNotAccepted() {
    const entity = store?.profile?.entity as Entity | undefined;
    if (!entity?.kapnetLicenseConsentedAt) {
      await this.$router.push({ path: '/mina-avtal', query: { tillbakaTill: this.$route.path } });
      return true;
    }
    return false;
  }
}
</script>
<style lang="scss">
.UserInvestmentsPage {
  > p {
    margin-bottom: 2em;
  }
}
</style>
