import { Entity } from '@/models';
import axios from 'axios';
import CustodianHoldings from './CustodianHoldings';

const baseUrl = '/webapi/custodian';

export async function findCustodianHoldings(
  custodian: Entity,
  settleDate: Date = new Date(),
): Promise<CustodianHoldings> {
  const { data: holdings } = await axios.post(baseUrl + '/holdings', {
    custodian: custodian.id,
    settleDate,
  });

  const custodianHoldings = CustodianHoldings.newModel({ custodian, holdings, settleDate });

  return custodianHoldings;
}
