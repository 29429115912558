<template>
  <q-page v-if="ready" class="DocumentTradeEventPage">
    <div class="row justify-between">
      <h1>Ägarbyte</h1>
      <LastEntityUpdate :date="instruction.updatedAt" />
    </div>
    <Stepper pageType="tradeEvent" :currentstep="2" :urlId="instruction.id" />
    <h4>Verifiera ägarbyte</h4>
    <p class="p2">Bifoga ett eller flera signerade dokument i PDF-format som verifierar ägarbytet.</p>
    <p class="p2">
      De dokument som bifogas ska kunna verifiera aktieboken och eventuella förbehåll och förvar som du har registrerat
    </p>
    <q-separator />

    <UploadFileButton @click="uploadDocuments" class="q-mt-lg" />
    <InputFile :instruction="instruction" ref="inputFile" />
    <FileList class="q-mt-md" :parent="instruction" />
    <q-btn v-bind="$defs.btn" type="button" class="step-btn" :disable="!isDone" @click="proceed">Gå vidare</q-btn>
    <a-btn-link v-on:click="goBack" class="arrow icon-left">Gå tillbaka</a-btn-link>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { ShareRegister, Instruction } from '@/models';
import { findInstruction } from '@/models/instruction/instructionService';
import LastEntityUpdate from '@shared/components/LastEntityUpdate.vue';
import Stepper from '@/components/Stepper.vue';
import FileList from '@/views/file/FileList.vue';
import UploadFileButton from '@/views/file/UploadFileButton.vue';
import InputFile from '@/components/InputFile.vue';
@Component({
  components: { LastEntityUpdate, FileList, Stepper, InputFile, UploadFileButton },
})
export default class DocumentTradeEventPage extends Vue {
  instruction: Instruction = null;
  shareRegister: ShareRegister = null;

  async created() {
    const params = this.$route.params;
    const { id } = params;
    const { shareRegister, instruction } = await findInstruction(id);
    Object.assign(this, { shareRegister, instruction });
  }

  uploadDocuments() {
    const componenet = this.$refs.inputFile as any;
    componenet.input.click();
  }

  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }
  get isDone() {
    return this.instruction.hasFiles;
  }
  proceed() {
    this.$router.push({
      path: `${this.instruction.entity.baseUrl}/agarbyte/${this.instruction.id}/granska`,
    });
  }
  goBack() {
    this.$router.push({
      path: `${this.instruction.entity.baseUrl}/agarbyte/${this.instruction.id}`,
    });
  }
}
</script>
