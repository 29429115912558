<template>
  <div v-if="ready" class="row q-col-gutter-md DashboardHeader">
    <div class="col col-6 col-sm-4">
      <q-card flat>
        <q-card-section align="center" class="card-header text-white">
          <p class="B1Small">Antal aktier</p>
        </q-card-section>

        <q-card-actions vertical align="center" class="card-body">
          <p class="p3">{{ formatNumber(issuerData.totalQuantity) }}</p>
        </q-card-actions>
      </q-card>
    </div>
    <div class="col col-6 col-sm-4">
      <q-card flat>
        <q-card-section align="center" class="card-header text-white">
          <p class="B1Small">Kvotvärde</p>
        </q-card-section>

        <q-card-actions vertical align="center" class="card-body">
          <p class="p3">{{ formatNumber(issuerData.quotaValue, 0, 4) }}</p>
        </q-card-actions>
      </q-card>
    </div>
    <div class="col col-6 col-sm-4">
      <q-card flat>
        <q-card-section align="center" class="card-header text-white">
          <p class="B1Small">Aktiekapital</p>
        </q-card-section>

        <q-card-actions vertical align="center" class="card-body">
          <p class="p3">{{ formatNumber(issuerData.totalCapital) }}</p>
        </q-card-actions>
      </q-card>
    </div>
    <div class="col col-6 col-sm-4">
      <q-card flat>
        <q-card-section align="center" class="card-header text-white">
          <p class="B1Small">Valuta</p>
        </q-card-section>

        <q-card-actions vertical align="center" class="card-body">
          <p class="p3">{{ issuerData.currencyCode }}</p>
        </q-card-actions>
      </q-card>
    </div>
    <div class="col col-6 col-sm-4">
      <q-card flat>
        <q-card-section align="center" class="card-header text-white">
          <p class="B1Small">Antal ägare</p>
        </q-card-section>

        <q-card-actions vertical align="center" class="card-body">
          <p class="p3">{{ formatNumber(ownerCount) }}</p>
        </q-card-actions>
      </q-card>
    </div>
    <div class="col col-6 col-sm-4">
      <q-card flat>
        <q-card-section align="center" class="card-header text-white">
          <p class="B1Small">Antal investerare</p>
        </q-card-section>

        <q-card-actions vertical align="center" class="card-body">
          <p class="p3">{{ formatNumber(investorCount) }}</p>
        </q-card-actions>
      </q-card>
    </div>
  </div>
</template>
<script lang="ts">
import { ShareRegister } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({})
export default class DashboardHeader extends Vue {
  @Prop() shareRegister: ShareRegister;

  get ready() {
    return this.shareRegister != null;
  }

  get issuerData() {
    return this.shareRegister.entity.issuerData;
  }

  get investorCount() {
    return this.shareRegister.getUniqueOwnersAndInvestors().length;
  }

  get ownerCount() {
    return this.shareRegister.getUniqueOwners().length;
  }
}
</script>
<style lang="scss">
.DashboardHeader {
  padding-top: 20px;
  .card-header {
    background-color: $secondary;
    height: 24px;
    white-space: nowrap;
    padding: 0;
    p {
      margin: 0;
      line-height: 24px;
    }
  }
  .card-body {
    height: 56px;
    padding: 0;
    p {
      margin: 0;
      line-height: 56px;
    }
  }
}
</style>
