<template>
  <q-page v-if="ready" class="CreateEntityAdminPage">
    <div class="row justify-between">
      <h1 v-if="isCreate">{{ isCreate ? 'Lägg till administratör' : 'Editera adminstratör' }}</h1>
      <LastEntityUpdate />
    </div>
    <Stepper pageType="newAdmin" :currentstep="step === 'sign' ? 2 : 1" :urlId="entity.baseUrl" v-if="isCreate" />
    <q-separator v-if="isCreate" />
    <q-form @submit.stop="proceed">
      <template v-if="step === 'form'">
        <h4 class="table-header">{{ isCreate ? 'Lägg till ny administratör' : 'Editera administratör' }}</h4>
        <div class="input-container row q-col-gutter-lg">
          <div class="col-12 col-md-8">
            <EntityAdminForm
              :isCreate="isCreate"
              :entity="entity"
              :entity-admin="entityAdmin"
              v-model:personal-number="personalNumber"
              v-model:can-login-with-email="entityAdmin.canLoginWithEmail"
              v-model:phone="entityAdmin.phone"
              v-model:email="entityAdmin.email"
              v-model:role="entityAdmin.role"
              v-model:access="access"
              v-model:want-emails="entityAdmin.wantEmails"
            />
          </div>
        </div>
      </template>

      <template v-if="step === 'sign'">
        <h4 class="table-header">Granska att uppgifterna stämmer</h4>
        <ul class="view-information">
          <li>
            <strong>{{ entityAdmin.user.name }}</strong>
            <span class="nationalid-small">{{ entityAdmin.user.personalNumber }}</span>
          </li>
          <li>
            <strong>{{ entityAdmin.email }}</strong>
          </li>
          <li>
            <strong>{{ entityAdmin.phone }}</strong>
          </li>
          <li>
            <strong>{{ toEntityAdminRoleLabel(entityAdmin.role) }}</strong>
          </li>
        </ul>
      </template>

      <q-btn v-bind="$defs.btn" class="step-btn" type="submit" :label="buttonText" :disable="!isFullAccess && !isMe" />
    </q-form>
    <a-btn-link v-on:click="goBack" class="arrow icon-left" v-if="step === 'sign'">Gå tillbaka</a-btn-link>
  </q-page>
</template>
<script lang="ts">
// @ts-nocheck @TODO kolla q-radio
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { Entity, EntityAdmin } from '@/models';
import Stepper from '@/components/Stepper.vue';
import LastEntityUpdate from '@shared/components/LastEntityUpdate.vue';
import { validation } from '@shared/common';
import { findEntityAdmin, lookupUser, saveEntityAdmin } from '@/models/entityAdmin/entityAdminService';
import BankIdSignModal from '@/views/auth/BankIdSignModal.vue';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { EntityAdminRole, EntityType } from '@shared/models/types';
import { toEntityAdminRoleLabel } from '@shared/common';
import { store } from '@shared/web/store';
import { isToNewPage } from '@/utils';
import EntityAdminForm from '@shared/views/entityAdmin/EntityAdminForm.vue';
@Component({
  components: { Stepper, LastEntityUpdate, EntityAdminForm },
})
export default class CreateEntityAdminPage extends Vue {
  entityAdmin: EntityAdmin = null;
  entity: Entity = null;
  personalNumber = null;
  access: 'full' | 'limited' = null;
  step: 'form' | 'sign' = 'form';

  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const entity = await findEntityWithRouteParams(this.$route.params);
    const entityAdmin = this.$route.params.id
      ? await findEntityAdmin(this.$route.params.id)
      : new EntityAdmin({ limitedAccess: true });
    this.personalNumber = entityAdmin.user?.personalNumber;
    this.access = entityAdmin?.limitedAccess === true ? 'limited' : 'full';
    Object.assign(this, { entityAdmin, entity });
  }

  toEntityAdminRoleLabel(entityAdminRole: EntityAdminRole) {
    return toEntityAdminRoleLabel(entityAdminRole);
  }

  get ready() {
    return this.entityAdmin != null;
  }
  get validation() {
    return validation;
  }

  get isCreate() {
    return this.entityAdmin.id == null;
  }

  get isFullAccess() {
    return store.entityAdmin?.limitedAccess === false;
  }

  get isMe() {
    return this.entityAdmin && this.entityAdmin.id === store.entityAdmin?.id;
  }

  get entityAdminRoleOptions() {
    const isCustodian = this.entity.types.includes(EntityType.participant);
    const roles = isCustodian
      ? Object.values(EntityAdminRole)
      : Object.values(EntityAdminRole).filter(role => role !== EntityAdminRole.CustodianAdmin);

    return roles.map(role => ({
      value: role,
      label: toEntityAdminRoleLabel(role),
    }));
  }

  async proceed() {
    Object.assign(this.entityAdmin, {
      entity: this.entity,
      limitedAccess: this.access === 'limited',
    });
    if (!this.entityAdmin.user) {
      this.entityAdmin.user = await lookupUser(this.personalNumber);
    }
    if (!this.isCreate) {
      await saveEntityAdmin(this.entityAdmin);
      this.$router.push({ path: `${this.entity.baseUrl}/admin` });
      return;
    }
    if (this.step === 'form') {
      this.step = 'sign';
    } else {
      this.signBankIDModal();
    }
  }

  signBankIDModal() {
    this.$q.dialog({
      component: BankIdSignModal,
      componentProps: {
        payload: { user: this.entityAdmin.user.id, entity: this.entityAdmin.entity.id },
        type: 'ADD_ENTITYADMIN',
        onSign: async () => {
          try {
            await saveEntityAdmin(this.entityAdmin);
            this.$router.push({ path: `/${this.entity.baseUrl}/admin` });
          } catch (e) {
            if (e?.text) {
              this.$q.dialog({ title: 'Ett fel uppstod', message: e.text });
            } else {
              throw e;
            }
          }
        },
      },
    });
  }

  get buttonText() {
    if (!this.isCreate) {
      return 'Spara';
    }
    return this.step === 'form' ? 'Gå vidare' : 'Signera och lägg till';
  }

  goBack() {
    this.step = 'form';
  }
}
</script>
