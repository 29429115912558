import { IInstruction } from '../modelTypes';
import { sumBy } from 'lodash';
import { TransactionType } from '../types';

export default class DebtInstructionValidator {
  instruction: IInstruction;

  constructor(instruction: IInstruction) {
    this.instruction = instruction;
  }

  get rightsData() {
    return this.instruction.corporateEvent.rightsData;
  }

  isDistributionValid() {
    if (this.rightsData.distributeByValue) {
      if (this.instruction.type === TransactionType.ISSUE_SHAREHOLDER_CONTRIBUTION) {
        return this.rightsData.nominalAmountTotal > 0;
      }
      return this.rightsData.nominalAmountTotal === sumBy(this.instruction.destinations, d => d.amount);
    }
    if (!this.rightsData.distributeByValue) {
      return this.rightsData.totalQuantity === sumBy(this.instruction.destinations, d => d.quantity);
    }

    return true;
  }

  isValid() {
    return this.isDistributionValid();
  }
}
