import { IHistoryPosition } from '@shared/models/modelTypes';
import { Model } from '../base';
import { Entity } from '@shared/web/models';
import { TransactionType } from '@shared/models/types';

export class HistoryEntry extends Model implements IHistoryPosition {
  settleDate: Date = null;
  owner: Entity = null;
  investor?: Entity = null;
  type: TransactionType;
  manuallyCreated?: boolean;
  static storeModel = false;

  static define = () => ({
    owner: {
      model: Entity,
    },
    investor: {
      model: Entity,
    },
    settleDate: {
      type: 'date',
    },
  });
}

export default class PositionHistory extends Model {
  position: string = null;
  history: Array<HistoryEntry>;

  static define = () => ({
    history: {
      embeddedModel: [HistoryEntry],
    },
  });
}
