<template>
  <div class="debenture-details">
    <div class="row q-col-gutter-md items-end">
      <div class="col-12 col-md-4">
        <span>Utgivningsdag</span>
        <a-input-date
          v-model="rightsData.issueDate"
          v-bind="$defs.input"
          :disable="readonly"
          :readonly="readonly"
          @change="$emit('change')"
        />
      </div>
      <div class="col-12 col-md-4">
        <label>Slutdag</label>
        <a-input-date
          v-model="rightsData.dueDate"
          v-bind="$defs.input"
          :readonly="readonly"
          :disable="readonly"
          @change="$emit('change')"
        />
      </div>
      <div class="col-12 col-md-4">
        <label>
          Namn på vinstandelslån
          <q-input
            v-model="rightsData.name"
            :disable="readonly"
            :readonly="readonly"
            v-bind="$defs.input"
            @change="$emit('change')"
          />
        </label>
      </div>
      <div class="col-12 col-md-6">
        <DistributionDetails
          :rightsData="rightsData"
          :disable="readonly"
          :readonly="readonly"
          @change="$emit('change')"
          @input="$emit('input')"
        />
      </div>
      <label class="col-12 col-md-1">
        Valuta
        <SelectCurrency
          v-model="rightsData.currency"
          v-bind="$defs.input"
          :disable="readonly"
          :readonly="readonly"
          @change="$emit('change')"
          @update:modelValue="$emit('input')"
        />
      </label>
      <label class="col-12 col-md-5">
        Frivillig tilläggsinformation avseende lånets villkor
        <q-input
          type="text"
          v-model="rightsData.note"
          v-bind="$defs.input"
          :disable="readonly"
          :readonly="readonly"
          @change="$emit('change')"
          @update:modelValue="$emit('input')"
          placeholder="Fritext"
        />
      </label>
    </div>
  </div>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Instruction, Instrument } from '@shared/web/models';
import SelectCurrency from '@shared/components/SelectCurrency.vue';
import DistributionDetails from '@shared/components/DistributionDetails.vue';
import InputDate from '@shared/components/InputDate.vue';

@Component({
  components: { SelectCurrency, DistributionDetails, 'a-input-date': InputDate },
})
export default class DebentureDetails extends Vue {
  @Prop() instruction: Instruction;
  @Prop() instrument: Instrument;
  @Prop({ default: true }) readonly: boolean;

  get rightsData() {
    if (this.instruction) {
      return this.instruction.corporateEvent.rightsData;
    }
    if (this.instrument) {
      return this.instrument.rightsData;
    }
    throw 'Cannot find rightsData';
  }
}
</script>
