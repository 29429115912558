<template>
  <q-btn-dropdown
    padding="md sm md lg"
    style="max-width: 100%; width: 100%"
    class="text-h5 test-company-name"
    :label="viewName"
    flat
    size="md"
    no-ripple
    dropdown-icon="keyboard_arrow_down"
    content-class="shadow-1"
  >
    <q-list class="select-active-entity">
      <q-item clickable v-close-popup v-for="entityAdmin in userEntityAdmins" :key="entityAdmin.id">
        <q-item-section @click="selectEntityAdmin(entityAdmin)">
          <q-item-label>{{ entityAdmin.entity.viewName }}</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable v-close-popup>
        <q-item-section @click="newEntity">
          <q-item-label>Nytt bolag</q-item-label>
        </q-item-section>
      </q-item>
      <q-item clickable v-close-popup class="user-context" key="userInvestments" v-if="showUserInvestmentsLink">
        <q-item-section @click="selectUserInvestments">
          <q-item-label>Mina innehav / Intressen</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script lang="ts">
import store from '@/store';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { findEntityAdminsForUser } from '@/models/entityAdmin/entityAdminService';
import { Context } from '@/router';
import { EntityType } from '@shared/models/types';
import { getRepresentedPersonalEntity } from '@/models/profileService';

@Component
export default class SelectActiveEntity extends Vue {
  @Prop() config: { title: string; context: Context };

  userEntityAdmins;

  get viewName() {
    if (this.config.context === Context.USER) {
      return this.config.title;
    }
    return store.activeEntity.viewName;
  }

  get showUserInvestmentsLink() {
    return !!getRepresentedPersonalEntity();
  }

  async created() {
    this.userEntityAdmins = await findEntityAdminsForUser();
  }

  selectEntityAdmin(entityAdmin) {
    if (entityAdmin.entity.types.includes(EntityType.issuer)) {
      this.$router.push({ path: `${entityAdmin.entity.baseUrl}/dashboard` });
    } else if (entityAdmin.entity.types.includes(EntityType.insuranceCompany)) {
      this.$router.push({ path: `${entityAdmin.entity.baseUrl}/insurer` });
    } else if (entityAdmin.entity.types.includes(EntityType.participant)) {
      this.$router.push({ path: `${entityAdmin.entity.baseUrl}/custodian` });
    }
  }
  newEntity() {
    this.$router.push({ path: '/registrerabolag' });
  }
  selectUserInvestments() {
    this.$router.push({ path: '/mina-innehav' });
  }
}
</script>
<style lang="scss">
.select-active-entity {
  .q-item {
    border-top: solid 1px $grey-3;
  }
  .user-context {
    background: $grey-0;
  }
}
</style>
