<template>
  <q-dialog ref="dialog" persistent>
    <div class="q-dialog-plugin bg-white relative-position" style="width: 740px; max-width: initial">
      <h4 class="q-pa-lg">{{ label }}</h4>
      <q-markup-table flat>
        <thead>
          <tr>
            <th class="text-left" style="min-width: initial; padding-right: 0">
              <q-checkbox
                v-model="allEmailsSelected"
                :indeterminate-value="null"
                @update:modelValue="toggleAllEmails"
              />
            </th>
            <th class="text-left">Namn</th>
            <th class="text-left">E-postadress</th>
          </tr>
        </thead>
        <EmailAllModalContacts
          v-if="contacts"
          :contacts="contacts"
          @emailChange="updateContacts"
          @emailSelected="handleEmailSelected"
          :selectedEmails="selectedEmails"
        />
      </q-markup-table>
      <q-separator spaced="0" />
      <q-card-actions align="right" class="q-pa-lg">
        <q-btn v-bind="$defs.btn" flat @click="hide">Avbryt</q-btn>
        <q-btn-dropdown
          color="green"
          :label="`Mejla ${selectedEmails.length} av ${contacts.length}`"
          :disable-main-btn="!isSendReady"
        >
          <q-list>
            <q-item clickable v-close-popup @click="mailtoWithSemicolon">
              <q-item-section>
                <q-item-label>Jag har Outlook</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="mailtoWithComma">
              <q-item-section>
                <q-item-label>Jag har annat mejl-program</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-card-actions>
      <q-inner-loading :showing="!ready"></q-inner-loading>
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DialogComponent, FormComponent } from '@shared/mixins';
import { ContactInfo, Entity, IssuerInfo } from '@shared/web/models';
import { ShareRegister } from '@shared/web/models';
import { findIssuerInfo, saveIssuerInfo } from '@shared/web/models/entity/issuerInfoService';
import EmailAllModalContacts from '@shared/components/EmailAllModalContacts.vue';
import { sortBy, uniq, uniqBy } from 'lodash';
@Component({
  components: { EmailAllModalContacts },
  mixins: [DialogComponent, FormComponent],
})
export default class EmailAllModal extends Vue {
  @Prop({ default: 'Mejla alla' }) label: string;
  @Prop() register: ShareRegister;
  @Prop({ default: null, required: true }) entities: Entity[];
  ready = false;
  issuerInfo: IssuerInfo;
  contacts: ContactInfo[] = [];
  isSendReady = false;
  selectedEmails: string[] = [];
  allEmailsSelected: boolean | null = false;

  get availableEmails() {
    return this.contacts.map(contact => contact.email).filter(email => email);
  }

  async mounted() {
    await this.load();
  }

  async load() {
    this.ready = false;
    this.issuerInfo = await findIssuerInfo(this.register.entity);

    const contacts = uniqBy(
      this.entities?.length
        ? this.entities.map(entity => this.issuerInfo.getContactInfo(entity))
        : this.register.positions.map(position => {
            return this.issuerInfo.getContactInfo(position.owner);
          }),
      'entity.id',
    );

    // sorting contacts by missing email and surname
    this.contacts = sortBy(contacts, c => (!c.email ? '' : c.surName));

    this.ready = true;
  }

  handleEmailSelected(email: string, isChecked: boolean) {
    const exists = this.selectedEmails.some(e => e === email);
    if (exists && isChecked) {
      return;
    } else if (!exists && !isChecked) {
      return;
    } else if (exists && !isChecked) {
      this.selectedEmails = this.selectedEmails.filter(e => e !== email);
    } else if (!exists && isChecked) {
      this.selectedEmails.push(email);
    }

    this.refreshIsSendReady();
    this.refreshAllEmailSelected();
  }

  refreshIsSendReady() {
    this.isSendReady = this.selectedEmails.length > 0;
  }

  refreshAllEmailSelected() {
    const availableEmailsCount = this.availableEmails.length;

    if (this.selectedEmails.length === 0) {
      this.allEmailsSelected = false;
    } else if (this.selectedEmails.length < availableEmailsCount) {
      this.allEmailsSelected = null;
    } else if (this.selectedEmails.length === availableEmailsCount) {
      this.allEmailsSelected = true;
    }
  }

  unselectsNonExistentEmails() {
    this.selectedEmails = this.selectedEmails.filter(selectedEmail => this.availableEmails.includes(selectedEmail));
  }

  async updateContacts() {
    this.ready = false;
    await saveIssuerInfo(this.issuerInfo);
    await this.load();
    this.ready = true;
    this.unselectsNonExistentEmails();
    this.refreshAllEmailSelected();
  }

  toggleAllEmails(value) {
    if (value === true) {
      this.selectAllEmails();
    }
    if (value === false) {
      this.unselectAllEmails();
    }
  }

  selectAllEmails() {
    this.selectedEmails = [...this.availableEmails];
    this.refreshIsSendReady();
  }

  unselectAllEmails() {
    this.selectedEmails = [];
    this.refreshIsSendReady();
  }

  mailtoWithComma() {
    location.href = 'mailto:?bcc=' + uniq(this.selectedEmails).join(',');
  }

  mailtoWithSemicolon() {
    location.href = 'mailto:?bcc=' + uniq(this.selectedEmails).join(';');
  }
}
</script>
