import { Model } from '@shared/web/models/base';
import { Activity, User } from '@shared/web/models';

export default class InvestmentOffer extends Model {
  author: User;
  title: string;
  text: string;
  offerCompanyName?: string;
  archived: boolean = false;
  draft: boolean = true;
  createdAt: Date;
  updatedAt: Date;
  lastActivity: Activity;
  files = [];

  static define = () => ({
    author: {
      model: User,
    },
    files: {
      serialize: false,
    },
  });
}
