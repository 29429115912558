<template>
  <tr class="holder-row InvestorManagerRow" @click="$emit('click', investor.id)">
    <td colspan="3">
      <q-icon name="arrow_right" size="sm" :class="{ 'q-mr-xs': true, collapsed }" />
      {{ investor.viewName }}
    </td>
    <td></td>
    <td></td>
    <q-td class="text-right">
      {{ formatNumber(totals.totalQuantity) }}
    </q-td>
    <td class="text-right">{{ formatPercent(totals.totalShare, 2) }}</td>
    <td class="text-right">{{ formatPercent(totals.votingPower, 2) }}</td>
  </tr>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Entity } from '@/models';

@Component({})
export default class InvestorManagerRow extends Vue {
  @Prop() investor: Entity;
  @Prop() shareRegisterValidator;
  @Prop({ default: false }) collapsed;

  get icon() {
    return this.collapsed ? 'arrow_right' : 'arrow_drop_down';
  }
  get totals() {
    return this.shareRegisterValidator.forAbsoluteInvestorManager(this.investor);
  }
}
</script>
<style lang="scss">
.InvestorManagerRow {
  cursor: pointer;
  i {
    transform: rotate(90deg);
    transition: transform 300ms ease-in-out;
    &.collapsed {
      transform: rotate(0deg);
    }
  }
}
</style>
