import { Model } from './base';
import { UserRole } from '../../models/types';
export default class User extends Model {
  username?: string;
  name?: string;
  roles?: Array<UserRole>;
  personalNumber: string;
  settingsReviewedAt?: Date;
  boLoginToken?: string;
  canLoginWithEmail?: boolean;

  get viewName() {
    return this.name || this.username;
  }
}
