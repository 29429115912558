<template>
  <div class="FullConversionTable">
    <q-markup-table v-if="ready" flat class="standard-table DestinationsTable" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Investeringsansvarig</th>
          <th class="text-left">Värdepapper</th>
          <th class="text-left">Ägare</th>
          <th class="text-left">Försäkringsägare</th>
          <th class="text-left">Förvar</th>
          <th class="text-right number">
            <span class="align-info">Antal/Nominellt belopp</span>
            <a-info-btn>
              <p>Summeringen för investeraren visar enbart antalet aktier</p>
            </a-info-btn>
          </th>
          <th class="text-right number">Kapitalandel</th>
          <th class="text-right number">Röstandel</th>
        </tr>
      </thead>
      <tbody class="two-row">
        <template v-for="{ holder, positions } in positionGroups" :key="holder.id">
          <InvestorManagerRow
            :investor="holder"
            :shareRegisterValidator="shareRegisterValidator"
            :collapsed="!uncollapsed.includes(holder.id)"
            @click="onClickInvestorManager"
          />
          <template v-for="position in positions" :key="position.id">
            <InvestorManagerPositionRow
              :position="position"
              :shareRegisterValidator="shareRegisterValidator"
              :showFullConversion="true"
              :collapsed="!uncollapsed.includes(holder.id)"
            />
          </template>
        </template>
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { IssuerInfo, ShareRegister, InstrumentCategory } from '@shared/web/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import TableSettings from '@shared/web/models/shareRegister/TableSettings';
import InvestorManagerRow from '@shared/views/investorBook/InvestorManagerRow.vue';
import PositionRow from '@shared/views/shareRegister/PositionRow.vue';
import InvestorManagerPositionRow from '@shared/views/investorBook/InvestorManagerPositionRow.vue';
import { makeShareRegisterValidation } from '@shared/web/models/shareRegister/shareRegisterService';
import { findIssuerInfo } from '@shared/web/models/entity/issuerInfoService';

@Component({
  components: {
    InvestorManagerRow,
    PositionRow,
    InvestorManagerPositionRow,
  },
})
export default class InvestorBookTable extends Vue {
  @Prop() shareRegister: ShareRegister;
  tableSettings = new TableSettings();
  issuerInfo: IssuerInfo = null;
  uncollapsed: string[] = [];

  get ready() {
    return this.shareRegister != null && this.issuerInfo != null;
  }

  get shareRegisterValidator() {
    return makeShareRegisterValidation(
      this.shareRegister,
      this.shareRegister.positions.filter(pos => pos.instrument.isShare || pos.instrument.isRight),
    );
  }

  get positionGroups() {
    this.tableSettings.groupProperty = 'absoluteInvestor';
    this.tableSettings.sortProperty = 'quantity';
    this.tableSettings.sortDesc = true;
    return this.sortHolder(this.shareRegister.getPositionGroups(this.tableSettings)).map(this.sortPositions);
  }

  onClickInvestorManager(investorManagerId: string) {
    const index = this.uncollapsed.indexOf(investorManagerId);
    if (index >= 0) {
      this.uncollapsed.splice(index, 1);
    } else {
      this.uncollapsed.push(investorManagerId);
    }
  }
  async mounted() {
    this.issuerInfo = await findIssuerInfo(this.shareRegister.entity);
  }

  sortHolder(holders) {
    return holders.sort((a, b) => this.sumTotals(b.holder) - this.sumTotals(a.holder));
  }

  sortPositions(group) {
    const categoryOrder = {
      [InstrumentCategory.DEBT]: 0,
      [InstrumentCategory.RIGHTS]: 1,
      [InstrumentCategory.SHA]: 2,
    };
    group.positions
      .sort((p1, p2) => {
        return (p2.quantity || 0) - (p1.quantity || 0);
      })
      .sort((p1, p2) => {
        return (p2.amount || 0) - (p1.amount || 0);
      })
      .sort((p1, p2) => {
        return p1.instrument.name.localeCompare(p2.instrument.name);
      })
      .sort((p1, p2) => {
        return categoryOrder[p2.instrument.category] - categoryOrder[p1.instrument.category];
      });
    return group;
  }

  sumTotals(investor) {
    return this.shareRegisterValidator.forAbsoluteInvestorManager(investor).totalQuantity;
  }
}
</script>
<style lang="scss">
.FullConversionTable {
  position: relative;
  .align-info {
    vertical-align: middle;
  }
}
</style>
