<template>
  <q-page v-if="ready" class="DocumentRightsIssuePage">
    <h1>{{ instruction.processTitle }}</h1>
    <Stepper pageType="rightsIssue" :currentstep="2" :urlId="instruction.id" />
    <q-separator />
    <h4>Verifiera nyemission</h4>
    <p class="p2">Bifoga ett eller flera signerade dokument i PDF-format som verifierar nyemissionen</p>
    <p class="p2">
      De dokument som bifogas ska kunna verifiera aktieboken och eventuella förbehåll och förvar som du har registrerat
    </p>
    <q-separator />

    <UploadFileButton @click="uploadDocuments" class="q-mt-lg" />
    <InputFile :instruction="instruction" ref="inputFile" />
    <FileList class="q-mt-md" :parent="instruction" />
    <q-btn v-bind="$defs.btn" type="button" class="step-btn" :disable="!isDone" @click="proceed">Gå vidare</q-btn>
    <a-btn-link v-on:click="goBack" class="arrow icon-left">Gå tillbaka</a-btn-link>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { ShareRegister, Instruction } from '@/models';
import Stepper from '@/components/Stepper.vue';
import FileList from '@/views/file/FileList.vue';
import UploadFileButton from '@/views/file/UploadFileButton.vue';
import InputFile from '@/components/InputFile.vue';
import { findInstruction } from '@/models/instruction/instructionService';
import { isToNewPage } from '@/utils';
@Component({
  components: { Stepper, FileList, InputFile, UploadFileButton },
})
export default class DocumentRightsIssuePage extends Vue {
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;

  async created() {
    await this.loadData();
  }
  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;
    const { shareRegister, instruction } = await findInstruction(id);
    Object.assign(this, { shareRegister, instruction });
  }
  uploadDocuments() {
    const component = this.$refs.inputFile as any;
    component.input.click();
  }
  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }
  get isDone() {
    return this.instruction.hasFiles;
  }
  proceed() {
    this.$router.push({
      path: `${this.instruction.entity.baseUrl}/nyemission/${this.instruction.id}/granska`,
    });
  }
  goBack() {
    this.$router.push({
      path: `${this.instruction.entity.baseUrl}/nyemission/${this.instruction.id}`,
    });
  }
}
</script>
