import SearchQuery from '@shared/web/models/SearchQuery';

import { Entity, Instrument, Ownership } from '@shared/web/models';
import { Products } from '@shared/models/types';

export type TransactionDateType = 'settleDate' | 'registrationDate' | 'tradeDate';

export default class TransactionSearchQuery extends SearchQuery {
  entity?: Entity = null;
  custodian?: Entity = null;
  instrument?: Instrument = null;
  owner?: Entity = null;
  ownership?: Ownership;
  dateType: TransactionDateType = null;
  from: Date = null;
  to: Date = null;
  ownerOrInvestor?: Entity = null;
  includeCounterparts = true;
  onlyInsurance?: boolean;
  instrumentProduct?: Products;

  constructor(attr) {
    super(attr);
    Object.assign(this, attr);
  }

  isEmpty() {
    return this.entity == null && this.instrument == null;
  }

  static define = () => ({
    entity: {
      model: Entity,
    },
    custodian: {
      model: Entity,
    },
    instrument: {
      model: Instrument,
    },
    owner: {
      model: Entity,
    },
    ownerOrInvestor: {
      model: Entity,
    },
    ownership: {
      model: Ownership,
    },
    from: {
      type: 'date',
    },
    to: {
      type: 'date',
    },
  });
}
