<template>
  <div class="PositionAdditionalInformationOnRowItem" :class="{ useMargin: useMargin }">
    <p class="nationalid-small" v-if="position.pledgeOwner">Aktiepost är pantsatt</p>
    <p class="nationalid-small" v-if="position.certificateIssued">Aktiebrev är utfärdat</p>
  </div>
</template>
<script lang="ts">
import { Position } from '@shared/web/models';
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component({})
export default class PositionAdditionalInformationOnRowItem extends Vue {
  @Prop() position: Position;
  @Prop() useMargin: boolean;
}
</script>
<style lang="scss">
.PositionAdditionalInformationOnRowItem {
  &.useMargin p:first-child {
    margin-top: 3px;
  }
}
</style>
