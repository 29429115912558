<template>
  <q-page class="flex flex-center column OnboardingPage">
    <q-btn padding="none" class="logo-symbol" flat unelevated @click="homeUrl">
      <q-avatar :square="true">
        <img src="/kapclear-logo-transparent.svg" alt="Kapclear logotype" />
      </q-avatar>
    </q-btn>
    <div :class="'onboarding-content relative-position z-fab ' + (wide ? 'wide' : '')">
      <slot />
    </div>
  </q-page>
</template>
<script lang="ts">
import { findProfile } from '@/models/profileService';
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component
export default class OnboardingPage extends Vue {
  @Prop({ default: false }) wide;
  async homeUrl() {
    const profile = await findProfile();
    if (profile && profile.loggedIn) {
      this.$router.push('/valjbolag');
    } else {
      this.$router.push('/');
    }
  }
}
</script>

<style lang="scss">
.OnboardingPage {
  background: #282a2d;

  padding: 0 !important;
  .onboarding-content {
    &.wide {
      @media (min-width: 1024px) {
        width: 100%;
        max-width: 900px;
      }
    }
  }
  .onboarding-box {
    width: 100%;
    display: flex;
    background: $grey-1;
  }
  .logo-symbol {
    position: fixed;
    top: 23px;
    left: 21px;
    z-index: 0;
    .q-avatar {
      height: auto;
      width: 153.81px;
      img {
        height: 44px;
      }
    }
  }

  @media (min-width: 1024px) {
    // @vue3 dessa klasser kunde jag inte längre hitta i quasar
    .q-mt-md-xl {
      margin-top: 48px;
    }
    .order-md-last {
      order: 10000;
    }
  }
}
</style>
