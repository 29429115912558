import { Instruction, ShareRegister, Entity } from '@/models';

import instructionValidator from '@shared/models/instructionValidator/instructionValidator';
import { modelHandler } from '../base';
import InstructionSearchQuery from '@shared/web/models/instruction/InstructionSearchQuery';
import axios from 'axios';
import { asModelId } from '@/utils';
import { InstructionMainType, InstructionState } from '@shared/models/types';

const baseUrl = '/webapi/instruction';
const { saveModel, destroyModel, searchModels } = modelHandler(Instruction, baseUrl);

export async function saveInstruction(instruction: Instruction) {
  const result = await saveModel(instruction);

  return result;
}

export async function destroyInstruction(instruction) {
  return destroyModel(instruction);
}

export async function clearInstruction(id) {
  const { data } = await axios.put(baseUrl + '/clear', {
    id,
  });
  return Instruction.newModel(data, true);
}

export async function findInstruction(id) {
  const { data } = await axios.get(baseUrl + '/' + id);

  return {
    shareRegister: ShareRegister.newModel(data.shareRegister, true),
    instruction: Instruction.newModel(data.instruction, true),
  };
}

export async function getInstructionDefaults({ type, entity }) {
  const { data } = await axios.post(baseUrl + '/defaults', { type, entity });

  return {
    shareRegister: ShareRegister.newModel(data.shareRegister, true),
    instruction: Instruction.newModel(data.instruction, true),
  };
}

export async function findTradeEvents({ entity }) {
  const { data } = await axios.post(baseUrl + '/find', {
    entity: asModelId(entity),
    mainType: InstructionMainType.TRADE_EVENT,
  });
  return Instruction.newModels(data, true);
}

export async function findInstructions({ entity, type }) {
  const { data } = await axios.post(baseUrl + '/find', {
    entity: asModelId(entity),
    type,
  });
  return Instruction.newModels(data, true);
}

export async function makeNewInstruction({ entity, type }) {
  const { data } = await axios.post(baseUrl + '/new', {
    entity: asModelId(entity),
    type,
  });
  return Instruction.newModel(data, true);
}

function isSameModel(a, b) {
  return (a == null && b == null) || a === b;
}

export function makeInstructionValidator(instruction: Instruction, shareRegister: ShareRegister) {
  return instructionValidator({
    instruction,
    entity: shareRegister.entity,
    instruments: shareRegister.instruments,
    positions: shareRegister.positions,
    isSameModel,
    settleDate: shareRegister.settleDate || instruction.settleDate,
    activeInstructions: shareRegister.activeInstructions,
  });
}

export async function loadShareRegisterForInstruction(instruction: Instruction) {
  const { data } = await axios.post(baseUrl + '/shareregister', {
    id: instruction.id,
  });
  return ShareRegister.newModel(data, true);
}

export async function findActiveInstructions(
  entity: Entity,
  mainType,
  state = InstructionState.IN_PROGRESS,
): Promise<Array<Instruction>> {
  if (!entity.isKapclearIssuer) {
    return [];
  }
  return search(new InstructionSearchQuery({ entity, mainType, state }));
}

export async function search(query: InstructionSearchQuery): Promise<Array<Instruction>> {
  const result = await searchModels(query);
  return result;
}
