<template>
  <div class="UserTransactionsSearch">
    <div class="row q-col-gutter-lg">
      <div class="col-4 col-md-auto">
        <p class="B1Small">Ägare</p>
        <a-select
          v-bind="$defs.input"
          v-model="query.ownerOrInvestor"
          :options="filters.owners"
          map-options
          null-label="Alla"
          option-label="viewName"
          @change="change"
        ></a-select>
      </div>

      <div class="col-4 col-md-auto">
        <p class="B1Small">Värdepapper</p>
        <a-select
          v-bind="$defs.input"
          v-model="query.instrument"
          :options="filters.instruments"
          map-options
          null-label="Alla"
          option-label="viewName"
          @change="change"
        ></a-select>
      </div>
      <div class="col-4 col-md-auto">
        <p class="B1Small">Datumtyp</p>
        <a-select
          v-model="query.dateType"
          v-bind="$defs.input"
          :options="dateTypes"
          emit-value
          map-options
          class="fill-width"
          @change="change"
        />
      </div>
      <div class="col-6 col-md-auto">
        <p class="B1Small">Från</p>
        <a-input-date v-model="query.from" v-bind="$defs.input" :disable="!query.dateType" @change="change" />
      </div>
      <div class="col-6 col-md-auto">
        <p class="B1Small">Till</p>
        <a-input-date v-model="query.to" v-bind="$defs.input" :disable="!query.dateType" @change="change" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-facing-decorator';

import TransactionSearchQuery from '@shared/web/models/transaction/TransactionSearchQuery';
import TransactionFilters from '@/models/transaction/TransactionFilters';

@Component({})
export default class UserTransactionsSearch extends Vue {
  @Prop() searchQuery: TransactionSearchQuery;
  @Prop() filters: TransactionFilters;
  query: TransactionSearchQuery = null;

  @Watch('searchQuery', { immediate: true })
  changeQuery(query: TransactionSearchQuery) {
    this.query = query.copy();
  }
  @Watch('query.dateType')
  clearDateRange(dateType) {
    if (!dateType) {
      this.query.from = null;
      this.query.to = null;
    }
  }

  change() {
    this.$emit('change', this.query);
  }

  get dateTypes() {
    return [
      { value: 'registrationDate', label: 'Registreringsdag' },
      { value: 'settleDate', label: 'Införd i aktieboken' },
      { value: 'tradeDate', label: 'Affärsdag' },
    ];
  }
}
</script>
<style lang="scss">
.UserTransactionsSearch {
  position: relative;
}
</style>
