import _ from 'lodash';
import { InstrumentCategory } from '..';
import { ShareRegister, TableSettings, IIssuerInfo } from '../models/modelTypes';
import { formatPercent } from '../utils';
import { formatPhoneNumber } from '../excel/utils';
import { makeShareRegisterValidation } from '../shareRegister/shareRegisterService';
import { getPositionGroups } from '../shareRegister/utils';
import { ContactInfo, Entity, Instrument } from '@shared/web/models';
import { formatNationalId } from '@shared/common';

export default function createShareRegisterExporter(
  shareRegister: ShareRegister,
  issuerInfo: IIssuerInfo,
  tableSettings: TableSettings,
  showFullConversion = false,
) {
  return async function shareRegisterExporter() {
    const { entity } = shareRegister;
    const validation = makeShareRegisterValidation(shareRegister);

    return {
      name: entity.viewName,
      records: getPositionGroups(shareRegister.positions, shareRegister.instruments, tableSettings)
        .map(data => {
          const sortedPositions = data.positions.sort((a, b) => a.shareNumberFrom - b.shareNumberFrom);

          return sortedPositions.map(position => {
            const totals = showFullConversion
              ? validation.forPositionFullyConverted(position)
              : validation.forPosition(position);

            const isShare = position.instrument.category === InstrumentCategory.SHA;

            const displayShareData =
              isShare || (showFullConversion && position.instrument.category === InstrumentCategory.RIGHTS);

            const ownerInfo = issuerInfo.getContactInfo(position.owner as Entity) as ContactInfo;
            const investorInfo = issuerInfo.getContactInfo(position.investor);
            const pledgeOwnerInfo = issuerInfo.getContactInfo(position.pledgeOwner);
            const ownerAddress = ownerInfo.address || position.owner?.addresses[0];
            const investorAddress = position.investor?.addresses[0];
            const ownerPhone = formatPhoneNumber(ownerInfo.phone);
            const investorPhone = formatPhoneNumber(investorInfo.phone);

            return {
              Aktie: position.instrument.name,
              Ägare: position.owner.viewName,
              Investeringsansvarig: position?.absoluteInvestorManager.viewName,
              Antal: position.quantity,
              'Aktienummer från': position.shareNumberFrom,
              'Aktienummer till': position.shareNumberTo,
              Kapitalandel: displayShareData ? formatPercent(totals.quantityQuota) : null,
              Röstandel: displayShareData ? formatPercent(totals.votingPowerQuota) : null,
              Aktiebrev: isShare ? (position.certificateIssued === true ? 'Ja' : 'Nej') : null,
              Försäkringsägare: position.investor?.viewName,
              Försäkringsnummer: position.insuranceNumber,
              Depåförvar: position.custodianName,
              Depånummer: position.custodianAccountNumber,
              'Ägare mail': ownerInfo?.email,
              'Ägare telefon': ownerPhone,
              'Ägare gatuadress': ownerAddress?.address1,
              'Ägare stad': ownerAddress?.city,
              'Ägare postnummer': ownerAddress?.zip,
              'Investerare mail': investorInfo?.email,
              'Investerare telefon': investorPhone,
              'Investerare gatuadress': investorAddress?.address1,
              'Investerare stad': investorAddress?.city,
              'Investerare postnummer': investorAddress?.zip,
              Pantägare: position.pledgeOwner?.viewName,
              'Pantägare mail': pledgeOwnerInfo.email,
              'Pantägare telefon': formatPhoneNumber(pledgeOwnerInfo.phone),
            };
          });
        })
        .flat(),
    };
  };
}

export function investorBookExporter(
  shareRegister: ShareRegister,
  issuerInfo: IIssuerInfo,
  tableSettings: TableSettings,
  showFullConversion = false,
) {
  return async function exporter() {
    let { totalVotingPower, totalCapital, totalQuantity } = makeShareRegisterValidation(shareRegister).forEntity();

    const quotaValue = totalCapital / totalQuantity;
    const records = [];

    const groupedByInstrument = getPositionGroups(shareRegister.positions, shareRegister.instruments, tableSettings);

    groupedByInstrument.forEach(instrumentGrouping => {
      const { holder, positions } = instrumentGrouping;
      const instrument = holder as Instrument;
      const isShare = instrument.category === InstrumentCategory.SHA;
      const isWarrant = instrument.category === InstrumentCategory.RIGHTS;
      const displayShareData = isShare || (showFullConversion && isWarrant);
      const contractSize = isWarrant ? instrument.rightsData.contractSize : null;

      let votingPower = isShare ? instrument.shareData.votingPower : 0;

      if (isWarrant && showFullConversion) {
        const { underlyingInstrument, contractSize, totalQuantity: rightsQuantity } = instrument.rightsData;
        votingPower = underlyingInstrument.shareData.votingPower;
        totalVotingPower = totalVotingPower + votingPower * contractSize * rightsQuantity;
        totalQuantity = totalQuantity + contractSize * rightsQuantity;
        totalCapital = totalCapital + quotaValue * contractSize * rightsQuantity;
      }

      const groupedByInvestor = Object.values(
        _.groupBy(positions, p => {
          return p.absoluteInvestor?.id + p.owner.id;
        }),
      );

      groupedByInvestor.forEach(investorPositions => {
        const pos0 = investorPositions[0];

        const quantity = isWarrant
          ? _.sumBy(investorPositions, 'quantity') * instrument.rightsData.contractSize
          : _.sumBy(investorPositions, 'quantity');

        const ownerInfo = issuerInfo.getContactInfo(pos0.owner);
        const investorInfo = issuerInfo.getContactInfo(pos0.investor);
        const ownerAddress = pos0.owner?.addresses[0];
        const investorAddress = pos0.investor?.addresses[0];
        const ownerPhone = formatPhoneNumber(ownerInfo.phone);
        const investorPhone = formatPhoneNumber(investorInfo.phone);
        const capital = quotaValue * quantity;

        let votes;
        if (displayShareData) {
          votes = isWarrant
            ? instrument.rightsData.underlyingInstrument.shareData.votingPower * quantity * contractSize
            : instrument.shareData.votingPower * quantity;
        }

        records.push({
          Aktie: (holder as Instrument).name,
          Investeringsansvarig: pos0.absoluteInvestorManager.viewName,
          'Investeringsansvarig personnr/orgnr': formatNationalId(pos0?.absoluteInvestorManager),
          Ägare: pos0.owner.viewName,
          'Ägare personnr/orgnr': formatNationalId(pos0.owner),
          Antal: _.sumBy(investorPositions, 'quantity'),
          Kapitalandel: displayShareData ? formatPercent(capital / totalCapital) : null,
          'Antal Röster': votes,
          Röstandel: displayShareData ? formatPercent(votes / totalVotingPower) : null,
          Försäkringsägare: pos0.investor?.viewName,
          'Ägare mail': ownerInfo?.email,
          'Ägare telefon': ownerPhone,
          'Ägare gatuadress': ownerAddress?.address1,
          'Ägare stad': ownerAddress?.city,
          'Ägare postnummer': ownerAddress?.zip,
          'Investerare mail': investorInfo?.email,
          'Investerare telefon': investorPhone,
          'Investerare gatuadress': investorAddress?.address1,
          'Investerare stad': investorAddress?.city,
          'Investerare postnummer': investorAddress?.zip,
        });
      });
    });

    // IB/IBK: [I]nvesterar[B]ok vid full [K]onvertering
    const suffix = showFullConversion ? 'IBK' : 'IB';

    return {
      name: `${shareRegister.entity.viewName}-${suffix}`,
      records: records
        .sort((a, b) => Math.sign(b['Antal Röster'] - a['Antal Röster']))
        .sort((a, b) => a.Aktie.localeCompare(b.Aktie)),
    };
  };
}
