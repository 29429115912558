import * as _ from 'lodash';
import { isEmpty } from 'lodash';
import Personnummer from 'personnummer';
import iso7064 from 'iso-7064';
import isInt from 'validator/lib/isInt';
import isDecimal from 'validator/lib/isDecimal';
import isEmail from 'validator/lib/isEmail';
// import isIsinValid from 'isin-validator'; // @vue3 isin-validator fungerade inte att importera pga commonJS require
import { isIsinValid } from 'js-isin-validator';
import { toNumber } from '@shared/utils';

const isLEI = (code: string): boolean => {
  const formatIsValid = /^[0-9]{4}[0-9A-Z]{14}[0-9]{2}$/;

  if (code === undefined || code === null) {
    return false;
  }

  const codeToUpperCase = code.toUpperCase();

  if (!codeToUpperCase.match(formatIsValid)) {
    return false;
  }
  return iso7064.computeWithoutCheck(codeToUpperCase) === 1;
};

function validateLEI(val, errorText = 'Värdet måste vara LEI') {
  return _.isEmpty(val) || isLEI(val) || errorText;
}

export function required(val, errorText = 'Värdet måste anges') {
  return !_.isEmpty(val) || errorText;
}

export function requiredInteger(val: string | number, errorText = 'Värdet måste vara ett nummer') {
  return isInt(('' + val).replace(/\s+/g, '')) || errorText;
}

export function requiredNumber(val: string | number, errorText = 'Värdet måste vara ett nummer') {
  return isDecimal(('' + val).replace(/\s+/g, ''), { locale: 'sv-SE' }) || errorText;
}

export function validateISIN(val, errorText = 'Värdet måste vara ett ISIN') {
  return _.isEmpty(val) || isIsinValid(val) || errorText;
}

export function requiredLargerThanZero(val, errorText = 'Värdet måste vara större än 0') {
  return toNumber(val) > 0 || errorText;
}

export function pledgeOwnerRequired(pledgeIssued: boolean, val, errorText) {
  return pledgeIssued ? !_.isEmpty(val) || val != null || errorText : true;
}

export function validEmail(val: string, errorText = 'Email är felaktig') {
  return isEmail(val) || errorText;
}

export function validPhone(val: string, errorText = 'Ogiltigt telefonnummer') {
  return /^\+?[0-9 ()-]*$/.test(val) || errorText;
}

export default {
  nationalId: [val => required(val, 'Bolagsnr/Personnr måste anges')],
  name: [val => required(val, 'Namnet måste anges')],
  countryCode: [val => required(val, 'Land måste anges')],
  types: [val => required(val, 'Minst en typ måste väljas')],
  surName: [val => required(val, 'Efternamn måste anges')],
  required: label => [val => required(val, `${label} måste anges`)],
  requiredInteger: [val => requiredInteger(val)],
  requiredNumber: [val => requiredNumber(val)],
  ISIN: [val => validateISIN(val)],
  noRule: [() => true],
  requiredLargerThanZero: [val => requiredNumber(val), val => requiredLargerThanZero(val)],
  validateLEI: [val => validateLEI(val)],
  pledgeOwnerRequired: pledgeIssued => [val => pledgeOwnerRequired(pledgeIssued, val, 'Pantägare måste anges')],
  email: [val => isEmpty(val) || validEmail(val)],
  phone: [val => isEmpty(val) || validPhone(val)],
  requiredEmail: [val => required(val, `Email måste anges`), val => validEmail(val)],
  personalNumber: [val => Personnummer.valid(val) || 'Ogiltig personnummer'],
  optionalPersonalNumber: [val => !val || Personnummer.valid(val) || 'Ogiltigt personnummer'],
};
