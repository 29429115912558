<template>
  <div class="UserTransactionsTable">
    <q-markup-table v-if="ready" flat class="standard-table" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Bolag</th>
          <th class="text-left">Värdepapper</th>
          <th class="text-left">Typ</th>
          <th class="text-left">Motpart</th>
          <th class="text-left">Affärsdag</th>
          <th class="text-right">Införd i aktieboken</th>
          <th class="text-right">Antal</th>
          <th class="text-right">Pris</th>
          <th class="text-left">Kapitalförsäkring</th>
          <th class="text-left" v-if="false">Förvar</th>
        </tr>
      </thead>
      <tbody>
        <UserTransactionsTableRow
          v-for="transaction in transactions"
          :key="transaction.id"
          :transaction="transaction"
        />
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import store from '@/store';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import UserTransactionsTableRow from './UserTransactionsTableRow.vue';
import { Transaction } from '@/models';

@Component({
  components: { UserTransactionsTableRow },
})
export default class UserTransactionsTable extends Vue {
  @Prop() transactions: Transaction[];

  get user() {
    return store.profile.user;
  }
  get ready() {
    return this.transactions != null;
  }
}
</script>
<style lang="scss">
.UserTransactionsTable {
  position: relative;
}
</style>
