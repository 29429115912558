<template>
  <q-page v-if="ready" class="SplitsPage">
    <h1>Split</h1>
    <q-btn v-if="canEdit" v-bind="$defs.btn" type="button" @click="newInstruction">Skapa split</q-btn>
    <q-separator />
    <SplitsTable
      @destroy="destroy"
      header="Pågående splittar"
      subheader="Ta bort inaktuella registreringar som inte ska genomföras, genom att klicka på krysset"
      :value="ongoingInstructions"
    />
    <SplitsTable @destroy="destroy" header="Genomförda splittar" :value="executedInstructions" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Instruction, TransactionType, Entity } from '@/models';
import SplitsTable from './SplitsTable.vue';
import { findInstructions, makeNewInstruction } from '@/models/instruction/instructionService';
import { remove } from '@/utils';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { store } from '@shared/web/store';
import { reverse, sortBy } from 'lodash';

@Component({
  components: { SplitsTable },
})
export default class SplitsPage extends Vue {
  @Prop({ default: true }) readonly: boolean;
  instructions: Array<Instruction> = null;
  entity: Entity = null;

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  async created() {
    const entity = await findEntityWithRouteParams(this.$route.params);
    const instructions = await findInstructions({ entity, type: TransactionType.SPLIT });
    this.instructions = reverse(sortBy(instructions, 'updatedAt'));
    this.entity = entity;
  }

  get ongoingInstructions() {
    return this.instructions.filter(instruction => {
      return instruction.calculatedState !== 'EXECUTED';
    });
  }

  get executedInstructions() {
    return this.instructions.filter(instruction => {
      return instruction.calculatedState === 'EXECUTED';
    });
  }

  get ready() {
    return this.instructions != null;
  }

  destroy(instruction) {
    remove(this.instructions, instruction);
  }
  async newInstruction() {
    const instruction = await makeNewInstruction({ entity: this.entity, type: TransactionType.SPLIT });
    this.$router.push({ path: `${this.entity.baseUrl}/split/${instruction.id}` });
  }
}
</script>
