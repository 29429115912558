import { Model } from '../base';
import { PersonRole } from '@shared/web/models';
import { IPerson } from '@shared/models/modelTypes';
export default class Person extends Model implements IPerson {
  surName: string = null;
  firstName?: string = null;
  middleName?: string = null;
  roles?: Array<PersonRole>;
  secrecyMarked?: boolean = false;
  static define = () => ({
    roles: {
      default: () => [],
      embeddedModel: [PersonRole],
    },
  });
}
