<template>
  <div class="CustodianInstrumentsTable relative-position">
    <ExportLinks v-if="ready">
      <ExportExcel :exporter="excelExporter" />
    </ExportLinks>
    <q-markup-table v-if="ready && !loading" flat class="standard-table" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Instrument</th>
          <th class="text-left">Typ</th>
          <th class="text-right">Totalt antal</th>
          <th class="text-right">Nominellt belopp</th>
        </tr>
      </thead>
      <tbody v-for="instrumentGroup in instrumentGroups" :key="instrumentGroup.holder.id">
        <tr class="large">
          <td class="text-left">
            <a-btn-link @click="viewInstrument(instrumentGroup)">{{ instrumentGroup.holder.name }}</a-btn-link>
            <div class="text-small">{{ instrumentGroup.holder.instrumentId }}</div>
          </td>
          <td class="text-left">{{ toInstrumentGroupLabel(instrumentGroup.holder.group) }}</td>
          <td class="text-right">{{ instrumentGroup.quantity ? formatNumber(instrumentGroup.quantity) : '' }}</td>
          <td class="text-right">{{ instrumentGroup.amount ? formatNumber(instrumentGroup.amount) : '' }}</td>
        </tr>
      </tbody>
    </q-markup-table>
    <q-inner-loading :showing="loading" />
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { formatDate, formatNumber } from '@shared/utils';
import { Entity } from '@/models';
import CustodianHoldings from '@/models/custodian/CustodianHoldings';
import { toInstrumentGroupLabel } from '@shared/common';
import ExportLinks from '@shared/components/ExportLinks.vue';
import ExportExcel from '@shared/views/excel/ExportExcel.vue';

@Component({
  components: { ExportLinks, ExportExcel },
})
export default class CustodianInstrumentsTable extends Vue {
  @Prop() custodian: Entity;
  @Prop() holdings: CustodianHoldings;
  @Prop({ default: false }) loading;

  toInstrumentGroupLabel(group) {
    return toInstrumentGroupLabel(group);
  }

  formatNumber(num) {
    return formatNumber(num);
  }

  get instrumentGroups() {
    return this.holdings.getGrouping({ property: 'instrument' });
  }

  get ready() {
    return this.custodian && this.holdings;
  }

  viewInstrument(instrumentGroup) {
    const {
      holder: { id },
    } = instrumentGroup;
    this.$router.push(`${this.custodian.baseUrl}/custodian/${id}/instrument`);
  }

  async excelExporter() {
    const { settleDate } = this.holdings;
    const name = `Innehav ${formatDate(settleDate, 'yyyy-MM-dd')}`;

    return {
      name,
      records: this.instrumentGroups.map(({ holder: instrument, quantity, amount }) => {
        return {
          Instrument: instrument.name,
          Type: toInstrumentGroupLabel(instrument.group),
          'Totalt antal': quantity,
          'Nominellt belopp': amount,
        };
      }),
    };
  }
}
</script>
<style lang="scss">
.CustodianInstrumentsTable {
  position: relative;
  .q-table {
    tbody {
      td {
        border-bottom-width: 2px;
      }
    }
  }
}
</style>
