<template>
  <div class="CreateShareRegisterInfo" v-if="!isExecuted">
    <div class="q-py-md" v-if="isSubmitted">
      <h4>Tack! Aktieboken är nu under verifiering.</h4>
      <p class="p2">
        Kapclear granskar aktieboken och jämför med bifogade dokumentationen för att kunna verifiera och säkerställa att
        aktieboken är korrekt, normalt tar detta 1-2 arbetsdagar.
      </p>
      <p class="p2">
        Vi kontaktar dig på {{ email }} när aktieboken är godkänd eller om kompletteringar behöver göras.
      </p>
    </div>
    <div class="q-py-md" v-else-if="isInReview">
      <h4>Tack! Aktieboken är under granskning.</h4>
      <p class="p2">
        Vi kontaktar dig på {{ email }} när aktieboken är godkänd eller om kompletteringar behöver göras.
      </p>
    </div>
    <div class="q-py-md" v-else-if="isRequestedChange">
      <h4>Aktieboken behöver kompletteras.</h4>
      <p class="p2">
        Aktieboken behöver kompletteras. Vi har skickat mail till {{ email }} och behöver komma i kontakt med er för att
        gå igenom aktieboken.
        <span v-if="shareRegister.entity.shareRegisterRequestedChangeMessage">
          Kommentar: {{ shareRegister.entity.shareRegisterRequestedChangeMessage }}
        </span>
      </p>
    </div>
    <div class="q-py-md" v-else>
      <h4>Välkommen till Kapclear!</h4>
      <template v-if="isDashBoard">
        <p class="p2">
          Detta är ditt bolags dashboard. Här får du en överblick av bolagets aktier, senaste affärs- och
          bolagshändelser.
        </p>
        <q-btn v-bind="$defs.btn" type="button" class="q-mt-lg" @click="procces">Skapa aktiebok</q-btn>
      </template>
      <template v-else>
        <p class="p2">
          Det första du behöver göra är att skapa en aktiebok. Har du många ägare att lägga in och ont om tid? Ta det
          lugnt! Nästa gång du loggar in är allt sparat och du kan fortsätta där du slutade.
        </p>
        <p class="p2">
          Du kan också göra en medarbetare eller annan betrodd person till administratör, klicka på Administratörer i
          vänsternavigationen. Kom dock ihåg att det är bolagets styrelse som är ansvarig för att aktieboken förs
          korrekt.
        </p>
        <p class="p2">
          Vill du ha hjälp av Kapclear att lägga upp aktieboken, kontakta oss för ett prisförslag på
          <a :href="'mailto:' + contacts.kapclearEmail">{{ contacts.kapclearEmail }}</a>
          eller ring <a :href="'tel:' + contacts.kapclearPhone"> {{ contacts.kapclearPhoneDisplay }}</a
          >.
        </p>
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { EntityAdmin, ShareRegister, InstructionState } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { findCurrentEntityAdmin } from '@/models/entityAdmin/entityAdminService';
import { KaptenaContact } from '@/models';

@Component({
  components: {},
})
export default class CreateShareRegister extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() isDashBoard: boolean;
  entityAdmin: EntityAdmin = null;

  async created() {
    this.entityAdmin = await findCurrentEntityAdmin(this.shareRegister.entity);
  }

  get contacts() {
    return KaptenaContact;
  }

  get isSubmitted() {
    const state = this.shareRegister.entity.issuerData.shareRegisterState;
    return state === InstructionState.SUBMITTED;
  }
  get isInReview() {
    const state = this.shareRegister.entity.issuerData.shareRegisterState;
    return state === InstructionState.REVIEW;
  }
  get isRequestedChange() {
    const state = this.shareRegister.entity.issuerData.shareRegisterState;
    return state === InstructionState.REQUEST_CHANGE;
  }
  get isExecuted() {
    const state = this.shareRegister.entity.issuerData.shareRegisterState;
    return state === InstructionState.EXECUTED;
  }

  get email() {
    return this.entityAdmin?.email;
  }

  get entity() {
    return this.shareRegister.entity;
  }

  procces() {
    this.$router.push({ path: `${this.entity.baseUrl}/skapa-aktiebok` });
  }
}
</script>
<style lang="scss">
.CreateShareRegisterInfo {
  margin-top: 30px 0 20px;

  p {
    margin: 12px 0;
    max-width: 800px;
  }
}
</style>
