import { Model } from '../base';
import { getHistoricShareData } from '@shared/models/historicShareData';
import { ShareDataHistory, IShareData } from '@shared/models/modelTypes';
import { shareClassTypes } from '@shared/common';
import * as _ from 'lodash';

export default class ShareData extends Model implements IShareData {
  totalQuantity: number;
  totalCapital: number;
  classType: keyof typeof shareClassTypes;
  reservations: Array<string> = [];
  certificateIssued: boolean;
  votingPower: number;
  positionsValid: boolean;
  history: Array<ShareDataHistory>;

  constructor(attr = {}) {
    super(attr);
    Object.assign(this, attr);
  }

  get quotaValue() {
    const { totalQuantity, totalCapital } = this;
    return totalCapital / totalQuantity;
  }

  get hasReservations() {
    return !_.isEmpty(this.reservations);
  }

  getHistoricVersion(settleDate) {
    const history = getHistoricShareData(this, settleDate);

    return new ShareData({ ...this, ...history });
  }
}
