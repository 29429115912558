<template>
  <a-btn-link
    :loading="loading"
    @click="downloadFileWithData"
    class="icon-left align-right"
    icon="svguse:/svg-icons.svg#icon-download"
    size="sm"
    >Excel</a-btn-link
  >
</template>
<script lang="ts">
import { Vue, Prop, Component } from 'vue-facing-decorator';
import xlsx, { IColumn, IContent } from 'json-as-xlsx';

type SheetData = {
  records: Record<string, string | number>[];
  name: string;
};

/* xlsx tab names can not be > 31 chars */
function truncateSheetName(name: string) {
  if (name.length > 31) {
    return `${name.substring(0, 30)}~`;
  }
  return name;
}

@Component({})
export default class ExcelDownload extends Vue {
  @Prop() exporter: () => Promise<SheetData>;
  loading = false;

  async downloadFileWithData() {
    this.loading = true;
    const { records, name } = await this.exporter();

    const columns = this.getColumnsFromRecords(records);
    const content = this.getDataFromRecords(records);
    xlsx(
      [
        {
          sheet: (name && truncateSheetName(`${name}`)) || 'Namnlös',
          columns,
          content,
        },
      ],
      { fileName: name, extraLength: 3, writeOptions: { ignoreEC: true } },
    );

    this.loading = false;
  }

  getColumnsFromRecords(records): IColumn[] {
    if (!records?.length) {
      return [];
    }

    return Object.keys(records[0]).map(name => {
      const columnInfo: IColumn = { label: name, value: name.replace('.', '') };

      const exampleValue = records[0][name];

      if (typeof exampleValue === 'string' && exampleValue.includes('%')) {
        columnInfo.format = '0.00%';
      }

      return columnInfo;
    });
  }

  getDataFromRecords(records): IContent[] {
    return records.map(row => {
      const columnsWithDots = Object.keys(row).filter(column => column.includes('.'));

      // fixes columns with dots
      columnsWithDots.forEach(column => {
        const sanitizedColumnName = column.replace('.', '');
        row[sanitizedColumnName] = row[column];
        delete row[column];
      });

      // fixes percentage values
      Object.keys(row).forEach(column => {
        let value = row[column];
        if (typeof value === 'string' && value.includes(' %')) {
          value = value.replace(',', '.');
          value = parseFloat(value) / 100;
        }
        row[column] = value;
      });

      return row;
    });
  }
}
</script>
<style lang="scss">
.ExcelDownload {
  margin-left: 18px;
  display: block;
  float: right;
}
</style>
