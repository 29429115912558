<template>
  <q-page v-if="ready" class="IssueWarrantPage">
    <h1>{{ instruction.processTitle }}</h1>
    <Stepper :pageType="instruction.type" :currentstep="1" :urlId="instruction.id" v-if="instruction.isEditable" />
    <q-separator />
    <PageBanner page="issueWarrant" v-if="isEditable">
      <h4 v-if="isExerciseWarrantPage">En lösen av teckningsoptioner som drar ut på tiden?</h4>
      <h4 v-else>En emission av teckningsoptioner som drar ut på tiden?</h4>
      <p class="p2">
        Ingen fara! All data som du fyller i sparas automatiskt hela tiden så oavsett om det tar timmar eller veckor
        finns din data sparad här nästa gång du loggar in.
      </p>
    </PageBanner>
    <q-card flat class="q-pa-md" v-if="isExerciseWarrantPage">
      Har det varit en split eller annan bolagshändelse mellan utgivningen av TO programmet och lösendagen som innebär
      att TO ägarna ska kompenseras i antal aktier?
      <q-toggle
        label="Ja"
        v-model="instruction.corporateEvent.splitHappened"
        :disable="!isEditable"
        :readonly="!isEditable"
      />
    </q-card>
    <h4 class="table-header">Villkor</h4>
    <q-form class="" ref="form">
      <WarrantDetails
        :shareRegister="shareRegister"
        :instruction="instruction"
        :entity="entity"
        :errors="errors"
        @change="handleDetailsChange"
        :readonly="!isEditable"
        :splitHappened="splitHappened"
        :validator="validator"
      />
      <div v-if="instruction.warning" class="warning-text q-mt-lg">
        <p class="q-pl-md p2">Det finns pågående ägarbyten med införd i aktieboken efter avstämningsdagen.</p>
      </div>

      <h4 class="table-header">Deltagare</h4>
      <DestinationsTableCard
        :shareRegister="shareRegister"
        v-if="shareRegister"
        :instruction="instruction"
        :validator="validator"
        :readonly="!isEditable"
        :disableAdd="isExerciseWarrantPage || hasUnsavedChanges"
        @change="handleDestinationsChange(true)"
        @clear="clear"
      />
      <div class="row q-mt-xl q-mb-xl" v-if="!isExerciseWarrantPage">
        <div class="col-12 col-md-4">
          <div class="text-bold text-small text-black q-mb-sm">Införd i aktieboken</div>
          <a-input-date
            v-bind="$defs.input"
            :readonly="!isEditable"
            :disable="!isEditable"
            v-model="instruction.settleDate"
            @update:modelValue="handleSettleDateChanged"
          />
        </div>
      </div>
      <InstructionSaveRow
        v-if="instruction.isEditable && canEdit"
        :disable="isSaving"
        :is-saving="isSaving"
        :has-unsaved-changes="hasUnsavedChanges"
        @proceed="proceed"
      />
    </q-form>
  </q-page>
</template>
<script lang="ts">
import { Component, Ref, Vue, Watch } from 'vue-facing-decorator';
import { Entity, Instruction, ShareRegister, TransactionType } from '@/models';
import Stepper from '@/components/Stepper.vue';
import PageBanner from '@/components/PageBanner.vue';
import WarrantDetails from './WarrantDetails.vue';
import DestinationsTableCard from './DestinationsTableCard.vue';
import { makeShareRegisterValidation } from '@shared/web/models/shareRegister/shareRegisterService';
import {
  findInstruction,
  getInstructionDefaults,
  makeInstructionValidator,
  saveInstruction,
} from '@/models/instruction/instructionService';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { debounce } from 'lodash';
import { store } from '@shared/web/store';
import { looksLikeId } from '@shared/utils';
import InstructionSaveRow from '@/components/InstructionSaveRow.vue';
import { isToNewPage } from '@/utils';
import { ref } from 'vue';

const instructionDirty = ref(false);

@Component({
  components: { InstructionSaveRow, Stepper, PageBanner, WarrantDetails, DestinationsTableCard },
  provide: { instructionDirty },
})
export default class IssueWarrantPage extends Vue {
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;
  entity: Entity = null;
  errors = {};
  @Ref() readonly form;
  debouncedSave = null;
  isSaving = false;
  hasUnsavedChanges = false;
  originalContractSize: number;

  get splitHappened() {
    return this.instruction?.corporateEvent?.splitHappened;
  }

  get rightsData() {
    return this.instruction?.corporateEvent?.rightsData;
  }

  async created() {
    this.debouncedSave = debounce(async (reSync = false) => {
      await this.save(reSync);
    }, 1000);
    await this.loadData();
  }

  @Watch('instruction.corporateEvent.splitHappened')
  splitHappenedChanged(newValue, oldValue) {
    // skipping default
    if (newValue === false && !oldValue) {
      return;
    }
    this.save();
    if (this.isExerciseWarrantPage && newValue === false) {
      this.rightsData.contractSize = this.originalContractSize;
    }
  }

  handleDetailsChange(reSync = false) {
    this.hasUnsavedChanges = true;
    this.debouncedSave(reSync);
  }

  handleDestinationsChange(reSync = false) {
    this.hasUnsavedChanges = true;
    this.debouncedSave(reSync);
  }

  @Watch('hasUnsavedChanges')
  handleHasUnsavedChangesChange(value) {
    instructionDirty.value = value;
  }

  handleSettleDateChanged() {
    this.save();
  }

  get isNew() {
    return !looksLikeId(this.$route.params.id);
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const idOrType = this.$route.params.id;
    this.entity = await findEntityWithRouteParams(this.$route.params);

    const { shareRegister, instruction } = this.isNew
      ? await getInstructionDefaults({
          type: TransactionType.ISSUE_WARRANT,
          entity: this.entity.id,
        })
      : await findInstruction(idOrType);

    Object.assign(this, { shareRegister, instruction });
  }

  get isExerciseWarrantPage() {
    return this.instruction?.type === TransactionType.EXERCISE_WARRANT;
  }

  get isEditable() {
    if (!this.canEdit) return false;
    return this.isNew || (this.instruction.isEditable && !this.isExerciseWarrantPage);
  }

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false;
  }

  async save(reFetch = false) {
    this.isSaving = true;
    const savedInstruction = (await saveInstruction(this.instruction)).model;
    if (!this.instruction.id) {
      window.history.pushState({}, '', savedInstruction.webUrl);
    }
    if (reFetch) {
      this.instruction = savedInstruction;
    } else if (!this.instruction.id) {
      this.instruction.id = savedInstruction.id;
    }
    this.hasUnsavedChanges = false;
    this.isSaving = false;
  }

  get validator() {
    return this.ready && makeInstructionValidator(this.instruction, this.shareRegister);
  }

  get shareRegisterValidation() {
    return makeShareRegisterValidation(this.shareRegister);
  }

  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }

  clear(instruction) {
    this.instruction = instruction;
  }

  async proceed() {
    const error = this.validator.error();

    if (error) {
      this.errors = {
        [error.path]: error.text,
      };
      return;
    }
    await this.save(true);
    await this.$router.push({
      path: `${this.instruction.entity.baseUrl}/optionsprogram/${this.instruction.id}/dokument`,
    });
  }
}
</script>
<style lang="scss">
.RightsIssuePage {
  .warning-text {
    height: 72px;
    background-color: white;
    p {
      line-height: 72px;
    }
  }
}
</style>
