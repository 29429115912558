import { InstructionState } from '@shared/models/types';
import { Model } from '../base';
import ShareData from '@shared/web/models/instrument/ShareData';
import { IIssuerData } from '@shared/models/modelTypes';

export default class IssuerData extends Model implements IIssuerData {
  totalQuantity: number;
  totalCapital: number;
  companyValue: number;
  totalVotingPower: number;
  currencyCode: string;
  hasShareNumbers: boolean;
  shareRegisterValid: boolean;
  shareRegisterState: InstructionState;
  shareRegisterSettleDate: Date;
  onlyWarrantCompany: boolean;
  missingInstruments: { shareData: ShareData }[];
  lastUpdate: Date;

  static define = () => ({
    lastUpdate: {
      type: 'date',
    },
    shareRegisterSettleDate: {
      type: 'date',
    },
  });

  get quotaValue() {
    const { totalQuantity, totalCapital } = this;
    return totalCapital / totalQuantity;
  }

  get isRecordCompany() {
    return !this.hasShareNumbers;
  }
  set isRecordCompany(value) {
    this.hasShareNumbers = !value;
  }

  get locked() {
    return this.shareRegisterState === InstructionState.EXECUTED;
  }

  get hasShareRegister() {
    return this.locked;
  }
}
