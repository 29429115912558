<template>
  <q-dialog ref="dialog" persistent>
    <div class="q-dialog-plugin DeclareInterestModal">
      <a-page-section class="padded">
        <q-form @submit="submitForm" ref="form">
          <div class="col">
            <q-list separator class="standard-list row-md-7">
              <div class="q-pb-md">
                <p>{{ title }}</p>
                <h3 class="legal-entity-name">
                  {{ investmentOffer?.offerCompanyName }}
                </h3>
                <p>{{ subTitle }}</p>
                <h6>{{ note }}</h6>
              </div>

              <q-input
                label="Förnamn"
                v-bind="inputDefaults"
                v-model="entity.person.firstName"
                :readonly="false"
                :rules="validation.required('Förnamn')"
              />
              <q-input
                label="Efternamn"
                v-bind="inputDefaults"
                v-model="entity.person.surName"
                :rules="validation.required('Efternamn')"
                :readonly="false"
              />
              <q-input
                label="Personnummer"
                v-bind="inputDefaults"
                v-model="entity.nationalId"
                :rules="validation.required('Personnummer')"
                :readonly="false"
              />

              <q-input
                label="Email"
                v-bind="inputDefaults"
                v-model="entity.addresses[0].email"
                lazy-rules
                :rules="validation.required('Email')"
                :readonly="false"
              />
              <q-input
                label="Telefonnummer"
                v-bind="inputDefaults"
                v-model="entity.addresses[0].phone"
                :rules="validation.required('Telefonnummer')"
                :readonly="false"
              />

              <div>
                <p>Gäller intresseanmälan ett bolag:</p>
              </div>
              <q-input
                label="Bolagets namn"
                v-bind="inputDefaults"
                v-model="companyName"
                :readonly="false"
                :rules="[null]"
              />

              <q-input label="Org.nr" v-bind="inputDefaults" v-model="orgNr" :readonly="false" />
            </q-list>
            <div class="q-pt-lg">
              <q-input
                v-model="textMessage"
                filled
                autogrow
                label="Har du några frågor? Skriv det här innan du skickar intressanmälan."
              />
            </div>
          </div>

          <q-card-actions align="right" class="q-pa-md">
            <q-btn label="Avbryt" v-bind="$defs.btn" flat @click="$emit('hide')" />
            <q-btn label="Skicka" type="submit" v-bind="$defs.btn" />
          </q-card-actions>
        </q-form>
      </a-page-section>
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { DialogComponent } from '@shared/mixins';
import { Entity } from '@shared/web/models';
import InvestmentOffer from '@shared/web/models/investmentOffer/InvestorOffer';
import { validation } from '@shared/common';
@Component({
  mixins: [DialogComponent],
})
export default class DeclareInterestModal extends Vue {
  @Prop() onSubmit: Function;
  @Prop() title: String;
  @Prop() subTitle: String;
  @Prop() note: String;
  @Prop() entity: Entity;
  @Prop() investmentOffer: InvestmentOffer;

  textMessage = '';
  companyName = '';
  orgNr = null;

  get inputDefaults() {
    return {
      dense: true,
      filled: true,
      square: true,
    };
  }

  get validation() {
    return validation;
  }

  async submitForm() {
    const investmentOffer = {
      textMessage: this.textMessage,
      email: this.entity.addresses[0].email,
      phone: this.entity.addresses[0].phone,
      firstName: this.entity.person.firstName,
      surName: this.entity.person.surName,
      offerCompanyName: this.investmentOffer.offerCompanyName,
      companyName: this.companyName,
      orgNr: this.orgNr,
    };
    if (this.onSubmit) {
      await this.onSubmit(investmentOffer);
    }
    this.done();
  }
}
</script>

<style lang="scss">
.DeclareInterestModal {
  min-width: 35rem;
  padding: 2.5rem;
  background: white;

  .legal-entity-name {
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-size: 1.2rem;
  }

  .q-field__control {
    height: auto !important;
  }
}
</style>
