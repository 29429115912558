<template>
  <tr
    :class="{
      large: true,
      InvestorManagerPositionRow: true,
      collapsed,
    }"
  >
    <td><div></div></td>
    <td class="text-left wrap-text">
      <div>
        <p>{{ position.instrument.viewName }}</p>
      </div>
    </td>
    <td class="text-left wrap-text">
      <div>
        <p>{{ position.owner.viewName }}</p>
        <p class="nationalid-small">{{ formatNationalId(position.owner) }}</p>
      </div>
    </td>
    <td class="text-left wrap-text">
      <div>
        <p>{{ position.investor && position.investor.viewName }}</p>
        <p class="nationalid-small">{{ formatNationalId(position.investor) }}</p>
      </div>
    </td>
    <td class="text-left">
      <div>{{ position.custodianName }}</div>
    </td>
    <td class="text-right" v-if="!isRight || !showFullConversion">
      <div>{{ formatPositionValue(position) }}</div>
    </td>
    <td class="text-right" v-else>
      <div>{{ formatNumber(totals.shareQuantity) }}</div>
    </td>
    <td class="text-right">
      <div>
        <template v-if="showQuantityQuota">{{ formatPercent(totals.quantityQuota) }}</template>
      </div>
    </td>
    <td class="text-right">
      <div>
        <template v-if="showVotingPowerQuota">{{ formatPercent(totals.votingPowerQuota) }}</template>
      </div>
    </td>
  </tr>
</template>
<script lang="ts">
import { Position } from '@shared/web/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';

import { formatPositionValue } from '@shared/web/models/position/common';

@Component({})
export default class InvestorManagerPositionRow extends Vue {
  @Prop() position: Position;
  @Prop() shareRegisterValidator;
  @Prop({ default: false }) showFullConversion;
  @Prop({ default: true }) collapsed;

  formatPositionValue(position) {
    return formatPositionValue(position);
  }

  get showQuantityQuota() {
    return this.isShare || (this.isRight && this.showFullConversion);
  }

  get showVotingPowerQuota() {
    return this.isShare || (this.isRight && this.showFullConversion);
  }

  get isShare() {
    return this.position.instrument.isShare;
  }

  get isRight() {
    return this.position.instrument.isRight;
  }

  get totals() {
    if (this.showFullConversion) {
      return this.shareRegisterValidator.forPositionFullyConverted(this.position);
    }
    return this.shareRegisterValidator.forPosition(this.position);
  }
}
</script>
<style lang="scss">
.InvestorManagerPositionRow {
  td {
    transition: all 300ms ease-in-out;
    div {
      overflow: hidden;
      transition: max-height 300ms ease-in-out;
      max-height: 150px;
    }
  }
  &.collapsed {
    td {
      border: 0;
      border-color: transparent;
      height: 0px !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      div {
        max-height: 0px;
      }
    }
  }
}
</style>
