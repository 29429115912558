<template>
  <div
    data-test-id="cookie-banner"
    class="print-hide fixed-bottom bg-primary-2 z-top text-white q-pa-lg"
    v-if="visible"
  >
    <div class="row q-col-gutter-md">
      <div class="col-12 col-md-10">
        <h2 class="text-white q-mb-sm">Vi använder cookies</h2>
        <p>
          Vi använder Cookies för att optimera din upplevelse, för mer information om hur vi behandlar dina
          personuppgifter se vår
          <a class="text-white" href="https://www.kaptena.se/cookies-gdpr/"><u>integritetspolicy</u></a
          >.
        </p>
      </div>
      <div class="col-12 col-md-2 flex items-center">
        <q-btn class="test-cookies" label="Acceptera cookies" @click="hide" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { Cookies } from 'quasar';

@Component
export default class CookiesBanner extends Vue {
  visible = false;

  mounted() {
    if (!Cookies.has('cookies_accepted')) {
      this.visible = true;
    }
  }

  hide() {
    Cookies.set('cookies_accepted', '1', { expires: 9999 });
    this.visible = false;
  }
}
</script>
