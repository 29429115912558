import { Model } from '../base';
import { Entity, Instrument } from '@/models';
import { groupBy, sumBy } from 'lodash';

class CustodianHolding extends Model {
  static storeModel = false;
  static define = () => ({
    owner: { model: Entity },
    investor: { model: Entity },
    instrument: { model: Instrument },
  });
  owner: Entity;
  investor: Entity;
  instrument: Instrument;
  custodianAccountNumber: string;
  quantity: number;
  amount: number;
}

export default class CustodianHoldings extends Model {
  static storeModel = false;
  static define = () => ({
    custodian: { model: Entity },
    holdings: {
      embeddedModel: [CustodianHolding],
    },
  });
  custodian: Entity;
  holdings: CustodianHolding[];
  settleDate: Date;

  getGrouping({
    filter = () => true,
    property,
  }: {
    filter?: (CustodianHolding) => boolean;
    property: 'custodianAccountNumber' | 'entity' | 'instrument';
  }): { holder: any; quantity: number; amount: number; holdings: CustodianHolding[] }[] {
    const filtered = Object.values(this.holdings).filter(filter);
    const propertyPath = ['entity', 'instrument'].includes(property) ? `${property}.id` : property;
    const groups = groupBy(filtered, propertyPath);

    return Object.values(groups).map(([item, ...rest]) => {
      return {
        holder: item[property],
        quantity: sumBy([item, ...rest], 'quantity'),
        amount: sumBy([item, ...rest], 'amount'),
        holdings: [item, ...rest],
      };
    });
  }
}
