<template>
  <q-page v-if="ready" class="RightsIssuePage">
    <h1>{{ instruction.processTitle }}</h1>
    <Stepper pageType="rightsIssue" :currentstep="1" :isDone="isDone" :urlId="instruction.id" v-if="isEditable" />
    <q-separator />
    <PageBanner page="showRightsIssue" v-if="isEditable">
      <h4>En nyemission som drar ut på tiden?</h4>

      <p class="p2">
        Ingen fara! All data som du fyller i sparas automatiskt hela tiden så oavsett om det tar timmar eller veckor
        finns din data sparad här nästa gång du loggar in.
      </p>
    </PageBanner>
    <h4 class="table-header">Skapa emission</h4>
    <q-form class="" ref="form">
      <template v-if="isIssueShare">
        <IssueShareContent
          :instruction="instruction"
          :shareRegister="shareRegister"
          @change="handleIssueShareContentChange"
          :readonly="!isEditable"
        />
      </template>
      <template v-else>
        <TransactionsDetails
          :shareRegister="shareRegister"
          :instruction="instruction"
          @change="handleChange(true)"
          :readonly="!isEditable"
          :validator="validator"
        />
        <div v-if="instruction.warning" class="warning-text q-mt-lg">
          <p class="q-pl-md p2">Det finns pågående ägarbyten med införd i aktieboken efter avstämningsdagen.</p>
        </div>

        <InstrumentSourceTable
          :shareRegister="shareRegister"
          :instruction="instruction"
          :validator="validator"
          :readonly="!isEditable"
          :isSaving="isSaving"
          @change="handleChange(true)"
        />
        <div class="table-header">
          <h4>Teckna emission</h4>
          <ExportLinks>
            <ExportExcel :exporter="excelExporter" />
          </ExportLinks>
        </div>
        <DestinationsTableCard
          :shareRegister="shareRegister"
          v-if="shareRegister"
          :instruction="instruction"
          :validator="validator"
          :readonly="!isEditable"
          :disableAdd="!isEditable || hasUnsavedChanges"
          @change="handleChange(true)"
          @clear="clear"
        />
      </template>
      <InstructionSaveRow
        v-if="isEditable"
        :disable="!isDone"
        :is-saving="isSaving"
        :has-unsaved-changes="hasUnsavedChanges"
        @proceed="proceed"
      />
    </q-form>
  </q-page>
</template>
<script lang="ts">
import { Component, Ref, Vue, Watch } from 'vue-facing-decorator';
import { Instruction, ShareRegister, TransactionType } from '@/models';
import Stepper from '@/components/Stepper.vue';
import PageBanner from '@/components/PageBanner.vue';
import TransactionsDetails from './TransactionsDetails.vue';
import InstrumentSourceTable from './InstrumentSourceTable.vue';

import DestinationsTableCard from './DestinationsTableCard.vue';
import { makeShareRegisterValidation } from '@shared/web/models/shareRegister/shareRegisterService';
import {
  findInstruction,
  getInstructionDefaults,
  makeInstructionValidator,
  saveInstruction,
} from '@/models/instruction/instructionService';
import ExportExcel from '@shared/views/excel/ExportExcel.vue';
import createExcelExporter from '@/views/excel/rightsIssueExport';
import ExportLinks from '@shared/components/ExportLinks.vue';
import IssueShareContent from './issueShare/IssueShareContent.vue';
import { looksLikeId } from '@shared/utils';
import { Entity } from '@shared/web/models';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { transactionTypes } from '@shared/common';
import { cloneDeep, debounce, isEqual } from 'lodash';
import InstructionSaveRow from '@/components/InstructionSaveRow.vue';
import { isToNewPage } from '@/utils';
@Component({
  components: {
    InstructionSaveRow,
    Stepper,
    PageBanner,
    TransactionsDetails,
    InstrumentSourceTable,
    DestinationsTableCard,
    ExportLinks,
    ExportExcel,
    IssueShareContent,
  },
})
export default class RightsIssuePage extends Vue {
  debouncedSave = null;
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;
  savedInstruction: Instruction = null;
  entity: Entity = null;
  isSaving = false;
  hasUnsavedChanges = false;
  @Ref() readonly form;

  get isNew() {
    return !looksLikeId(this.$route.params.id);
  }

  async created() {
    this.debouncedSave = debounce(async (syncFromBackend = false) => {
      await this.save(syncFromBackend);
    }, 1000);
    await this.loadData();
  }

  handleChange(reSync = false) {
    this.hasUnsavedChanges = this.hasInstructionChanged();
    this.debouncedSave(reSync);
  }

  hasInstructionChanged() {
    return isEqual(this.instruction, this.savedInstruction) === false;
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const idOrType = this.$route.params.id;
    this.entity = await findEntityWithRouteParams(this.$route.params);

    const { shareRegister, instruction } = this.isNew
      ? await getInstructionDefaults({
          type:
            idOrType === transactionTypes.RIGHTS_ISSUE.toLowerCase().replace(/\s/gi, '-')
              ? TransactionType.RIGHTS_ISSUE
              : TransactionType.ISSUE_SHARE,
          entity: this.entity.id,
        })
      : await findInstruction(idOrType);

    Object.assign(this, { shareRegister, instruction });
  }

  get isEditable() {
    return this.isNew || this.instruction.isEditable;
  }

  get isIssueShare() {
    return this.instruction.type === TransactionType.ISSUE_SHARE;
  }

  async save(syncFromBackend = false) {
    this.isSaving = true;
    const savedInstruction = (await saveInstruction(this.instruction)).model;
    if (syncFromBackend) {
      this.instruction = savedInstruction;
    } else if (!this.instruction.id) {
      this.instruction.id = savedInstruction.id;
    }
    this.savedInstruction = cloneDeep(savedInstruction);
    this.isSaving = false;
    this.hasUnsavedChanges = false;
  }

  get validator() {
    return this.ready && makeInstructionValidator(this.instruction, this.shareRegister);
  }

  get shareRegisterValidation() {
    return makeShareRegisterValidation(this.shareRegister);
  }

  get isDone() {
    return this.validator.isPreValid();
  }

  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }

  get excelExporter() {
    return createExcelExporter(this.shareRegister, this.instruction);
  }

  clear() {
    this.instruction.destinations = [];
  }

  handleIssueShareContentChange(reSync = false) {
    this.debouncedSave(reSync === true);
  }

  async proceed() {
    await this.save(true);
    const valid = await this.form.validate();
    if (!valid) {
      return false;
    }
    await this.$router.push({
      path: `${this.instruction.entity.baseUrl}/nyemission/${this.instruction.id}/dokument`,
    });
  }
}
</script>
<style lang="scss">
.RightsIssuePage {
  .warning-text {
    height: 72px;
    background-color: white;
    p {
      line-height: 72px;
    }
  }
  .table-header {
    display: flex;
    justify-content: space-between;
    align-content: flex-end;
    h4 {
      margin-block-end: 6px;
    }
  }
}
</style>
