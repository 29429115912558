<template>
  <q-page v-if="ready" class="UserTransactionsPage">
    <h1>Transaktioner</h1>
    <UserTransactionSearch :searchQuery="searchQuery" :filters="filters" @change="change" />
    <ExportLinks>
      <ExportExcel :exporter="excelExporter" />
    </ExportLinks>
    <UserTransactionsTable :transactions="transactions" />
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { findUserTransactions } from '@/models/userInvestments/userInvestmentsService';
import UserTransactionsTable from './UserTransactionsTable.vue';
import TransactionSearchQuery from '@shared/web/models/transaction/TransactionSearchQuery';
import TransactionFilters from '@/models/transaction/TransactionFilters';
import UserTransactionSearch from './UserTransactionsSearch.vue';
import ExportLinks from '@shared/components/ExportLinks.vue';
import ExportExcel from '@shared/views/excel/ExportExcel.vue';
import exportUserTransactionsToExcel from '@/views/excel/userTransactionsExport';
import { mergeTransactions, ExtendedTransaction } from '@shared/web/models/transaction/utils';
import { store } from '@/store';

@Component({
  components: { UserTransactionsTable, UserTransactionSearch, ExportLinks, ExportExcel },
})
export default class UserTransactionsPage extends Vue {
  transactions: Array<ExtendedTransaction> = null;
  searchQuery: TransactionSearchQuery;
  filters: TransactionFilters;
  async created() {
    await this.loadData();
  }

  async loadData() {
    this.searchQuery = TransactionSearchQuery.newModel({});
    this.transactions = mergeTransactions(await findUserTransactions(this.searchQuery));
    this.filters = new TransactionFilters(this.transactions);
  }

  async change(updatedQuery: TransactionSearchQuery) {
    this.searchQuery = updatedQuery;
    this.transactions = mergeTransactions(await findUserTransactions(this.searchQuery));
  }

  get ready() {
    return this.transactions != null && this.filters != null;
  }

  get excelExporter() {
    return exportUserTransactionsToExcel(store.profile.entity, this.transactions);
  }
}
</script>
<style lang="scss">
.UserTransactionsPage {
  h1 {
    margin-bottom: 40px;
  }
}
</style>
