<template>
  <q-dialog ref="dialog" persistent>
    <div class="q-dialog-plugin EditRoleModal" @hide="hide" v-if="person">
      <div class="modal-header">
        <h2>{{ person.viewName }}</h2>
        <q-btn v-if="!saving" flat round size="sm" icon="close" @click="hide"></q-btn>
        <q-spinner v-if="saving" size="16" />
      </div>
      <section class="info">
        <div class="row q-gutter-x-md">
          <q-input
            label="Land"
            class="col-1"
            readonly
            v-bind="$defs.input"
            id="land"
            :modelValue="person.countryCode"
          />
          <q-input
            label="Personnummer"
            class="col"
            readonly
            v-bind="$defs.input"
            id="personnummer"
            :modelValue="nationalId"
          />
        </div>
        <div class="row primary q-gutter-x-md">
          <q-input readonly class="col col-1" v-bind="$defs.input" label="Land" :modelValue="address.countryCode" />
          <q-input readonly class="col" v-bind="$defs.input" label="Adress" :modelValue="address.address1" />
          <q-input
            v-if="address.address2"
            readonly
            class="col"
            v-bind="$defs.input"
            label="Adress 2"
            :modelValue="address.address2"
          />
          <q-input readonly class="col" v-bind="$defs.input" label="Postnummer" :modelValue="address.zip" />
          <q-input readonly class="col" v-bind="$defs.input" label="Ort" :modelValue="address.city" />
        </div>
        <form>
          <div class="row q-gutter-x-md secondary" v-if="roleInfo">
            <span>
              <q-input
                :readonly="saving"
                class="col"
                v-bind="$defs.input"
                label="E-post"
                :rules="[() => emailValid || 'Ogiltig e-postadress']"
                v-model="roleInfoCopy.email"
              />
            </span>
            <q-input :readonly="saving" class="col" v-bind="$defs.input" label="Telefon" v-model="roleInfoCopy.phone" />
          </div>
          <div class="q-mt-sm">
            <h4>Roller</h4>
            <q-checkbox v-model="isInvestorManager" label="Investeringsansvarig" :disable="hasInvestorManagerRole" />
          </div>
          <div class="q-my-sm">
            <h4>E-postalternativ</h4>
            <q-checkbox v-model="sendMail" label="Skicka välkomstmail" />
            <q-checkbox
              v-if="roleInfo"
              :model-value="roleInfo.wantEmails !== false"
              @update:model-value="v => (roleInfo.wantEmails = v)"
              label="Skicka e-post notiser"
            />
          </div>
        </form>
      </section>
      <section>
        <div class="row q-gutter-x-md justify-end">
          <div class="col-2">
            <q-btn :disable="saving" @click="hide" flat>
              {{ dirty ? 'Avbryt' : 'Stäng' }}
            </q-btn>
          </div>
          <div class="col-2">
            <q-btn class="saveBtn" @click="saveChanges" :disable="!dirty || saving || !emailValid"> Spara</q-btn>
          </div>
        </div>
      </section>
    </div>
  </q-dialog>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { QMenu, QSeparator, QTable } from 'quasar';
import { DialogComponent } from '@shared/mixins';
import { Address, ContactInfo, Entity, IssuerInfo } from '@shared/web/models';
import { formatNationalId } from '@shared/common';
import { RoleType } from '@shared/models/types';
import { findIssuerInfo, saveIssuerInfo } from '@shared/services/issuerInfoService';
import { sendRoleWelcomeMail } from '@shared/services/mailService';
import isEmail from 'validator/lib/isEmail';
import { saveEntity } from '@shared/web/entity/entityService';

@Component({
  components: { QTable, QSeparator, QMenu },
  mixins: [DialogComponent],
})
export default class EditRoleModal extends Vue {
  @Prop() entity: Entity;
  @Prop() person: Entity;
  @Prop() type: RoleType;
  @Prop() roles: RoleType[];

  issuerInfo: IssuerInfo = null;
  roleInfo: ContactInfo = null;
  roleInfoCopy: ContactInfo = null;
  sendMail = false;
  saving = false;
  isInvestorManager: boolean = null;

  get emailValid() {
    const email = this.roleInfoCopy?.email;
    return (email && isEmail(email)) || !email;
  }

  @Watch('roleInfoCopy.email')
  onEmailUpdate() {
    return this.emailValid;
  }

  async mounted() {
    this.issuerInfo = await findIssuerInfo(this.entity);
    this.roleInfo = this.issuerInfo.getRoleInfo(this.person);
    this.roleInfoCopy = this.roleInfo.copy();
    this.isInvestorManager = this.hasInvestorManagerRole;
  }

  get hasInvestorManagerRole() {
    return this.roles.includes(RoleType.InvestorManager);
  }

  get address(): Address | object {
    return this.person?.addresses[0] || {};
  }

  get nationalId() {
    return formatNationalId(this.person);
  }

  get wantEmailsChanged() {
    return this.roleInfo?.wantEmails !== this.roleInfoCopy?.wantEmails;
  }

  get dirty() {
    if (this.gotInvestorManagerRole || this.lostInvestorManagerRole || this.wantEmailsChanged) {
      return true;
    }
    if (this.roleInfo && this.roleInfoCopy) {
      return !!['email', 'phone'].find(prop => {
        return !((this.roleInfo[prop] || null) == (this.roleInfoCopy[prop] || null));
      });
    }
    return false;
  }

  get gotInvestorManagerRole() {
    return !this.hasInvestorManagerRole && this.isInvestorManager;
  }

  get lostInvestorManagerRole() {
    return this.hasInvestorManagerRole && !this.isInvestorManager;
  }

  get investorManagerRoleChanged() {
    return this.gotInvestorManagerRole || this.lostInvestorManagerRole;
  }

  async saveInvestorManagerRole() {
    if (this.investorManagerRoleChanged) {
      this.entity.legalEntity.investorManager = this.gotInvestorManagerRole ? this.person : null;
      await saveEntity(this.entity);
    }
  }

  async saveChanges() {
    this.saving = true;
    try {
      const { issuerInfo, person, entity, sendMail, roleInfoCopy } = this;
      const { email, phone } = roleInfoCopy;
      Object.assign(this.roleInfo, { email, phone });
      await saveIssuerInfo(issuerInfo);
      await this.saveInvestorManagerRole();
      if (sendMail) {
        await sendRoleWelcomeMail(entity.id, person.id, email);
      }
      this.$emit('ok');
      this.hide();
      this.saving = false;
    } catch (e) {
      this.saving = false;
      throw e;
    }
  }
}
</script>
<style lang="scss">
.EditRoleModal.q-dialog-plugin {
  position: absolute;
  width: auto;
  max-width: 100vw !important;
  padding: 20px;
  background-color: white;

  .modal-header {
    justify-content: space-between;
    align-items: middle;
    display: flex;
  }

  .cursor-move {
    cursor: move;
  }

  *[readonly] {
    color: $grey-3;
    cursor: not-allowed !important;
  }

  h2 {
    padding-bottom: 8px;
    font-size: 24px;
  }

  section {
    padding: 0 16px;
    &.info {
      padding: 16px;
      background-color: $grey-1;
      margin-top: 0;
      margin-bottom: 24px;
      > .row {
        margin-bottom: 12px;
      }
    }
  }

  tr {
    overflow-y: scroll;
  }
}
</style>
