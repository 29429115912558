<template>
  <q-page v-if="ready" class="DocumentShareRegisterPage">
    <div class="row justify-between">
      <h1>Skapa aktiebok</h1>
      <LastEntityUpdate />
    </div>
    <Stepper pageType="shareRegister" :currentstep="2" :urlId="urlId" />
    <div class="q-py-md">
      <h4>Verifiera aktieboken</h4>
      <p class="p2">Bifoga ett eller flera signerade dokument i Pdf-format som verifierar aktiebolagets ägare.</p>
      <p class="p2">
        De dokument som bifogas ska kunna verifiera aktieboken och eventuella förbehåll och förvar som du har
        registrerat
      </p>
    </div>
    <q-separator />

    <UploadFileButton @click="uploadDocuments" class="q-mt-lg" />

    <InputFile :instruction="instruction" ref="inputFile" />
    <FileList class="q-mt-md" :parent="instruction" />
    <q-btn v-bind="$defs.btn" type="button" class="step-btn" :disable="!isDone" @click="proceed">Gå vidare</q-btn>
    <a-btn-link v-on:click="goBack" class="arrow icon-left">Gå tillbaka</a-btn-link>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { findShareRegisterByEntityId } from '@shared/web/models/shareRegister/shareRegisterService';
import { makeNewInstruction } from '@/models/instruction/instructionService';
import Stepper from '@/components/Stepper.vue';
import FileList from '@/views/file/FileList.vue';
import UploadFileButton from '@/views/file/UploadFileButton.vue';
import InputFile from '@/components/InputFile.vue';
import LastEntityUpdate from '@shared/components/LastEntityUpdate.vue';
import { Instruction, ShareRegister, TransactionType } from '@/models';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { isToNewPage } from '@/utils';
@Component({
  components: { Stepper, FileList, InputFile, LastEntityUpdate, UploadFileButton },
})
export default class DocumentShareRegisterPage extends Vue {
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;

  async created() {
    await this.loadData();
  }

  @Watch('instruction.files')
  async updateEntity() {
    await findEntityWithRouteParams(this.$route.params);
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const entity = await findEntityWithRouteParams(this.$route.params);
    this.shareRegister = await findShareRegisterByEntityId(entity);
    this.instruction = await makeNewInstruction({
      entity: this.shareRegister.entity,
      type: TransactionType.SHAREREGISTER_CREATED,
    });
  }

  uploadDocuments() {
    const componenet = this.$refs.inputFile as any;
    componenet.input.click();
  }

  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }

  get isDone() {
    return this.instruction.hasFiles;
  }

  get urlId() {
    return this.shareRegister.entity.countryCode + '/' + this.shareRegister.entity.nationalId;
  }

  proceed() {
    this.$router.push({
      path: `${this.shareRegister.entity.baseUrl}/skapa-aktiebok/granska`,
    });
  }
  goBack() {
    this.$router.push({
      path: `${this.shareRegister.entity.baseUrl}/skapa-aktiebok`,
    });
  }
}
</script>
