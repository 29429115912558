<template>
  <q-page class="GeneralMeetingPage">
    <div class="row justify-between">
      <h1>Bolagsstämma</h1>
    </div>
    <div
      v-if="shareRegister"
      class="relative-position q-col-gutter-md q-my-md"
      :style="loading ? 'max-height:400px;overflow:hidden;' : ''"
    >
      <GeneralMeetingView
        :shareRegister="shareRegister"
        :generalMeetingParticipation="generalMeetingParticipation"
        :readonly="true"
      />
      <q-inner-loading :showing="loading" />
    </div>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { Entity, InstrumentCategory, ShareRegister, GeneralMeetingParticipation } from '@/models';
import GeneralMeetingView from '@/views/generalMeeting/GeneralMeetingView.vue';

import { findShareRegister } from '@shared/web/models/shareRegister/shareRegisterService';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { isToNewPage } from '@/utils';
import {
  loadGeneralMeetingParticipation,
  saveGeneralMeetingParticipation,
} from '@/models/generalMeeting/generalMeetingParticipationService';

@Component({
  components: { GeneralMeetingView },
})
export default class GeneralMeeting extends Vue {
  shareRegister: ShareRegister = null;
  generalMeetingParticipation: GeneralMeetingParticipation;
  entity: Entity;
  settleDate: Date = new Date();
  loading = true;

  async created() {
    await this.loadData();
  }

  @Watch('generalMeetingParticipation', { deep: true })
  async onChange() {
    await saveGeneralMeetingParticipation(this.generalMeetingParticipation);
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }

    this.loading = true;
    this.entity = await findEntityWithRouteParams(this.$route.params);
    this.generalMeetingParticipation = await loadGeneralMeetingParticipation(this.entity);
    await this.loadShareRegister();
    this.loading = false;
  }

  async loadShareRegister() {
    this.shareRegister = await findShareRegister({
      entity: this.entity,
      settleDate: this.settleDate,
      instrumentCategory: InstrumentCategory.SHA,
    });
  }
}
</script>
