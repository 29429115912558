import { Model } from '../base';
import { Instrument } from '@shared/web/models';

export default class IssueShareData extends Model {
  underlyingInstrument: Instrument = null;
  name: string = null;
  totalQuantity: number = null;
  votingPower: number = null;
  interimShareKapID: string = null;

  static define = () => ({
    underlyingInstrument: {
      model: Instrument,
    },
  });
}
