import axios from 'axios';
import { Entity, EntityAdmin, User } from '@/models';
import { asModelId } from '@/utils';
import { modelHandler } from '../base';
import store from '@/store';
const baseUrl = '/webapi/entityadmin';
const { saveModel, findModel } = modelHandler(EntityAdmin, baseUrl);

export async function findEntityAdminsForEntity(entity: string | Entity) {
  const { data } = await axios.get(`${baseUrl}/forentity/${asModelId(entity)}`);

  return EntityAdmin.newModels(data, true);
}

export async function findEntityAdmin(id) {
  return findModel(id);
}

export function clearEntityAdminCache() {
  store.profile.myEntityAdmins = null;
}

export async function findCurrentEntityAdmin(entity: string | Entity): Promise<EntityAdmin> {
  if (!store.profile.myEntityAdmins) {
    store.profile.myEntityAdmins = await findEntityAdminsForEntity(entity);
  }
  const profile = store.profile;

  return store.profile.myEntityAdmins.find(entityAdmin => asModelId(entityAdmin.user) === asModelId(profile.user));
}

export async function findEntityAdminsForUser() {
  const { data } = await axios.get(`${baseUrl}`);
  return EntityAdmin.newModels(data, true);
}

export async function lookupUser(personalNumber: string) {
  const { data } = await axios.post(`${baseUrl}/lookup-user`, { personalNumber });
  return User.newModel(data, true);
}

export async function saveEntityAdmin(entityAdmin) {
  return saveModel(entityAdmin);
}

export async function deleteAdmin(entityAdmin) {
  const { id } = entityAdmin;
  return axios.delete('/webapi/entityadmin/' + id);
}

export async function isLimitedAccess(entity: string | Entity): Promise<boolean> {
  return (await findCurrentEntityAdmin(entity)).limitedAccess === true;
}

export async function isFullAccess(entity: string | Entity): Promise<boolean> {
  return (await isLimitedAccess(entity)) === false;
}
