<template>
  <div v-if="ready" class="RecentRightsIssueTable">
    <h4 class="table-header">Senaste bolagshändelser</h4>
    <q-markup-table flat class="standard-table position-table" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Införd i aktieboken</th>
          <th class="text-left">Händelse</th>
          <th class="text-right">Antal nya värdepapper</th>
          <th class="text-left">Status</th>
        </tr>
      </thead>
      <tbody v-if="hasOngoingRightsIssue" class="two-row">
        <template v-for="instruction in ongoingRightsIssue" :key="instruction.id">
          <RecentRightsIssueRow :instruction="instruction" />
        </template>
      </tbody>
    </q-markup-table>
    <p v-if="!hasOngoingRightsIssue" class="text-small">Det finns inga bolagshändelser</p>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import RecentRightsIssueRow from './RecentRightsIssueRow.vue';
import { Instruction, Entity, InstructionMainType } from '@/models';
import { findActiveInstructions } from '@/models/instruction/instructionService';

@Component({
  components: { RecentRightsIssueRow },
})
export default class RecentRightsIssueTable extends Vue {
  @Prop() entity: Entity;
  instructions: Array<Instruction> = null;

  async created() {
    await this.loadData();
  }

  @Watch('entity')
  async loadData() {
    this.instructions =
      this.entity.isKapclearIssuer &&
      (await findActiveInstructions(this.entity, InstructionMainType.CORPORATE_EVENT, null));
  }

  get hasRightsIssue() {
    return this.instructions.length !== 0;
  }

  get ongoingRightsIssue() {
    return this.instructions.filter(instruction => {
      return instruction.calculatedState !== 'EXECUTED';
    });
  }

  get hasOngoingRightsIssue() {
    return this.ongoingRightsIssue.length !== 0;
  }

  get ready() {
    return this.instructions != null;
  }
}
</script>
<style lang="scss">
.RecentRightsIssueTable {
  max-width: 100%;
  .q-table {
    max-width: 100%;
    width: $col-max;
  }
}
</style>
