<template>
  <OnboardingPage class="RegisterEntity test-register-entity">
    <div class="onboarding-box q-pa-xl q-mx-xl">
      <h2>Lägg till bolag</h2>
      <q-form @submit="register" class="q-gutter-md" ref="form">
        <q-input
          label="Organisationsnummer"
          input-class="register-entery"
          :modelValue="formattedNationalId"
          :rules="nationalIdRules"
          lazy-rules
          v-bind="$defs.input"
          @update:modelValue="inputNationalId"
          :hint="entity && entity.viewName"
          :error-message="errorMessage && errorMessage.text"
          :error="errorMessage != null"
        /><q-input
          label="E-post"
          v-bind="$defs.input"
          v-model="userInfo.email"
          :rules="validation.requiredEmail"
          lazy-rules
        ></q-input>
        <q-input
          label="Telefon"
          v-bind="$defs.input"
          v-model="userInfo.phone"
          :rules="validation.required('Telefon')"
        ></q-input>
        <q-btn
          label="Lägg till bolag i Kapclear"
          class="test-register"
          type="submit"
          v-bind="$defs.btn"
          :disable="!isDone"
          :loading="loading"
        />
      </q-form>
    </div>
  </OnboardingPage>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { FormComponent } from '@shared/mixins';
import { registerEntity, lookupEntity } from '@/models/entity/entityService';
import { Entity } from '@/models';
import { formatNationalId } from '@shared/common';
import OnboardingPage from '@/views/OnboardingPage.vue';
import { validation } from '@shared/common';
import { required } from '@shared/utils/validation';

@Component({
  components: { OnboardingPage },
  mixins: [FormComponent],
})
export default class RegisterEntity extends Vue {
  nationalId: string = null;
  entity: Entity = null;
  consent = false;
  userInfo = {
    email: null,
    phone: null,
  };

  mounted() {
    const { nationalId } = this.$route.params;
    if (nationalId) {
      this.inputNationalId(nationalId);
    }
  }

  get validation() {
    return validation;
  }

  get isDone() {
    return this.nationalId !== null && this.userInfo.email !== null && this.userInfo.phone !== null;
  }

  async inputNationalId(val) {
    val = val.replace(/\s/g, '');
    this.errorMessage = null;
    this.nationalId = val;
    val = cleanNationalId(val);
    if (val && val.length === 10) {
      try {
        this.entity = await lookupEntity({ nationalId: val, isPerson: false });
      } catch (error) {
        this.errorMessage = error;
      }
    } else {
      this.entity = null;
    }
  }

  async register() {
    await this.submitForm(async () => {
      if (!this.isDone) {
        return;
      }
      const entity = await registerEntity({
        nationalId: this.nationalId,
        email: this.userInfo.email,
        phone: this.userInfo.phone,
      });
      this.$router.push({ path: `${entity.baseUrl}/valj-typ` });
    });
  }

  get formattedNationalId() {
    return this.entity ? formatNationalId(this.entity) : this.nationalId;
  }

  get nationalIdRules() {
    return [required, val => cleanNationalId(val).length === 10 || 'Ogiltigt organisationsnummer'];
  }
}

function cleanNationalId(val) {
  return val.replace('-', '');
}
</script>
<style lang="scss">
.RegisterEntity {
  .onboarding-box {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }
  .q-form {
    min-height: 215px;
    .q-btn {
      width: 100%;
      .q-btn__wrapper {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
</style>
