/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import * as _ from 'lodash';
import Model from './Model';
import { initModel, newModel } from './initModel';
import { serialize, serializedChanges } from './serialize';
import { addModel, getBackup, getModel as getModelFromStore, removeModel } from '@shared/web/store';

export interface Update<T> {
  changes: any;
  model: T;
  backup?: any;
}

export default function <C extends typeof Model>(M: C, baseUrl: string) {
  type T = InstanceType<typeof M>;

  async function loadAll(url = baseUrl): Promise<Array<T>> {
    const { data } = await axios.get(url);
    return mapToModels(data);
  }

  async function searchModels(query): Promise<Array<T>> {
    const { data } = await axios.post(baseUrl + '/search', serialize(query));
    return mapToModels(data);
  }

  async function findModel(id, forceUpdate = false): Promise<T> {
    let model = getModelFromStore(id, M);
    if (model && !forceUpdate) {
      return model;
    }
    model = await loadModel(id);
    addModel(model);
    return model;
  }

  async function findOneByQuery(query) {
    const { data } = await axios.post(baseUrl + '/findone', query);
    const model = mapToModel(data);
    addModel(model);
    return model;
  }

  async function loadModel(id): Promise<T> {
    const { data } = await axios.get(baseUrl + '/' + id);
    return mapToModel(data);
  }

  async function createModel(model): Promise<Update<T>> {
    const serialized = serialize(model);
    const { data } = await axios.post(baseUrl, serialized);
    return { model: mapToModel(data), changes: serialized, backup: {} };
  }

  async function updateWithChanges(original, changes): Promise<Update<T>> {
    const { data } = await axios.put(baseUrl, { id: original.id, ...changes });

    Object.assign(original, data);
    initModel(original, true);

    return { model: original, changes };
  }
  async function updateModel(model, original?: T): Promise<Update<T>> {
    const changes = serializedChanges(model, original);
    original = original || getModelFromStore(model.id, M);
    const backup = getBackup(model.id);

    if (_.isEmpty(changes)) {
      return {
        model,
        changes,
      };
    }

    const { data } = await axios.put(baseUrl, changes);

    Object.assign(original, data);
    initModel(original, true);

    return { model: original, changes, backup };
  }

  async function saveModel(model): Promise<Update<T>> {
    if (model.id == null) {
      return createModel(model);
    } else {
      return updateModel(model);
    }
  }

  async function destroyModel(model): Promise<Update<T>> {
    await axios.delete(baseUrl + '/' + model.id);
    removeModel(model);

    return { model, changes: {}, backup: getBackup(model.id) };
  }

  function mapToModel(data): T {
    return newModel(data, M, true) as T;
  }
  function mapToModels(data): Array<T> {
    return data.map(m => mapToModel(m));
  }
  async function getModel(id) {
    if (id == null) {
      return null;
    }
    const model = getModelFromStore(id, M);
    if (model) {
      return model;
    }
    return findModel(id);
  }

  return {
    loadAll,
    saveModel,
    destroyModel,
    searchModels,
    createModel,
    updateModel,
    findModel,
    loadModel,
    updateWithChanges,
    getModel,
    findOneByQuery,
  };
}
