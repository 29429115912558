<template>
  <q-page v-if="ready" class="CheckTradeEventPage">
    <div class="row justify-between">
      <h1>Ägarbyte</h1>
      <LastEntityUpdate :date="instruction.updatedAt" />
    </div>
    <Stepper pageType="tradeEvent" :currentstep="3" :isDone="isDone" :urlId="instruction.id" />
    <TransactionsDetails :shareRegister="shareRegister" :instruction="instruction" :readonly="true" />
    <SourceRow :shareRegister="shareRegister" :instruction="instruction" :readonly="true" />
    <InstructionDestinationTable
      :shareRegister="shareRegister"
      :instruction="instruction"
      :readonly="true"
      :validator="validator"
    />
    <q-btn v-bind="$defs.btn" type="button" class="step-btn test-register" :disable="!isDone" @click="proceed"
      >Registrera ägarbyte</q-btn
    >
    <a-btn-link v-on:click="goBack" class="arrow icon-left">Gå tillbaka</a-btn-link>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { ShareRegister, Instruction, InstructionState } from '@/models';
import TransactionsDetails from './TransactionsDetails.vue';
import InstructionDestinationTable from './InstructionDestinationTable.vue';
import Stepper from '@/components/Stepper.vue';
import SourceRow from './SourceRow.vue';

import LastEntityUpdate from '@shared/components/LastEntityUpdate.vue';
import { findInstruction, makeInstructionValidator, saveInstruction } from '@/models/instruction/instructionService';
import { isToNewPage } from '@/utils';
@Component({
  components: { TransactionsDetails, InstructionDestinationTable, LastEntityUpdate, Stepper, SourceRow },
})
export default class CheckTradeEventPage extends Vue {
  shareRegister: ShareRegister = null;
  instruction: Instruction = null;

  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    const { id } = this.$route.params;
    const { shareRegister, instruction } = await findInstruction(id);

    Object.assign(this, { shareRegister, instruction });
  }

  get isDone() {
    return true;
  }

  get ready() {
    return this.shareRegister != null && this.instruction != null;
  }
  get validator() {
    return this.shareRegister && makeInstructionValidator(this.instruction, this.shareRegister);
  }
  async proceed() {
    this.instruction.state = InstructionState.SUBMITTED;
    await saveInstruction(this.instruction);
    this.$router.push({
      path: `${this.instruction.entity.baseUrl}/agarbyte/${this.instruction.id}/skickain`,
    });
  }
  goBack() {
    this.$router.push({
      path: `${this.instruction.entity.baseUrl}/agarbyte/${this.instruction.id}/dokument`,
    });
  }
}
</script>
