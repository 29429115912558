<template>
  <q-page class="SelectEntityTypePage" v-if="ready">
    <h1>Välj typ av Emittent</h1>

    <div class="row q-col-gutter-x-xl">
      <router-link :to="`${entity.baseUrl}/skapa-aktiebok`" class="col-5">
        <q-card class="box" flat>
          <q-card-section>
            <h3>Skapa aktiebok</h3>
            <p>
              Skapa en fullständing aktiebok. Som fullvärdig aktiebokskund har du tillgång till alla delar av Kapclear.
            </p>
          </q-card-section>
        </q-card>
      </router-link>
      <router-link :to="`${entity.baseUrl}/optionsprogram`" class="col-5">
        <q-card class="box" flat @click="selectOnlyWarrantCompany">
          <q-card-section>
            <h3>Skapa teckningsoptionsprogram</h3>
            <p>
              Är du ett avstämningsbolag och har aktieboken hos en annan aktör kan du ändå hantera teckningsoptioner hos
              Kapclear.
            </p>
          </q-card-section>
        </q-card>
      </router-link>
    </div>
  </q-page>
</template>

<script lang="ts">
import { Entity } from '@/models';
import { saveEntity } from '@/models/entity/entityService';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import { isToNewPage } from '@/utils';
import { Component, Vue, Watch } from 'vue-facing-decorator';
@Component({})
export default class SelectEntityTypePage extends Vue {
  entity: Entity = null;

  async created() {
    await this.loadData();
  }

  @Watch('$route')
  async loadData(to?, from?) {
    if (isToNewPage(to, from)) {
      return;
    }
    this.entity = await findEntityWithRouteParams(this.$route.params);
  }

  async selectOnlyWarrantCompany() {
    this.entity.issuerData.onlyWarrantCompany = true;
    await saveEntity(this.entity);
  }

  get ready() {
    return this.entity != null;
  }
}
</script>
<style lang="scss">
.SelectEntityTypePage {
  padding: 48px 24px;
  h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: $grey-6;
    margin-bottom: 40px;
  }
  .row {
    margin-bottom: 24px;
    justify-content: center;
    a {
      text-decoration: none;
    }
  }
  .box {
    max-width: 400px;
    background: white;
    text-align: center;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $grey-6;
    text-decoration: none;
    border-bottom: 2px solid $grey-2;
    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: $grey-6;
      text-decoration: none;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $grey-6;
      padding: 8px 0;
      margin: 0;
      text-decoration: none;
    }
  }
}
</style>
