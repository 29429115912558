import { Model } from '../base';
import { Instrument } from '@shared/web/models';
import { IRightsData } from '@shared/models/modelTypes';

export default class RightsData extends Model implements IRightsData {
  underlyingInstrument: Instrument = null;
  underlyingInstrumentName?: string = null;
  contractSize: number = null;
  totalQuantity: number = null;
  exercisePrice: number = null;
  issueDate: Date = null;
  dueDate: Date = null;
  exercisePeriodFrom: Date = null;
  exercisePeriodTo: Date = null;
  premium: number;
  issued: boolean;

  /** conditional: Exclusive to shareholder contribution */
  conditional: boolean;

  /**
   * false - default, fördelas per st
   * true - fördelas med belopp (tex vid konvertibler)
   */
  distributeByValue?: boolean = false;

  /**
   * nominellt belopp, total emitterat
   */
  nominalAmountTotal?: number = null;

  /**
   * nominellt belopp/st (konvertibler)
   */
  nominalAmountPerPiece?: number = null;

  /**
   * oftast bara 'SEK'
   */
  currency?: string = null;

  /**
   * fritext, kan vara tex: info om ränta
   */
  note?: string = null;

  /**
   * namn (tex för obligationer)
   */
  name?: string = null;

  static define = () => ({
    underlyingInstrument: {
      model: Instrument,
    },
    issueDate: {
      type: 'date',
    },
    dueDate: {
      type: 'date',
    },
    exercisePeriodFrom: {
      type: 'date',
    },
    exercisePeriodTo: {
      type: 'date',
    },
  });

  isValueDistribution() {
    return this.distributeByValue;
  }

  get shareQuantity() {
    const { totalQuantity, contractSize } = this;
    return totalQuantity * contractSize;
  }
}
