<template>
  <q-dialog ref="dialog" persistent>
    <div class="q-dialog-plugin ConfirmModal">
      <a-page-section class="padded">
        <div class="q-pb-md">
          {{ confirmText }}
        </div>
        <div>
          <a-input-date v-model="date" :label="dateLabel" v-bind="$defs.input" />
        </div>
      </a-page-section>

      <q-card-actions align="right" class="q-pa-md">
        <q-btn v-bind="$defs.btn" flat @click="cancel">Avbryt</q-btn>
        <q-btn type="submit" v-bind="$defs.btn" @click="confirm"> Spara </q-btn>
      </q-card-actions>
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { DialogComponent } from '@shared/mixins';

@Component({
  mixins: [DialogComponent],
})
export default class ConfirmPositionWithTransactionModal extends Vue {
  @Prop() onConfirm: Function;
  @Prop() onReject: Function;

  @Prop() confirmText: String;
  @Prop() dateLabel: String;

  date = new Date();

  cancel() {
    this.onReject && this.onReject();
    this.$emit('hide');
  }

  async confirm() {
    if (this.onConfirm) {
      await this.onConfirm(this.date);
    }

    this.done();
  }
}
</script>
