/* eslint-disable @typescript-eslint/no-use-before-define */
import { IPosition, IInstrument, IEntity } from '../modelTypes';
import * as _ from 'lodash';
import { InstrumentCategory, InstrumentGroup } from '../types';
import errors from './errors';
import { sumBy } from 'lodash';
import { getHistoricIssuerData, getHistoricShareData } from '@shared/models/historicShareData';

export default function shareRegisterValidator(
  entity: IEntity,
  instruments: Array<IInstrument>,
  positions: Array<IPosition>,
  isSameModel = (a, b) => a === b,
  settleDate: Date = null,
  skipIssuerDataValidation = false,
) {
  const issuerData = entity.issuerData;
  const historicIssuerData = getHistoricIssuerData(instruments, settleDate);
  const shares = instruments.filter(instr => instr.category === InstrumentCategory.SHA);
  const issuerDataTotalQuantity = historicIssuerData.totalQuantity;
  const totalVotingPower = _.sumBy(
    shares,
    s => s.shareData?.votingPower * getHistoricShareData(s.shareData, settleDate).totalQuantity,
  );
  const totalNumberSerie = issuerData.hasShareNumbers && makeTotalNumberSerie(positions);

  function instrumentVotingPowerPerShare(instrument) {
    return instrument.shareData?.votingPower / totalVotingPower;
  }

  function forEntity() {
    const sumTotals = (total, instrument) => {
      return {
        totalQuantity: total.totalQuantity + instrument.shareData.totalQuantity,
        totalCapital: total.totalCapital + instrument.shareData.totalCapital,
      };
    };

    const result = shares.reduce(sumTotals, {
      totalCapital: 0,
      totalQuantity: 0,
    });

    const resultNonPaySha = shares
      .filter(instrument => instrument.group !== InstrumentGroup.PAYSHA)
      .reduce(sumTotals, {
        totalCapital: 0,
        totalQuantity: 0,
      });

    result.allocatedQuantity = _.sumBy(shares, share => forInstrument(share).allocatedQuantity);
    result.valid = skipIssuerDataValidation ? true : result.totalQuantity === issuerData.totalQuantity;
    result.validNonPaySha = skipIssuerDataValidation
      ? true
      : resultNonPaySha.totalQuantity === issuerData.totalQuantity;
    result.validAllocatedQuantity = skipIssuerDataValidation ? true : result.allocatedQuantity === result.totalQuantity;
    return {
      ...result,
      totalVotingPower,
    };
  }

  function forInstrument(instrument: IInstrument) {
    const instrumentPositions = positions.filter(pos => isSameModel(pos.instrument, instrument));
    const totalQuantity = _.sumBy(instrumentPositions, pos => pos.quantity);
    const historicQuantity = getHistoricShareData(instrument.shareData, settleDate)?.totalQuantity;
    const positionsValid = skipIssuerDataValidation || totalQuantity === instrument.shareData?.totalQuantity;

    return {
      totalQuantity,
      positionsValid,
      allocatedQuantity: totalQuantity,
      votingPowerQuota: instrumentVotingPowerPerShare(instrument) * historicQuantity,
      totalShare: totalQuantity / historicIssuerData.totalQuantity,
    };
  }

  function forOwner(owner: IEntity) {
    const ownerPositions = positions.filter(pos => isSameModel(pos.owner, owner));
    const totalQuantity = _.sumBy(ownerPositions, pos => pos.quantity);
    const votingPower = _.sumBy(ownerPositions, pos => {
      return instrumentVotingPowerPerShare(pos.instrument) * pos.quantity;
    });
    return {
      totalQuantity,
      allocatedQuantity: totalQuantity,
      votingPower,
      totalShare: totalQuantity / issuerDataTotalQuantity,
    };
  }

  function getPositionShareQuantity(pos: IPosition): number {
    const { quantity, instrument } = pos;
    if (instrument.group === InstrumentGroup.WARRANT) {
      return quantity * instrument.rightsData.contractSize;
    }
    return pos.quantity;
  }

  function getPositionShareVotingPower(pos: IPosition): number {
    const { quantity, instrument } = pos;
    if (instrument.group === InstrumentGroup.WARRANT) {
      return (
        instrumentVotingPowerPerShare(instrument.rightsData.underlyingInstrument) *
        quantity *
        instrument.rightsData.contractSize
      );
    }
    return instrumentVotingPowerPerShare(instrument) * quantity;
  }

  function calculateTotalQuantity(positions: IPosition[]) {
    return sumBy(positions, getPositionShareQuantity);
  }

  function calculateTotalVotingPower(positions: IPosition[]) {
    return sumBy(positions, getPositionShareVotingPower);
  }

  function getInstrumentQuotaValue(instrument: IInstrument) {
    if (instrument.category === InstrumentCategory.SHA) {
      return instrument.shareData.totalCapital / instrument.shareData.totalQuantity;
    }
  }

  function getPositionCapital(pos: IPosition): number {
    const { quantity, instrument } = pos;
    if (instrument.group === InstrumentGroup.WARRANT) {
      return (
        getInstrumentQuotaValue(instrument.rightsData.underlyingInstrument) *
        instrument.rightsData.contractSize *
        quantity
      );
    }
    return quantity * getInstrumentQuotaValue(instrument);
  }

  function calculateTotalCapital(positions: IPosition[]) {
    return sumBy(positions, getPositionCapital);
  }

  function forAbsoluteInvestorManager(investorManager: IEntity) {
    const investorPositions = positions.filter(pos => isSameModel(pos.absoluteInvestorManager, investorManager));
    const totalQuantity = _.sumBy(investorPositions, getPositionShareQuantity);
    const votingPower = _.sumBy(investorPositions, getPositionShareVotingPower);
    return {
      totalQuantity,
      allocatedQuantity: totalQuantity,
      votingPower: votingPower / calculateTotalVotingPower(positions),
      totalShare: totalQuantity / calculateTotalQuantity(positions),
    };
  }

  function forPositionFullyConverted(position: IPosition) {
    return {
      shareQuantity: getPositionShareQuantity(position),
      quantityQuota: getPositionShareQuantity(position) / calculateTotalQuantity(positions),
      capital: getPositionCapital(position) / calculateTotalCapital(positions),
      votingPowerQuota: getPositionShareVotingPower(position) / calculateTotalVotingPower(positions),
    };
  }

  function getInstrumentMap() {
    const map = new Map();
    shares.map(share => map.set(share, forInstrument(share)));
    return map;
  }

  function getPositionMap() {
    const map = new Map();
    positions.map(position => map.set(position, forPosition(position)));
    return map;
  }

  function hasDuplicatePosition(position: IPosition) {
    return _.some(positions, pos => {
      if (position === pos) {
        return false;
      }
      return (
        pos.instrument === position.instrument &&
        pos.owner === position.owner &&
        pos.investor === position.investor &&
        pos.insuranceNumber === position.insuranceNumber
      );
    });
  }

  function forPosition(position: IPosition) {
    let error, numberSerieError;
    if (issuerData.hasShareNumbers) {
      const { shareNumberFrom, shareNumberTo } = position;
      if (!_.isNumber(shareNumberTo) || !_.isNumber(shareNumberFrom)) {
        numberSerieError = errors.missingShareNumbers;
      } else if (shareNumberFrom > shareNumberTo) {
        numberSerieError = errors.invalidShareNumbers;
      } else if (position.quantity !== shareNumberTo - shareNumberFrom + 1) {
        numberSerieError = errors.invalidCount;
      } else if (totalNumberSerie && totalNumberSerie.hasOverlap(position)) {
        numberSerieError = errors.overlap;
      }
    } else {
      if (hasDuplicatePosition(position)) {
        error = errors.duplicatePosition;
      }
    }
    return {
      error,
      numberSerieError,
      valid: error == null && numberSerieError == null,
      quantityQuota: position.quantity / issuerDataTotalQuantity,
      capital: (position.quantity * issuerData.totalCapital) / issuerDataTotalQuantity,
      votingPowerQuota: position.quantity * instrumentVotingPowerPerShare(position.instrument),
    };
  }

  function isInvalidShares() {
    return shares.reduce((invalid, instrument) => invalid || !forInstrument(instrument).positionsValid, false)
      ? errors.invalidShares
      : false;
  }
  function isInvalidPositions() {
    return positions.reduce((invalid, position) => invalid || !forPosition(position).valid, false)
      ? errors.invalidPositions
      : false;
  }

  function isValidNonPaySha() {
    const invalid = isInvalidShares() || isInvalidPositions() || !forEntity().validNonPaySha;
    return !invalid;
  }

  function error() {
    const error =
      isInvalidShares() || isInvalidPositions() || (!forEntity().valid ? errors.failedEntityValidation : null);

    return error;
  }

  function isValid() {
    return !error();
  }

  return {
    forEntity,
    forOwner,
    forAbsoluteInvestorManager,
    forInstrument,
    forPosition,
    forPositionFullyConverted,
    getInstrumentMap,
    getPositionMap,
    isInvalidShares,
    isInvalidPositions,
    isValid,
    isValidNonPaySha,
    error,
  };
}

function shareNumberFromSorter(pos1, pos2) {
  return pos1.shareNumberFrom - pos2.shareNumberFrom;
}

function makeTotalNumberSerie(positions: Array<IPosition>) {
  const positionsSorted = positions.filter(pos => pos.shareNumberFrom != null && pos.shareNumberTo != null);
  positionsSorted.sort(shareNumberFromSorter);
  return {
    hasOverlap(position: IPosition) {
      const shareNumberIndex = _.indexOf(positionsSorted, position);
      const prev = positionsSorted[shareNumberIndex - 1];
      const next = positionsSorted[shareNumberIndex + 1];

      const validFrom = !prev || position.shareNumberFrom > prev.shareNumberTo;
      const validTo = !next || position.shareNumberTo < next.shareNumberFrom;
      return !(validTo && validFrom);
    },
  };
}
