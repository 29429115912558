<template>
  <div class="distribution-details row q-col-gutter-md items-end">
    <label class="col-12 col-md-3">
      <div>Typ</div>
      <div>
        <q-select
          emit-value
          map-options
          v-model="rightsData.distributeByValue"
          @update:modelValue="$emit('change')"
          :options="[
            { value: false, label: 'Antal' },
            { value: true, label: 'Nom.belopp' },
          ]"
          :modelValue="true"
          v-bind="$defs.input"
          :disable="readonly"
        />
      </div>
    </label>
    <label class="col-4 col-md-3" v-if="!rightsData.distributeByValue">
      <div>Totalt tecknat antal</div>
      <div>
        <NumberInput
          v-model="rightsData.totalQuantity"
          v-bind="$defs.input"
          class="number"
          :disable="readonly"
          @change="$emit('change')"
          @update:modelValue="$emit('input')"
          input-class="test-totalQuantity"
        />
      </div>
    </label>
    <label class="col-4 col-md-3" v-if="!rightsData.distributeByValue">
      <div>Nom.bel/st</div>
      <div>
        <NumberInput
          v-model="rightsData.nominalAmountPerPiece"
          v-bind="$defs.input"
          class="number"
          :disable="readonly"
          @change="$emit('change')"
          @update:modelValue="$emit('input')"
          input-class="test-nominalAmountPerPiece"
        />
      </div>
    </label>
    <label :class="rightsData.distributeByValue ? 'col-12 col-md-3' : 'col-4 col-md-3'">
      <div>Totalt tecknat belopp</div>
      <div>
        <NumberInput
          v-model="rightsData.nominalAmountTotal"
          v-bind="$defs.input"
          class="number"
          :disable="!isNominalAmountTotalEditable"
          @change="$emit('change')"
          @update:modelValue="$emit('input')"
          input-class="test-nominalAmountTotal"
        />
      </div>
    </label>
  </div>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { RightsData } from '../web/models';
import NumberInput from './NumberInput.vue';

@Component({
  components: { NumberInput },
})
export default class DistributionDetails extends Vue {
  @Prop({ required: true }) rightsData: RightsData;
  @Prop({ default: false }) readonly;
  @Prop({ default: 'row' }) layout;

  @Watch('rightsData.distributeByValue')
  @Watch('rightsData.totalQuantity')
  @Watch('rightsData.nominalAmountPerPiece')
  calcNominalAmountTotal() {
    if (!this.rightsData.distributeByValue) {
      this.rightsData.nominalAmountTotal = this.rightsData.totalQuantity * this.rightsData.nominalAmountPerPiece;
    }
  }

  get isNominalAmountTotalEditable() {
    return !this.readonly && this.rightsData.distributeByValue;
  }
}
</script>
