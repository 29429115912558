<template>
  <q-form @submit="sign" class="SignBankID">
    <div class="header">
      <h2 class="text-black q-mt-lg q-mb-lg text-center">BankID Signering</h2>
    </div>
    <q-btn class="closebtn" flat round size="sm" icon="close" @click="hide"></q-btn>
    <div v-if="mobileBankIdMethod" class="center">
      <img v-if="!errorMessage" class="qrImage" ref="qrImage" :src="qrImage" />
    </div>
    <div v-if="errorMessage !== null" class="center">
      <p class="error-text">{{ errorMessage }}</p>
      <q-btn flat class="q-mt-sm center" @click="refreshMobileBankID" label="Försök igen" />
    </div>
    <p v-else class="center">{{ methodInfo.text }}</p>
    <q-btn class="sign-method" color="primary" @click="switchMethod" :disabled="bankidSameUnitIsLodading">
      <img src="@/assets/bankid.png" alt="BankID Icon" class="q-mr-sm" :label="methodInfo.btnLabel" />
      <q-inner-loading :showing="bankidSameUnitIsLodading" />
      <span v-if="methodInfo.btnLabel">{{ mobileBankIdMethod ? 'BankID på samma enhet' : ' Mobilt BankID' }}</span>
    </q-btn>
  </q-form>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { sign, getStatus } from '@/models/auth/bankIdService';

@Component({
  components: {},
})
export default class SignBankID extends Vue {
  @Prop() onSign: Function;
  @Prop() type: string;
  @Prop() payload?: Record<string, unknown>;
  status = null;
  orderRef = null;
  loading = false;
  errorMessage = null;
  mobileBankIdMethod = true;
  bankidSameUnitIsLodading: boolean = false;
  qrImage = null;

  mounted() {
    this.sign();
  }
  refreshMobileBankID() {
    this.errorMessage = null;
    this.sign();
  }
  async renderQRCode(qrImageUrl) {
    const imageEl: Partial<HTMLImageElement> = this.$refs.qrImage;
    if (!this.qrImage) imageEl.src = qrImageUrl;
    this.qrImage = qrImageUrl;
  }

  async signMethod(signResponse) {
    if (this.mobileBankIdMethod) {
      this.bankidSameUnitIsLodading = false;
      await this.renderQRCode(signResponse.qrImage);
    } else {
      this.bankidSameUnitIsLodading = true;
      return (window.location.href = `bankid:///?autostarttoken=${signResponse.autoStartToken}&redirect=null`);
    }
  }

  async sign() {
    this.errorMessage = null;
    try {
      const signResponse = await sign(this.type, this.payload);
      this.status = signResponse.status;
      this.orderRef = signResponse.orderRef;
      this.qrImage = signResponse.qrImage;
      await this.signMethod(signResponse);
    } catch (error) {
      this.loading = false;
      this.errorMessage = error.text;
    }

    if (this.status === 'pending') {
      this.setStatus();
    }
  }

  async setStatus() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    let count = 0;
    try {
      while (this.status === 'pending') {
        await new Promise(resolve => {
          this.renderQRCode(`${this.qrImage.split('?')[0]}?${count++}`);
          setTimeout(resolve, 1000);
        });
        const logInStatus = await getStatus(this.orderRef);
        this.status = logInStatus.status;
      }
    } catch (error) {
      this.bankidSameUnitIsLodading = false;
      this.errorMessage = error.text;
    }

    if (this.status === 'complete') {
      await this.onSign();
      this.$emit('closeModal');
    }
    this.loading = false;
  }

  hide() {
    this.$emit('closeModal');
  }

  get methodInfo() {
    return this.mobileBankIdMethod
      ? {
          text: 'Starta BankID och skanna QR-koden',
          btnLabel: 'Jag har BankID på samma enhet',
        }
      : {
          text: 'Starta BankID-appen.',
          btnLabel: 'Jag har BankID på en annan enhet',
        };
  }

  switchMethod() {
    this.mobileBankIdMethod = !this.mobileBankIdMethod;
    this.sign();
  }
}
</script>
<style lang="scss">
.SignBankID {
  display: grid;
  padding: 2rem;
  gap: 1em;
  min-height: 460px;
  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    justify-items: stretch;
    h2 {
      margin-top: 0;
    }
  }
  .qrImage {
    width: 200px;
    min-height: 200px;
  }
  .center {
    text-align: center;
  }
  .sign-method {
    color: $secondary;
    font-size: 12px;
    margin: 0 auto;
    display: block;
    font-weight: 400;
    align-self: end;
    justify-self: center;
    width: 100%;

    .q-btn__wrapper {
      padding: 0;
    }
  }
}
</style>
