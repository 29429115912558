<template>
  <tr class="large test-transaction-row">
    <td class="text-left"></td>
    <td class="text-left">
      <a-btn-link v-if="eventLink" :to="eventLink">{{ typeLabel }}</a-btn-link>
      <span v-else>{{ typeLabel }}</span>
    </td>
    <td class="wrap-text">{{ transaction.previousOwner && transaction.previousOwner.viewName }}</td>
    <td class="wrap-text">{{ transaction.owner.viewName }}</td>
    <td class="text-left">{{ formatDay(transaction.tradeDate) }}</td>
    <td class="text-left">{{ formatDay(transaction.settleDate) }}</td>
    <td class="text-right">
      <span class="text-right" v-if="transaction.quantity">{{ formatNumber(transaction.quantity) }}</span>
      <span class="text-right" v-else>(1)</span>
      <a-info-btn v-if="isDistributedByValue">värdepapper är ett nominellt värdepapper</a-info-btn>
    </td>

    <td class="text-right">{{ formatPrice(transaction.amount) }}</td>
    <td class="text-right">{{ formatPrice(transaction.price) }}</td>
  </tr>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { toTransactionTypesLabel } from '@shared/common';
import { formatNumber } from '@/utils';
import { ExtendedTransaction } from '@shared/web/models/transaction/utils';
import { TransactionType } from '@/models';
import { isTradeEvent } from '@shared/instruction/utils';

@Component({
  components: {},
})
export default class TransactionsTableRow extends Vue {
  @Prop() transaction: ExtendedTransaction;

  formatPrice(number) {
    return formatNumber(number, 2);
  }

  get typeLabel() {
    if (this.transaction.transactionType === TransactionType.TRADE) {
      return 'Ägarbyte';
    }
    return toTransactionTypesLabel(this.transaction.transactionType, this.transaction.debit);
  }

  get eventLink() {
    const { nationalId, countryCode } = this.transaction.instrument.entity;
    const instructionId = this.transaction.instruction?.id || this.transaction.instruction;
    if (instructionId == null) {
      return null;
    }

    if (isTradeEvent(this.transaction.transactionType)) {
      return `/${countryCode}/${nationalId}/agarbyte/${instructionId}`;
    }
    switch (this.transaction.transactionType) {
      case TransactionType.ISSUE_WARRANT:
      case TransactionType.EXERCISE_WARRANT:
        return `/${nationalId}/optionsprogram/${instructionId}`;
      case TransactionType.SETTLED:
      case TransactionType.RIGHTS_ISSUE:
        return `/${nationalId}/nyemission/${instructionId}`;
      case TransactionType.SPLIT:
        return `/${nationalId}/split/${instructionId}`;
      case TransactionType.ISSUE_CONVERTIBLE:
      case TransactionType.EXERCISE_CONVERTIBLE:
        return `/${nationalId}/konvertibler/${instructionId}`;
      case TransactionType.ISSUE_BOND:
      case TransactionType.EXERCISE_BOND:
        return `/${nationalId}/obligationer/${instructionId}`;

      default:
        return '';
    }
  }

  get isDistributedByValue() {
    return this.transaction?.instrument?.rightsData?.distributeByValue;
  }
}
</script>
