<template>
  <div>
    <q-card flat class="CompanyInformation q-mt-md">
      <q-card-actions vertical>
        <h4 class="text-secondary">Bolagsinformation</h4>
        <p class="B1Small">{{ entity.viewName }}</p>
      </q-card-actions>
      <q-card-actions vertical class="info-field q-my-none q-py-none">
        <dl>
          <template v-if="ceo">
            <dt>VD:</dt>
            <dd>{{ (ceo && ceo.viewName) || '' }}</dd>
          </template>
          <template v-if="address">
            <dt>Säte:</dt>
            <dd>
              {{ address.city || '' }}
            </dd>
          </template>
          <dt>Org.nr:</dt>
          <dd>{{ formatNationalId(entity) }}</dd>
        </dl>
        <div :class="{ hide: !showMore }" class="extra-info">
          <dl v-if="boardMembers.length > 0">
            <dt>Styrelse:</dt>
            <dd>
              <div v-for="member in boardMembers" :key="member.id">
                {{ member.viewName }}
              </div>
            </dd>
          </dl>
          <dl v-if="address">
            <dt>Adress:</dt>
            <dd>
              <template v-if="address.address1"> {{ address.address1 }}<br /></template>
              <template v-if="address.address2"> {{ address.address2 }}<br /></template>
              <template v-if="address.zip"> {{ address.zip }}<br /> </template>
              <template v-if="address.city"> {{ address.city }} </template>
            </dd>
          </dl>
          <div class="text-center q-mt-md">
            <p class="q-pb-xs">Har uppgifter ändrats?</p>
            <q-btn @click="updateCompanyInfo">Uppdatera information</q-btn>
          </div>
        </div>
      </q-card-actions>
      <a-btn-link :icon="showMore ? 'keyboard_arrow_up' : 'keyboard_arrow_down'" color="secondary" @click="show" />
    </q-card>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { Entity } from '@/models';
import { RoleType } from '@shared/models/types';
import axios from 'axios';

@Component({})
export default class CompanyInformation extends Vue {
  @Prop() entity: Entity;
  showMore = false;

  get address() {
    return this.entity.addresses[0];
  }

  get ceo() {
    const role = this.entity.legalEntity.roles.find(role => role.type === RoleType.CEO);
    return role && role.person;
  }

  get boardMembers() {
    const roles = this.entity.legalEntity.roles.filter(role => role.type === RoleType.BoardMember);
    return roles.map(role => role.person);
  }

  show() {
    this.showMore = !this.showMore;
  }

  async updateCompanyInfo() {
    await axios.get(`/webapi/entity/${this.entity.id}/refresh-info`);
    await axios.get(`/webapi/entity/${this.entity.id}/refresh-roles`);
    this.$q.dialog({
      title: 'Informationen har uppdaterats!',
      message: 'Kontakta kundservice om du behöver hjälp',
    });
    this.$emit('change');
  }
}
</script>
<style lang="scss">
.CompanyInformation {
  position: relative;
  flex: 1;
  min-height: 164px;
  z-index: 1;
  overflow: hidden;

  .column {
    padding: 9px 16px 0;
  }

  h4 {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
  }
  p {
    font-size: 14px;
    margin: 0;
  }
  .a-btn-link .q-icon {
    margin-bottom: 5px;
    margin-right: -3px;
    font-size: 24px !important;
  }
  .info-icon {
    position: absolute;
    top: 9px;
    right: 12px;
  }

  .info-field {
    dl {
      width: 100%;
      overflow: hidden;
      line-height: 19px;
      margin-top: 19px;
    }
    dt {
      width: 64px;
      margin-right: 8px;
      &:empty {
        height: 19px;
      }
    }
    dd {
      width: calc(100% - 100px);
      &:empty {
        height: 19px;
      }
    }
    .extra-info {
      max-height: 400px;
      transition: max-height 0.2s linear;
      overflow: hidden;
      padding-bottom: 40px;
      &.hide {
        max-height: 0;
        transition: max-height 0.2s linear;
        padding-bottom: 0;
      }
    }
  }

  .a-btn-link {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
  }

  .a-btn-link .q-icon {
    right: 12px;
    bottom: 8px;
    position: absolute;
  }
}
</style>
