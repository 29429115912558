import { InstrumentCategory, EntityType, InstrumentGroup, EntityConnectionType, Products } from '../models/types';
const { issuer, insuranceCompany, participant, investor } = EntityType;
const { SHA } = InstrumentCategory;

const getTestData = () => ({
  User: {
    user1: {
      id: '100000000000000000000001',
      username: 'user1',
      roles: ['ADMIN'],
    },
    user2: {
      id: '100000000000000000000002',
      username: 'user2',
    },
    user3: {
      id: '100000000000000000000003',
      name: 'Uno Efternamn',
      personalNumber: '194812161596',
    },
    user4: {
      id: '100000000000000000000004',
      name: 'Lennart Lo',
      personalNumber: '199102177830',
    },
    user5: {
      id: '100000000000000000000005',
      name: 'Example',
      username: 'example@example.com',
      canLoginWithEmail: true,
      emailLoginToken: 'abc123',
    },
  },
  Entity: {
    e1: {
      id: '100000000000000000000011',
      nationalId: '5560160680',
      countryCode: 'SE',
      autoUpdate: true,
      isPerson: false,
      activeTypes: {
        issuer: true,
      },
      issuerData: {
        totalQuantity: 200,
        totalCapital: 2000,
        currencyCode: 'SEK',
        shareRegisterValid: true,
        shareRegisterState: 'EXECUTED',
        hasShareNumbers: true,
      },
      types: [issuer],
      legalEntity: {
        name: 'Svenskt bolag med aktier',
        roles: [{ person: '100000000000000000000015', type: 'AuthorizedSignatory' }],
      },
    },
    e2: {
      id: '100000000000000000000012',
      nationalId: '1111222333',
      countryCode: 'NO',
      isPerson: false,
      types: [insuranceCompany],
      legalEntity: {
        name: 'Norskt försäkringsbolag',
        entityConnections: [
          {
            type: EntityConnectionType.AsParticipant,
            entity: '100000000000000000000016',
          },
        ],
      },
      creator: '100000000000000000000011',
    },
    e3: {
      id: '100000000000000000000013',
      person: {
        firstName: 'Lennart',
        surName: 'Lo',
      },
      nationalId: '199102177830',
      countryCode: 'SE',
      autoUpdate: true,
      isPerson: true,
    },
    e4: {
      id: '100000000000000000000014',
      person: {
        firstName: 'Sven',
        surName: 'Rabin',
      },

      nationalId: '197202072182',
      countryCode: 'SE',
      autoUpdate: true,
      isPerson: true,
    },
    e5: {
      id: '100000000000000000000015',
      person: {
        firstName: 'Uno',
        surName: 'Nord',
      },
      nationalId: '194812161596',
      countryCode: 'SE',
      autoUpdate: true,
      isPerson: true,
      kapnetLicenseConsentedAt: '2024-02-09T12:28:37.416+00:00',
    },
    e6: {
      id: '100000000000000000000016',
      nationalId: '1111222344',
      countryCode: 'NO',
      isPerson: false,
      types: [participant],
      legalEntity: {
        name: 'Depåinstititut',
        entityConnections: [
          {
            type: EntityConnectionType.AsInsuranceCompany,
            entity: '100000000000000000000012',
          },
        ],
      },
    },
    e7: {
      id: '100000000000000000000017',
      nationalId: '11112223255',
      countryCode: 'NO',
      isPerson: false,
      types: [investor],
      legalEntity: {
        name: 'Investeringar AB',
        investorManager: '100000000000000000000014',
      },
      creator: '100000000000000000000011',
    },
  },

  EntityAdmin: {
    ea1: { user: '100000000000000000000001', entity: '100000000000000000000011', email: 'ea1@example.com' },
    ea2: { user: '100000000000000000000001', entity: '100000000000000000000012', email: 'ea2@example.com' },
    ea3: { user: '100000000000000000000005', entity: '100000000000000000000011', email: 'example@example.com' },
  },
  Instrument: {
    i1: {
      id: '100000000000000000000021',
      entity: '100000000000000000000011',
      name: 'Svenskt bolag med aktier (STAM)',
      category: SHA,
      group: InstrumentGroup.SHA,
      currencyCode: 'SEK',
      ISIN: 'SE0009920994',
      KapID: undefined,
      autoUpdate: true,
      products: [Products.KAPCLEAR],
      shareData: {
        totalCapital: 2000,
        totalQuantity: 200,
        votingPower: 1,
        classType: 'A',
        positionsValid: true,
      },
    },
  },
  Position: {
    p1: {
      id: '100000000000000000000031',
      entity: '100000000000000000000011',
      instrument: '100000000000000000000021',
      quantity: 150,
      owner: '100000000000000000000013',
      settleFrom: '2021-01-01',
      shareNumberFrom: 1,
      shareNumberTo: 150,
    },
    p2: {
      id: '100000000000000000000032',
      entity: '100000000000000000000011',
      instrument: '100000000000000000000021',
      quantity: 50,
      owner: '100000000000000000000014',
      settleFrom: '2021-01-01',
      shareNumberFrom: 151,
      shareNumberTo: 200,
    },
  },
  Instruction: {
    n1: {
      id: '100000000000000000000041',
      entity: '100000000000000000000011',
      state: 'NEW',
      type: 'TRADE',
      settleDate: '2021-02-01',
      price: 10,
      tradeEvent: {
        source: {
          owner: '100000000000000000000013',
          quantity: 10,
          instrument: '100000000000000000000021',
        },
        sourcePositions: [
          {
            position: '100000000000000000000031',
            quantity: 10,
          },
        ],
        instrument: '100000000000000000000021',
        tradeDate: '2021-02-01',
      },
      destinations: [
        {
          id: '100000000000000000000100',
          owner: '100000000000000000000015',
          quantity: 10,
          instrument: '100000000000000000000021',
        },
      ],
    },
    invalidPledge: {
      id: '100000000000000000000995',
      entity: '100000000000000000000011',
      state: 'NEW',
      type: 'TRADE',
      settleDate: '2021-02-01',
      price: 10,
      tradeEvent: {
        source: {
          owner: {
            id: '100000000000000000000015',
          },
          quantity: 10,
          instrument: '100000000000000000000021',
        },
        sourcePositions: [
          {
            position: '100000000000000000000031',
            quantity: 10,
          },
        ],
        instrument: '100000000000000000000021',
        tradeDate: '2021-02-01',
      },
      destinations: [
        {
          id: '100000000000000000000100',
          owner: {
            id: '100000000000000000000013',
          },
          quantity: 10,
          instrument: '100000000000000000000021',
          pledgeOwner: undefined,
        },
      ],
    },
    validPledge: {
      id: '100000000000000000000996',
      entity: '100000000000000000000011',
      state: 'NEW',
      type: 'TRADE',
      settleDate: '2021-02-01',
      price: 10,
      tradeEvent: {
        source: {
          owner: {
            id: '100000000000000000000013',
          },
          quantity: 10,
          instrument: '100000000000000000000021',
        },
        sourcePositions: [
          {
            position: '100000000000000000000031',
            quantity: 10,
          },
        ],
        instrument: '100000000000000000000021',
        tradeDate: '2021-02-01',
      },
      destinations: [
        {
          id: '100000000000000000000100',
          owner: {
            id: '100000000000000000000013',
          },
          quantity: 10,
          instrument: '100000000000000000000021',
          pledgeOwner: {
            id: '100000000000000000000017',
          },
        },
      ],
    },
  },
  Ownership: {
    o1: {
      id: '100000000000000000000051',
      owner: '100000000000000000000012',
      investor: '100000000000000000000013',
      custodian: '100000000000000000000016',
      insuranceNumber: '123456',
      state: 'CONFIRMED',
    },
  },
  InvestmentOffer: {
    Offer1: {
      id: '65c1d5625f26f53bbe81496b',
      author: '100000000000000000000001',
      text: '<p>Här är en test text för andra inlägget.</p>',
      title: 'Test 2',
      archived: true,
      draft: false,
      deleted: false,
      createdAt: '2024-02-06T06:44:50.323Z',
      updatedAt: '2024-02-06T06:45:11.226Z',
      lastActivity: {
        at: '2024-02-06T06:45:11.225Z',
        by: '100000000000000000000001',
        webhook: false,
        type: 'UPDATED',
        data: null,
      },
    },
    Offer2: {
      id: '65c1d5545f26f53bbe81495b',
      author: '100000000000000000000001',
      text: '<p>Här är en test text för första inlägget.</p>',
      title: 'Test 1',
      archived: false,
      draft: false,
      deleted: false,
      createdAt: '2024-02-06T06:44:36.744Z',
      updatedAt: '2024-02-06T06:44:48.187Z',
      lastActivity: {
        at: '2024-02-06T06:44:48.186Z',
        by: '100000000000000000000001',
        webhook: false,
        type: 'UPDATED',
        data: null,
      },
    },
  },
  CurrencyRates: {
    cr1: {
      rates: {
        EUR: 10,
        USD: 10,
        NOK: 1,
        DKK: 1.5,
      },
      date: '2024-02-06T06:44:48.186Z',
    },
  },
});

const testData = getTestData();

export { getTestData };
export default testData;
