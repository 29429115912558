<template>
  <q-header v-bind:class="[profile && profile.loggedIn ? 'authorized' : 'unauthorized']">
    <q-toolbar class="Navbar">
      <q-btn
        v-if="profile && profile.loggedIn"
        flat
        round
        dense
        :icon="sidebarOpen ? 'close' : 'menu'"
        class="q-mr-sm menu-btn"
        :class="{ 'close-btn': sidebarOpen }"
        @click="$emit('sidebar')"
        :aria-label="sidebarOpen ? 'Stäng meny' : 'Öppna meny'"
      />
      <q-space class="lt-md" />
      <q-btn padding="none" class="logo" flat unelevated :to="landingPage">
        <q-avatar :square="true">
          <svg alt="Kapclear logotype">
            <use xlink:href="/kapclear-logo.svg#logo-avatar"></use>
          </svg>
        </q-avatar>
        <q-toolbar-title :shrink="true">
          <svg alt="Kapclear – Den smarta digitala aktieboken">
            <use xlink:href="/kapclear-logo.svg#logo-title"></use>
          </svg>
        </q-toolbar-title>
      </q-btn>
      <q-space />
      <div
        class="menu"
        :class="{ active: menuVisible }"
        aria-label="Huvudmeny"
        role="navigation"
        :aria-expanded="menuVisible ? 'true' : 'false'"
      >
        <template v-if="profile && !profile.loggedIn">
          <div class="nav-buttons mobile gt-sm">
            <q-btn-group rounded unelevated>
              <q-btn text-color="white" label="Logga in depåinstitut" :to="{ path: '/' }" />
              <q-btn text-color="white" label="Bli kund" :to="{ path: '/registrera' }" />
              <q-btn text-color="white" label="Logga in" :to="{ path: '/' }" />
            </q-btn-group>
          </div>
          <div class="nav-buttons mobile lt-md">
            <q-btn
              padding="sm"
              text-color="white"
              class="full-width"
              label="Logga in depåinstitut"
              :to="{ path: '/' }"
            />
            <q-btn padding="sm" text-color="white" class="full-width" label="Bli kund" :to="{ path: '/registrera' }" />
            <q-btn padding="sm" text-color="white" class="full-width" label="Logga in" :to="{ path: '/' }" />
          </div>
        </template>
        <template v-if="profile && profile.loggedIn">
          <q-btn flat unelevated @click="logout" class="logout-btn q-pl-md q-ml-xl">Logga ut</q-btn>
          <q-btn round color="accent" unelevated text-color="primary-5" class="profile-btn q-mr-md" :to="landingPage">{{
            initialName
          }}</q-btn>
        </template>
      </div>
    </q-toolbar>
  </q-header>
</template>
<script lang="ts">
import store from '@/store';
import { logout } from '@/models/profileService';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import nameInitials from 'name-initials';

@Component({})
export default class Navbar extends Vue {
  leftDrawerOpen = false;
  menuAnimation = false;
  menuVisible = false;

  @Prop({ default: false }) sidebarOpen;

  get profile() {
    return store.profile;
  }

  @Watch('$route')
  onRouteChange() {
    this.menuVisible = false;
  }

  async logout() {
    await logout();
    this.$router.push({ path: '/' });
  }

  get landingPage() {
    if (this.profile?.loggedIn) {
      return '/valjbolag';
    }
    return '/';
  }

  get name() {
    return this.profile.user.name;
  }

  get initialName() {
    if (this.name != null) {
      return nameInitials(this.name);
    }
    return '';
  }
}
</script>
<style lang="scss">
.Navbar {
  height: 72px;
  max-width: 1360px;
  margin: 0 auto;

  .menu-btn {
    display: none;
  }
  .menu:not(.active) {
    display: flex;
    justify-content: space-between;
    justify-self: stretch;
  }
  .menu {
    height: 100%;
  }
  .q-tabs {
    flex: 0 1 auto;
    height: 100%;
    position: static;
    margin: 0 20px 0 auto;

    .q-tab {
      text-transform: none;
      .q-tab__content {
        min-width: unset;
      }
      .q-tab__label {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .logo {
    height: 100%;
    .q-btn__content {
      padding: 3px 10px 0;
      width: 220px;
    }
    .q-avatar {
      font-size: 54px;
    }
    svg {
      height: 54px;
      width: 127px;
    }
  }
  &.q-toolbar {
    padding-left: 7px;
  }
  .q-toolbar__title {
    overflow: visible;
    max-width: fit-content;
    .q-btn {
      height: 100%;
    }
    svg {
      fill: #fff;
    }
  }

  .profile-btn {
    font-size: 14px;
    margin: 0 12px 0 0;
    height: 32px;
    min-height: 32px;
    min-width: 32px;
    align-self: center;

    .q-btn__wrapper {
      min-width: 32px;
      min-height: 32px;
    }
  }
  .logout-btn {
    font-size: 12px;
    // @vue3 .q-btn__wrapper är borttagen, styling satt direkt på .q-btn. Se över styling med q-btn__wrapper på andra ställen. Detta finns inte som klass längre i quasar q-btn.
    padding-left: 16px;
    padding-right: 16px;
    width: 82px;
    white-space: nowrap;
  }
}
header {
  &.authorized {
    .Navbar button:not(.close-btn) {
      color: #fff;
    }
    background-color: #1f331e !important;
    .menu:not(.active) .q-tab {
      color: #fff;
    }
    svg {
      fill: #fff !important;
    }
  }
  &.unauthorized {
    background-color: #fff;
  }
  .Navbar {
    color: #000;
    .logo .q-toolbar__title svg {
      fill: #000;
    }
    .q-btn-group {
      height: 100%;
      margin-right: 10px;
      .q-btn__wrapper {
        padding: 0 10px;
        min-height: auto;
      }
      .q-btn {
        background-color: $dark;
        font-size: 12px;
        line-height: 28px;
        height: 28px;
        border-right: 1px solid #fff;
        align-self: center;
        + .q-btn {
          border-left: 1px solid #fff;
        }
      }
    }

    @media (max-width: 950px) {
      width: 100%;
      margin: 0;
      padding: 0;

      .menu-btn {
        display: block;
        z-index: 2;
        position: absolute;

        &.close-btn {
          position: fixed;
          right: 10px;
        }
      }
      .menu {
        width: 100%;
        overflow: hidden;
        padding-left: calc(100% - 272px);
        background-color: transparent;
        position: absolute;
        right: 0;
        top: 0;
        padding-bottom: 40px;
        visibility: hidden;
        height: 100vh;
        transition: background-color 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
        .q-tabs {
          right: -272px;
          transition: right 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
        }
        .q-btn-group {
          left: 272px;
          margin-right: 0;
          transition: left 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
        }
        .nav-buttons.mobile {
          position: fixed;
          bottom: -300px;
          left: calc(100% - 272px);
          padding: 10px;
          & > a,
          & > button {
            position: relative;
            top: 500px;
            transition: top 0.3s ease-in-out;
            @for $i from 1 through 5 {
              &:nth-child(#{$i}) {
                transition-delay: calc((#{$i}s - 1s) / 10);
              }
            }
          }
        }

        &.active {
          visibility: visible;
          position: fixed;
          background-color: rgba(0, 0, 0, 0.5);

          .q-tabs {
            right: 0;
            transition: right 0.5s cubic-bezier(0.82, 0.085, 0.395, 0.895);
          }
          .nav-buttons {
            margin-top: 0;
            position: relative;
            top: -50px;
            left: 32px;
            right: auto;
            height: auto;
            &.mobile {
              position: fixed;
              left: calc(100% - 272px);
              bottom: 0;
              top: initial;
              transition-delay: 0.3s;
              & > a,
              & > button {
                top: 0;
                transition-duration: 0.5s;
                margin-bottom: 10px;
              }
            }
          }
        }
      }

      .q-tabs {
        background-color: white;
        width: 272px;
        padding: 50px 0;
        margin: 0;
        list-style: none;
        display: block !important;
        position: relative;
        right: 0;
        height: 100vh;

        .q-tab {
          -webkit-flex-direction: column !important;
          flex-direction: column !important;
          float: left !important;
          text-transform: none;
          width: 100%;
          padding-left: 32px;
        }
        .q-tab__content {
          align-items: flex-start;
          text-align: left !important;
        }
        .q-tabs__content {
          display: block !important;
        }
      }
      .q-btn-group {
        margin-top: 20px;
      }
    }
  }
}
</style>
