import store from '@/store';
import Profile from './Profile';
import jwtProvider from './auth/jwtProvider';
import { findEntity, lookupEntity } from './entity/entityService';
import { findEntityAdminsForUser } from '@/models/entityAdmin/entityAdminService';
import { Entity } from '@shared/web/models';

export async function findProfile(): Promise<Profile> {
  if (store.profile) {
    return store.profile;
  }
  return loadProfile();
}

export async function loadProfile() {
  const data = await jwtProvider.fetchProfile();
  // Ensure corresponding entity in store

  let entity = null;
  const loggedIn = data.loggedIn;
  if (loggedIn && data.user.personalNumber) {
    entity = await lookupEntity({ nationalId: data.user.personalNumber, isPerson: true });
  } else if (loggedIn) {
    const admins = await findEntityAdminsForUser();
    entity = await findEntity(admins[0].entity);
  }
  store.profile = Profile.newModel({ entity, ...data });
  return store.profile;
}

export async function logout() {
  await jwtProvider.logout();

  store.profile = Profile.newModel({ loggedIn: false });
}

export function getPersonalNumber() {
  const { profile } = store;
  if (profile.entity.isPerson) {
    return profile.entity.nationalId;
  }
  if (profile?.user.personalNumber) {
    return profile?.user.personalNumber;
  }
}

export function getRepresentedEntity(): Entity | void {
  return store?.profile?.entity;
}

export function getRepresentedPersonalEntity(): Entity | void {
  const entity = getRepresentedEntity();
  if (entity && entity?.isPerson) {
    return entity;
  }
}
