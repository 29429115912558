<template>
  <q-dialog ref="dialog" persistent>
    <div class="q-dialog-plugin clearInstructionModal">
      <p class="p2">Vill du rensa all information för {{ modalName }}?</p>
      <div class="flex column flex-center q-px-lg">
        <q-btn label="Rensa" class="test-confirmation" :loading="loading" v-bind="$defs.btn" @click="clearTarget" />
        <q-btn flat class="cancelBtn" @click="hide" label="Avbryt" />
      </div>
    </div>
  </q-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { DialogComponent, FormComponent } from '@shared/mixins';
import { clearInstruction } from '@/models/instruction/instructionService';

@Component({
  mixins: [DialogComponent, FormComponent],
})
export default class ClearInstructionModal extends Vue {
  @Prop() id: string;
  @Prop() modalName: string;

  async clearTarget() {
    const instruction = await clearInstruction(this.id);
    this.$emit('ok', instruction);
    this.hide();
  }
}
</script>
<style lang="scss">
.clearInstructionModal {
  background-color: white;
  .q-btn {
    width: 100%;
  }
  .cancelBtn {
    font-size: 16px;
    color: $secondary;
  }
  .p2 {
    padding: 48px 24px 56px 24px;
  }
}
</style>
