<template>
  <tr class="holder-row">
    <td>
      {{ holder.viewName }}
    </td>
    <td colspan="3"></td>
    <td class="text-right">{{ holder.shareData.totalQuantity }}</td>
  </tr>
</template>
<script lang="ts">
// @ts-nocheck
import { Entity, Instrument } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({})
export default class HolderRow extends Vue {
  @Prop() holder: Entity | Instrument;
}
</script>
