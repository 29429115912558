<template>
  <q-dialog ref="dialog" persistent>
    <div class="q-dialog-plugin BankIdSignModal" @hide="hide">
      <SignBankID @closeModal="hideModal" :onSign="onSign" :type="type" :payload="payload" />
    </div>
  </q-dialog>
</template>
<script lang="ts">
import { DialogComponent } from '@shared/mixins';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import SignMobileBankID from './SignBankID.vue';
import SignBankID from './SignBankID.vue';

@Component({
  components: { SignMobileBankID, SignBankID },
  mixins: [DialogComponent],
})
export default class BankIdSignModal extends Vue {
  @Prop() onSign: Function;
  @Prop() type: string;
  @Prop() payload?: Record<string, unknown>;

  hideModal() {
    this.$emit('hide');
  }
}
</script>
<style lang="scss">
.BankIdSignModal {
  min-width: 440px;
  min-height: 440px;
  background: #e5e5e5;
  p {
    text-align: center;
  }
  .center {
    text-align: center;
  }
  .closebtn {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
</style>
