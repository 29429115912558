import { Model } from '../base';
import { Person, IssuerData } from '@shared/web/models';

export default class RoaringData extends Model {
  person: Person = null;
  issuerData: IssuerData = null;
  static define = () => ({
    issuerData: {
      embeddedModel: IssuerData,
    },
    person: {
      embeddedModel: Person,
    },
  });
}
