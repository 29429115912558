<template>
  <tr class="narrow-row">
    <td colspan="100%">
      <q-btn v-bind="$defs.btn" color="secondary" dense rounded flat class="small" icon="add_circle">{{ label }}</q-btn>
    </td>
  </tr>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
@Component
export default class ListItem extends Vue {
  @Prop() label: string;
}
</script>
