<template>
  <div class="DebtsInfo">
    <h4 class="table-header">Obligationer / Konvertibler i {{ shareRegister.entity.viewName }}</h4>
    <q-markup-table flat class="standard-table" separator="horizontal">
      <thead class="has-background">
        <tr>
          <th class="text-left">Värdepapper</th>
          <th class="text-left">Antal / Nominellt belopp</th>
          <th class="text-right">Antal</th>
          <th class="text-right">Belopp per styck</th>
          <th class="text-right">Tot. Nominellt belopp</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="instrument in shareRegister.instruments" :key="instrument.id">
          <td class="text-left bold wrap-text">
            {{ instrument.viewName }}
          </td>
          <td class="text-left bold">{{ instrument.rightsData.distributeByValue ? 'Nom Belopp' : 'Antal' }}</td>
          <td class="text-right bold">
            {{ formatNumber(instrument.rightsData.totalQuantity) }}
          </td>
          <td class="text-right bold">
            {{ formatNumber(instrument.rightsData.nominalAmountPerPiece) }}
          </td>
          <td class="text-right bold">
            {{ formatNumber(instrument.rightsData.nominalAmountTotal) }} {{ instrument.rightsData.currency }}
          </td>
        </tr>
        <tr v-if="shareRegister.instruments.length" class="holder-row">
          <td>Summa</td>
          <td colspan="999" class="text-right">
            {{
              Object.entries(amountTotalSumByCurrency)
                .map(([currency, total]) => formatNumber(total) + ' ' + currency)
                .join(', ')
            }}
          </td>
        </tr>
      </tbody>
      <tfoot></tfoot>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { ShareRegister } from '@shared/web/models';
import { groupBy, sumBy, mapValues } from 'lodash';

@Component({})
export default class DebtsInfo extends Vue {
  @Prop() shareRegister: ShareRegister;

  get amountTotalSumByCurrency(): { [key: string]: number } {
    const amountsByCurrencies = groupBy(this.shareRegister.instruments, ins => ins.rightsData.currency);
    return mapValues(amountsByCurrencies, instruments =>
      sumBy(instruments, instrument => instrument.rightsData.nominalAmountTotal),
    );
  }
}
</script>
<style lang="scss">
.DebtsInfo {
  .q-table {
    max-width: $col-6;
    th {
      &:nth-child(1) {
        min-width: auto;
      }
    }
    .bold {
      font-weight: 700;
      color: $grey-5;
    }
  }
}
</style>
