<template>
  <div class="RightsPositionTable">
    <div class="export-link-box">
      <ExportLinks v-if="isDownloadAble">
        <ExportExcel :exporter="exportExcel"> Excel </ExportExcel>
        <PdfDownload
          :entity="shareRegister.entity"
          :settleDate="shareRegister.settleDate"
          instrumentProduct="KAPCLEAR"
          documentType="complete"
          :category="InstrumentCategory.RIGHTS"
        >
          Optionsbok / Pdf
        </PdfDownload>
      </ExportLinks>
    </div>
    <q-markup-table flat class="standard-table position-table" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Option</th>
          <th class="text-left">Ägare</th>
          <th class="text-left">Försäkringsägare</th>
          <th class="text-left">Försäkringsnummer</th>
          <th class="text-left">Förvar</th>
          <th class="text-right">Antal</th>
        </tr>
      </thead>
      <tbody class="two-row">
        <template v-for="{ holder, positions } in positionGrouping" :key="holder.id">
          <InstrumentRow :instrument="holder" :shareRegister="shareRegister" />
          <template v-for="position in positions" :key="position.id">
            <PositionRow :position="position" />
          </template>
        </template>
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { ShareRegister, InstrumentCategory, InstructionState } from '@shared/web/models';
import PositionRow from './PositionRow.vue';
import InstrumentRow from './InstrumentRow.vue';
import { positionGrouping } from '@shared/shareRegister/utils';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import ExportLinks from '@shared/components/ExportLinks.vue';
import PdfDownload from '@shared/components/PdfDownload.vue';
import ExportExcel from '@shared/views/excel/ExportExcel.vue';

@Component({
  components: { PositionRow, InstrumentRow, ExportLinks, PdfDownload, ExportExcel },
})
export default class RightsPositionTable extends Vue {
  @Prop() shareRegister: ShareRegister;

  InstrumentCategory: typeof InstrumentCategory = InstrumentCategory;

  get positionGrouping() {
    const groups = positionGrouping(this.shareRegister.instruments, this.shareRegister.positions);
    groups.forEach(({ positions }) => {
      positions
        .sort((p1, p2) => {
          return p1.owner.viewName.localeCompare(p2.owner.viewName);
        })
        .sort((p1, p2) => {
          return p1.investor?.viewName.localeCompare(p2.investor?.viewName);
        });
    });
    return groups;
  }

  get isDownloadAble() {
    const state = this.shareRegister.entity.issuerData.shareRegisterState;
    return state === InstructionState.EXECUTED;
  }

  exportExcel() {
    return {
      name: this.shareRegister.entity.viewName,
      records: this.positionGrouping
        .map(({ holder, positions }) => {
          return positions.map(position => ({
            Option: holder.name,
            Ägare: position.owner?.viewName,
            Försäkringsägare: position.investor?.viewName,
            Förvar: position.custodian?.viewName,
            Antal: position.quantity,
          }));
        })
        .flat(),
    };
  }
}
</script>
<style lang="scss">
.RightsPositionTable {
  position: relative;
}
</style>
