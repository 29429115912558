<template>
  <q-page class="CustodianHoldings">
    <div class="row q-col-gutter-lg">
      <h1 class="col-9">
        {{ (instrument && instrument.name) || 'Värdepapper' }}
        <small>{{ instrument && (instrument.ISIN || instrument.KapID) }}</small>
      </h1>
    </div>
    <div class="row q-col-gutter-lg">
      <div class="col-3">
        <a-btn-link v-if="instrument" @click="back">&larr; Tillbaka till översikt</a-btn-link>
      </div>
    </div>
    <div class="row q-col-gutter-lg">
      <div class="col-5">
        <p class="B1Small">Välj avstämningsdag</p>
        <p>
          Observera, avstämningsdag är gårdagens datum, vilket då tar med transaktioner med likviddag tom igår. Om man
          vill stämma av dagens positioner så byter man datum.
        </p>
        <a-input-date v-model="settleDate" v-bind="$defs.input" />
      </div>
    </div>
    <br />
    <component
      v-if="holdings"
      :is="component"
      @setSettleDate="updateHoldings"
      :loading="loading"
      :custodian="custodian"
      :holdings="holdings"
      :instrument="instrument"
    />
  </q-page>
</template>
<script lang="ts">
import startOfYesterday from 'date-fns/startOfYesterday';
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { Entity } from '@/models';
import { loadEntityWithRouteParams } from '@shared/web/entity/entityService';
import { findCustodianHoldings } from '@/models/custodian/custodianService';
import CustodianInstrumentsTable from './CustodianInstrumentsTable.vue';
import CustodianAccountTable from './CustodianAccountTable.vue';

@Component({
  components: { CustodianInstrumentsTable, CustodianAccountTable },
})
export default class CustodianHoldings extends Vue {
  custodian: Entity = null;
  holdings = null;
  settleDate = startOfYesterday();

  loading = false;

  async mounted() {
    await this.loadData();
  }

  @Watch('settleDate')
  async updateHoldings(settleDate) {
    this.loading = true;
    await new Promise(resolve => setTimeout(resolve, 300));
    this.holdings = await findCustodianHoldings(this.custodian, settleDate);
    this.loading = false;
  }

  async loadData() {
    this.custodian = await loadEntityWithRouteParams(this.$route.params);
    this.updateHoldings(this.settleDate);
  }

  get instrument() {
    const { instrument: instrumentId } = this.$route.params;
    const holding = this.holdings?.holdings.find(({ instrument }) => instrument.id === instrumentId);
    return holding?.instrument;
  }

  get component() {
    const { instrument } = this.$route.params;
    if (!instrument) {
      return CustodianInstrumentsTable;
    }
    return CustodianAccountTable;
  }

  back() {
    return this.$router.push(`/${this.$route.params.countryCode}/${this.$route.params.entity}/custodian`);
  }
}
</script>
<style lang="scss">
.CustodianHoldings {
  h1 {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    small {
      color: $grey-4;
      font-size: 0.4em;
      line-height: 1em;
    }
  }
  .row > .col-9 {
    max-width: 814px;
  }
  .q-markup-table {
    & + .text-small {
      margin: 5px 0;
    }
  }
}
</style>
