<template>
  <tr class="large test-transaction-row">
    <td class="wrap-text">{{ instrument.entity.viewName }}</td>
    <td class="text-left wrap-text">
      <div>{{ instrument.name }}</div>
      <div class="text-small">{{ instrument.instrumentId }}</div>
    </td>
    <td class="text-left">{{ typeLabel }}</td>
    <td class="text-left">{{ counterpart }}</td>
    <td class="text-left">{{ formatDay(transaction.tradeDate) }}</td>
    <td class="text-left">{{ formatDay(transaction.settleDate) }}</td>
    <td class="text-right">{{ formatPositionValue(transaction) }}</td>
    <td class="text-right">{{ formatPrice(transaction.price) }}</td>
    <td class="text-left wrap-text">{{ insuranceCompanyName }}</td>
    <td class="text-right number wrap-text" v-if="false">{{ custodian && custodian.viewName }}</td>
  </tr>
</template>
<script lang="ts">
import { ExtendedTransaction } from '@shared/web/models/transaction/utils';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { toTransactionTypesLabel } from '@shared/common';
import { formatNumber } from '@/utils';
import { formatPositionValue } from '@shared/web/models/position/common';
import { store } from '@/store';

@Component({})
export default class UserTransactionsTableRow extends Vue {
  @Prop() transaction: ExtendedTransaction;

  formatPositionValue(transaction) {
    return formatPositionValue(transaction);
  }

  formatPrice(number) {
    return formatNumber(number, 2);
  }
  get debit() {
    return !(this.transaction?.previousOwner?.id === store.profile.entity.id);
  }

  get owner() {
    return this.transaction.owner;
  }
  get counterpart() {
    if (this.debit) {
      return this.transaction?.previousOwner?.viewName;
    }
    return this.transaction?.owner?.viewName;
  }
  get custodian() {
    return this.transaction.custodian;
  }
  get instrument() {
    return this.transaction.instrument;
  }
  get typeLabel() {
    return toTransactionTypesLabel(this.transaction.transactionType, this.debit);
  }

  get insuranceCompanyName() {
    const { owner } = this.transaction;
    if (owner.isInsuranceCompany) {
      return owner.viewName;
    }
    return null;
  }
}
</script>
