import { Model } from '../base';
import { User } from '../';
import { ActivityType } from '@shared/models/types';

export default class Activity extends Model {
  at: Date;
  by: User;
  type: ActivityType;

  static define = () => ({
    at: {
      type: 'date',
    },
    by: {
      model: User,
    },
  });
}
