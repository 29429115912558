<template>
  <div>
    <div v-if="!isEditMode" class="row justify-between">
      <div class="col-auto flex items-center">
        <span class="text-grey" v-if="!contact.email">Ingen e-post</span>
        <span v-if="contact.email">{{ contact.email }}</span>
      </div>
      <div class="col-auto" v-if="canEdit">
        <q-btn
          v-if="contact.email"
          color="green"
          dense
          padding="sm"
          size="md"
          class="q-ml-sm"
          outline
          @click="showEmailEdit(contact.email)"
        >
          <q-icon name="edit" size="sm" class="q-mr-xs" />
          <span>Ändra</span>
        </q-btn>
        <q-btn
          v-if="!contact.email"
          color="green"
          dense
          padding="sm"
          size="md"
          class="q-ml-sm"
          outline
          @click="showEmailEdit()"
        >
          <q-icon name="add" size="sm" class="q-mr-xs" />
          <span>Lägg till</span>
        </q-btn>
      </div>
    </div>
    <form v-if="!!isEditMode" @submit.prevent="saveNewEmail">
      <q-input value="" type="email" placeholder="Ange e-post" input-class="q-px-sm" v-model="newEmail" />
      <div class="row">
        <div class="col">
          <q-btn color="red" dense padding="sm" size="md" block class="full-width" @click="cancelEmailEdit">
            <q-icon name="cancel" size="sm" class="q-mr-xs" />
            <span>Avbryt</span>
          </q-btn>
        </div>
        <div class="col">
          <q-btn color="green" dense padding="sm" size="md" block class="full-width" @click="saveNewEmail">
            <q-icon name="save" size="sm" class="q-mr-xs" />
            <span>Spara</span>
          </q-btn>
        </div>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { ContactInfo } from '@shared/web/models';
import { store } from '@shared/web/store';
import { isBackOffice } from '@shared/web/utils';

@Component({
  components: {},
})
export default class EmailAllModalContactsEmail extends Vue {
  @Prop({ required: true }) contact: ContactInfo;
  isEditMode = false;
  newEmail = '';

  get canEdit() {
    return store.entityAdmin?.limitedAccess === false || isBackOffice();
  }

  showEmailEdit(email = '') {
    this.newEmail = email;
    this.isEditMode = true;
  }

  cancelEmailEdit() {
    this.isEditMode = false;
  }

  saveNewEmail() {
    this.contact.email = this.newEmail;
    this.$emit('emailChange');
    this.isEditMode = false;
  }
}
</script>
