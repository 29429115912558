import * as _ from 'lodash';
import FileUpload from './FileUpload';
import { signFile, uploadToAmazon } from './uploadutils';

export async function uploadFiles(ev, fileParent) {
  const fileUploads = _.map(ev.target.files, file => new FileUpload(file, fileParent));

  for (const fileUpload of fileUploads) {
    uploadFile(fileUpload);
  }
}

async function uploadFile(fileUpload: FileUpload) {
  const { signedUrl, file } = await signFile(fileUpload);
  await uploadToAmazon(signedUrl, fileUpload);
  fileUpload.parent.hasFiles = true;
  fileUpload.parent.files.push(file);
}
