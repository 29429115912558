export enum Layout {
  PUBLIC = 'public',
  ONBOARDING = 'onboarding',
  PDF = 'pdf',
}

export enum Intent {
  LOGIN = 'intent/login',
  REGISTER = 'intent/register',
}

export enum Context {
  USER = 'context/user',
}
