<template>
  <q-banner class="MessageBanner q-ma-md" v-if="globalError">
    <p>{{ globalError.text }}</p>
    <template v-slot:action>
      <q-btn flat icon="close" @click="close" />
    </template>
  </q-banner>
</template>
<script lang="ts">
import store from '@/store';
import { Component, Vue } from 'vue-facing-decorator';

@Component
export default class MessageBanner extends Vue {
  get globalError() {
    return store.globalError;
  }
  close() {
    store.globalError = null;
  }
}
</script>
<style lang="scss">
.MessageBanner {
  position: fixed;
  z-index: 10000;
  bottom: 0;
  right: 0;
  padding-top: 50px !important;
  width: 320px;

  h5 {
    color: $negative;
    padding: 8px 0;
    font-size: 14px;
  }
  p {
    padding: 4px 0;
  }
  .q-btn {
    position: absolute;
    top: 0;
    right: 0;
    .q-btn__wrapper {
      padding: 14px 20px;
    }
    .q-icon {
      font-size: 18px;
    }
  }
}
</style>
