<template>
  <a-btn-link :class="className" @click="openEmailAllModal">
    {{ label }}
  </a-btn-link>
</template>
<script lang="ts">
import { Prop, Component, Vue } from 'vue-facing-decorator';
import { ShareRegister } from '@shared/web/models';
import { uniq } from 'lodash';
import { findIssuerInfo } from '@shared/web/models/entity/issuerInfoService';
import EmailAllModal from '@shared/components/EmailAllModal.vue';
import { Entity } from '@shared/web/models';

@Component
export default class EmailAllButton extends Vue {
  @Prop()
  register: ShareRegister;

  @Prop({ default: 'float-right' })
  className: string;

  @Prop({ default: 'Mejla alla' })
  label: string;

  @Prop({ default: null, required: true })
  entities: Entity[];

  openEmailAllModal() {
    this.$q.dialog({
      component: EmailAllModal,
      componentProps: {
        label: this.label,
        register: this.register,
        entities: this.entities,
      },
    });
  }

  async emailAll() {
    const issuerInfo = await findIssuerInfo(this.register.entity);
    const emails = issuerInfo.contacts.map(contact => contact.email).filter(email => email);
    location.href = 'mailto:?bcc=' + uniq(emails).join(',');
  }
}
</script>
