<template>
  <q-markup-table flat class="standard-table DestinationsTable" separator="horizontal">
    <thead>
      <tr>
        <th class="text-left">Aktie</th>
        <th class="text-left">Ägare</th>
        <th class="text-left">Försäkringsägare</th>
        <th class="text-left">Förvar</th>
        <th class="text-right number">Antal</th>
        <th class="text-right number">Nya aktier, pro-rata</th>
        <th class="text-right number">Tecknade</th>
        <th></th>
      </tr>
    </thead>
    <tbody class="two-row">
      <template v-for="{ holder, positions } in positionGroups" :key="holder.id">
        <HolderRow :holder="holder" />

        <template v-for="destination in positions" :key="destination.id">
          <DestinationRow
            :destination="destination"
            :instruction="instruction"
            :validator="validator"
            :shareRegister="shareRegister"
            :tableSettings="tableSettings"
            @change="$emit('change')"
            :readonly="readonly"
          />
        </template>
        <tr :key="'footer:' + holder.id" v-if="!readonly" class="narrow-row">
          <td colspan="100">
            <q-btn
              color="secondary"
              id="test-add-owner"
              :disable="disableAdd"
              dense
              rounded
              flat
              @click="addPosition(holder)"
              class="small"
              icon="add_circle"
              >Lägg till deltagare</q-btn
            >
          </td>
        </tr>
      </template>
    </tbody>
  </q-markup-table>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';

import { ShareRegister, Instruction, InstructionParty } from '@/models';
import { getPositionGroups } from '@shared/shareRegister/utils';

import DestinationRow from '../issueWarrant/DestinationRow.vue';
import HolderRow from './HolderRow.vue';
import EditPositionModal from '@shared/views/editPositionModal/EditPositionModal.vue';

import TableSettings from '@shared/web/models/shareRegister/TableSettings';
@Component({
  components: { HolderRow, DestinationRow, EditPositionModal },
})
export default class DestinationsTable extends Vue {
  @Prop() shareRegister: ShareRegister;
  @Prop() tableSettings: TableSettings;
  @Prop() instruction: Instruction;
  @Prop({ default: false }) disableAdd;
  @Prop() validator;
  @Prop({ default: () => false }) readonly: boolean;

  get positionGroups() {
    const positionGroups = getPositionGroups(
      this.instruction.destinations,
      this.shareRegister.instruments,
      this.tableSettings,
    );

    return positionGroups;
  }

  addPosition(instrument) {
    const entity = this.shareRegister.entity;
    const data = { instrument, entity };

    this.$q
      .dialog({
        component: EditPositionModal,
        componentProps: {
          positionClass: InstructionParty,
          value: data,
          label: 'deltagare',
          tradeEventForm: true,
          instruction: this.instruction,
          shareRegister: this.shareRegister,
        },
      })
      .onOk(() => {
        this.$emit('change');
      });
  }
}
</script>
<style lang="scss">
.DestinationsTable {
  .q-input.number {
    width: 80px;
    margin-left: auto;
  }
  .holder-name-th {
    width: 100px;
  }
}
</style>
