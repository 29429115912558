<template>
  <div class="InstrumentPDF panel">
    <div :class="['panel-header', 'stock-title', `test-instrument-${instrument.id}-header`]">
      {{ instrument.viewName }}
    </div>
    <hr class="separator short" />
    <div class="panel-main">
      <div class="floating-list">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Instrument } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({})
export default class InstrumentPDF extends Vue {
  @Prop() instrument: Instrument;
}
</script>
<style lang="scss">
.InstrumentPDF {
  page-break-before: always;

  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  .separator.short {
    width: 49%;
  }

  .panel-header,
  .large,
  .medium {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .stock-title {
    font-size: 40px;
    font-weight: 200;
    line-height: 40px;
    color: #2d612c;
  }

  .panel-main .floating-list {
    width: 60%;
    margin: 0 auto 20px;
    text-align: center;
    page-break-inside: avoid;

    dl {
      width: auto;
      text-align: center;
      white-space: nowrap;
      display: inline-block;
      overflow: hidden;

      dt,
      dd {
        height: 18px;
        display: inline-block;
        margin: 0;
      }

      dt {
        padding-right: 5px;
        font-size: 14px;
        padding-top: 1px;
      }
      dd {
        padding-right: 15px;
        font-size: 16px;
      }
    }
  }
}
</style>
