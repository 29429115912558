<template>
  <tr class="position-row">
    <td></td>
    <td class="text-left wrap-text">
      <p>{{ position.owner.viewName }}</p>
      <p class="nationalid-small">{{ formatNationalId(position.owner) }}</p>
    </td>
    <td class="text-left wrap-text">
      <p>{{ position.investor && position.investor.viewName }}</p>
      <p class="nationalid-small">{{ formatNationalId(position.investor) }}</p>
    </td>
    <td>{{ position.custodianName }}</td>

    <td class="text-right">
      {{ formatNumber(position.quantity) }}
    </td>
    <td class="text-right">{{ formatNumber(position.amount) }} {{ position.instrument.rightsData.currency }}</td>
  </tr>
</template>
<script lang="ts">
import { Position } from '@shared/web/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({})
export default class PositionRow extends Vue {
  @Prop() position: Position;
}
</script>
