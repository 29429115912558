<template>
  <div>
    <div class="q-mt-xl q-mb-md text-h4" v-if="connectedCompaniesICanAdmin.length">
      Nedan visas Kapclearanslutna bolag där du är bolagsföreträdare eller utsedd administratör.
    </div>
    <q-spinner size="64" v-if="!isReady" />
    <div class="row tiles-row q-col-gutter-lg" v-if="isReady">
      <div
        class="col col-12 col-md-4"
        v-for="{ companyName, orgnr, countryCode } in connectedCompaniesICanAdmin"
        :key="orgnr"
      >
        <q-card>
          <div class="q-py-lg q-px-xs text-center text-black test-entity-card">
            <h4>{{ companyName }}</h4>
            <div class="q-my-sm">{{ myRolesInCompany(orgnr).join(', ') }}</div>
            <q-btn @click="goToCompanyPage(orgnr, countryCode)">Visa</q-btn>
          </div>
        </q-card>
      </div>
    </div>
    <div v-if="store.profile?.user?.personalNumber">
      <div class="q-mt-xl q-mb-md text-h4">
        Nedan visas bolag som du är bolagsföreträdare för men som ännu inte har anslutit sin investerarbok (aktiebok)
        till Kapclear.
      </div>
      <q-spinner size="64" v-if="!isReady" />
      <div class="row tiles-row q-col-gutter-lg" v-if="isReady">
        <div v-if="!nonConnectedCompanies.length">Inga företag hittades</div>
        <div
          class="col col-12 col-md-4 test-not-connected"
          v-for="{ companyName, orgnr } in nonConnectedCompanies"
          :key="orgnr"
        >
          <q-card>
            <div class="q-py-lg q-px-xs text-center text-black test-entity-card test-not-connected">
              <h4>{{ companyName }}</h4>
              <div class="q-my-sm">{{ myRolesInCompany(orgnr).join(', ') }}</div>
              <q-btn class="test-register-btn" v-if="iCanSignCompany(orgnr)" @click="goToRegisterPage(orgnr)">
                Anslut
              </q-btn>
            </div>
          </q-card>
        </div>
      </div>
    </div>
    <div v-if="store.profile?.user?.personalNumber" class="q-my-lg flex items-center">
      <p class="q-mr-md">Saknas det något bolag du företräder?</p>
      <q-btn @click="revalidate()" :loading="!isReady">klicka här</q-btn>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { sortBy, uniqBy } from 'lodash';
import axios from 'axios';
import { EntityAdmin } from '@shared/web/models';
import { CompanyEngagement, CompanyEngagementRole } from '@shared/types/roaring/Engagement';
import { findEntityAdminsForUser } from '@/models/entityAdmin/entityAdminService';
import { EntityAdminRole, EntityType } from '@shared/models/types';
import { hasSigningRole } from '@shared/utils/roaringRoles';
import { store } from '@shared/web/store';
import { User } from '@shared/models/modelTypes';

@Component
export default class EntitiesGrid extends Vue {
  ready = false;
  entityAdmins: EntityAdmin[] = [];
  issuerEntityAdmins: EntityAdmin[] = [];
  custodianEntityAdmins: EntityAdmin[] = [];
  engagements: CompanyEngagement[] = [];
  companies: { nationalId: string; countryCode: string; types: Array<string> }[] = [];

  get store() {
    return store;
  }

  get connectedCompaniesICanAdmin(): { companyName: string; orgnr: string; countryCode: string }[] {
    const connectedCompaniesIAmAdminAt = this.issuerEntityAdmins
      .filter(({ entity }) => this.companyHasConnectedType(entity))
      .map(({ entity }) => ({
        companyName: entity.viewName,
        orgnr: entity.nationalId,
        countryCode: entity.countryCode,
      }));

    const connectedCompaniesICouldAdmin = this.engagements
      // where I am not admin, but can sign
      .reduce((ack, { companyId: orgnr, roles, ...rest }) => {
        const entity = this.companies.find(({ nationalId }) => nationalId === orgnr);
        if (entity && !this.companyHasConnectedType(entity)) {
          return ack;
        }
        const isAdmin = connectedCompaniesIAmAdminAt.map(c => c.orgnr).includes(orgnr);
        if (isAdmin) {
          return ack;
        }
        if (this.hasSigningRole(roles) && this.isCompanyAlreadyConnected(orgnr)) {
          ack.push({ ...rest, orgnr, countryCode: entity.countryCode });
        }
        return ack;
      }, []);

    return uniqBy(
      [
        ...this.custodianEntityAdmins.map(({ entity }) => ({
          companyName: entity.viewName,
          orgnr: entity.nationalId,
          countryCode: entity.countryCode,
        })),
        ...sortBy([...connectedCompaniesIAmAdminAt, ...connectedCompaniesICouldAdmin], 'companyName'),
      ],
      'orgnr',
    );
  }

  get nonConnectedCompanies() {
    const companies = this.engagements
      .filter(({ companyId }) => !this.isCompanyAlreadyConnected(companyId))
      .map(({ companyName, companyId }) => ({ companyName, orgnr: companyId }));

    const companiesICanSign = companies.filter(c => this.iCanSignCompany(c.orgnr));
    const companiesICannotSign = companies.filter(c => !this.iCanSignCompany(c.orgnr));

    return [...sortBy(companiesICanSign, 'companyName'), ...sortBy(companiesICannotSign, 'companyName')];
  }

  async goToCompanyPage(nationalId, countryCode) {
    if (!this.amIAdminForOrgNr(nationalId)) {
      await axios.post(`/webapi/entityadmin/${nationalId}`);
    }
    await this.companyLandingPage(nationalId, countryCode);
  }

  async companyLandingPage(nationalId: string, countryCode: string) {
    const entityAdmin = this.entityAdmins.find(ea => ea.entity.nationalId === nationalId);
    if (!entityAdmin.entity.isIssuer) {
      if (entityAdmin.entity.isInsuranceCompany) {
        return this.$router.push(`/${countryCode}/${nationalId}/insurer`);
      }
      return this.$router.push(`/${countryCode}/${nationalId}/custodian`);
    }
    return this.$router.push(`/${countryCode}/${nationalId}/dashboard`);
  }

  async created() {
    this.entityAdmins = await findEntityAdminsForUser();
    this.custodianEntityAdmins = this.entityAdmins.filter(({ role }) => role === EntityAdminRole.CustodianAdmin);
    this.issuerEntityAdmins = this.entityAdmins.filter(({ role }) => role !== EntityAdminRole.CustodianAdmin);

    const user: User | undefined = store.profile?.user;
    if (user?.personalNumber) {
      await this.loadEngagements();
    }
    this.ready = true;
  }

  async fetchEngagements(refresh = false): Promise<{ engagements: CompanyEngagement[]; companies: any }> {
    return (await axios.get(`/webapi/engagement${refresh ? '/refresh' : ''}`)).data;
  }

  async loadEngagements(refresh = false) {
    const { engagements, companies } = await this.fetchEngagements(refresh);
    this.engagements = engagements;
    this.companies = companies;
  }

  get isReady() {
    return this.ready;
  }

  amIAdminForOrgNr(orgnr: string) {
    return [...this.issuerEntityAdmins, ...this.custodianEntityAdmins]
      .map(({ entity }) => entity.nationalId)
      .includes(orgnr);
  }

  myEngagementRolesInCompany(orgnr: string) {
    return this.engagements.find(({ companyId }) => companyId === orgnr)?.roles;
  }

  myRolesInCompany(orgnr: string) {
    const engagementRoles = this.myEngagementRolesInCompany(orgnr);

    if (engagementRoles) {
      return engagementRoles.map(role => role.roleName);
    }

    return ['Administratör'];
  }

  isCompanyAlreadyConnected(nationalId) {
    return this.companies.find(c => c.nationalId === nationalId && this.companyHasConnectedType(c));
  }

  companyHasConnectedType(company: { nationalId: string; types: Array<string> }) {
    if (!company.types) {
      return false;
    }
    const { types } = company;
    return (
      types.includes(EntityType.issuer) ||
      types.includes(EntityType.insuranceCompany) ||
      types.includes(EntityType.participant)
    );
  }

  iCanSignCompany(orgnr: string) {
    return this.hasSigningRole(this.myEngagementRolesInCompany(orgnr));
  }

  hasSigningRole(roles: CompanyEngagementRole[]) {
    return hasSigningRole(roles.map(r => r.roleCode));
  }

  async goToRegisterPage(nationalId) {
    await this.$router.push(`/registrerabolag/${nationalId}`);
  }

  async revalidate() {
    this.ready = false;
    const { engagements, companies } = await this.fetchEngagements(true);
    this.ready = true;
    if (JSON.stringify(engagements) === JSON.stringify(this.engagements)) {
      this.$q.dialog({
        title: 'Inga andra företag hittades',
        message: 'Kontakta kundservice om du behöver hjälp',
      });
    } else {
      this.engagements = engagements;
      this.companies = companies;
      this.$q.dialog({
        title: 'Dina engagemang har uppdaterats!',
        message: 'Kontakta kundservice om du behöver hjälp',
      });
    }
  }
}
</script>
<style lang="scss">
.tiles-row .q-card,
.tile {
  text-decoration: none;
  height: 100%;
  min-height: 130px;
  @media (min-width: 1024px) {
    min-height: 184px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
