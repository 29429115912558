<template>
  <q-page v-if="ready" class="RolesAdminPage">
    <h1>Bolagsföreträdare</h1>
    <RolesTable :entity="entity" />
    <div class="q-my-lg flex items-center">
      <p class="q-mr-md">Har styrelsen ändrats?</p>
      <q-btn @click="refreshRoles">Hämta från bolagsverket</q-btn>
    </div>
  </q-page>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import RolesTable from './RolesTable.vue';
import { findEntityWithRouteParams } from '@shared/web/entity/entityService';
import axios from 'axios';
import { Entity } from '@shared/web/models';

@Component({
  components: { RolesTable },
})
export default class RolesAdminPage extends Vue {
  entity: Entity | null = null;
  ready = false;

  async created() {
    await this.fetchInfo();
  }

  async fetchInfo() {
    this.ready = false;
    this.entity = await findEntityWithRouteParams(this.$route.params);
    this.ready = true;
  }

  rolesChanged(rolesA, rolesB) {
    const transform = roles => roles.map(({ person, type }) => [person?.id || person, type]);
    return JSON.stringify(transform(rolesA)) !== JSON.stringify(transform(rolesB));
  }

  async refreshRoles() {
    this.ready = false;
    const { data }: { data: Entity } = await axios.get(`/webapi/entity/${this.entity.id}/refresh-roles`);
    const changed = this.rolesChanged(this.entity.legalEntity.roles, data.legalEntity.roles);
    if (changed) {
      await this.fetchInfo();
    }
    this.ready = true;
    if (changed) {
      this.$q.dialog({
        title: 'Listan har uppdaterats!',
        message: 'Kontakta kundservice om du behöver hjälp',
      });
    } else {
      this.$q.dialog({
        title: 'Inga ändringar hittades',
        message: 'Kontakta kundservice om du behöver hjälp',
      });
    }
  }
}
</script>
<style lang="scss">
.RolesAdminPage {
  .step-btn {
    width: 200px;
  }
}
</style>
