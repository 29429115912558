<template>
  <tr class="holder-row">
    <td colspan="5">
      <router-link :to="instrument.issueInstructionUrl" v-if="instrument.issueInstructionUrl"
        >{{ instrument.viewName }}
      </router-link>
      <template v-else>{{ instrument.viewName }}</template>
    </td>

    <q-td class="text-right">
      {{ formatNumber(instrument.shareData.totalQuantity) }}
    </q-td>
  </tr>
</template>

<script lang="ts">
import { Instrument } from '@shared/web/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({})
export default class InstrumentRow extends Vue {
  @Prop() instrument: Instrument;
}
</script>
