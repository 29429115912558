<template>
  <tr class="InstrumentSourceRow">
    <td>
      {{ source.instrument.viewName }}
    </td>

    <td class="text-right marked-bg">
      {{ formatNumber(shareData.totalQuantity) }}
    </td>
    <td class="text-right marked-bg">
      {{ formatNumber(shareData.quotaValue) }}
    </td>
    <td class="text-right marked-bg">
      {{ formatNumber(shareData.totalCapital) }}
    </td>
    <td class="text-right">
      <template v-if="sourceValidation.splitFactor"> {{ sourceValidation.splitFactor }}:1 </template>
    </td>
    <td class="text-right">
      <template v-if="sourceValidation.splitFactor">
        {{ formatNumber(sourceValidation.newTotalQuantity) }}
      </template>
    </td>
    <td class="text-right">
      <template v-if="sourceValidation.splitFactor">{{ formatNumber(sourceValidation.newQuoteValue, 2) }}</template>
    </td>
    <td class="text-right">
      <template v-if="sourceValidation.splitFactor">
        {{ formatNumber(shareData.totalCapital) }}
      </template>
    </td>
  </tr>
</template>

<script lang="ts">
import { Instruction, ShareRegister, InstrumentSource } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';

@Component({})
export default class InstrumentSourceRow extends Vue {
  @Prop() source: InstrumentSource;
  @Prop() shareRegister: ShareRegister;
  @Prop() instruction: Instruction;
  @Prop({ default: () => false }) readonly: boolean;
  @Prop() validator;

  get sourceValidation() {
    return this.validator.forSplitInstrumentSource(this.source.instrument);
  }

  get shareData() {
    return this.source.instrument.shareData.getHistoricVersion(this.shareRegister.settleDate);
  }
}
</script>
