<template>
  <tr class="RecentTradeEventRow">
    <td class="text-left">
      <p>{{ instrumentName }}</p>
    </td>
    <td class="text-left">
      <p>{{ type }}</p>
    </td>
    <td class="text-left">
      <template v-if="sourceOwner">
        <p>{{ sourceOwner.viewName }}</p>
        <p class="nationalid-small">{{ formatNationalId(sourceOwner) }}</p>
      </template>
    </td>
    <td class="text-left">
      <template v-if="destinationOwner">
        <p>{{ destinationOwner.viewName }}</p>
        <p class="nationalid-small">{{ formatNationalId(destinationOwner) }}</p>
        <div v-if="numberOfDestinations > 1">
          <a-btn-link icon="svguse:/svg-icons.svg#icon-add" color="secondary">
            {{ numberOfDestinations }} fler köpare
          </a-btn-link>
        </div>
      </template>
    </td>
    <td class="text-left">
      <p>{{ formatDay(instruction.settleDate) }}</p>
    </td>
    <td class="text-left">
      <p>{{ calculatedState }}</p>
    </td>
  </tr>
</template>
<script lang="ts">
import { Instruction } from '@/models';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { toInstructionStateLabel, toTransactionTypesLabel } from '@shared/common';

@Component({})
export default class RecentTradeEventRow extends Vue {
  @Prop() instruction: Instruction;

  get calculatedState() {
    return toInstructionStateLabel(this.instruction.calculatedState);
  }

  get type() {
    return toTransactionTypesLabel(this.instruction.type);
  }

  get numberOfDestinations() {
    return this.instruction.destinations.length;
  }

  get destinationOwner() {
    const { destinations } = this.instruction;
    if (destinations.length === 0) {
      return null;
    }
    return destinations[0].owner;
  }

  get instrumentName() {
    return this.instruction.tradeEvent.source.instrument?.viewName;
  }
  get sourceOwner() {
    return this.instruction.tradeEvent.source.owner;
  }
}
</script>
<style lang="scss">
.RecentTradeEventRow {
  .a-btn-link svg.q-icon {
    font-size: 9px;
    margin-left: -2px;
  }
}
</style>
