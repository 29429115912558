import shareRegisterValidator from '../models/instructionValidator/shareRegisterValidator';
import { ShareRegister } from '../models/modelTypes';

function isSameModel(a, b) {
  return (a == null && b == null) || a === b;
}

export function makeShareRegisterValidation(
  shareRegister: ShareRegister,
  positions = shareRegister.positions,
  skipIssuerDataValidation = false,
) {
  return shareRegisterValidator(
    shareRegister.entity,
    shareRegister.instruments,
    positions,
    isSameModel,
    shareRegister.settleDate,
    skipIssuerDataValidation,
  );
}
