<template>
  <div :class="['PositionSectionPDF', 'panel', `test-position-${position.id}`]">
    <fieldset class="panel-main">
      <legend align="center">Skuldpost</legend>
      <h3>
        {{ position.owner.viewName }}<span>({{ formatNationalId(position.owner) }})</span>
      </h3>
      <table border="0" cellpadding="15" width="100%" class="boxes">
        <tr>
          <td>
            <strong v-if="position.instrument.rightsData.distributeByValue"> Belopp </strong>
            <strong v-else> Antal </strong>
            <hr />
            <template v-if="position.instrument.rightsData.distributeByValue">
              {{ formatNumber(position.amount) }} {{ position.instrument.currencyCode }}
            </template>
            <template v-else> {{ formatNumber(position.quantity) }} st. </template>
          </td>
        </tr>
      </table>
      <div class="column-list">
        <dl v-if="address" class="w-full">
          <dt>Adress:</dt>
          <dd>
            {{ address.address1 }}<br v-if="address.address2" />{{ address.address2 }} <br />{{ address.zip }}
            {{ address.city }}<br />{{ countryName(address.countryCode) }}<br />
            <template v-if="ownerContactInfo.phone"> {{ ownerContactInfo.phone }}<br /> </template>
            <template v-if="ownerContactInfo.email">
              {{ ownerContactInfo.email }}
            </template>
          </dd>
        </dl>
        <dl>
          <dt>Transaktion:</dt>
          <dd>{{ toTransactionTypesLabel(lastHistoryEntry.type) }}</dd>
          <dt>Införd i Skuldboken:</dt>
          <dd>{{ formatDay(lastHistoryEntry.settleDate) }}</dd>
        </dl>
        <dl v-if="position.investor">
          <dt>Investerare:</dt>
          <dd>{{ position.investor.viewName }}</dd>
          <dd class="nationalid-small">{{ formatNationalId(position.investor) }}</dd>
        </dl>
        <dl>
          <dt v-if="position.custodianName">Förvar:</dt>
          <dd>{{ position.custodianName }}</dd>
          <dt v-if="position.custodianAccountNumber">Depånummer:</dt>
          <dd>{{ position.custodianAccountNumber }}</dd>
        </dl>
      </div>

      <table border="0" cellpadding="0" width="100%" class="prev-owners" v-if="history.length">
        <thead>
          <caption>
            Tidigare ägare:
          </caption>
          <tr>
            <th>Ägare</th>
            <th>Transaktion</th>
            <th>Införd i Skuldboken</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(entry, index) in history" :key="index">
            <td>{{ entry.owner.viewName }}</td>
            <td>{{ toTransactionTypesLabel(entry.type) }}</td>
            <td>{{ formatDay(entry.settleDate) }}</td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  </div>
</template>
<script lang="ts">
// @ts-nocheck
import { Position, ShareRegister, IssuerInfo } from '@/models';
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { toTransactionTypesLabel } from '@shared/common';
import { toReservationLabel } from '@shared/common';

@Component({})
export default class PositionDebtPDF extends Vue {
  @Prop() position: Position;
  @Prop() shareRegister: ShareRegister;
  @Prop() index: number;
  @Prop() isComplete: boolean;
  @Prop() issuerInfo: IssuerInfo;

  toTransactionTypesLabel(label) {
    return toTransactionTypesLabel(label, true);
  }
  toReservationLabel(val) {
    return toReservationLabel(val);
  }

  get lastHistoryEntry() {
    return this.positionHistory[0];
  }

  get history() {
    return this.positionHistory.slice(1);
  }

  get ownerContactInfo() {
    return this.issuerInfo.getContactInfo(this.position.owner);
  }

  get address() {
    return this.position.owner.addresses[0];
  }

  get investor() {
    return this.position.investor;
  }

  get positionHistory() {
    const positionHistory = this.shareRegister.positionHistoryList.find(
      positionHistory => positionHistory.position === this.position.id,
    );
    return positionHistory.history;
  }
}
</script>
<style type="text/scss">
.SavePDF .panel .column-list dl.w-full {
  grid-column: 1 / -1;
}
</style>
