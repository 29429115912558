import { Entity } from '@/models';
import { EntitySearchQuery } from '@shared/web/models/entity/EntitySearchQuery';
import { modelHandler, newModel, serialize } from '../base';
import { asModelId } from '@/utils';
import axios from 'axios';
import store, { addModel } from '@/store';
import { findCurrentEntityAdmin, clearEntityAdminCache } from '@/models/entityAdmin/entityAdminService';
import { findEntityWithCountryCode } from '@shared/web/entity/entityService';

const baseUrl = '/webapi/entity';
const { findModel, saveModel } = modelHandler(Entity, baseUrl);

export async function loadEntity(id: Entity | string) {
  return findEntity(id, true);
}

export async function findEntity(id: Entity | string, forceUpdate = false) {
  const entity = await findModel(asModelId(id), forceUpdate);
  if (entity.isSE) {
    addModel(entity, entity.nationalId);
  }
  return entity;
}

export async function searchEntity(searchQuery: EntitySearchQuery) {
  const { data } = await axios.post('/webapi/entity/search-autocomplete', serialize(searchQuery));
  return Entity.newModels(data, true);
}
let custodians = null;
export async function findCustodians() {
  if (custodians == null) {
    const { data } = await axios.post('/webapi/entity/custodians');
    custodians = Entity.newModels(data, true).sort((a, b) => {
      return a.viewName > b.viewName ? 1 : -1;
    });
  }
  return custodians;
}

export async function registerEntity({ nationalId, email, phone }) {
  const { data } = await axios.post(baseUrl + '/register', { nationalId, email, phone });
  return newModel(data, Entity, true);
}

export async function lookupEntity(query) {
  const { data } = await axios.post(baseUrl + '/lookup', query);
  return newModel(data, Entity, true);
}

export async function saveEntity(entity: Entity) {
  const { model } = await saveModel(entity);

  return model;
}

type ActiveEntityInfo = { countryCode: string; nationalId: string } | false;
export async function setActiveEntity(entityInfo: ActiveEntityInfo) {
  if (entityInfo) {
    const activeEntity = await findEntityWithCountryCode(entityInfo.countryCode, entityInfo.nationalId);
    if (activeEntity.id !== store.activeEntity?.id) {
      clearEntityAdminCache();
    }
    const entityAdmin = await findCurrentEntityAdmin(activeEntity.id);
    Object.assign(store, { activeEntity, entityAdmin });
    return { activeEntity, entityAdmin };
  } else {
    store.activeEntity = null;
    store.entityAdmin = null;
  }
}
