<template>
  <div class="DebtsPositionTable">
    <div class="export-link-box">
      <ExportLinks v-if="isDownloadAble">
        <ExportExcel :exporter="excelExporter" />
        <PdfDownload
          :entity="shareRegister.entity"
          instrumentProduct="KAPCLEAR"
          :settleDate="shareRegister.settleDate"
          documentType="complete"
          :category="InstrumentCategory.DEBT"
        >
          Skuldbok / Pdf
        </PdfDownload>
      </ExportLinks>
    </div>
    <q-markup-table flat class="standard-table position-table q-mt-xl" separator="horizontal">
      <thead>
        <tr>
          <th class="text-left">Värdepapper</th>
          <th class="text-left">Ägare</th>
          <th class="text-left">Försäkringsägare</th>
          <th class="text-left">Förvar</th>
          <th class="text-right">Antal</th>
          <th class="text-right">Nom Belopp</th>
        </tr>
      </thead>
      <tbody class="two-row">
        <template v-for="{ holder, positions } in positionGrouping" :key="holder.id">
          <InstrumentRow :instrument="holder" :shareRegister="shareRegister" />
          <template v-for="position in positions" :key="position.id">
            <PositionRow :position="position" />
          </template>
        </template>
      </tbody>
    </q-markup-table>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { ShareRegister, InstrumentCategory, InstructionState } from '@shared/web/models';
import PositionRow from './PositionRow.vue';
import InstrumentRow from './InstrumentRow.vue';
import { positionGrouping } from '@shared/shareRegister/utils';
import ExportLinks from '@shared/components/ExportLinks.vue';
import PdfDownload from '@shared/components/PdfDownload.vue';
import ExportExcel from '@shared/views/excel/ExportExcel.vue';

@Component({
  components: { PositionRow, InstrumentRow, ExportLinks, PdfDownload, ExportExcel },
})
export default class RightsPositionTable extends Vue {
  @Prop() shareRegister: ShareRegister;

  InstrumentCategory: typeof InstrumentCategory = InstrumentCategory;

  get positionGrouping() {
    return positionGrouping(this.shareRegister.instruments, this.shareRegister.positions);
  }

  get isDownloadAble() {
    const state = this.shareRegister.entity.issuerData.shareRegisterState;
    return state === InstructionState.EXECUTED;
  }

  async excelExporter() {
    const { entity } = this.shareRegister;
    const name = `${entity.viewName.substring(0, 30)}`;

    const records = this.positionGrouping
      .map(({ positions }) =>
        positions.map(pos => ({
          Värdepapper: pos.instrument.viewName,
          Ägare: pos.owner.viewName,
          'Ägare person/orgnr.': pos.owner.nationalId,
          Försäkringsägare: pos.investor?.viewName,
          'Försäkringsägare person/orgnr.': pos.investor?.viewName,
          Förvar: pos.custodian?.viewName,
          Depå: pos.custodianAccountNumber,
          Antal: pos.quantity,
          Belopp: pos.amount,
          Valuta: pos.instrument.rightsData?.currency,
        })),
      )
      .flat();

    return {
      name,
      records,
    };
  }
}
</script>
<style lang="scss">
.DebtsPositionTable {
  position: relative;
  .export-link-box {
    margin-top: 30px;
  }
  .position-table {
    margin-top: 4px;
  }
}
</style>
