import { Position } from '..';
import { formatNumber } from '@shared/utils';
import { sumBy } from 'lodash';
export const toCapitalShare = (position: Position): number => {
  if (!position.instrument.isShare) {
    return;
  }
  const {
    quantity,
    entity: { issuerData },
    instrument: { shareData },
  } = position;
  const positionValue = (shareData.totalCapital / shareData.totalQuantity) * quantity;
  const capitalShare = positionValue / issuerData.totalCapital;

  return capitalShare;
};

export function toVotingPowerQuota(position: Position): number {
  if (!position.instrument.isShare) {
    return;
  }
  const {
    quantity,
    entity: {
      issuerData: { totalVotingPower },
    },
    instrument: {
      shareData: { votingPower },
    },
  } = position;
  if (totalVotingPower && votingPower) {
    return (quantity * votingPower) / totalVotingPower;
  }
  return null;
}

export function formatPositionValue(position: Position) {
  if (position.instrument.isDebt) {
    return `${formatNumber(position.amount)} ${position.instrument.rightsData.currency}`;
  }
  return formatNumber(position.quantity);
}

export function positionsCapitalShare(positions: Position[]) {
  return sumBy(positions, position => toCapitalShare(position));
}

export function positionsPowerQuota(positions: Position[]) {
  return sumBy(positions, position => toVotingPowerQuota(position));
}
