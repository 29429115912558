import axios from 'axios';
import { isBackOffice } from '@shared/web/utils';
import { InvestmentOffer } from '@shared/models/types';

const baseUrl = `/${isBackOffice() ? 'api' : 'webapi'}/mail`;

export async function sendRoleWelcomeMail(entity: string, person: string, email: string) {
  await axios.post(baseUrl + '/welcome', { entity, person, email });
  return;
}

export async function resendEmailToInvestor(issuerName, email, investorName) {
  await axios.post(baseUrl + '/resend-welcome', { issuerName, email, investorName });
  return;
}

export async function sendInvestmentOfferMail(investmentOffer: InvestmentOffer) {
  return await axios.post(baseUrl + '/invesment-offer', investmentOffer);
}
