<template>
  <input type="file" id="test-input-file" @change="onFileChange" class="input-file" ref="input" multiple="true" />
</template>
<script lang="ts">
import { Component, Vue, Prop, Ref } from 'vue-facing-decorator';
import { uploadFiles } from '@/models/document/fileHandler';
import { Instruction } from '@/models';

@Component({})
export default class InputFile extends Vue {
  @Prop() instruction: Instruction;
  @Ref() readonly input;

  onFileChange(ev) {
    uploadFiles(ev, this.instruction);
  }
}
</script>
<style lang="scss">
.input-file {
  opacity: 0;
  width: 0;
}
</style>
