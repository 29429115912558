<template>
  <div class="CoverPagePDF">
    <div class="panel" style="height: 120px; margin-top: 80px">
      <div class="large test-pdf-header">{{ title }}</div>
    </div>
    <h2 :key="subtitle" v-for="subtitle of subtitles" class="subtitle">{{ subtitle }}</h2>
    <div>
      <table class="q-mx-auto q-mb-lg">
        <tbody>
          <tr>
            <td class="text-right text-bold">Per datum:</td>
            <td>{{ format(shareRegister.settleDate, 'yyyy-MM-dd') }}</td>
          </tr>
          <tr>
            <td class="text-right text-bold">Utskriven:</td>
            <td>{{ format(new Date(), 'yyyy-MM-dd') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <slot></slot>
  </div>
</template>
<script lang="ts">
// @ts-nocheck
import { Component, Vue, Prop } from 'vue-facing-decorator';
import { ShareRegister } from '@shared/models/modelTypes';
import { format } from 'date-fns';

@Component({})
export default class CoverPagePDF extends Vue {
  @Prop() title;
  @Prop() subtitle: string | Array<string>;
  @Prop() shareRegister: ShareRegister;

  format(date, form) {
    return format(date, form);
  }

  get subtitles() {
    return this.subtitle instanceof Array ? this.subtitle : [this.subtitle];
  }
}
</script>

<style lang="scss">
@media print {
  .MessageBanner {
    display: none;
  }
}

.CoverPagePDF {
  .large {
    font-size: 97px;
    font-weight: 200;
    height: 72px;
    letter-spacing: 1px;
    color: #2d612c;
  }

  .panel-header,
  .large,
  .medium {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
    color: black;
    font-weight: bold;
    &:not(:first-of-type) {
      font-weight: medium;
    }
  }

  .panel {
    width: 100%;

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    .logo {
      height: 80px;
      width: 140px;
      svg {
        height: 100%;
        width: 64%;
      }
      svg:first-child {
        width: 32%;
        padding-right: 10px;
      }
    }
  }
}
</style>
